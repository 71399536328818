.content-bx{
	&.style-1{
		padding: 30px;
		height: 100%;
		border-radius: var(--border-radius-base);
		display: flex;
		flex-direction: column;
		justify-content: center;
		@include transitionMedium;
		
		&:hover{
			transform: translateY(-10px);
		}
	}
	
	&.style-2{
		.inner-content{
			padding-left: 70px;
		}
		@include respond('tab-land'){
			.inner-content{
				padding-left: 0;
			}
		}
	}
}