@charset "UTF-8";
/*
    =========================
	Template Name 	 : Akcel
	Author			 : DexignZone
	Version			 : 1.0
	Author Portfolio : https://themeforest.net/user/dexignzone/portfolio
	=========================
	
	[Table of Contents] 

	=== SCSS Start ===
	
    1. Abstract
		1.1 abstract.scss
		1.2 mixins.scss
		1.3 variable.scss
		
    2. Base
		2.1 base.scss
		2.2 custom-grid.scss
		2.3 fonts.scss
		2.4 preloader.scss
		2.5 reset.scss
		2.6 shortcode.scss
		
    3. Components
		3.1 About
		3.2 Blog Post
		3.3 Iconbox
		3.4 Main Slider
		3.5 Portfolio
		3.6 Section Head
		3.7 Team
		3.8 Testimonials
		3.9 Other scss File
		
    4. Layout
		4.1 Banner
		4.2 Footer
		4.3 Header
		4.4 Theme
		4.5 Other scss File
		
    5. Pages
		5.1 index.scss
		5.2 index2.scss
		5.3 blog-details.scss
		5.4 coming-soon.scss
		5.5 contact-us.scss
		5.6 error-404.scss
		5.7 fundraiser-details.scss
		5.8 project.scss
		5.9 under-maintenance.scss
		
	=== SCSS eND ===



  // header menu hide size line -16220
*/
/*=== Abstract ===*/
@import url("../icons/line-awesome/css/line-awesome.min.css");
@import url("../icons/font-awesome/css/all.min.css");
@import url("../icons/flaticon/flaticon.css");
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Playfair+Display:wght@400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
:root {
  /* --primary: #1b8271; */
  --primary-style-2-lifemember: #43a2dd6b;
  --primary: #416bdb;
  --secondary: #ff7468;
  --gray: #f3f4f8;
  --primary-hover: #2e7bc7;
  --secondary-hover: #f64f40;
  --primary-dark: #ea4619;
  --rgba-secondary-1: rgba(255, 116, 104, 0.1);
  --rgba-primary-1: rgba(27, 130, 113, 0.1);
  --rgba-primary-2: rgba(27, 130, 113, 0.2);
  --rgba-primary-3: rgba(27, 130, 113, 0.3);
  --rgba-primary-4: rgba(27, 130, 113, 0.4);
  --rgba-primary-5: rgba(27, 130, 113, 0.5);
  --rgba-primary-6: rgba(27, 130, 113, 0.6);
  --rgba-primary-7: rgba(27, 130, 113, 0.7);
  --rgba-primary-8: rgba(27, 130, 113, 0.8);
  --rgba-primary-9: rgba(27, 130, 113, 0.9);
  --font-family-base: Poppins, sans-serif;
  --font-family-title: Poppins, sans-serif;
  --border-radius-base: 6px;
  --title: #071c35;
}

/*=== Bootstrap ===*/
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #1b8271;
  --bs-secondary: #ff7468;
  --bs-success: #13c24d;
  --bs-info: #00aeff;
  --bs-warning: #fea500;
  --bs-danger: #ff0003;
  --bs-light: #faf8f2;
  --bs-dark: #050d36;
  --bs-primary-rgb: 27, 130, 113;
  --bs-secondary-rgb: 255, 116, 104;
  --bs-success-rgb: 19, 194, 77;
  --bs-info-rgb: 0, 174, 255;
  --bs-warning-rgb: 254, 165, 0;
  --bs-danger-rgb: 255, 0, 3;
  --bs-light-rgb: 250, 248, 242;
  --bs-dark-rgb: 5, 13, 54;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 119, 119, 119;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: Poppins, sans-serif;
  --bs-body-font-size: 0.9375rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #777;
  --bs-body-bg: #ffffff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: 'Noto Sans Tamil', sans-serif;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #050d36;
}

h1,
.h1 {
  font-size: calc(1.3875rem + 1.65vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.625rem;
  }
}

h2,
.h2 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2.25rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.875rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #1b8271;
  text-decoration: underline;
}
a:hover {
  color: #16685a;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.text-justify,
p {
  text-align: justify;
  text-justify: inter-word;
}

code {
  font-size: 0.875em;
  color: #ff5e78;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.75rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #aeaed5;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.17188rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.17188rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1170px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #777;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #777;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #777;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #777;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d1e6e3;
  --bs-table-striped-bg: #c7dbd8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bccfcc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d5d2;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bccfcc;
}

.table-secondary {
  --bs-table-bg: #ffe3e1;
  --bs-table-striped-bg: #f2d8d6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6cccb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd2d0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6cccb;
}

.table-success {
  --bs-table-bg: #d0f3db;
  --bs-table-striped-bg: #c6e7d0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbdbc5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c0e1cb;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bbdbc5;
}

.table-info {
  --bs-table-bg: #ccefff;
  --bs-table-striped-bg: #c2e3f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d7e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdddec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d7e6;
}

.table-warning {
  --bs-table-bg: #ffedcc;
  --bs-table-striped-bg: #f2e1c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d5b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdbbd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d5b8;
}

.table-danger {
  --bs-table-bg: #ffcccd;
  --bs-table-striped-bg: #f2c2c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6b8b9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecbdbe;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6b8b9;
}

.table-light {
  --bs-table-bg: #faf8f2;
  --bs-table-striped-bg: #eeece6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1dfda;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7e5e0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1dfda;
}

.table-dark {
  --bs-table-bg: #050d36;
  --bs-table-striped-bg: #121940;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1e254a;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #181f45;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #1e254a;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.17188rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.82031rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #aeaed5;
}

.form-control,
.default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .dropdown-toggle.wp-block-button__link,
.default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .dropdown-toggle.wp-block-button__link,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select,
.comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p textarea {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #777;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control,
  .default-select.style-2 .btn.dropdown-toggle,
  .default-select.style-2 .dropdown-toggle.wp-block-button__link,
  .default-select.style-3 .btn.dropdown-toggle,
  .default-select.style-3 .dropdown-toggle.wp-block-button__link,
  .wp-block-categories-dropdown select,
  .wp-block-archives-dropdown select,
  .comment-respond .comment-form p.comment-form-author input,
  .comment-respond .comment-form p.comment-form-email input,
  .comment-respond .comment-form p.comment-form-url input,
  .comment-respond .comment-form p textarea {
    transition: none;
  }
}
.form-control[type="file"],
.default-select.style-2 .btn.dropdown-toggle[type="file"],
.default-select.style-2 .dropdown-toggle.wp-block-button__link[type="file"],
.default-select.style-3 .btn.dropdown-toggle[type="file"],
.default-select.style-3 .dropdown-toggle.wp-block-button__link[type="file"],
.wp-block-categories-dropdown select[type="file"],
.wp-block-archives-dropdown select[type="file"],
.comment-respond .comment-form p.comment-form-author input[type="file"],
.comment-respond .comment-form p.comment-form-email input[type="file"],
.comment-respond .comment-form p.comment-form-url input[type="file"],
.comment-respond .comment-form p textarea[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]),
.default-select.style-2
  .btn.dropdown-toggle[type="file"]:not(:disabled):not([readonly]),
.default-select.style-2
  .dropdown-toggle.wp-block-button__link[type="file"]:not(:disabled):not(
    [readonly]
  ),
.default-select.style-3
  .btn.dropdown-toggle[type="file"]:not(:disabled):not([readonly]),
.default-select.style-3
  .dropdown-toggle.wp-block-button__link[type="file"]:not(:disabled):not(
    [readonly]
  ),
.wp-block-categories-dropdown
  select[type="file"]:not(:disabled):not([readonly]),
.wp-block-archives-dropdown select[type="file"]:not(:disabled):not([readonly]),
.comment-respond
  .comment-form
  p.comment-form-author
  input[type="file"]:not(:disabled):not([readonly]),
.comment-respond
  .comment-form
  p.comment-form-email
  input[type="file"]:not(:disabled):not([readonly]),
.comment-respond
  .comment-form
  p.comment-form-url
  input[type="file"]:not(:disabled):not([readonly]),
.comment-respond
  .comment-form
  p
  textarea[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus,
.default-select.style-2 .btn.dropdown-toggle:focus,
.default-select.style-2 .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3 .btn.dropdown-toggle:focus,
.default-select.style-3 .dropdown-toggle.wp-block-button__link:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus,
.comment-respond .comment-form p.comment-form-author input:focus,
.comment-respond .comment-form p.comment-form-email input:focus,
.comment-respond .comment-form p.comment-form-url input:focus,
.comment-respond .comment-form p textarea:focus {
  color: #777;
  background-color: #ffffff;
  border-color: #8dc1b8;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(27, 130, 113, 0.25);
}
.form-control::-webkit-date-and-time-value,
.default-select.style-2 .btn.dropdown-toggle::-webkit-date-and-time-value,
.default-select.style-2
  .dropdown-toggle.wp-block-button__link::-webkit-date-and-time-value,
.default-select.style-3 .btn.dropdown-toggle::-webkit-date-and-time-value,
.default-select.style-3
  .dropdown-toggle.wp-block-button__link::-webkit-date-and-time-value,
.wp-block-categories-dropdown select::-webkit-date-and-time-value,
.wp-block-archives-dropdown select::-webkit-date-and-time-value,
.comment-respond
  .comment-form
  p.comment-form-author
  input::-webkit-date-and-time-value,
.comment-respond
  .comment-form
  p.comment-form-email
  input::-webkit-date-and-time-value,
.comment-respond
  .comment-form
  p.comment-form-url
  input::-webkit-date-and-time-value,
.comment-respond .comment-form p textarea::-webkit-date-and-time-value {
  height: 1.6em;
}
.form-control::placeholder,
.default-select.style-2 .btn.dropdown-toggle::placeholder,
.default-select.style-2 .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3 .btn.dropdown-toggle::placeholder,
.default-select.style-3 .dropdown-toggle.wp-block-button__link::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder,
.comment-respond .comment-form p.comment-form-author input::placeholder,
.comment-respond .comment-form p.comment-form-email input::placeholder,
.comment-respond .comment-form p.comment-form-url input::placeholder,
.comment-respond .comment-form p textarea::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.default-select.style-2 .btn.dropdown-toggle:disabled,
.default-select.style-2 .dropdown-toggle.wp-block-button__link:disabled,
.default-select.style-3 .btn.dropdown-toggle:disabled,
.default-select.style-3 .dropdown-toggle.wp-block-button__link:disabled,
.wp-block-categories-dropdown select:disabled,
.wp-block-archives-dropdown select:disabled,
.comment-respond .comment-form p.comment-form-author input:disabled,
.comment-respond .comment-form p.comment-form-email input:disabled,
.comment-respond .comment-form p.comment-form-url input:disabled,
.comment-respond .comment-form p textarea:disabled,
.form-control[readonly],
.default-select.style-2 .btn.dropdown-toggle[readonly],
.default-select.style-2 .dropdown-toggle.wp-block-button__link[readonly],
.default-select.style-3 .btn.dropdown-toggle[readonly],
.default-select.style-3 .dropdown-toggle.wp-block-button__link[readonly],
.wp-block-categories-dropdown select[readonly],
.wp-block-archives-dropdown select[readonly],
.comment-respond .comment-form p.comment-form-author input[readonly],
.comment-respond .comment-form p.comment-form-email input[readonly],
.comment-respond .comment-form p.comment-form-url input[readonly],
.comment-respond .comment-form p textarea[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button,
.default-select.style-2 .btn.dropdown-toggle::file-selector-button,
.default-select.style-2
  .dropdown-toggle.wp-block-button__link::file-selector-button,
.default-select.style-3 .btn.dropdown-toggle::file-selector-button,
.default-select.style-3
  .dropdown-toggle.wp-block-button__link::file-selector-button,
.wp-block-categories-dropdown select::file-selector-button,
.wp-block-archives-dropdown select::file-selector-button,
.comment-respond
  .comment-form
  p.comment-form-author
  input::file-selector-button,
.comment-respond .comment-form p.comment-form-email input::file-selector-button,
.comment-respond .comment-form p.comment-form-url input::file-selector-button,
.comment-respond .comment-form p textarea::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #777;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button,
  .default-select.style-2 .btn.dropdown-toggle::file-selector-button,
  .default-select.style-2
    .dropdown-toggle.wp-block-button__link::file-selector-button,
  .default-select.style-3 .btn.dropdown-toggle::file-selector-button,
  .default-select.style-3
    .dropdown-toggle.wp-block-button__link::file-selector-button,
  .wp-block-categories-dropdown select::file-selector-button,
  .wp-block-archives-dropdown select::file-selector-button,
  .comment-respond
    .comment-form
    p.comment-form-author
    input::file-selector-button,
  .comment-respond
    .comment-form
    p.comment-form-email
    input::file-selector-button,
  .comment-respond .comment-form p.comment-form-url input::file-selector-button,
  .comment-respond .comment-form p textarea::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button,
.default-select.style-2
  .btn.dropdown-toggle:hover:not(:disabled):not(
    [readonly]
  )::file-selector-button,
.default-select.style-2
  .dropdown-toggle.wp-block-button__link:hover:not(:disabled):not(
    [readonly]
  )::file-selector-button,
.default-select.style-3
  .btn.dropdown-toggle:hover:not(:disabled):not(
    [readonly]
  )::file-selector-button,
.default-select.style-3
  .dropdown-toggle.wp-block-button__link:hover:not(:disabled):not(
    [readonly]
  )::file-selector-button,
.wp-block-categories-dropdown
  select:hover:not(:disabled):not([readonly])::file-selector-button,
.wp-block-archives-dropdown
  select:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-respond
  .comment-form
  p.comment-form-author
  input:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-respond
  .comment-form
  p.comment-form-email
  input:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-respond
  .comment-form
  p.comment-form-url
  input:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-respond
  .comment-form
  p
  textarea:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button,
.default-select.style-2 .btn.dropdown-toggle::-webkit-file-upload-button,
.default-select.style-2
  .dropdown-toggle.wp-block-button__link::-webkit-file-upload-button,
.default-select.style-3 .btn.dropdown-toggle::-webkit-file-upload-button,
.default-select.style-3
  .dropdown-toggle.wp-block-button__link::-webkit-file-upload-button,
.wp-block-categories-dropdown select::-webkit-file-upload-button,
.wp-block-archives-dropdown select::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-author
  input::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-email
  input::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-url
  input::-webkit-file-upload-button,
.comment-respond .comment-form p textarea::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #777;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button,
  .default-select.style-2 .btn.dropdown-toggle::-webkit-file-upload-button,
  .default-select.style-2
    .dropdown-toggle.wp-block-button__link::-webkit-file-upload-button,
  .default-select.style-3 .btn.dropdown-toggle::-webkit-file-upload-button,
  .default-select.style-3
    .dropdown-toggle.wp-block-button__link::-webkit-file-upload-button,
  .wp-block-categories-dropdown select::-webkit-file-upload-button,
  .wp-block-archives-dropdown select::-webkit-file-upload-button,
  .comment-respond
    .comment-form
    p.comment-form-author
    input::-webkit-file-upload-button,
  .comment-respond
    .comment-form
    p.comment-form-email
    input::-webkit-file-upload-button,
  .comment-respond
    .comment-form
    p.comment-form-url
    input::-webkit-file-upload-button,
  .comment-respond .comment-form p textarea::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.default-select.style-2
  .btn.dropdown-toggle:hover:not(:disabled):not(
    [readonly]
  )::-webkit-file-upload-button,
.default-select.style-2
  .dropdown-toggle.wp-block-button__link:hover:not(:disabled):not(
    [readonly]
  )::-webkit-file-upload-button,
.default-select.style-3
  .btn.dropdown-toggle:hover:not(:disabled):not(
    [readonly]
  )::-webkit-file-upload-button,
.default-select.style-3
  .dropdown-toggle.wp-block-button__link:hover:not(:disabled):not(
    [readonly]
  )::-webkit-file-upload-button,
.wp-block-categories-dropdown
  select:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.wp-block-archives-dropdown
  select:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-author
  input:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-email
  input:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p.comment-form-url
  input:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-respond
  .comment-form
  p
  textarea:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: #777;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control,
.default-select.style-2 textarea.btn.dropdown-toggle,
.default-select.style-2 textarea.dropdown-toggle.wp-block-button__link,
.default-select.style-3 textarea.btn.dropdown-toggle,
.default-select.style-3 textarea.dropdown-toggle.wp-block-button__link,
.comment-respond .comment-form p textarea {
  min-height: calc(1.6em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.6em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.6em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  height: 55px;
  padding: 10px 20px;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  color: #777;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: var(--border-radius-base);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8dc1b8;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(27, 130, 113, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #777;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1rem;
  height: 1rem;
  margin-top: 0.3em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8dc1b8;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(27, 130, 113, 0.25);
}
.form-check-input:checked {
  background-color: #1b8271;
  border-color: #1b8271;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #1b8271;
  border-color: #1b8271;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238dc1b8'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check[disabled] + .wp-block-button__link,
.btn-check:disabled + .btn,
.btn-check:disabled + .wp-block-button__link {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(27, 130, 113, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(27, 130, 113, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1b8271;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #bbdad4;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1b8271;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #bbdad4;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.default-select.style-2 .form-floating > .btn.dropdown-toggle,
.default-select.style-2 .form-floating > .dropdown-toggle.wp-block-button__link,
.default-select.style-3 .form-floating > .btn.dropdown-toggle,
.default-select.style-3 .form-floating > .dropdown-toggle.wp-block-button__link,
.wp-block-categories-dropdown .form-floating > select,
.wp-block-archives-dropdown .form-floating > select,
.comment-respond .comment-form p.comment-form-author .form-floating > input,
.comment-respond .comment-form p.comment-form-email .form-floating > input,
.comment-respond .comment-form p.comment-form-url .form-floating > input,
.comment-respond .comment-form p .form-floating > textarea,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.default-select.style-2 .form-floating > .btn.dropdown-toggle,
.default-select.style-2 .form-floating > .dropdown-toggle.wp-block-button__link,
.default-select.style-3 .form-floating > .btn.dropdown-toggle,
.default-select.style-3 .form-floating > .dropdown-toggle.wp-block-button__link,
.wp-block-categories-dropdown .form-floating > select,
.wp-block-archives-dropdown .form-floating > select,
.comment-respond .comment-form p.comment-form-author .form-floating > input,
.comment-respond .comment-form p.comment-form-email .form-floating > input,
.comment-respond .comment-form p.comment-form-url .form-floating > input,
.comment-respond .comment-form p .form-floating > textarea {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.default-select.style-2 .form-floating > .btn.dropdown-toggle::placeholder,
.default-select.style-2
  .form-floating
  > .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3 .form-floating > .btn.dropdown-toggle::placeholder,
.default-select.style-3
  .form-floating
  > .dropdown-toggle.wp-block-button__link::placeholder,
.wp-block-categories-dropdown .form-floating > select::placeholder,
.wp-block-archives-dropdown .form-floating > select::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input::placeholder,
.comment-respond .comment-form p .form-floating > textarea::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus,
.default-select.style-2 .form-floating > .btn.dropdown-toggle:focus,
.default-select.style-2
  .form-floating
  > .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3 .form-floating > .btn.dropdown-toggle:focus,
.default-select.style-3
  .form-floating
  > .dropdown-toggle.wp-block-button__link:focus,
.wp-block-categories-dropdown .form-floating > select:focus,
.wp-block-archives-dropdown .form-floating > select:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:focus,
.comment-respond .comment-form p.comment-form-url .form-floating > input:focus,
.comment-respond .comment-form p .form-floating > textarea:focus,
.form-floating > .form-control:not(:placeholder-shown),
.default-select.style-2
  .form-floating
  > .btn.dropdown-toggle:not(:placeholder-shown),
.default-select.style-2
  .form-floating
  > .dropdown-toggle.wp-block-button__link:not(:placeholder-shown),
.default-select.style-3
  .form-floating
  > .btn.dropdown-toggle:not(:placeholder-shown),
.default-select.style-3
  .form-floating
  > .dropdown-toggle.wp-block-button__link:not(:placeholder-shown),
.wp-block-categories-dropdown .form-floating > select:not(:placeholder-shown),
.wp-block-archives-dropdown .form-floating > select:not(:placeholder-shown),
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:not(:placeholder-shown),
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:not(:placeholder-shown),
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input:not(:placeholder-shown),
.comment-respond
  .comment-form
  p
  .form-floating
  > textarea:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.default-select.style-2 .form-floating > .btn.dropdown-toggle:-webkit-autofill,
.default-select.style-2
  .form-floating
  > .dropdown-toggle.wp-block-button__link:-webkit-autofill,
.default-select.style-3 .form-floating > .btn.dropdown-toggle:-webkit-autofill,
.default-select.style-3
  .form-floating
  > .dropdown-toggle.wp-block-button__link:-webkit-autofill,
.wp-block-categories-dropdown .form-floating > select:-webkit-autofill,
.wp-block-archives-dropdown .form-floating > select:-webkit-autofill,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:-webkit-autofill,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:-webkit-autofill,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input:-webkit-autofill,
.comment-respond .comment-form p .form-floating > textarea:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.default-select.style-2 .form-floating > .btn.dropdown-toggle:focus ~ label,
.default-select.style-2
  .form-floating
  > .dropdown-toggle.wp-block-button__link:focus
  ~ label,
.default-select.style-3 .form-floating > .btn.dropdown-toggle:focus ~ label,
.default-select.style-3
  .form-floating
  > .dropdown-toggle.wp-block-button__link:focus
  ~ label,
.wp-block-categories-dropdown .form-floating > select:focus ~ label,
.wp-block-archives-dropdown .form-floating > select:focus ~ label,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:focus
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:focus
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input:focus
  ~ label,
.comment-respond .comment-form p .form-floating > textarea:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.default-select.style-2
  .form-floating
  > .btn.dropdown-toggle:not(:placeholder-shown)
  ~ label,
.default-select.style-2
  .form-floating
  > .dropdown-toggle.wp-block-button__link:not(:placeholder-shown)
  ~ label,
.default-select.style-3
  .form-floating
  > .btn.dropdown-toggle:not(:placeholder-shown)
  ~ label,
.default-select.style-3
  .form-floating
  > .dropdown-toggle.wp-block-button__link:not(:placeholder-shown)
  ~ label,
.wp-block-categories-dropdown
  .form-floating
  > select:not(:placeholder-shown)
  ~ label,
.wp-block-archives-dropdown
  .form-floating
  > select:not(:placeholder-shown)
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:not(:placeholder-shown)
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:not(:placeholder-shown)
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input:not(:placeholder-shown)
  ~ label,
.comment-respond
  .comment-form
  p
  .form-floating
  > textarea:not(:placeholder-shown)
  ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label,
.default-select.style-2
  .form-floating
  > .btn.dropdown-toggle:-webkit-autofill
  ~ label,
.default-select.style-2
  .form-floating
  > .dropdown-toggle.wp-block-button__link:-webkit-autofill
  ~ label,
.default-select.style-3
  .form-floating
  > .btn.dropdown-toggle:-webkit-autofill
  ~ label,
.default-select.style-3
  .form-floating
  > .dropdown-toggle.wp-block-button__link:-webkit-autofill
  ~ label,
.wp-block-categories-dropdown .form-floating > select:-webkit-autofill ~ label,
.wp-block-archives-dropdown .form-floating > select:-webkit-autofill ~ label,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-floating
  > input:-webkit-autofill
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-floating
  > input:-webkit-autofill
  ~ label,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-floating
  > input:-webkit-autofill
  ~ label,
.comment-respond
  .comment-form
  p
  .form-floating
  > textarea:-webkit-autofill
  ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.default-select.style-2 .input-group > .btn.dropdown-toggle,
.default-select.style-2 .input-group > .dropdown-toggle.wp-block-button__link,
.default-select.style-3 .input-group > .btn.dropdown-toggle,
.default-select.style-3 .input-group > .dropdown-toggle.wp-block-button__link,
.wp-block-categories-dropdown .input-group > select,
.wp-block-archives-dropdown .input-group > select,
.comment-respond .comment-form p.comment-form-author .input-group > input,
.comment-respond .comment-form p.comment-form-email .input-group > input,
.comment-respond .comment-form p.comment-form-url .input-group > input,
.comment-respond .comment-form p .input-group > textarea,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.default-select.style-2 .input-group > .btn.dropdown-toggle:focus,
.default-select.style-2
  .input-group
  > .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3 .input-group > .btn.dropdown-toggle:focus,
.default-select.style-3
  .input-group
  > .dropdown-toggle.wp-block-button__link:focus,
.wp-block-categories-dropdown .input-group > select:focus,
.wp-block-archives-dropdown .input-group > select:focus,
.comment-respond .comment-form p.comment-form-author .input-group > input:focus,
.comment-respond .comment-form p.comment-form-email .input-group > input:focus,
.comment-respond .comment-form p.comment-form-url .input-group > input:focus,
.comment-respond .comment-form p .input-group > textarea:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn,
.input-group .wp-block-button__link {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus,
.input-group .wp-block-button__link:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #777;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.default-select.style-2 .input-group-lg > .btn.dropdown-toggle,
.default-select.style-2
  .input-group-lg
  > .dropdown-toggle.wp-block-button__link,
.default-select.style-3 .input-group-lg > .btn.dropdown-toggle,
.default-select.style-3
  .input-group-lg
  > .dropdown-toggle.wp-block-button__link,
.wp-block-categories-dropdown .input-group-lg > select,
.wp-block-archives-dropdown .input-group-lg > select,
.comment-respond .comment-form p.comment-form-author .input-group-lg > input,
.comment-respond .comment-form p.comment-form-email .input-group-lg > input,
.comment-respond .comment-form p.comment-form-url .input-group-lg > input,
.comment-respond .comment-form p .input-group-lg > textarea,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.input-group-lg > .wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}

.input-group-sm > .form-control,
.default-select.style-2 .input-group-sm > .btn.dropdown-toggle,
.default-select.style-2
  .input-group-sm
  > .dropdown-toggle.wp-block-button__link,
.default-select.style-3 .input-group-sm > .btn.dropdown-toggle,
.default-select.style-3
  .input-group-sm
  > .dropdown-toggle.wp-block-button__link,
.wp-block-categories-dropdown .input-group-sm > select,
.wp-block-archives-dropdown .input-group-sm > select,
.comment-respond .comment-form p.comment-form-author .input-group-sm > input,
.comment-respond .comment-form p.comment-form-email .input-group-sm > input,
.comment-respond .comment-form p.comment-form-url .input-group-sm > input,
.comment-respond .comment-form p .input-group-sm > textarea,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.input-group-sm > .wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #13c24d;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.82031rem;
  color: #000;
  background-color: rgba(19, 194, 77, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.was-validated .default-select.style-2 .btn.dropdown-toggle:valid,
.default-select.style-2 .was-validated .btn.dropdown-toggle:valid,
.was-validated
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:valid,
.default-select.style-2
  .was-validated
  .dropdown-toggle.wp-block-button__link:valid,
.was-validated .default-select.style-3 .btn.dropdown-toggle:valid,
.default-select.style-3 .was-validated .btn.dropdown-toggle:valid,
.was-validated
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:valid,
.default-select.style-3
  .was-validated
  .dropdown-toggle.wp-block-button__link:valid,
.was-validated .wp-block-categories-dropdown select:valid,
.wp-block-categories-dropdown .was-validated select:valid,
.was-validated .wp-block-archives-dropdown select:valid,
.wp-block-archives-dropdown .was-validated select:valid,
.was-validated .comment-respond .comment-form p.comment-form-author input:valid,
.comment-respond .comment-form p.comment-form-author .was-validated input:valid,
.was-validated .comment-respond .comment-form p.comment-form-email input:valid,
.comment-respond .comment-form p.comment-form-email .was-validated input:valid,
.was-validated .comment-respond .comment-form p.comment-form-url input:valid,
.comment-respond .comment-form p.comment-form-url .was-validated input:valid,
.was-validated .comment-respond .comment-form p textarea:valid,
.comment-respond .comment-form p .was-validated textarea:valid,
.form-control.is-valid,
.default-select.style-2 .is-valid.btn.dropdown-toggle,
.default-select.style-2 .is-valid.dropdown-toggle.wp-block-button__link,
.default-select.style-3 .is-valid.btn.dropdown-toggle,
.default-select.style-3 .is-valid.dropdown-toggle.wp-block-button__link,
.wp-block-categories-dropdown select.is-valid,
.wp-block-archives-dropdown select.is-valid,
.comment-respond .comment-form p.comment-form-author input.is-valid,
.comment-respond .comment-form p.comment-form-email input.is-valid,
.comment-respond .comment-form p.comment-form-url input.is-valid,
.comment-respond .comment-form p textarea.is-valid {
  border-color: #13c24d;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2313c24d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.was-validated .default-select.style-2 .btn.dropdown-toggle:valid:focus,
.default-select.style-2 .was-validated .btn.dropdown-toggle:valid:focus,
.was-validated
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:valid:focus,
.default-select.style-2
  .was-validated
  .dropdown-toggle.wp-block-button__link:valid:focus,
.was-validated .default-select.style-3 .btn.dropdown-toggle:valid:focus,
.default-select.style-3 .was-validated .btn.dropdown-toggle:valid:focus,
.was-validated
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:valid:focus,
.default-select.style-3
  .was-validated
  .dropdown-toggle.wp-block-button__link:valid:focus,
.was-validated .wp-block-categories-dropdown select:valid:focus,
.wp-block-categories-dropdown .was-validated select:valid:focus,
.was-validated .wp-block-archives-dropdown select:valid:focus,
.wp-block-archives-dropdown .was-validated select:valid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-author
  input:valid:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  input:valid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-email
  input:valid:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  input:valid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-url
  input:valid:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  .was-validated
  input:valid:focus,
.was-validated .comment-respond .comment-form p textarea:valid:focus,
.comment-respond .comment-form p .was-validated textarea:valid:focus,
.form-control.is-valid:focus,
.default-select.style-2 .is-valid.btn.dropdown-toggle:focus,
.default-select.style-2 .is-valid.dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3 .is-valid.btn.dropdown-toggle:focus,
.default-select.style-3 .is-valid.dropdown-toggle.wp-block-button__link:focus,
.wp-block-categories-dropdown select.is-valid:focus,
.wp-block-archives-dropdown select.is-valid:focus,
.comment-respond .comment-form p.comment-form-author input.is-valid:focus,
.comment-respond .comment-form p.comment-form-email input.is-valid:focus,
.comment-respond .comment-form p.comment-form-url input.is-valid:focus,
.comment-respond .comment-form p textarea.is-valid:focus {
  border-color: #13c24d;
  box-shadow: 0 0 0 0rem rgba(19, 194, 77, 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated .default-select.style-2 textarea.btn.dropdown-toggle:valid,
.default-select.style-2 .was-validated textarea.btn.dropdown-toggle:valid,
.was-validated
  .default-select.style-2
  textarea.dropdown-toggle.wp-block-button__link:valid,
.default-select.style-2
  .was-validated
  textarea.dropdown-toggle.wp-block-button__link:valid,
.was-validated .default-select.style-3 textarea.btn.dropdown-toggle:valid,
.default-select.style-3 .was-validated textarea.btn.dropdown-toggle:valid,
.was-validated
  .default-select.style-3
  textarea.dropdown-toggle.wp-block-button__link:valid,
.default-select.style-3
  .was-validated
  textarea.dropdown-toggle.wp-block-button__link:valid,
.was-validated .comment-respond .comment-form p textarea:valid,
.comment-respond .comment-form p .was-validated textarea:valid,
textarea.form-control.is-valid,
.default-select.style-2 textarea.is-valid.btn.dropdown-toggle,
.default-select.style-2 textarea.is-valid.dropdown-toggle.wp-block-button__link,
.default-select.style-3 textarea.is-valid.btn.dropdown-toggle,
.default-select.style-3 textarea.is-valid.dropdown-toggle.wp-block-button__link,
.comment-respond .comment-form p textarea.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #13c24d;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2313c24d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #13c24d;
  box-shadow: 0 0 0 0rem rgba(19, 194, 77, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #13c24d;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #13c24d;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0rem rgba(19, 194, 77, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #13c24d;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.was-validated .input-group .default-select.style-2 .btn.dropdown-toggle:valid,
.default-select.style-2 .was-validated .input-group .btn.dropdown-toggle:valid,
.was-validated
  .input-group
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:valid,
.default-select.style-2
  .was-validated
  .input-group
  .dropdown-toggle.wp-block-button__link:valid,
.was-validated .input-group .default-select.style-3 .btn.dropdown-toggle:valid,
.default-select.style-3 .was-validated .input-group .btn.dropdown-toggle:valid,
.was-validated
  .input-group
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:valid,
.default-select.style-3
  .was-validated
  .input-group
  .dropdown-toggle.wp-block-button__link:valid,
.was-validated .input-group .wp-block-categories-dropdown select:valid,
.wp-block-categories-dropdown .was-validated .input-group select:valid,
.was-validated .input-group .wp-block-archives-dropdown select:valid,
.wp-block-archives-dropdown .was-validated .input-group select:valid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input:valid,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  .input-group
  input:valid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input:valid,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  .input-group
  input:valid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input:valid,
.comment-respond
  .comment-form
  p.comment-form-url
  .was-validated
  .input-group
  input:valid,
.was-validated .input-group .comment-respond .comment-form p textarea:valid,
.comment-respond .comment-form p .was-validated .input-group textarea:valid,
.input-group .form-control.is-valid,
.input-group .default-select.style-2 .is-valid.btn.dropdown-toggle,
.default-select.style-2 .input-group .is-valid.btn.dropdown-toggle,
.input-group
  .default-select.style-2
  .is-valid.dropdown-toggle.wp-block-button__link,
.default-select.style-2
  .input-group
  .is-valid.dropdown-toggle.wp-block-button__link,
.input-group .default-select.style-3 .is-valid.btn.dropdown-toggle,
.default-select.style-3 .input-group .is-valid.btn.dropdown-toggle,
.input-group
  .default-select.style-3
  .is-valid.dropdown-toggle.wp-block-button__link,
.default-select.style-3
  .input-group
  .is-valid.dropdown-toggle.wp-block-button__link,
.input-group .wp-block-categories-dropdown select.is-valid,
.wp-block-categories-dropdown .input-group select.is-valid,
.input-group .wp-block-archives-dropdown select.is-valid,
.wp-block-archives-dropdown .input-group select.is-valid,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input.is-valid,
.comment-respond
  .comment-form
  p.comment-form-author
  .input-group
  input.is-valid,
.input-group .comment-respond .comment-form p.comment-form-email input.is-valid,
.comment-respond .comment-form p.comment-form-email .input-group input.is-valid,
.input-group .comment-respond .comment-form p.comment-form-url input.is-valid,
.comment-respond .comment-form p.comment-form-url .input-group input.is-valid,
.input-group .comment-respond .comment-form p textarea.is-valid,
.comment-respond .comment-form p .input-group textarea.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus,
.was-validated
  .input-group
  .default-select.style-2
  .btn.dropdown-toggle:valid:focus,
.default-select.style-2
  .was-validated
  .input-group
  .btn.dropdown-toggle:valid:focus,
.was-validated
  .input-group
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:valid:focus,
.default-select.style-2
  .was-validated
  .input-group
  .dropdown-toggle.wp-block-button__link:valid:focus,
.was-validated
  .input-group
  .default-select.style-3
  .btn.dropdown-toggle:valid:focus,
.default-select.style-3
  .was-validated
  .input-group
  .btn.dropdown-toggle:valid:focus,
.was-validated
  .input-group
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:valid:focus,
.default-select.style-3
  .was-validated
  .input-group
  .dropdown-toggle.wp-block-button__link:valid:focus,
.was-validated .input-group .wp-block-categories-dropdown select:valid:focus,
.wp-block-categories-dropdown .was-validated .input-group select:valid:focus,
.was-validated .input-group .wp-block-archives-dropdown select:valid:focus,
.wp-block-archives-dropdown .was-validated .input-group select:valid:focus,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input:valid:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  .input-group
  input:valid:focus,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input:valid:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  .input-group
  input:valid:focus,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input:valid:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  .was-validated
  .input-group
  input:valid:focus,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p
  textarea:valid:focus,
.comment-respond
  .comment-form
  p
  .was-validated
  .input-group
  textarea:valid:focus,
.input-group .form-control.is-valid:focus,
.input-group .default-select.style-2 .is-valid.btn.dropdown-toggle:focus,
.default-select.style-2 .input-group .is-valid.btn.dropdown-toggle:focus,
.input-group
  .default-select.style-2
  .is-valid.dropdown-toggle.wp-block-button__link:focus,
.default-select.style-2
  .input-group
  .is-valid.dropdown-toggle.wp-block-button__link:focus,
.input-group .default-select.style-3 .is-valid.btn.dropdown-toggle:focus,
.default-select.style-3 .input-group .is-valid.btn.dropdown-toggle:focus,
.input-group
  .default-select.style-3
  .is-valid.dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3
  .input-group
  .is-valid.dropdown-toggle.wp-block-button__link:focus,
.input-group .wp-block-categories-dropdown select.is-valid:focus,
.wp-block-categories-dropdown .input-group select.is-valid:focus,
.input-group .wp-block-archives-dropdown select.is-valid:focus,
.wp-block-archives-dropdown .input-group select.is-valid:focus,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input.is-valid:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .input-group
  input.is-valid:focus,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input.is-valid:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .input-group
  input.is-valid:focus,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input.is-valid:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  .input-group
  input.is-valid:focus,
.input-group .comment-respond .comment-form p textarea.is-valid:focus,
.comment-respond .comment-form p .input-group textarea.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ff0003;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.82031rem;
  color: #000;
  background-color: rgba(255, 0, 3, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.was-validated .default-select.style-2 .btn.dropdown-toggle:invalid,
.default-select.style-2 .was-validated .btn.dropdown-toggle:invalid,
.was-validated
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:invalid,
.default-select.style-2
  .was-validated
  .dropdown-toggle.wp-block-button__link:invalid,
.was-validated .default-select.style-3 .btn.dropdown-toggle:invalid,
.default-select.style-3 .was-validated .btn.dropdown-toggle:invalid,
.was-validated
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:invalid,
.default-select.style-3
  .was-validated
  .dropdown-toggle.wp-block-button__link:invalid,
.was-validated .wp-block-categories-dropdown select:invalid,
.wp-block-categories-dropdown .was-validated select:invalid,
.was-validated .wp-block-archives-dropdown select:invalid,
.wp-block-archives-dropdown .was-validated select:invalid,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-author
  input:invalid,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  input:invalid,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-email
  input:invalid,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  input:invalid,
.was-validated .comment-respond .comment-form p.comment-form-url input:invalid,
.comment-respond .comment-form p.comment-form-url .was-validated input:invalid,
.was-validated .comment-respond .comment-form p textarea:invalid,
.comment-respond .comment-form p .was-validated textarea:invalid,
.form-control.is-invalid,
.default-select.style-2 .is-invalid.btn.dropdown-toggle,
.default-select.style-2 .is-invalid.dropdown-toggle.wp-block-button__link,
.default-select.style-3 .is-invalid.btn.dropdown-toggle,
.default-select.style-3 .is-invalid.dropdown-toggle.wp-block-button__link,
.wp-block-categories-dropdown select.is-invalid,
.wp-block-archives-dropdown select.is-invalid,
.comment-respond .comment-form p.comment-form-author input.is-invalid,
.comment-respond .comment-form p.comment-form-email input.is-invalid,
.comment-respond .comment-form p.comment-form-url input.is-invalid,
.comment-respond .comment-form p textarea.is-invalid {
  border-color: #ff0003;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff0003'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff0003' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.was-validated .default-select.style-2 .btn.dropdown-toggle:invalid:focus,
.default-select.style-2 .was-validated .btn.dropdown-toggle:invalid:focus,
.was-validated
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:invalid:focus,
.default-select.style-2
  .was-validated
  .dropdown-toggle.wp-block-button__link:invalid:focus,
.was-validated .default-select.style-3 .btn.dropdown-toggle:invalid:focus,
.default-select.style-3 .was-validated .btn.dropdown-toggle:invalid:focus,
.was-validated
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:invalid:focus,
.default-select.style-3
  .was-validated
  .dropdown-toggle.wp-block-button__link:invalid:focus,
.was-validated .wp-block-categories-dropdown select:invalid:focus,
.wp-block-categories-dropdown .was-validated select:invalid:focus,
.was-validated .wp-block-archives-dropdown select:invalid:focus,
.wp-block-archives-dropdown .was-validated select:invalid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-author
  input:invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  input:invalid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-email
  input:invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  input:invalid:focus,
.was-validated
  .comment-respond
  .comment-form
  p.comment-form-url
  input:invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  .was-validated
  input:invalid:focus,
.was-validated .comment-respond .comment-form p textarea:invalid:focus,
.comment-respond .comment-form p .was-validated textarea:invalid:focus,
.form-control.is-invalid:focus,
.default-select.style-2 .is-invalid.btn.dropdown-toggle:focus,
.default-select.style-2 .is-invalid.dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3 .is-invalid.btn.dropdown-toggle:focus,
.default-select.style-3 .is-invalid.dropdown-toggle.wp-block-button__link:focus,
.wp-block-categories-dropdown select.is-invalid:focus,
.wp-block-archives-dropdown select.is-invalid:focus,
.comment-respond .comment-form p.comment-form-author input.is-invalid:focus,
.comment-respond .comment-form p.comment-form-email input.is-invalid:focus,
.comment-respond .comment-form p.comment-form-url input.is-invalid:focus,
.comment-respond .comment-form p textarea.is-invalid:focus {
  border-color: #ff0003;
  box-shadow: 0 0 0 0rem rgba(255, 0, 3, 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated .default-select.style-2 textarea.btn.dropdown-toggle:invalid,
.default-select.style-2 .was-validated textarea.btn.dropdown-toggle:invalid,
.was-validated
  .default-select.style-2
  textarea.dropdown-toggle.wp-block-button__link:invalid,
.default-select.style-2
  .was-validated
  textarea.dropdown-toggle.wp-block-button__link:invalid,
.was-validated .default-select.style-3 textarea.btn.dropdown-toggle:invalid,
.default-select.style-3 .was-validated textarea.btn.dropdown-toggle:invalid,
.was-validated
  .default-select.style-3
  textarea.dropdown-toggle.wp-block-button__link:invalid,
.default-select.style-3
  .was-validated
  textarea.dropdown-toggle.wp-block-button__link:invalid,
.was-validated .comment-respond .comment-form p textarea:invalid,
.comment-respond .comment-form p .was-validated textarea:invalid,
textarea.form-control.is-invalid,
.default-select.style-2 textarea.is-invalid.btn.dropdown-toggle,
.default-select.style-2
  textarea.is-invalid.dropdown-toggle.wp-block-button__link,
.default-select.style-3 textarea.is-invalid.btn.dropdown-toggle,
.default-select.style-3
  textarea.is-invalid.dropdown-toggle.wp-block-button__link,
.comment-respond .comment-form p textarea.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #ff0003;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff0003'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff0003' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #ff0003;
  box-shadow: 0 0 0 0rem rgba(255, 0, 3, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #ff0003;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #ff0003;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0rem rgba(255, 0, 3, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #ff0003;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.was-validated
  .input-group
  .default-select.style-2
  .btn.dropdown-toggle:invalid,
.default-select.style-2
  .was-validated
  .input-group
  .btn.dropdown-toggle:invalid,
.was-validated
  .input-group
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:invalid,
.default-select.style-2
  .was-validated
  .input-group
  .dropdown-toggle.wp-block-button__link:invalid,
.was-validated
  .input-group
  .default-select.style-3
  .btn.dropdown-toggle:invalid,
.default-select.style-3
  .was-validated
  .input-group
  .btn.dropdown-toggle:invalid,
.was-validated
  .input-group
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:invalid,
.default-select.style-3
  .was-validated
  .input-group
  .dropdown-toggle.wp-block-button__link:invalid,
.was-validated .input-group .wp-block-categories-dropdown select:invalid,
.wp-block-categories-dropdown .was-validated .input-group select:invalid,
.was-validated .input-group .wp-block-archives-dropdown select:invalid,
.wp-block-archives-dropdown .was-validated .input-group select:invalid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input:invalid,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  .input-group
  input:invalid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input:invalid,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  .input-group
  input:invalid,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input:invalid,
.comment-respond
  .comment-form
  p.comment-form-url
  .was-validated
  .input-group
  input:invalid,
.was-validated .input-group .comment-respond .comment-form p textarea:invalid,
.comment-respond .comment-form p .was-validated .input-group textarea:invalid,
.input-group .form-control.is-invalid,
.input-group .default-select.style-2 .is-invalid.btn.dropdown-toggle,
.default-select.style-2 .input-group .is-invalid.btn.dropdown-toggle,
.input-group
  .default-select.style-2
  .is-invalid.dropdown-toggle.wp-block-button__link,
.default-select.style-2
  .input-group
  .is-invalid.dropdown-toggle.wp-block-button__link,
.input-group .default-select.style-3 .is-invalid.btn.dropdown-toggle,
.default-select.style-3 .input-group .is-invalid.btn.dropdown-toggle,
.input-group
  .default-select.style-3
  .is-invalid.dropdown-toggle.wp-block-button__link,
.default-select.style-3
  .input-group
  .is-invalid.dropdown-toggle.wp-block-button__link,
.input-group .wp-block-categories-dropdown select.is-invalid,
.wp-block-categories-dropdown .input-group select.is-invalid,
.input-group .wp-block-archives-dropdown select.is-invalid,
.wp-block-archives-dropdown .input-group select.is-invalid,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input.is-invalid,
.comment-respond
  .comment-form
  p.comment-form-author
  .input-group
  input.is-invalid,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input.is-invalid,
.comment-respond
  .comment-form
  p.comment-form-email
  .input-group
  input.is-invalid,
.input-group .comment-respond .comment-form p.comment-form-url input.is-invalid,
.comment-respond .comment-form p.comment-form-url .input-group input.is-invalid,
.input-group .comment-respond .comment-form p textarea.is-invalid,
.comment-respond .comment-form p .input-group textarea.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus,
.was-validated
  .input-group
  .default-select.style-2
  .btn.dropdown-toggle:invalid:focus,
.default-select.style-2
  .was-validated
  .input-group
  .btn.dropdown-toggle:invalid:focus,
.was-validated
  .input-group
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:invalid:focus,
.default-select.style-2
  .was-validated
  .input-group
  .dropdown-toggle.wp-block-button__link:invalid:focus,
.was-validated
  .input-group
  .default-select.style-3
  .btn.dropdown-toggle:invalid:focus,
.default-select.style-3
  .was-validated
  .input-group
  .btn.dropdown-toggle:invalid:focus,
.was-validated
  .input-group
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:invalid:focus,
.default-select.style-3
  .was-validated
  .input-group
  .dropdown-toggle.wp-block-button__link:invalid:focus,
.was-validated .input-group .wp-block-categories-dropdown select:invalid:focus,
.wp-block-categories-dropdown .was-validated .input-group select:invalid:focus,
.was-validated .input-group .wp-block-archives-dropdown select:invalid:focus,
.wp-block-archives-dropdown .was-validated .input-group select:invalid:focus,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input:invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .was-validated
  .input-group
  input:invalid:focus,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input:invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .was-validated
  .input-group
  input:invalid:focus,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input:invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  .was-validated
  .input-group
  input:invalid:focus,
.was-validated
  .input-group
  .comment-respond
  .comment-form
  p
  textarea:invalid:focus,
.comment-respond
  .comment-form
  p
  .was-validated
  .input-group
  textarea:invalid:focus,
.input-group .form-control.is-invalid:focus,
.input-group .default-select.style-2 .is-invalid.btn.dropdown-toggle:focus,
.default-select.style-2 .input-group .is-invalid.btn.dropdown-toggle:focus,
.input-group
  .default-select.style-2
  .is-invalid.dropdown-toggle.wp-block-button__link:focus,
.default-select.style-2
  .input-group
  .is-invalid.dropdown-toggle.wp-block-button__link:focus,
.input-group .default-select.style-3 .is-invalid.btn.dropdown-toggle:focus,
.default-select.style-3 .input-group .is-invalid.btn.dropdown-toggle:focus,
.input-group
  .default-select.style-3
  .is-invalid.dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3
  .input-group
  .is-invalid.dropdown-toggle.wp-block-button__link:focus,
.input-group .wp-block-categories-dropdown select.is-invalid:focus,
.wp-block-categories-dropdown .input-group select.is-invalid:focus,
.input-group .wp-block-archives-dropdown select.is-invalid:focus,
.wp-block-archives-dropdown .input-group select.is-invalid:focus,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input.is-invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .input-group
  input.is-invalid:focus,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input.is-invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .input-group
  input.is-invalid:focus,
.input-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input.is-invalid:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  .input-group
  input.is-invalid:focus,
.input-group .comment-respond .comment-form p textarea.is-invalid:focus,
.comment-respond .comment-form p .input-group textarea.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn,
.wp-block-button__link {
  display: inline-block;
  font-weight: 400;
  line-height: 1.6;
  color: #777;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn,
  .wp-block-button__link {
    transition: none;
  }
}
.btn:hover,
.wp-block-button__link:hover {
  color: #777;
}
.btn-check:focus + .btn,
.btn-check:focus + .wp-block-button__link,
.btn:focus,
.wp-block-button__link:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(27, 130, 113, 0.25);
}
.btn:disabled,
.wp-block-button__link:disabled,
.btn.disabled,
.disabled.wp-block-button__link,
fieldset:disabled .btn,
fieldset:disabled .wp-block-button__link {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary,
.wp-block-button__link {
  color: #fff;
  background-color: #1b8271;
  border-color: #1b8271;
}
.btn-primary:hover,
.wp-block-button__link:hover {
  color: #fff;
  background-color: #176f60;
  border-color: #16685a;
}
.btn-check:focus + .btn-primary,
.btn-check:focus + .wp-block-button__link,
.btn-primary:focus,
.wp-block-button__link:focus {
  color: #fff;
  background-color: #176f60;
  border-color: #16685a;
  box-shadow: 0 0 0 0rem rgba(61, 149, 134, 0.5);
}
.btn-check:checked + .btn-primary,
.btn-check:checked + .wp-block-button__link,
.btn-check:active + .btn-primary,
.btn-check:active + .wp-block-button__link,
.btn-primary:active,
.wp-block-button__link:active,
.btn-primary.active,
.active.wp-block-button__link,
.show > .btn-primary.dropdown-toggle,
.show > .dropdown-toggle.wp-block-button__link {
  color: #fff;
  background-color: #16685a;
  border-color: #146255;
}
.btn-check:checked + .btn-primary:focus,
.btn-check:checked + .wp-block-button__link:focus,
.btn-check:active + .btn-primary:focus,
.btn-check:active + .wp-block-button__link:focus,
.btn-primary:active:focus,
.wp-block-button__link:active:focus,
.btn-primary.active:focus,
.active.wp-block-button__link:focus,
.show > .btn-primary.dropdown-toggle:focus,
.show > .dropdown-toggle.wp-block-button__link:focus {
  box-shadow: 0 0 0 0rem rgba(61, 149, 134, 0.5);
}
.btn-primary:disabled,
.wp-block-button__link:disabled,
.btn-primary.disabled,
.disabled.wp-block-button__link {
  color: #fff;
  background-color: #1b8271;
  border-color: #1b8271;
}

.btn-secondary {
  color: #000;
  background-color: #ff7468;
  border-color: #ff7468;
}
.btn-secondary:hover {
  color: #000;
  background-color: #ff897f;
  border-color: #ff8277;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #000;
  background-color: #ff897f;
  border-color: #ff8277;
  box-shadow: 0 0 0 0rem rgba(217, 99, 88, 0.5);
}
.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #ff9086;
  border-color: #ff8277;
}
.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(217, 99, 88, 0.5);
}
.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #000;
  background-color: #ff7468;
  border-color: #ff7468;
}

.btn-success {
  color: #000;
  background-color: #13c24d;
  border-color: #13c24d;
}
.btn-success:hover {
  color: #000;
  background-color: #36cb68;
  border-color: #2bc85f;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #000;
  background-color: #36cb68;
  border-color: #2bc85f;
  box-shadow: 0 0 0 0rem rgba(16, 165, 65, 0.5);
}
.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #42ce71;
  border-color: #2bc85f;
}
.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(16, 165, 65, 0.5);
}
.btn-success:disabled,
.btn-success.disabled {
  color: #000;
  background-color: #13c24d;
  border-color: #13c24d;
}

.btn-info {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}
.btn-info:hover {
  color: #000;
  background-color: #26baff;
  border-color: #1ab6ff;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #26baff;
  border-color: #1ab6ff;
  box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5);
}
.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #33beff;
  border-color: #1ab6ff;
}
.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5);
}
.btn-info:disabled,
.btn-info.disabled {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}

.btn-warning {
  color: #fff;
  background-color: #fea500;
  border-color: #fea500;
}
.btn-warning:hover {
  color: #000;
  background-color: #feb326;
  border-color: #feae1a;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #feb326;
  border-color: #feae1a;
  box-shadow: 0 0 0 0rem rgba(216, 140, 0, 0.5);
}
.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #feb733;
  border-color: #feae1a;
}
.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(216, 140, 0, 0.5);
}
.btn-warning:disabled,
.btn-warning.disabled {
  color: #000;
  background-color: #fea500;
  border-color: #fea500;
}

.btn-danger {
  color: #000;
  background-color: #ff0003;
  border-color: #ff0003;
}
.btn-danger:hover {
  color: #000;
  background-color: #ff2629;
  border-color: #ff1a1c;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #000;
  background-color: #ff2629;
  border-color: #ff1a1c;
  box-shadow: 0 0 0 0rem rgba(217, 0, 3, 0.5);
}
.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: #ff3335;
  border-color: #ff1a1c;
}
.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(217, 0, 3, 0.5);
}
.btn-danger:disabled,
.btn-danger.disabled {
  color: #000;
  background-color: #ff0003;
  border-color: #ff0003;
}

.btn-light {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}
.btn-light:hover {
  color: #000;
  background-color: #fbf9f4;
  border-color: #fbf9f3;
}
.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #fbf9f4;
  border-color: #fbf9f3;
  box-shadow: 0 0 0 0rem rgba(213, 211, 206, 0.5);
}
.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #fbf9f5;
  border-color: #fbf9f3;
}
.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(213, 211, 206, 0.5);
}
.btn-light:disabled,
.btn-light.disabled {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}

.btn-dark {
  color: #fff;
  background-color: #050d36;
  border-color: #050d36;
}
.btn-dark:hover {
  color: #fff;
  background-color: #040b2e;
  border-color: #040a2b;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #040b2e;
  border-color: #040a2b;
  box-shadow: 0 0 0 0rem rgba(43, 49, 84, 0.5);
}
.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #040a2b;
  border-color: #040a29;
}
.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(43, 49, 84, 0.5);
}
.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: #050d36;
  border-color: #050d36;
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  color: #1b8271;
  border-color: #1b8271;
}
.btn-outline-primary:hover,
.is-style-outline .wp-block-button__link:hover {
  color: #fff;
  background-color: #1b8271;
  border-color: #1b8271;
}
.btn-check:focus + .btn-outline-primary,
.is-style-outline .btn-check:focus + .wp-block-button__link,
.btn-outline-primary:focus,
.is-style-outline .wp-block-button__link:focus {
  box-shadow: 0 0 0 0rem rgba(27, 130, 113, 0.5);
}
.btn-check:checked + .btn-outline-primary,
.is-style-outline .btn-check:checked + .wp-block-button__link,
.btn-check:active + .btn-outline-primary,
.is-style-outline .btn-check:active + .wp-block-button__link,
.btn-outline-primary:active,
.is-style-outline .wp-block-button__link:active,
.btn-outline-primary.active,
.is-style-outline .active.wp-block-button__link,
.btn-outline-primary.dropdown-toggle.show,
.is-style-outline .dropdown-toggle.show.wp-block-button__link {
  color: #fff;
  background-color: #1b8271;
  border-color: #1b8271;
}
.btn-check:checked + .btn-outline-primary:focus,
.is-style-outline .btn-check:checked + .wp-block-button__link:focus,
.btn-check:active + .btn-outline-primary:focus,
.is-style-outline .btn-check:active + .wp-block-button__link:focus,
.btn-outline-primary:active:focus,
.is-style-outline .wp-block-button__link:active:focus,
.btn-outline-primary.active:focus,
.is-style-outline .active.wp-block-button__link:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.is-style-outline .dropdown-toggle.show.wp-block-button__link:focus {
  box-shadow: 0 0 0 0rem rgba(27, 130, 113, 0.5);
}
.btn-outline-primary:disabled,
.is-style-outline .wp-block-button__link:disabled,
.btn-outline-primary.disabled,
.is-style-outline .disabled.wp-block-button__link {
  color: #1b8271;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #ff7468;
  border-color: #ff7468;
}
.btn-outline-secondary:hover {
  color: #000;
  background-color: #ff7468;
  border-color: #ff7468;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0rem rgba(255, 116, 104, 0.5);
}
.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #ff7468;
  border-color: #ff7468;
}
.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(255, 116, 104, 0.5);
}
.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #ff7468;
  background-color: transparent;
}

.btn-outline-success {
  color: #13c24d;
  border-color: #13c24d;
}
.btn-outline-success:hover {
  color: #000;
  background-color: #13c24d;
  border-color: #13c24d;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0rem rgba(19, 194, 77, 0.5);
}
.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #13c24d;
  border-color: #13c24d;
}
.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(19, 194, 77, 0.5);
}
.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #13c24d;
  background-color: transparent;
}

.btn-outline-info {
  color: #00aeff;
  border-color: #00aeff;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5);
}
.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}
.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5);
}
.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #00aeff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #fea500;
  border-color: #fea500;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #fea500;
  border-color: #fea500;
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0rem rgba(254, 165, 0, 0.5);
}
.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #fea500;
  border-color: #fea500;
}
.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(254, 165, 0, 0.5);
}
.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #fea500;
  background-color: transparent;
}

.btn-outline-danger {
  color: #ff0003;
  border-color: #ff0003;
}
.btn-outline-danger:hover {
  color: #000;
  background-color: #ff0003;
  border-color: #ff0003;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0rem rgba(255, 0, 3, 0.5);
}
.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #ff0003;
  border-color: #ff0003;
}
.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(255, 0, 3, 0.5);
}
.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #ff0003;
  background-color: transparent;
}

.btn-outline-light {
  color: #faf8f2;
  border-color: #faf8f2;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5);
}
.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}
.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5);
}
.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #faf8f2;
  background-color: transparent;
}

.btn-outline-dark {
  color: #050d36;
  border-color: #050d36;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #050d36;
  border-color: #050d36;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0rem rgba(5, 13, 54, 0.5);
}
.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #050d36;
  border-color: #050d36;
}
.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(5, 13, 54, 0.5);
}
.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #050d36;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #1b8271;
  text-decoration: underline;
}
.btn-link:hover {
  color: #16685a;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}

.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm > .wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.9375rem;
  color: #777;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1b8271;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.82031rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #1b8271;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group > .wp-block-button__link,
.btn-group-vertical > .btn,
.btn-group-vertical > .wp-block-button__link {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:checked + .wp-block-button__link,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn-check:focus + .wp-block-button__link,
.btn-group > .btn:hover,
.btn-group > .wp-block-button__link:hover,
.btn-group > .btn:focus,
.btn-group > .wp-block-button__link:focus,
.btn-group > .btn:active,
.btn-group > .wp-block-button__link:active,
.btn-group > .btn.active,
.btn-group > .active.wp-block-button__link,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:checked + .wp-block-button__link,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn-check:focus + .wp-block-button__link,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .wp-block-button__link:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .wp-block-button__link:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .wp-block-button__link:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.wp-block-button__link {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .wp-block-button__link:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .wp-block-button__link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > .wp-block-button__link:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > :not(.btn-check) + .wp-block-button__link,
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .wp-block-button__link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-group-sm > .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-group-lg > .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .wp-block-button__link,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .wp-block-button__link:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical
  > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .wp-block-button__link {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .wp-block-button__link ~ .btn,
.btn-group-vertical > .btn ~ .wp-block-button__link,
.btn-group-vertical > .wp-block-button__link ~ .wp-block-button__link,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .wp-block-button__link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #416bdb;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: #416bdb;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #416bdb;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.17188rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.17188rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
  .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
  .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
  .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
  .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.9375rem;
  color: #777;
  text-align: left;
  background-color: #ffffff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #187566;
  background-color: #e8f3f1;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23187566'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23777'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #8dc1b8;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(27, 130, 113, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #1b8271;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #16685a;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #16685a;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(27, 130, 113, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1b8271;
  border-color: #1b8271;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.17188rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge,
.wp-block-button__link .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #104e44;
  background-color: #d1e6e3;
  border-color: #bbdad4;
}
.alert-primary .alert-link {
  color: #0d3e36;
}

.alert-secondary {
  color: #99463e;
  background-color: #ffe3e1;
  border-color: #ffd5d2;
}
.alert-secondary .alert-link {
  color: #7a3832;
}

.alert-success {
  color: #0b742e;
  background-color: #d0f3db;
  border-color: #b8edca;
}
.alert-success .alert-link {
  color: #095d25;
}

.alert-info {
  color: #006899;
  background-color: #ccefff;
  border-color: #b3e7ff;
}
.alert-info .alert-link {
  color: #00537a;
}

.alert-warning {
  color: #664200;
  background-color: #ffedcc;
  border-color: #ffe4b3;
}
.alert-warning .alert-link {
  color: #523500;
}

.alert-danger {
  color: #990002;
  background-color: #ffcccd;
  border-color: #ffb3b3;
}
.alert-danger .alert-link {
  color: #7a0002;
}

.alert-light {
  color: #646361;
  background-color: #fefefc;
  border-color: #fefdfb;
}
.alert-light .alert-link {
  color: #504f4e;
}

.alert-dark {
  color: #030820;
  background-color: #cdcfd7;
  border-color: #b4b6c3;
}
.alert-dark .alert-link {
  color: #02061a;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.70313rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
@media (min-width: 1200px) {
  .progress {
    font-size: 0.70312rem;
  }
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1b8271;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #777;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1b8271;
  border-color: #1b8271;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #104e44;
  background-color: #d1e6e3;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #104e44;
  background-color: #bccfcc;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #104e44;
  border-color: #104e44;
}

.list-group-item-secondary {
  color: #99463e;
  background-color: #ffe3e1;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #99463e;
  background-color: #e6cccb;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #99463e;
  border-color: #99463e;
}

.list-group-item-success {
  color: #0b742e;
  background-color: #d0f3db;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #0b742e;
  background-color: #bbdbc5;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0b742e;
  border-color: #0b742e;
}

.list-group-item-info {
  color: #006899;
  background-color: #ccefff;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #006899;
  background-color: #b8d7e6;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #006899;
  border-color: #006899;
}

.list-group-item-warning {
  color: #664200;
  background-color: #ffedcc;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #664200;
  background-color: #e6d5b8;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664200;
  border-color: #664200;
}

.list-group-item-danger {
  color: #990002;
  background-color: #ffcccd;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #990002;
  background-color: #e6b8b9;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #990002;
  border-color: #990002;
}

.list-group-item-light {
  color: #646361;
  background-color: #fefefc;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #646361;
  background-color: #e5e5e3;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #646361;
  border-color: #646361;
}

.list-group-item-dark {
  color: #030820;
  background-color: #cdcfd7;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #030820;
  background-color: #b9bac2;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #030820;
  border-color: #030820;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(27, 130, 113, 0.25);
  opacity: 1;
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 15px;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(1rem - 1px);
  border-bottom-left-radius: calc(1rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.82031rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.82031rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: #050d36;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #777;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before,
.placeholder.wp-block-button__link::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #1b8271;
}
.link-primary:hover,
.link-primary:focus {
  color: #16685a;
}

.link-secondary {
  color: #ff7468;
}
.link-secondary:hover,
.link-secondary:focus {
  color: #ff9086;
}

.link-success {
  color: #13c24d;
}
.link-success:hover,
.link-success:focus {
  color: #42ce71;
}

.link-info {
  color: #00aeff;
}
.link-info:hover,
.link-info:focus {
  color: #33beff;
}

.link-warning {
  color: #fea500;
}
.link-warning:hover,
.link-warning:focus {
  color: #feb733;
}

.link-danger {
  color: #ff0003;
}
.link-danger:hover,
.link-danger:focus {
  color: #ff3335;
}

.link-light {
  color: #faf8f2;
}
.link-light:hover,
.link-light:focus {
  color: #fbf9f5;
}

.link-dark {
  color: #050d36;
}
.link-dark:hover,
.link-dark:focus {
  color: #040a2b;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 2px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #416bdb !important;
}

.border-secondary {
  border-color: #ff7468 !important;
}

.border-success {
  border-color: #13c24d !important;
}

.border-info {
  border-color: #00aeff !important;
}

.border-warning {
  border-color: #fea500 !important;
}

.border-danger {
  border-color: #ff0003 !important;
}

.border-light {
  border-color: #faf8f2 !important;
}

.border-dark {
  border-color: #050d36 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3875rem + 1.65vw) !important;
}

.fs-2 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-3 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #aeaed5 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-success-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-warning-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(
    var(--bs-body-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.75rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 1rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.625rem !important;
  }
  .fs-2 {
    font-size: 2.25rem !important;
  }
  .fs-3 {
    font-size: 1.875rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*=== Base  ===*/
* {
  outline: none;
  padding: 0;
}
*::after {
  margin: 0;
  padding: 0;
}
*::before {
  margin: 0;
  padding: 0;
}

body {
  line-height: 1.8;
  color: #777;
  font-size: 1rem;
  font-family: var(--font-family-base);
}
@media only screen and (max-width: 575px) {
  body {
    font-size: 15px;
  }
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: var(--title);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-family-title);
  color: var(--title);
}

h1,
.h1,
.h1 {
  line-height: 1.2;
  font-size: 2.625rem;
}
@media only screen and (max-width: 1280px) {
  h1,
  .h1,
  .h1 {
    font-size: 2.375rem;
  }
}
@media only screen and (max-width: 767px) {
  h1,
  .h1,
  .h1 {
    font-size: 32px;
  }
}

h2,
.h2,
.h2 {
  font-size: 2.25rem;
  line-height: 1.2;
}
@media only screen and (max-width: 1280px) {
  h2,
  .h2,
  .h2 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 767px) {
  h2,
  .h2,
  .h2 {
    font-size: 28px;
  }
}

h3,
.h3,
.h3 {
  line-height: 1.4;
  font-size: 1.875rem;
}
@media only screen and (max-width: 1280px) {
  h3,
  .h3,
  .h3 {
    font-size: 1.75rem;
  }
}
@media only screen and (max-width: 767px) {
  h3,
  .h3,
  .h3 {
    font-size: 1.5rem;
  }
}

h4,
.h4,
.h4 {
  font-size: 1.5rem;
  line-height: 1.2;
}
@media only screen and (max-width: 767px) {
  h4,
  .h4,
  .h4 {
    font-size: 1.375rem;
  }
}

h5,
.h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.4;
}

h6,
.h6,
.h6 {
  font-size: 1rem;
  line-height: 1.2;
}

ul {
  padding: 0;
  margin: 0;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
  color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
a:hover,
a:focus,
a.active {
  text-decoration: none;
  outline: none;
  /* color: var(--primary-hover); */
  color: #fff;
}

.text-primary {
  color: var(--primary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.gray-light {
  color: #b9b1b1b5;
}

b,
strong,
.strong {
  font-weight: 500;
}

.h-0 {
  height: 0;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 60px;
}
#main-wrapper.show {
  opacity: 1;
}

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px;
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 50px;
  padding-right: 50px;
}
@media only screen and (max-width: 1280px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

::selection {
  color: #fff;
  background: var(--primary);
}

/* Flaticon */
[class*="flaticon-"] {
  font-family: "Flaticon";
}

.content-inner {
  padding-top: 100px;
  padding-bottom: 70px;
}
@media only screen and (max-width: 1480px) {
  .content-inner {
    padding-top: 70px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .content-inner {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}

.content-inner-1 {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media only screen and (max-width: 1480px) {
  .content-inner-1 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media only screen and (max-width: 991px) {
  .content-inner-1 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner-1 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.content-inner-2 {
  padding-top: 100px;
  padding-bottom: 0;
}
@media only screen and (max-width: 1480px) {
  .content-inner-2 {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 991px) {
  .content-inner-2 {
    padding-top: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner-2 {
    padding-top: 50px;
  }
}

.content-inner-3 {
  padding-top: 60px;
  padding-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .content-inner-3 {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner-3 {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}

.mfp-bg {
  z-index: 99999999;
}

.mfp-wrap {
  z-index: 999999999;
}

.modal-backdrop {
  z-index: 9999999;
}

.modal {
  z-index: 99999999;
}

.rounded {
  border-radius: var(--border-radius-base) !important;
}

@media only screen and (max-width: 991px) {
  .dz-order-1 {
    order: 1;
    margin-top: 20px;
  }
}

.section-head {
  margin-bottom: 50px;
}
.section-head .sub-title {
  text-transform: uppercase;
  color: var(--primary);
  font-size: 1rem;
}
.section-head h2,
.section-head .h2 {
  font-size: 2.625rem;
}
@media only screen and (max-width: 1280px) {
  .section-head h2,
  .section-head .h2 {
    font-size: 2.375rem;
  }
}
@media only screen and (max-width: 767px) {
  .section-head h2,
  .section-head .h2 {
    font-size: 2.25rem;
  }
}
@media only screen and (max-width: 575px) {
  .section-head h2,
  .section-head .h2 {
    font-size: 1.75rem;
  }
}
.section-head h3,
.section-head .h3 {
  font-size: 2.25rem;
}
@media only screen and (max-width: 1280px) {
  .section-head h3,
  .section-head .h3 {
    font-size: 2rem;
  }
}
@media only screen and (max-width: 575px) {
  .section-head h3,
  .section-head .h3 {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 1280px) {
  .section-head {
    margin-bottom: 30px;
  }
}
.section-head.text-center p {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
}

.resize-wrapper {
  margin-left: calc((100% - 1140px) / 2);
}
@media only screen and (max-width: 1191px) {
  .resize-wrapper {
    margin-left: calc((100% - 930px) / 2);
  }
}
@media only screen and (max-width: 991px) {
  .resize-wrapper {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .resize-wrapper {
    margin-left: 15px;
    margin-right: 15px;
  }
}
.resize-wrapper.style-1 {
  margin-left: calc((100% - 1400px) / 2);
}

.scale1 {
  transform: scale(1.3);
}

#loading-area {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999999999;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
#loading-area.loading-page-1 {
  background: #faf8f2;
}
#loading-area.loading-page-1 .item-wrapper {
  height: 60px;
  width: 68px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
#loading-area.loading-page-1 .item-wrapper .item1 {
  position: absolute;
  left: 0;
  animation: leftHand 1.2s linear infinite;
}
#loading-area.loading-page-1 .item-wrapper .item2 {
  position: absolute;
  right: 0;
  animation: rightHand 1.2s linear infinite;
}
#loading-area.loading-page-1 .item-wrapper .item3 {
  position: absolute;
  left: 17px;
  -webkit-animation: HeartUpToDown 1.5s infinite;
  -moz-animation: HeartUpToDown 1.5s infinite;
  animation: HeartUpToDown 1.5s infinite;
}
#loading-area.loading-page-1 .load-text {
  margin-top: 5px;
}
#loading-area.loading-page-1 .load-text .text-load {
  font-weight: 800;
  display: inline-block;
  color: rgba(0, 0, 0, 0.1);
  position: relative;
  font-size: 70px;
  line-height: 1.4;
  font-size: 24px;
  text-align: center;
  user-select: none;
  text-transform: uppercase;
  font-family: var(--font-family-title);
  letter-spacing: -1px;
}
#loading-area.loading-page-1 .load-text .text-load:before {
  animation: lettersLoading 4s infinite;
  color: var(--title);
  content: attr(data-text);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 1.4;
  position: absolute;
  text-transform: uppercase;
}
#loading-area.loading-page-1 .load-text .text-load:nth-child(2):before {
  animation-delay: 0.2s;
}
#loading-area.loading-page-1 .load-text .text-load:nth-child(3):before {
  animation-delay: 0.4s;
}
#loading-area.loading-page-1 .load-text .text-load:nth-child(4):before {
  animation-delay: 0.6s;
}
#loading-area.loading-page-1 .load-text .text-load:nth-child(5):before {
  animation-delay: 0.8s;
}
#loading-area.loading-page-1 .load-text .text-load:nth-child(6):before {
  animation-delay: 1s;
}
#loading-area.loading-page-1 .load-text .text-load:nth-child(7):before {
  animation-delay: 1.2s;
}
#loading-area.loading-page-1 .load-text .text-load:nth-child(8):before {
  animation-delay: 1.4s;
}
#loading-area.loading-page-2 .loading-inner img {
  width: 250px;
}
#loading-area.loading-page-3 .loading-inner {
  width: 100px;
  height: 100px;
  margin: auto;
  animation: animate 1s infinite;
  position: absolute;
}
#loading-area.loading-page-3 .loading-inner div {
  width: 35%;
  height: 35%;
  border-radius: 50%;
  position: absolute;
  animation: dzSpeed 2s infinite ease-in-out;
}
#loading-area.loading-page-3 .loading-inner .item1 {
  background: var(--primary);
  animation-delay: 1.5s;
}
#loading-area.loading-page-3 .loading-inner .item2 {
  background: #f7cf47;
  animation-delay: 1s;
}
#loading-area.loading-page-3 .loading-inner .item3 {
  background: #fa85f8;
  animation-delay: 0.5s;
}
#loading-area.loading-page-3 .loading-inner .item4 {
  background: #fe8282;
}

@keyframes dzSpeed {
  0% {
    border-radius: 50%;
    transform: translate(0%);
  }
  25% {
    border-radius: 0;
    transform: translate(150%) scale(0.5);
  }
  50% {
    border-radius: 50%;
    transform: translate(150%, 150%);
  }
  75% {
    border-radius: 0;
    transform: translate(0, 150%) scale(0.5);
  }
}

@keyframes dotScale {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes HeartUpToDown {
  0% {
    transform: translateY(-35px);
    opacity: 0;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
    transform: translateY(-5px);
  }
}

@keyframes leftHand {
  0% {
    transform: rotate(-20deg) translateX(-20px);
  }
  100% {
    transform: rotate(0) translateX(0);
  }
}

@keyframes rightHand {
  0% {
    transform: rotate(20deg) translateX(20px);
  }
  100% {
    transform: rotate(0) translateX(0);
  }
}

@keyframes lettersLoading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-0 {
  font-size: 0px !important;
}

.font-5 {
  font-size: 5px !important;
}

.font-10 {
  font-size: 10px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-25 {
  font-size: 25px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-35 {
  font-size: 35px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-45 {
  font-size: 45px !important;
}

.font-50 {
  font-size: 50px !important;
}

.font-55 {
  font-size: 55px !important;
}

.font-60 {
  font-size: 60px !important;
}

.font-65 {
  font-size: 65px !important;
}

.font-70 {
  font-size: 70px !important;
}

.font-75 {
  font-size: 75px !important;
}

.font-80 {
  font-size: 80px !important;
}

.font-85 {
  font-size: 85px !important;
}

.font-90 {
  font-size: 90px !important;
}

.font-95 {
  font-size: 95px !important;
}

.font-100 {
  font-size: 100px !important;
}

.font-w0 {
  font-weight: 0 !important;
}

.font-w100 {
  font-weight: 100 !important;
}

.font-w200 {
  font-weight: 200 !important;
}

.font-w300 {
  font-weight: 300 !important;
}

.font-w400 {
  font-weight: 400 !important;
}

.font-w500 {
  font-weight: 500 !important;
}

.font-w600 {
  font-weight: 600 !important;
}

.font-w700 {
  font-weight: 700 !important;
}

.font-w800 {
  font-weight: 800 !important;
}

.font-w900 {
  font-weight: 900 !important;
}

.ls-0 {
  letter-spacing: 0px;
}

.ls-1 {
  letter-spacing: 1px;
}

.ls-2 {
  letter-spacing: 2px;
}

.ls-3 {
  letter-spacing: 3px;
}

.ls-4 {
  letter-spacing: 4px;
}

.ls-5 {
  letter-spacing: 5px;
}

.p-a0 {
  padding: 0px;
}

.p-t0 {
  padding-top: 0px;
}

.p-b0 {
  padding-bottom: 0px;
}

.p-l0 {
  padding-left: 0px;
}

.p-r0 {
  padding-right: 0px;
}

.p-lr0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p-tb0 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.m-a0 {
  margin: 0px;
}

.m-t0 {
  margin-top: 0px;
}

.m-b0 {
  margin-bottom: 0px;
}

.m-l0 {
  margin-left: 0px;
}

.m-r0 {
  margin-right: 0px;
}

.m-lr0 {
  margin-left: 0px;
  margin-right: 0px;
}

.m-tb0 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.p-a5 {
  padding: 5px;
}

.p-t5 {
  padding-top: 5px;
}

.p-b5 {
  padding-bottom: 5px;
}

.p-l5 {
  padding-left: 5px;
}

.p-r5 {
  padding-right: 5px;
}

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.m-a5 {
  margin: 5px;
}

.m-t5 {
  margin-top: 5px;
}

.m-b5 {
  margin-bottom: 5px;
}

.m-l5 {
  margin-left: 5px;
}

.m-r5 {
  margin-right: 5px;
}

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.p-a10 {
  padding: 10px;
}

.p-t10 {
  padding-top: 10px;
}

.p-b10 {
  padding-bottom: 10px;
}

.p-l10 {
  padding-left: 10px;
}

.p-r10 {
  padding-right: 10px;
}

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.m-a10 {
  margin: 10px;
}

.m-t10 {
  margin-top: 10px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-l10 {
  margin-left: 10px;
}

.m-r10 {
  margin-right: 10px;
}

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.p-a15 {
  padding: 15px;
}

.p-t15 {
  padding-top: 15px;
}

.p-b15 {
  padding-bottom: 15px;
}

.p-l15 {
  padding-left: 15px;
}

.p-r15 {
  padding-right: 15px;
}

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.m-a15 {
  margin: 15px;
}

.m-t15 {
  margin-top: 15px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-l15 {
  margin-left: 15px;
}

.m-r15 {
  margin-right: 15px;
}

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.p-a20 {
  padding: 20px;
}

.p-t20 {
  padding-top: 20px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-l20 {
  padding-left: 20px;
}

.p-r20 {
  padding-right: 20px;
}

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.m-a20 {
  margin: 20px;
}

.m-t20 {
  margin-top: 20px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-l20 {
  margin-left: 20px;
}

.m-r20 {
  margin-right: 20px;
}

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.p-a25 {
  padding: 25px;
}

.p-t25 {
  padding-top: 25px;
}

.p-b25 {
  padding-bottom: 25px;
}

.p-l25 {
  padding-left: 25px;
}

.p-r25 {
  padding-right: 25px;
}

.p-lr25 {
  padding-left: 25px;
  padding-right: 25px;
}

.p-tb25 {
  padding-bottom: 25px;
  padding-top: 25px;
}

.m-a25 {
  margin: 25px;
}

.m-t25 {
  margin-top: 25px;
}

.m-b25 {
  margin-bottom: 25px;
}

.m-l25 {
  margin-left: 25px;
}

.m-r25 {
  margin-right: 25px;
}

.m-lr25 {
  margin-left: 25px;
  margin-right: 25px;
}

.m-tb25 {
  margin-bottom: 25px;
  margin-top: 25px;
}

.p-a30 {
  padding: 30px;
}

.p-t30 {
  padding-top: 30px;
}

.p-b30 {
  padding-bottom: 30px;
}

.p-l30 {
  padding-left: 30px;
}

.p-r30 {
  padding-right: 30px;
}

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.m-a30 {
  margin: 30px;
}

.m-t30 {
  margin-top: 30px;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-l30 {
  margin-left: 30px;
}

.m-r30 {
  margin-right: 30px;
}

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.p-a35 {
  padding: 35px;
}

.p-t35 {
  padding-top: 35px;
}

.p-b35 {
  padding-bottom: 35px;
}

.p-l35 {
  padding-left: 35px;
}

.p-r35 {
  padding-right: 35px;
}

.p-lr35 {
  padding-left: 35px;
  padding-right: 35px;
}

.p-tb35 {
  padding-bottom: 35px;
  padding-top: 35px;
}

.m-a35 {
  margin: 35px;
}

.m-t35 {
  margin-top: 35px;
}

.m-b35 {
  margin-bottom: 35px;
}

.m-l35 {
  margin-left: 35px;
}

.m-r35 {
  margin-right: 35px;
}

.m-lr35 {
  margin-left: 35px;
  margin-right: 35px;
}

.m-tb35 {
  margin-bottom: 35px;
  margin-top: 35px;
}

.p-a40 {
  padding: 40px;
}

.p-t40 {
  padding-top: 40px;
}

.p-b40 {
  padding-bottom: 40px;
}

.p-l40 {
  padding-left: 40px;
}

.p-r40 {
  padding-right: 40px;
}

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.m-a40 {
  margin: 40px;
}

.m-t40 {
  margin-top: 40px;
}

.m-b40 {
  margin-bottom: 40px;
}

.m-l40 {
  margin-left: 40px;
}

.m-r40 {
  margin-right: 40px;
}

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.p-a45 {
  padding: 45px;
}

.p-t45 {
  padding-top: 45px;
}

.p-b45 {
  padding-bottom: 45px;
}

.p-l45 {
  padding-left: 45px;
}

.p-r45 {
  padding-right: 45px;
}

.p-lr45 {
  padding-left: 45px;
  padding-right: 45px;
}

.p-tb45 {
  padding-bottom: 45px;
  padding-top: 45px;
}

.m-a45 {
  margin: 45px;
}

.m-t45 {
  margin-top: 45px;
}

.m-b45 {
  margin-bottom: 45px;
}

.m-l45 {
  margin-left: 45px;
}

.m-r45 {
  margin-right: 45px;
}

.m-lr45 {
  margin-left: 45px;
  margin-right: 45px;
}

.m-tb45 {
  margin-bottom: 45px;
  margin-top: 45px;
}

.p-a50 {
  padding: 50px;
}

.p-t50 {
  padding-top: 50px;
}

.p-b50 {
  padding-bottom: 50px;
}

.p-l50 {
  padding-left: 50px;
}

.p-r50 {
  padding-right: 50px;
}

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.m-a50 {
  margin: 50px;
}

.m-t50 {
  margin-top: 50px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-l50 {
  margin-left: 50px;
}

.m-r50 {
  margin-right: 50px;
}

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}

.p-a55 {
  padding: 55px;
}

.p-t55 {
  padding-top: 55px;
}

.p-b55 {
  padding-bottom: 55px;
}

.p-l55 {
  padding-left: 55px;
}

.p-r55 {
  padding-right: 55px;
}

.p-lr55 {
  padding-left: 55px;
  padding-right: 55px;
}

.p-tb55 {
  padding-bottom: 55px;
  padding-top: 55px;
}

.m-a55 {
  margin: 55px;
}

.m-t55 {
  margin-top: 55px;
}

.m-b55 {
  margin-bottom: 55px;
}

.m-l55 {
  margin-left: 55px;
}

.m-r55 {
  margin-right: 55px;
}

.m-lr55 {
  margin-left: 55px;
  margin-right: 55px;
}

.m-tb55 {
  margin-bottom: 55px;
  margin-top: 55px;
}

.p-a60 {
  padding: 60px;
}

.p-t60 {
  padding-top: 60px;
}

.p-b60 {
  padding-bottom: 60px;
}

.p-l60 {
  padding-left: 60px;
}

.p-r60 {
  padding-right: 60px;
}

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.m-a60 {
  margin: 60px;
}

.m-t60 {
  margin-top: 60px;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-l60 {
  margin-left: 60px;
}

.m-r60 {
  margin-right: 60px;
}

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}

.p-a65 {
  padding: 65px;
}

.p-t65 {
  padding-top: 65px;
}

.p-b65 {
  padding-bottom: 65px;
}

.p-l65 {
  padding-left: 65px;
}

.p-r65 {
  padding-right: 65px;
}

.p-lr65 {
  padding-left: 65px;
  padding-right: 65px;
}

.p-tb65 {
  padding-bottom: 65px;
  padding-top: 65px;
}

.m-a65 {
  margin: 65px;
}

.m-t65 {
  margin-top: 65px;
}

.m-b65 {
  margin-bottom: 65px;
}

.m-l65 {
  margin-left: 65px;
}

.m-r65 {
  margin-right: 65px;
}

.m-lr65 {
  margin-left: 65px;
  margin-right: 65px;
}

.m-tb65 {
  margin-bottom: 65px;
  margin-top: 65px;
}

.p-a70 {
  padding: 70px;
}

.p-t70 {
  padding-top: 70px;
}

.p-b70 {
  padding-bottom: 70px;
}

.p-l70 {
  padding-left: 70px;
}

.p-r70 {
  padding-right: 70px;
}

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}

.m-a70 {
  margin: 70px;
}

.m-t70 {
  margin-top: 70px;
}

.m-b70 {
  margin-bottom: 70px;
}

.m-l70 {
  margin-left: 70px;
}

.m-r70 {
  margin-right: 70px;
}

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}

.p-a75 {
  padding: 75px;
}

.p-t75 {
  padding-top: 75px;
}

.p-b75 {
  padding-bottom: 75px;
}

.p-l75 {
  padding-left: 75px;
}

.p-r75 {
  padding-right: 75px;
}

.p-lr75 {
  padding-left: 75px;
  padding-right: 75px;
}

.p-tb75 {
  padding-bottom: 75px;
  padding-top: 75px;
}

.m-a75 {
  margin: 75px;
}

.m-t75 {
  margin-top: 75px;
}

.m-b75 {
  margin-bottom: 75px;
}

.m-l75 {
  margin-left: 75px;
}

.m-r75 {
  margin-right: 75px;
}

.m-lr75 {
  margin-left: 75px;
  margin-right: 75px;
}

.m-tb75 {
  margin-bottom: 75px;
  margin-top: 75px;
}

.p-a80 {
  padding: 80px;
}

.p-t80 {
  padding-top: 80px;
}

.p-b80 {
  padding-bottom: 80px;
}

.p-l80 {
  padding-left: 80px;
}

.p-r80 {
  padding-right: 80px;
}

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}

.m-a80 {
  margin: 80px;
}

.m-t80 {
  margin-top: 80px;
}

.m-b80 {
  margin-bottom: 80px;
}

.m-l80 {
  margin-left: 80px;
}

.m-r80 {
  margin-right: 80px;
}

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}

.p-a85 {
  padding: 85px;
}

.p-t85 {
  padding-top: 85px;
}

.p-b85 {
  padding-bottom: 85px;
}

.p-l85 {
  padding-left: 85px;
}

.p-r85 {
  padding-right: 85px;
}

.p-lr85 {
  padding-left: 85px;
  padding-right: 85px;
}

.p-tb85 {
  padding-bottom: 85px;
  padding-top: 85px;
}

.m-a85 {
  margin: 85px;
}

.m-t85 {
  margin-top: 85px;
}

.m-b85 {
  margin-bottom: 85px;
}

.m-l85 {
  margin-left: 85px;
}

.m-r85 {
  margin-right: 85px;
}

.m-lr85 {
  margin-left: 85px;
  margin-right: 85px;
}

.m-tb85 {
  margin-bottom: 85px;
  margin-top: 85px;
}

.p-a90 {
  padding: 90px;
}

.p-t90 {
  padding-top: 90px;
}

.p-b90 {
  padding-bottom: 90px;
}

.p-l90 {
  padding-left: 90px;
}

.p-r90 {
  padding-right: 90px;
}

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}

.m-a90 {
  margin: 90px;
}

.m-t90 {
  margin-top: 90px;
}

.m-b90 {
  margin-bottom: 90px;
}

.m-l90 {
  margin-left: 90px;
}

.m-r90 {
  margin-right: 90px;
}

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}

.p-a95 {
  padding: 95px;
}

.p-t95 {
  padding-top: 95px;
}

.p-b95 {
  padding-bottom: 95px;
}

.p-l95 {
  padding-left: 95px;
}

.p-r95 {
  padding-right: 95px;
}

.p-lr95 {
  padding-left: 95px;
  padding-right: 95px;
}

.p-tb95 {
  padding-bottom: 95px;
  padding-top: 95px;
}

.m-a95 {
  margin: 95px;
}

.m-t95 {
  margin-top: 95px;
}

.m-b95 {
  margin-bottom: 95px;
}

.m-l95 {
  margin-left: 95px;
}

.m-r95 {
  margin-right: 95px;
}

.m-lr95 {
  margin-left: 95px;
  margin-right: 95px;
}

.m-tb95 {
  margin-bottom: 95px;
  margin-top: 95px;
}

.p-a100 {
  padding: 100px;
}

.p-t100 {
  padding-top: 100px;
}

.p-b100 {
  padding-bottom: 100px;
}

.p-l100 {
  padding-left: 100px;
}

.p-r100 {
  padding-right: 100px;
}

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.m-a100 {
  margin: 100px;
}

.m-t100 {
  margin-top: 100px;
}

.m-b100 {
  margin-bottom: 100px;
}

.m-l100 {
  margin-left: 100px;
}

.m-r100 {
  margin-right: 100px;
}

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}

@media only screen and (max-width: 1200px) {
  .m-lg-t0 {
    margin-top: 0px;
  }
  .m-lg-b0 {
    margin-bottom: 0px;
  }
  .m-lg-t5 {
    margin-top: 5px;
  }
  .m-lg-b5 {
    margin-bottom: 5px;
  }
  .m-lg-t10 {
    margin-top: 10px;
  }
  .m-lg-b10 {
    margin-bottom: 10px;
  }
  .m-lg-t15 {
    margin-top: 15px;
  }
  .m-lg-b15 {
    margin-bottom: 15px;
  }
  .m-lg-t20 {
    margin-top: 20px;
  }
  .m-lg-b20 {
    margin-bottom: 20px;
  }
  .m-lg-t25 {
    margin-top: 25px;
  }
  .m-lg-b25 {
    margin-bottom: 25px;
  }
  .m-lg-t30 {
    margin-top: 30px;
  }
  .m-lg-b30 {
    margin-bottom: 30px;
  }
  .m-lg-t35 {
    margin-top: 35px;
  }
  .m-lg-b35 {
    margin-bottom: 35px;
  }
  .m-lg-t40 {
    margin-top: 40px;
  }
  .m-lg-b40 {
    margin-bottom: 40px;
  }
  .m-lg-t45 {
    margin-top: 45px;
  }
  .m-lg-b45 {
    margin-bottom: 45px;
  }
  .m-lg-t50 {
    margin-top: 50px;
  }
  .m-lg-b50 {
    margin-bottom: 50px;
  }
  .m-lg-t55 {
    margin-top: 55px;
  }
  .m-lg-b55 {
    margin-bottom: 55px;
  }
  .m-lg-t60 {
    margin-top: 60px;
  }
  .m-lg-b60 {
    margin-bottom: 60px;
  }
  .m-lg-t65 {
    margin-top: 65px;
  }
  .m-lg-b65 {
    margin-bottom: 65px;
  }
  .m-lg-t70 {
    margin-top: 70px;
  }
  .m-lg-b70 {
    margin-bottom: 70px;
  }
  .m-lg-t75 {
    margin-top: 75px;
  }
  .m-lg-b75 {
    margin-bottom: 75px;
  }
  .m-lg-t80 {
    margin-top: 80px;
  }
  .m-lg-b80 {
    margin-bottom: 80px;
  }
  .m-lg-t85 {
    margin-top: 85px;
  }
  .m-lg-b85 {
    margin-bottom: 85px;
  }
  .m-lg-t90 {
    margin-top: 90px;
  }
  .m-lg-b90 {
    margin-bottom: 90px;
  }
  .m-lg-t95 {
    margin-top: 95px;
  }
  .m-lg-b95 {
    margin-bottom: 95px;
  }
  .m-lg-t100 {
    margin-top: 100px;
  }
  .m-lg-b100 {
    margin-bottom: 100px;
  }
  .m-lg-r0 {
    margin-right: 0;
  }
  .m-lg-l0 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .m-md-t0 {
    margin-top: 0px;
  }
  .m-md-b0 {
    margin-bottom: 0px;
  }
  .m-md-t5 {
    margin-top: 5px;
  }
  .m-md-b5 {
    margin-bottom: 5px;
  }
  .m-md-t10 {
    margin-top: 10px;
  }
  .m-md-b10 {
    margin-bottom: 10px;
  }
  .m-md-t15 {
    margin-top: 15px;
  }
  .m-md-b15 {
    margin-bottom: 15px;
  }
  .m-md-t20 {
    margin-top: 20px;
  }
  .m-md-b20 {
    margin-bottom: 20px;
  }
  .m-md-t25 {
    margin-top: 25px;
  }
  .m-md-b25 {
    margin-bottom: 25px;
  }
  .m-md-t30 {
    margin-top: 30px;
  }
  .m-md-b30 {
    margin-bottom: 30px;
  }
  .m-md-t35 {
    margin-top: 35px;
  }
  .m-md-b35 {
    margin-bottom: 35px;
  }
  .m-md-t40 {
    margin-top: 40px;
  }
  .m-md-b40 {
    margin-bottom: 40px;
  }
  .m-md-t45 {
    margin-top: 45px;
  }
  .m-md-b45 {
    margin-bottom: 45px;
  }
  .m-md-t50 {
    margin-top: 50px;
  }
  .m-md-b50 {
    margin-bottom: 50px;
  }
  .m-md-t55 {
    margin-top: 55px;
  }
  .m-md-b55 {
    margin-bottom: 55px;
  }
  .m-md-t60 {
    margin-top: 60px;
  }
  .m-md-b60 {
    margin-bottom: 60px;
  }
  .m-md-t65 {
    margin-top: 65px;
  }
  .m-md-b65 {
    margin-bottom: 65px;
  }
  .m-md-t70 {
    margin-top: 70px;
  }
  .m-md-b70 {
    margin-bottom: 70px;
  }
  .m-md-t75 {
    margin-top: 75px;
  }
  .m-md-b75 {
    margin-bottom: 75px;
  }
  .m-md-t80 {
    margin-top: 80px;
  }
  .m-md-b80 {
    margin-bottom: 80px;
  }
  .m-md-t85 {
    margin-top: 85px;
  }
  .m-md-b85 {
    margin-bottom: 85px;
  }
  .m-md-t90 {
    margin-top: 90px;
  }
  .m-md-b90 {
    margin-bottom: 90px;
  }
  .m-md-t95 {
    margin-top: 95px;
  }
  .m-md-b95 {
    margin-bottom: 95px;
  }
  .m-md-t100 {
    margin-top: 100px;
  }
  .m-md-b100 {
    margin-bottom: 100px;
  }
  .m-md-r0 {
    margin-right: 0;
  }
  .m-md-l0 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .m-sm-t0 {
    margin-top: 0px;
  }
  .m-sm-b0 {
    margin-bottom: 0px;
  }
  .m-sm-t5 {
    margin-top: 5px;
  }
  .m-sm-b5 {
    margin-bottom: 5px;
  }
  .m-sm-t10 {
    margin-top: 10px;
  }
  .m-sm-b10 {
    margin-bottom: 10px;
  }
  .m-sm-t15 {
    margin-top: 15px;
  }
  .m-sm-b15 {
    margin-bottom: 15px;
  }
  .m-sm-t20 {
    margin-top: 20px;
  }
  .m-sm-b20 {
    margin-bottom: 20px;
  }
  .m-sm-t25 {
    margin-top: 25px;
  }
  .m-sm-b25 {
    margin-bottom: 25px;
  }
  .m-sm-t30 {
    margin-top: 30px;
  }
  .m-sm-b30 {
    margin-bottom: 30px;
  }
  .m-sm-t35 {
    margin-top: 35px;
  }
  .m-sm-b35 {
    margin-bottom: 35px;
  }
  .m-sm-t40 {
    margin-top: 40px;
  }
  .m-sm-b40 {
    margin-bottom: 40px;
  }
  .m-sm-t45 {
    margin-top: 45px;
  }
  .m-sm-b45 {
    margin-bottom: 45px;
  }
  .m-sm-t50 {
    margin-top: 50px;
  }
  .m-sm-b50 {
    margin-bottom: 50px;
  }
  .m-sm-t55 {
    margin-top: 55px;
  }
  .m-sm-b55 {
    margin-bottom: 55px;
  }
  .m-sm-t60 {
    margin-top: 60px;
  }
  .m-sm-b60 {
    margin-bottom: 60px;
  }
  .m-sm-t65 {
    margin-top: 65px;
  }
  .m-sm-b65 {
    margin-bottom: 65px;
  }
  .m-sm-t70 {
    margin-top: 70px;
  }
  .m-sm-b70 {
    margin-bottom: 70px;
  }
  .m-sm-t75 {
    margin-top: 75px;
  }
  .m-sm-b75 {
    margin-bottom: 75px;
  }
  .m-sm-t80 {
    margin-top: 80px;
  }
  .m-sm-b80 {
    margin-bottom: 80px;
  }
  .m-sm-t85 {
    margin-top: 85px;
  }
  .m-sm-b85 {
    margin-bottom: 85px;
  }
  .m-sm-t90 {
    margin-top: 90px;
  }
  .m-sm-b90 {
    margin-bottom: 90px;
  }
  .m-sm-t95 {
    margin-top: 95px;
  }
  .m-sm-b95 {
    margin-bottom: 95px;
  }
  .m-sm-t100 {
    margin-top: 100px;
  }
  .m-sm-b100 {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 576px) {
  .m-xs-t0 {
    margin-top: 0px;
  }
  .m-xs-b0 {
    margin-bottom: 0px;
  }
  .m-xs-t5 {
    margin-top: 5px;
  }
  .m-xs-b5 {
    margin-bottom: 5px;
  }
  .m-xs-t10 {
    margin-top: 10px;
  }
  .m-xs-b10 {
    margin-bottom: 10px;
  }
  .m-xs-t15 {
    margin-top: 15px;
  }
  .m-xs-b15 {
    margin-bottom: 15px;
  }
  .m-xs-t20 {
    margin-top: 20px;
  }
  .m-xs-b20 {
    margin-bottom: 20px;
  }
  .m-xs-t25 {
    margin-top: 25px;
  }
  .m-xs-b25 {
    margin-bottom: 25px;
  }
  .m-xs-t30 {
    margin-top: 30px;
  }
  .m-xs-b30 {
    margin-bottom: 30px;
  }
  .m-xs-t35 {
    margin-top: 35px;
  }
  .m-xs-b35 {
    margin-bottom: 35px;
  }
  .m-xs-t40 {
    margin-top: 40px;
  }
  .m-xs-b40 {
    margin-bottom: 40px;
  }
  .m-xs-t45 {
    margin-top: 45px;
  }
  .m-xs-b45 {
    margin-bottom: 45px;
  }
  .m-xs-t50 {
    margin-top: 50px;
  }
  .m-xs-b50 {
    margin-bottom: 50px;
  }
  .m-xs-t55 {
    margin-top: 55px;
  }
  .m-xs-b55 {
    margin-bottom: 55px;
  }
  .m-xs-t60 {
    margin-top: 60px;
  }
  .m-xs-b60 {
    margin-bottom: 60px;
  }
  .m-xs-t65 {
    margin-top: 65px;
  }
  .m-xs-b65 {
    margin-bottom: 65px;
  }
  .m-xs-t70 {
    margin-top: 70px;
  }
  .m-xs-b70 {
    margin-bottom: 70px;
  }
  .m-xs-t75 {
    margin-top: 75px;
  }
  .m-xs-b75 {
    margin-bottom: 75px;
  }
  .m-xs-t80 {
    margin-top: 80px;
  }
  .m-xs-b80 {
    margin-bottom: 80px;
  }
  .m-xs-t85 {
    margin-top: 85px;
  }
  .m-xs-b85 {
    margin-bottom: 85px;
  }
  .m-xs-t90 {
    margin-top: 90px;
  }
  .m-xs-b90 {
    margin-bottom: 90px;
  }
  .m-xs-t95 {
    margin-top: 95px;
  }
  .m-xs-b95 {
    margin-bottom: 95px;
  }
  .m-xs-t100 {
    margin-top: 100px;
  }
  .m-xs-b100 {
    margin-bottom: 100px;
  }
}

.max-w50 {
  max-width: 50px;
}

.max-w60 {
  max-width: 60px;
}

.max-w80 {
  max-width: 80px;
}

.max-w100 {
  max-width: 100px;
}

.max-w200 {
  max-width: 200px;
}

.max-w300 {
  max-width: 300px;
}

.max-w400 {
  max-width: 400px;
}

.max-w500 {
  max-width: 500px;
}

.max-w600 {
  max-width: 600px;
}

.max-w700 {
  max-width: 700px;
}

.max-w800 {
  max-width: 800px;
}

.max-w900 {
  max-width: 900px;
}

.max-w1000 {
  max-width: 1000px;
}

.bg-img-fix {
  background-attachment: fixed;
  background-size: cover;
}

.full-img {
  width: 100%;
}

.sticky-top {
  top: 120px;
}

.page-title {
  padding: 65px 0px 45px;
  position: relative;
  background: #faf8f2;
  background-size: cover;
  background-position: center;
}
.page-title p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #7272a8;
  text-transform: capitalize;
}
@media only screen and (max-width: 575px) {
  .page-title p {
    font-size: 16px;
  }
}
.page-title h3,
.page-title .h3 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: capitalize;
}
@media only screen and (max-width: 575px) {
  .page-title h3,
  .page-title .h3 {
    font-size: 28px;
  }
}

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

section {
  position: relative;
  z-index: 1;
}

.rounded-xl {
  border-radius: 50% !important;
}

.rounded-lg {
  border-radius: 20px !important;
}

.rounded-md {
  border-radius: 10px !important;
}

.rounded-sm {
  border-radius: 5px !important;
}

.text-orange {
  color: #ff8853;
}

.text-yellow {
  color: #ffa808;
}

.text-skyblue {
  color: #029e76;
}

.text-red {
  color: #ff0003;
}

.text-green {
  color: #029e76;
}

.text-blue {
  color: #5543d1;
}

.bg-gray {
  background-color: #f3f4f8;
}

.bg-orange {
  background-color: #ff8853;
}

.bg-yellow {
  background-color: #ffa808;
}

.bg-skyblue {
  background-color: #00aeff;
}

.bg-red {
  background-color: #ff0003;
}

.bg-green {
  background-color: #029e76;
}

.bg-blue {
  background-color: #5543d1;
}

.bg-pink {
  background-color: #ff5e78;
}

.text-black {
  color: #071c35;
}

.bg-purple {
  background-color: #8007e6;
}

.scale8 {
  transform: scale(1.85);
  -moz-transform: scale(1.85);
  -webkit-transform: scale(1.85);
  -ms-transform: scale(1.85);
  -o-transform: scale(1.85);
}

.scale7 {
  transform: scale(1.7);
  -moz-transform: scale(1.7);
  -webkit-transform: scale(1.7);
  -ms-transform: scale(1.7);
  -o-transform: scale(1.7);
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.scale08 {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

.scale05 {
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
}

.tl {
  transform-origin: left;
  -moz-transform-origin: left;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  -o-transform-origin: left;
}

.shadow-none {
  box-shadow: unset;
}

.separator {
  height: 1px;
  width: 100%;
  background: lightgrey;
}

.separator-2 {
  width: 55px;
  height: 5px;
  border-radius: 5px;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*=== Layout ===*/
.logo-header {
  display: table;
  float: left;
  vertical-align: middle;
  /* padding-left: 4.5rem; */
  color: #efbb20;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 100px;
  height: 100px;
  position: relative;
  z-index: 9;
}
@media only screen and (max-width: 991px) {
  .logo-header {
    width: 180px;
    height: 80px;
    padding-left: 1rem;
  }
}
@media only screen and (max-width: 575px) {
  .logo-header {
    width: 150px;
    height: 50px;
    padding-top: 10px;
  }
}
.logo-header a {
  display: table-cell;
  vertical-align: middle;
}
.logo-header img {
  max-width: 50px;
  object-fit: contain;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .logo-header img {
    width: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .logo-header img {
    max-width: 50px;
  }
}
.logo-header span {
  font-size: 20px;
  letter-spacing: 20px;
}

.top-bar {
  background-color: #fff;
  padding: 5px 0;
  position: relative;
  border-bottom: 1px solid #d7d7d7;
}
.top-bar .dz-topbar-left {
  float: left;
}
.top-bar .dz-topbar-left li {
  padding-right: 20px;
}
.top-bar .dz-topbar-right {
  float: right;
}
.top-bar .dz-topbar-right li {
  padding-left: 15px;
}
.top-bar .dz-social li a {
  color: inherit;
}
.top-bar .dz-topbar-inner {
  margin-left: -15px;
  margin-right: -15px;
}
.top-bar .dz-topbar-center,
.top-bar .dz-topbar-left,
.top-bar .dz-topbar-right {
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 600;
}
.top-bar .dz-topbar-center ul,
.top-bar .dz-topbar-left ul,
.top-bar .dz-topbar-right ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.top-bar .dz-topbar-center ul li,
.top-bar .dz-topbar-left ul li,
.top-bar .dz-topbar-right ul li {
  display: inline-block;
  color: var(--title);
  font-weight: 400;
  font-size: 15px;
}
.top-bar .dz-topbar-center ul i,
.top-bar .dz-topbar-left ul i,
.top-bar .dz-topbar-right ul i {
  margin-right: 5px;
  color: var(--primary);
}
.top-bar .dz-topbar-center .dz-social-icon li,
.top-bar .dz-topbar-left .dz-social-icon li,
.top-bar .dz-topbar-right .dz-social-icon li {
  padding-left: 0;
}
@media only screen and (max-width: 991px) {
  .top-bar {
    display: none;
  }
}

.dz-quik-search {
  background: rgba(33, 37, 41, 0.95);
  position: fixed;
  height: 100%;
  width: 100%;
  top: -100%;
  left: 0;
  padding: 0px 30px;
  z-index: 999;
  overflow: hidden;
  display: none;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-quik-search form {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  top: 50%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
  transform: translateY(-50%);
}
.dz-quik-search .form-control,
.dz-quik-search .default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .dz-quik-search .btn.dropdown-toggle,
.dz-quik-search .default-select.style-2 .dropdown-toggle.wp-block-button__link,
.default-select.style-2 .dz-quik-search .dropdown-toggle.wp-block-button__link,
.dz-quik-search .default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .dz-quik-search .btn.dropdown-toggle,
.dz-quik-search .default-select.style-3 .dropdown-toggle.wp-block-button__link,
.default-select.style-3 .dz-quik-search .dropdown-toggle.wp-block-button__link,
.dz-quik-search .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dz-quik-search select,
.dz-quik-search .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dz-quik-search select,
.dz-quik-search .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .dz-quik-search input,
.dz-quik-search .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .dz-quik-search input,
.dz-quik-search .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .dz-quik-search input,
.dz-quik-search .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .dz-quik-search textarea {
  padding: 15px 60px 15px 15px;
  width: 100%;
  height: 90px;
  border: none;
  background: 0 0;
  color: #fff;
  font-size: 20px;
  border-bottom: 2px solid #fff;
  border-radius: 0;
}
.dz-quik-search .form-control::-webkit-input-placeholder,
.dz-quik-search
  .default-select.style-2
  .btn.dropdown-toggle::-webkit-input-placeholder,
.default-select.style-2
  .dz-quik-search
  .btn.dropdown-toggle::-webkit-input-placeholder,
.dz-quik-search
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link::-webkit-input-placeholder,
.default-select.style-2
  .dz-quik-search
  .dropdown-toggle.wp-block-button__link::-webkit-input-placeholder,
.dz-quik-search
  .default-select.style-3
  .btn.dropdown-toggle::-webkit-input-placeholder,
.default-select.style-3
  .dz-quik-search
  .btn.dropdown-toggle::-webkit-input-placeholder,
.dz-quik-search
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link::-webkit-input-placeholder,
.default-select.style-3
  .dz-quik-search
  .dropdown-toggle.wp-block-button__link::-webkit-input-placeholder,
.dz-quik-search .wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-categories-dropdown .dz-quik-search select::-webkit-input-placeholder,
.dz-quik-search .wp-block-archives-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown .dz-quik-search select::-webkit-input-placeholder,
.dz-quik-search
  .comment-respond
  .comment-form
  p.comment-form-author
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .dz-quik-search
  input::-webkit-input-placeholder,
.dz-quik-search
  .comment-respond
  .comment-form
  p.comment-form-email
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .dz-quik-search
  input::-webkit-input-placeholder,
.dz-quik-search
  .comment-respond
  .comment-form
  p.comment-form-url
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .dz-quik-search
  input::-webkit-input-placeholder,
.dz-quik-search
  .comment-respond
  .comment-form
  p
  textarea::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p
  .dz-quik-search
  textarea::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dz-quik-search .form-control:-ms-input-placeholder,
.dz-quik-search
  .default-select.style-2
  .btn.dropdown-toggle:-ms-input-placeholder,
.default-select.style-2
  .dz-quik-search
  .btn.dropdown-toggle:-ms-input-placeholder,
.dz-quik-search
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:-ms-input-placeholder,
.default-select.style-2
  .dz-quik-search
  .dropdown-toggle.wp-block-button__link:-ms-input-placeholder,
.dz-quik-search
  .default-select.style-3
  .btn.dropdown-toggle:-ms-input-placeholder,
.default-select.style-3
  .dz-quik-search
  .btn.dropdown-toggle:-ms-input-placeholder,
.dz-quik-search
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:-ms-input-placeholder,
.default-select.style-3
  .dz-quik-search
  .dropdown-toggle.wp-block-button__link:-ms-input-placeholder,
.dz-quik-search .wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-categories-dropdown .dz-quik-search select:-ms-input-placeholder,
.dz-quik-search .wp-block-archives-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown .dz-quik-search select:-ms-input-placeholder,
.dz-quik-search
  .comment-respond
  .comment-form
  p.comment-form-author
  input:-ms-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .dz-quik-search
  input:-ms-input-placeholder,
.dz-quik-search
  .comment-respond
  .comment-form
  p.comment-form-email
  input:-ms-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .dz-quik-search
  input:-ms-input-placeholder,
.dz-quik-search
  .comment-respond
  .comment-form
  p.comment-form-url
  input:-ms-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .dz-quik-search
  input:-ms-input-placeholder,
.dz-quik-search .comment-respond .comment-form p textarea:-ms-input-placeholder,
.comment-respond
  .comment-form
  p
  .dz-quik-search
  textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dz-quik-search .form-control::placeholder,
.dz-quik-search .default-select.style-2 .btn.dropdown-toggle::placeholder,
.default-select.style-2 .dz-quik-search .btn.dropdown-toggle::placeholder,
.dz-quik-search
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-2
  .dz-quik-search
  .dropdown-toggle.wp-block-button__link::placeholder,
.dz-quik-search .default-select.style-3 .btn.dropdown-toggle::placeholder,
.default-select.style-3 .dz-quik-search .btn.dropdown-toggle::placeholder,
.dz-quik-search
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3
  .dz-quik-search
  .dropdown-toggle.wp-block-button__link::placeholder,
.dz-quik-search .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .dz-quik-search select::placeholder,
.dz-quik-search .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .dz-quik-search select::placeholder,
.dz-quik-search
  .comment-respond
  .comment-form
  p.comment-form-author
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .dz-quik-search
  input::placeholder,
.dz-quik-search
  .comment-respond
  .comment-form
  p.comment-form-email
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .dz-quik-search
  input::placeholder,
.dz-quik-search
  .comment-respond
  .comment-form
  p.comment-form-url
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .dz-quik-search
  input::placeholder,
.dz-quik-search .comment-respond .comment-form p textarea::placeholder,
.comment-respond .comment-form p .dz-quik-search textarea::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dz-quik-search span {
  position: absolute;
  right: 15px;
  top: 50%;
  margin: -15px 0;
  height: 25px;
  font-size: 20px;
  cursor: pointer;
}
.dz-quik-search.On {
  top: 0;
}

.site-header {
  position: relative;
  z-index: 9999;
}
.site-header .main-bar {
  background: #fff;
  width: 100%;
  position: relative;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
}
.site-header .container {
  position: relative;
}
.site-header .extra-nav {
  float: right;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  height: 80px;
}
.site-header .extra-nav #quik-search-btn {
  color: #212529;
  cursor: pointer;
  margin-right: 0px;
  border: 0;
  width: 45px;
  height: 45px;
  background: #ebebeb;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  border-radius: 45px;
}
@media only screen and (max-width: 575px) {
  .site-header .extra-nav .btn,
  .site-header .extra-nav .wp-block-button__link {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.site-header .extra-nav ul li {
  display: inline-block;
}
.site-header .extra-nav ul li .icon {
  padding: 0;
  background: transparent;
  min-width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
}
.site-header .extra-nav .extra-cell > * {
  margin-left: 30px;
}
@media only screen and (max-width: 1199px) {
  .site-header .extra-nav {
    height: 100px;
  }
  .site-header .extra-nav .extra-cell a.btn,
  .site-header .extra-nav .extra-cell a.wp-block-button__link {
    width: 50px;
    height: 50px;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  .site-header .extra-nav .extra-cell a span {
    display: none;
  }
  .site-header .extra-nav .extra-cell a i {
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 991px) {
  .site-header .extra-nav {
    height: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .site-header .extra-nav {
    display: none;
  }
}
.site-header .nav-link-list li {
  padding-right: 10px;
}
.site-header .nav-link-list li a {
  font-size: 16px;
  color: var(--title);
  text-transform: uppercase;
  font-weight: 500;
}
.site-header .nav-link-list li:after {
  content: "/";
  margin-left: 12px;
  font-size: 12px;
  font-weight: 700;
  opacity: 0.5;
}
.site-header .nav-link-list li:last-child:after {
  content: none;
}
.site-header .nav-link-list li:last-child {
  padding-right: 0;
}

.navbar-toggler {
  border: 0 solid #efbb20;
  font-size: 16px;
  line-height: 24px;
  margin: 40px 0 39px 15px;
  padding: 0;
  float: right;
  outline: none !important;
}
@media only screen and (max-width: 991px) {
  .navbar-toggler {
    margin: 15px 0 15px 15px;
  }
}
@media only screen and (max-width: 575px) {
  .navbar-toggler {
    margin: 15px ​0 15px 15p;
  }
}

.navicon {
  width: 50px;
  height: 50px;
  /* background: var(--primary); */
  background: var(--bs-warning);
  /* box-shadow: 0 5px 15px -10px var(--primary); */
  box-shadow: 0 5px 15px -10px var(--bs-warning);
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 575px) {
  .navicon {
    width: 40px;
    height: 40px;
  }
}
.navicon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  opacity: 1;
  background: #fff;

  left: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.navicon span:nth-child(1) {
  top: 15px;
  width: 30px;
}
@media only screen and (max-width: 575px) {
  .navicon span:nth-child(1) {
    top: 11px;
    width: 20px;
  }
}
.navicon span:nth-child(2) {
  top: 23.5px;
  width: 25px;
}
@media only screen and (max-width: 575px) {
  .navicon span:nth-child(2) {
    top: 18.5px;
    width: 16px;
  }
}
.navicon span:nth-child(3) {
  top: 33px;
  width: 20px;
}
@media only screen and (max-width: 575px) {
  .navicon span:nth-child(3) {
    top: 27px;
    width: 13px;
  }
}
.navicon.open span:nth-child(1) {
  top: 25px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
@media only screen and (max-width: 575px) {
  .navicon.open span:nth-child(1) {
    top: 20px;
  }
}
.navicon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.navicon.open span:nth-child(3) {
  top: 25px;
  width: 30px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
@media only screen and (max-width: 575px) {
  .navicon.open span:nth-child(3) {
    top: 20px;
    width: 20px;
  }
}

.header-tb .navicon span {
  background: #fff;
}

.header-nav {
  padding: 0;
}
@media only screen and (max-width: 991px) {
  .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh !important;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }
}
@media only screen and (max-width: 991px) {

  .header-nav.show {
    left: -1px;
    -webkit-transition: all 0.7s;
    -ms-transition: all 0.7s;
    transition: all 0.7s;
    margin: 0;
    width: 280px;
  }
}
.header-nav .logo-header {
  display: none;
}
@media only screen and (max-width: 991px) {

  .header-nav .logo-header {
    padding: 30px 20px;
    float: none;
    height: auto;
    width: 240px;
  }
}
.header-nav .nav {
  float: right;
  padding: 10px 0;
  font-size: 0;
}
.header-nav .nav > li {
  margin: 0px;
  font-weight: 400;
  position: relative;
}
.header-nav .nav > li.has-mega-menu {
  position: inherit;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
}
.header-nav .nav > li.sub-menu-down > a:after {
  content: "\f078";
  font-size: 9px;
  margin-left: 5px;
  margin-top: 0;
  vertical-align: middle;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.sub-menu-down > a:after {
    content: "\f054";
    /* background-color: var(--primary); */
    background-color: #416bdb;
    color: #fff;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 12px;
    margin: 0;
    border-radius: 4px;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.sub-menu-down > a:after:before {
    content: "\f054";
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.sub-menu-down.open > a:after {
    content: "\f078";
    /* background: #333; */
    background: var(--bs-warning);
  }
}
.header-nav .nav > li > a {
  color: #777;
  font-size: 16px;
  padding: 30px 15px;
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
  position: relative;
  border-width: 0 0 0 1px;
  line-height: 1.1;
  /* text-transform: capitalize; */
  font-family: var(--font-family-base);
}
@media only screen and (max-width: 1199px) {
  .header-nav .nav > li > a {
    padding: 40px 15px;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li > a {
    padding: 8px 0;
    display: block;
    line-height: 30px;
    font-size: 16px;
  }
}
.header-nav .nav > li > a span {
  display: inline-block;
}
.header-nav .nav > li .mega-menu,
.header-nav .nav > li .sub-menu {
  background-color: #fff;
  display: block;
  left: 0;
  list-style: none;
  opacity: 0;
  padding: 10px 0;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
  border-radius: var(--border-radius-base);
  margin-top: 20px;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu,
  .header-nav .nav > li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid #eee;
    padding: 10px 0;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
  .header-nav .nav > li .mega-menu > li > a i,
  .header-nav .nav > li .sub-menu > li > a i {
    color: var(--primary) !important;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    font-size: 14px !important;
    margin: 0 !important;
    background: var(--rgba-primary-2);
    border-radius: 4px;
  }
}
.header-nav .nav > li .mega-menu li,
.header-nav .nav > li .sub-menu li {
  position: relative;
}
.header-nav .nav > li .mega-menu li a,
.header-nav .nav > li .sub-menu li a {
  color: #777;
  display: block;
  font-size: 15px;
  padding: 8px 25px;
  line-height: 1.3;
  text-transform: capitalize;
  position: relative;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  font-weight: 500;
}
.header-nav .nav > li .mega-menu li a:after,
.header-nav .nav > li .sub-menu li a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background: var(--primary);
  left: 20px;
  top: 16px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li a,
  .header-nav .nav > li .sub-menu li a {
    padding: 2px 0 1px 16px;
    display: block;
    line-height: 30px;
    position: relative;
  }
  .header-nav .nav > li .mega-menu li a:after,
  .header-nav .nav > li .sub-menu li a:after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    width: auto;
    height: auto;
    background: 0 0 !important;
    top: 3px;
    color: #262f5a;
    font-size: 12px;
    position: absolute;
    left: 0;
    font-weight: 700;
  }
}
/* leftnavbarlinkhover */
.header-nav .nav > li .mega-menu li:hover > a,
.header-nav .nav > li .sub-menu li:hover > a {
  /* color: var(--primary); */
  color: var(--bs-warning) !important;
  padding: 8px 20px 8px 37px;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li:hover > a,
  .header-nav .nav > li .sub-menu li:hover > a {
    padding: 2px 0 1px 16px;
    background: transparent;
  }
}
.header-nav .nav > li .mega-menu li:hover > a:after,
.header-nav .nav > li .sub-menu li:hover > a:after {
  width: 10px;
}
.header-nav .nav > li .mega-menu li > .sub-menu,
.header-nav .nav > li .sub-menu li > .sub-menu {
  left: 220px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li > .sub-menu,
  .header-nav .nav > li .sub-menu li > .sub-menu {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu .mega-menu,
  .header-nav .nav > li .mega-menu .sub-menu,
  .header-nav .nav > li .sub-menu .mega-menu,
  .header-nav .nav > li .sub-menu .sub-menu {
    border-top: 0;
    padding-left: 20px;
  }
  .header-nav .nav > li .mega-menu .mega-menu li a:after,
  .header-nav .nav > li .mega-menu .sub-menu li a:after,
  .header-nav .nav > li .sub-menu .mega-menu li a:after,
  .header-nav .nav > li .sub-menu .sub-menu li a:after {
    width: 5px;
    content: "";
    height: 5px;
    background: #262f5a !important;
    border-radius: 4px;
    top: 13px;
  }
}
.header-nav .nav > li .sub-menu li > a > i {
  color: inherit;
  display: block;
  float: right;
  font-size: 14px;
  opacity: 1;
  margin-top: 4px;
}
.header-nav .nav > li .sub-menu.open > a > i:before {
  content: "\f078";
}
.header-nav .nav > li .sub-menu > li.open > a i:before {
  content: "\f107";
}
.header-nav .nav > li .mega-menu {
  display: flex;
  right: 0px;
  width: 100%;
  z-index: 9;
  padding: 0;
}
.header-nav .nav > li .mega-menu > li {
  display: block;
  padding: 30px 0 25px;
  position: relative;
  vertical-align: top;
  width: 25%;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li {
    width: 100%;
    padding: 0;
  }
}
.header-nav .nav > li .mega-menu > li > a {
  color: #050d36;
  display: block;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}
.header-nav .nav > li .mega-menu > li > a > i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li > a {
    display: none;
  }
}
.header-nav .nav > li .mega-menu > li:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 1px;
  height: 100%;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li:after {
    content: none;
  }
}
.header-nav .nav > li .mega-menu > li:last-child:after {
  display: none;
}
.header-nav .nav > li .mega-menu > li:hover > a {
  background-color: transparent;
}
.header-nav .nav > li .mega-menu > li li i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu {
    display: none;
    padding: 10px 0;
  }
}
.header-nav .nav > li.active > a {
  color: var(--title);
}
.header-nav .nav > li:hover > a {
  color: var(--primary);
}
.header-nav .nav > li:hover > .mega-menu,
.header-nav .nav > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li:hover > .mega-menu,
  .header-nav .nav > li:hover > .sub-menu {
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
}
.header-nav .nav > li:hover > .mega-menu li:hover > .sub-menu,
.header-nav .nav > li:hover > .sub-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  top: -1px;
}
.header-nav .nav > li.open .mega-menu,
.header-nav .nav > li.open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}
.header-nav .nav > li.open .open.menu-item-has-children > a:before {
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.header-nav .nav > li.open .open .mega-menu,
.header-nav .nav > li.open .open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}
@media only screen and (max-width: 1480px) {
  .header-nav .nav > li:nth-last-child(3) .sub-menu .sub-menu,
  .header-nav .nav > li:nth-last-child(2) .sub-menu .sub-menu,
  .header-nav .nav > li:last-child .sub-menu .sub-menu {
    left: -220px;
  }
}
@media only screen and (max-width: 1199px) {
  .header-nav .nav {
    padding: 0;
  }
  .header-nav .nav > li:last-child .sub-menu {
    left: auto;
    right: 0;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav {
    float: none;
    padding: 0 20px;
  }
}
.header-nav .dz-social-icon {
  display: none;
  text-align: center;
}
.header-nav .dz-social-icon ul {
  margin: 0 -2px;
}
.header-nav .dz-social-icon li {
  display: inline-block;
  padding: 0 2px;
}
.header-nav .dz-social-icon a {
  width: 35px;
  height: 35px;
  padding: 0;
  /* color: var(--primary); */
  color: white;
  background-color: #416bdb;
  border: 1px solid lightgrey;
  line-height: 33px;
  text-align: center;
  border-radius: var(--border-radius-base);
  font-size: 15px;
}

/* left nav icon */

.header-nav .dz-social-icon a:hover {
  background-color: var(--bs-warning);
  /* color: black !important; */
  /* background-color: var(--primary); */
  color: white;
  box-shadow: 0px 5px 15px var(--bs-warning);
}
@media only screen and (max-width: 991px) {
  .header-nav .dz-social-icon {
    display: block;
    padding: 30px 10px 20px;
  }
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.header-transparent .top-bar,
.header-transparent .main-bar {
  background: #416bdb !important;
}
.header-transparent .extra-nav #quik-search-btn {
  color: #fff;
}
.header-transparent .navbar-toggler span {
  background: #fff;
}

/* fixed navbar */
.header-transparent .is-fixed .extra-nav #quik-search-btn {
  color: #777;
}
.header-transparent .main-bar .container > .logo-header.logo-dark,
.header-transparent .main-bar .container-fluid > .logo-header.logo-dark,
.header-transparent .main-bar .container-sm > .logo-header.logo-dark,
.header-transparent .main-bar .container-md > .logo-header.logo-dark,
.header-transparent .main-bar .container-lg > .logo-header.logo-dark,
.header-transparent .main-bar .container-xl > .logo-header.logo-dark {
  display: none;
}
.header-transparent .is-fixed .main-bar .container > .logo-header.logo-dark,
.header-transparent
  .is-fixed
  .main-bar
  .container-fluid
  > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-sm > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-md > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-lg > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-xl > .logo-header.logo-dark {
  display: table;
}
.header-transparent .is-fixed .main-bar .container > .logo-header.logo-light,
.header-transparent
  .is-fixed
  .main-bar
  .container-fluid
  > .logo-header.logo-light,
.header-transparent .is-fixed .main-bar .container-sm > .logo-header.logo-light,
.header-transparent .is-fixed .main-bar .container-md > .logo-header.logo-light,
.header-transparent .is-fixed .main-bar .container-lg > .logo-header.logo-light,
.header-transparent
  .is-fixed
  .main-bar
  .container-xl
  > .logo-header.logo-light {
  display: none;
}
.header-transparent .top-bar {
  border-color: #fff;
}
.header-transparent .top-bar .dz-topbar-left li,
.header-transparent .top-bar .dz-topbar-left i,
.header-transparent .top-bar .dz-topbar-right li,
.header-transparent .top-bar .dz-topbar-right i {
  color: #fff;
}
@media only screen and (min-width: 991px) {
  .header-transparent .is-fixed .nav > li > a {
    /* color: #050d36; */
    color: #fff !important;
  }
  .header-transparent .is-fixed .nav > li > a :hover {
    /* color: #050d36; */
    color: var(--bs-warning) !important;
  }
  .header-transparent .nav > li > a {
    color: #fff;
    /* color: var(--bs-warning) !important; */
  }
}

.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background: #416bdb !important;
  transition: all 0.5s;
}

.header-bottom {
  display: none;
}

@media only screen and (max-width: 991px) {

  .mo-left .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 280px;
    left: -280px;
    height: 100vh !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }
  .mo-left .header-nav.nav-dark {
    background-color: #202020;
  }
  .mo-left .header-nav.show {
    left: -1px;
    transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    margin: 0;
  }
  .mo-left .header-nav .logo-header {
    display: block;
    float: none;
    height: auto !important;
    max-width: 100%;
    padding: 25px 30%;
    width: 100% !important;
  }
  .mo-left .header-nav .logo-header img {
    max-width: unset;
    width: 100px !important;
    vertical-align: middle;
  }
  .mo-left .header-nav li.open a {
    position: relative;
  }
  .mo-left .header-nav .header-bottom {
    margin: 30px 20px 0;
    display: block;
  }
  .mo-left .header-nav .header-bottom .btn-login {
    justify-content: center;
    display: block;
  }
  .mo-left .header-nav .header-bottom .btn-login i {
    transform: scale(1.2);
  }
  .mo-left .navbar-toggler.open {
    z-index: 99;
  }
  .mo-left .navbar-toggler.open:after {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    left: 0;
    position: fixed;
    right: -20px;
    top: -63px;
    transform: scale(100);
    -o-transform: scale(100);
    -moz-transform: scale(100);
    -webkit-transform: scale(100);
    width: 100%;
    z-index: -1;
    transition: all 0.5s;
    transform-origin: top right;
    margin: 0 0px 0px 20px;
    -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
  }
  .mo-left .navbar-toggler.open span {
    background: #fff;
    /* background-color: #416bdb; */
  }
}

.text-black .nav > li > a {
  color: #050d36;
}

.text-black .navbar-toggler span {
  background: #050d36;
}

/* Start Header is-fixed Animation */
.main-bar {
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}

.is-fixed .main-bar {
  -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
}

@-moz-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@-ms-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

/* Header is-fixed Animation End */
.site-header.style-1 .main-bar {
  box-shadow: none;
}

.site-header.style-1 .is-fixed .main-bar {
  box-shadow: 1px 2px 60px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.site-header.style-1 .is-fixed .main-bar .container {
  box-shadow: none;
}

.site-header.style-1 .is-fixed .logo-header .logo-dark {
  display: table-cell;
}

.site-header.style-1 .is-fixed .logo-header .logo-light {
  display: none;
}

.site-header.style-1 .top-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.site-header.style-1 .top-bar p {
  color: var(--title);
  font-weight: 400;
  font-size: 15px;
}
.site-header.style-1 .top-bar .badge-primary {
  color: #fff;
  background-color: var(--primary) !important;
}
.site-header.style-1 .top-bar .badge-primary i {
  color: #fff;
}

.site-header.style-2 .main-bar {
  box-shadow: none;
}

.site-header.style-2 .is-fixed .main-bar {
  box-shadow: 1px 2px 60px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}
.site-header.style-2 .is-fixed .main-bar .container {
  box-shadow: none;
}

.site-header.style-2 .is-fixed .logo-header .logo-dark {
  display: table-cell;
}

.site-header.style-2 .is-fixed .logo-header .logo-light {
  display: none;
}

.site-header.style-2 .top-bar {
  border-bottom: 1px solid rgba(235, 229, 219, 0.2);
}
.site-header.style-2 .top-bar p {
  color: var(--title);
  font-weight: 400;
  font-size: 15px;
}
.site-header.style-2 .top-bar .badge-primary {
  color: #fff;
  background-color: var(--primary) !important;
}
.site-header.style-2 .top-bar .badge-primary i {
  color: #fff;
}

@media only screen and (min-width: 991px) {
  .site-header.style-2 .logo-header {
    /* height: 90px; */
    height: 70px;
  }
  .site-header.style-2 .extra-nav {
    /* height: 90px; */
    height: 70px;
  }
  .site-header.style-2 .header-nav .nav {
    margin-left: 30px;
    /* margin-left: 60px; */
    padding: 0;
  }
  .site-header.style-2 .header-nav .nav > li > a {
    padding: 20px 10px;
    /* padding: 36px 20px; */
    color: #003b4a;
  }
  .site-header.style-2 .header-nav .nav > li:after {
    content: "";
    width: 100%;
    height: 0;
    background-color: var(--secondary);
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 10px 10px 0 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }
  .site-header.style-2 .header-nav .nav > li.active:after,
  .site-header.style-2 .header-nav .nav > li:hover:after {
    height: 5px;
  }
  .site-header.style-2 .header-nav .nav > li.active > a,
  .site-header.style-2 .header-nav .nav > li:hover > a {
    color: var(--primary);
  }
}
@media only screen and (min-width: 991px) and (max-width: 1280px) {
  .site-header.style-2 .header-nav .nav {
    margin-left: 0px;
    /* margin-left: 50px; */
  }
  .site-header.style-2 .header-nav .nav > li > a {
    /* padding: 36px 15px; */
    padding: 20px 10px;
  }
}

@media only screen and (max-width: 1480px) {
  .site-header.style-2 .extra-nav .nav-link-list {
    display: none;
  }
}

@media only screen and (max-width: 1280px) {
  .site-header.style-2 .extra-nav .btn i,
  .site-header.style-2 .extra-nav .wp-block-button__link i,
  .site-header.style-2 .extra-nav .btn svg,
  .site-header.style-2 .extra-nav .wp-block-button__link svg {
    margin: 0 !important;
  }
}

@media only screen and (max-width: 991px) {

  .site-header.style-2 .menu-btn {
    display: none;
  }
}

.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .header-nav
  .nav
  > li:hover
  > a,
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .header-nav
  .nav
  > li.active
  > a {
  color: var(--primary);
}

@media only screen and (min-width: 991px) {
  .site-header.header-transparent
    .main-bar-wraper:not(.is-fixed)
    .header-nav
    .nav
    > li
    > a,
  .site-header.header-transparent
    .main-bar-wraper:not(.is-fixed)
    .nav-link-list
    li
    a {
    color: #fff;
  }
}

.site-header.header-transparent .main-bar-wraper:not(.is-fixed) .menu-btn i {
  color: #fff;
}

.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .menu-btn
  svg
  rect {
  fill: #fff;
}

.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .top-bar
  .dz-topbar-left
  li,
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .top-bar
  .dz-topbar-left
  i,
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .top-bar
  .dz-topbar-left
  a,
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .top-bar
  .dz-topbar-right
  li,
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .top-bar
  .dz-topbar-right
  i,
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .top-bar
  .dz-topbar-right
  a {
  color: #fff;
}

.site-header.header-transparent .main-bar-wraper:not(.is-fixed) .dz-link {
  margin: 0;
  padding: 0;
}
.site-header.header-transparent .main-bar-wraper:not(.is-fixed) .dz-link li {
  padding-right: 15px;
  margin-right: 10px;
  font-weight: 600;
  padding-left: 0;
  color: var(--primary);
  display: inline-block;
  margin-left: 0;
  position: relative;
  font-size: 15px;
}
.site-header.header-transparent .main-bar-wraper:not(.is-fixed) .dz-link li a {
  color: inherit;
}
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .dz-link
  li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 13px;
  background-color: #fff;
  top: 6px;
  right: 0;
  max-width: 2px;
  transform: translatex(-50%);
  opacity: 0.3;
}
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .dz-link
  li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .dz-link
  li:last-child:after {
  content: none;
}

.site-header.header-transparent .main-bar-wraper:not(.is-fixed) .top-bar {
  border-bottom: 1px solid rgba(235, 229, 219, 0.2);
}
.site-header.header-transparent .main-bar-wraper:not(.is-fixed) .top-bar p {
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  font-family: Poppins;
}
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .top-bar
  .badge-primary {
  color: var(--primary) !important;
  background-color: #fff;
}
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .top-bar
  .badge-primary
  i {
  color: var(--primary) !important;
}

.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .logo-header
  .logo-dark {
  display: none;
}

.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .logo-header
  .logo-light {
  display: table-cell;
}

.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .header-nav
  .nav
  > li:hover
  > a,
.site-header.header-transparent
  .main-bar-wraper:not(.is-fixed)
  .header-nav
  .nav
  > li.active
  > a {
  color: var(--bs-warning) !important;
}

@media only screen and (max-width: 991px) {
  .site-header.header-transparent
    .main-bar-wraper:not(.is-fixed)
    .header-nav
    .nav
    > li:hover
    > a,
  .site-header.header-transparent
    .main-bar-wraper:not(.is-fixed)
    .header-nav
    .nav
    > li.active
    > a {
    /* color: var(--primary); */
    color: var(--bs-warning) !important;
  }
}

.btn-login i {
  top: 2px;
  position: relative;
}

.contact-sidebar {
  padding: 30px;
  position: fixed;
  height: 100%;
  width: 350px;
  z-index: 9999999;
  background: #fff;
  top: 0;
  left: -350px;
  overflow-y: auto;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.contact-sidebar.active {
  left: 0;
}
.contact-sidebar .logo-contact {
  margin-bottom: 40px;
  display: block;
}
.contact-sidebar .logo-contact img {
  width: 180px;
}
.contact-sidebar .dz-title {
  margin-bottom: 10px;
}
.contact-sidebar .dz-title h4,
.contact-sidebar .dz-title .h4 {
  font-size: 24px;
  font-family: var(--font-family-title);
  margin-bottom: 0;
  line-height: 1;
}
.contact-sidebar .icon-bx-wraper {
  margin-bottom: 20px;
}
.contact-sidebar .icon-bx-wraper.left .icon-md {
  margin-right: 15px;
}
.contact-sidebar .icon-bx-wraper .icon-md {
  padding: 0;
  background: var(--primary);
  box-shadow: 0px 5px 10px 0 var(--rgba-primary-1);
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px !important;
  margin-top: 5px;
  border-radius: var(--border-radius-base);
}
.contact-sidebar .icon-bx-wraper .icon-md i {
  font-size: 28px;
}
.contact-sidebar .icon-bx-wraper .tilte {
  font-family: var(--font-family-title);
  margin-bottom: 5px;
}
.contact-sidebar .icon-bx-wraper p {
  font-size: 16px;
}

.menu-close {
  width: 0;
  position: fixed;
  height: 100%;
  background: #333;
  top: 0;
  opacity: 0.9;
  left: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 99998;
}

.contact-sidebar.active + .menu-close {
  width: 100%;
}

:root {
  --sidebar-space: 80px;
}
@media only screen and (max-width: 1480px) {
  :root {
    --sidebar-space: 0;
  }
}

.page-wraper-sidebar {
  padding-left: var(--sidebar-space);
}
.page-wraper-sidebar .site-header .main-bar {
  padding-left: var(--sidebar-space);
}

.page-sidebar {
  position: fixed;
  left: 0;
  height: 100vh;
  z-index: 9999999;
  background-color: #fff;
  width: var(--sidebar-space);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px;
  justify-content: space-between;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.page-sidebar .dz-social {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.page-sidebar .dz-social li {
  position: relative;
  padding: 15px 0;
  display: inline-grid;
}
.page-sidebar .dz-social li a {
  writing-mode: vertical-rl;
  display: block;

  color: #141818;
  font-weight: 500;
}
.page-sidebar .dz-social li:after {
  content: "";
  position: absolute;
  background-color: var(--primary);
  height: 5px;
  width: 5px;
  border-radius: 50%;
  left: 50%;
  top: -8px;
  transform: translateX(-50%);
}
.page-sidebar .dz-social li:first-child:after {
  content: none;
}
.page-sidebar .btn-bottom {
  writing-mode: vertical-lr;
  padding: 40px 10px;
  width: var(--sidebar-space);
  display: flex;
  align-items: center;
  margin-bottom: -20px;
  font-weight: 500;
  border-radius: 0;
  border: 0;
}
@media only screen and (max-width: 1480px) {
  .page-sidebar {
    display: none;
  }
}

.dz-bnr-inr {
  background: #faf8f2;
  /* max-height: 300px; */
  max-height: 240px !important;
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr {
    min-height: 300px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr {
    min-height: 260px;
  }
}
.dz-bnr-inr .container {
  display: table;
  height: 100%;
}
.dz-bnr-inr .dz-bnr-inr-entry {
  vertical-align: middle;
  padding: 50px 0;
  display: table-cell;
}
@media only screen and (max-width: 767px) {
  .dz-bnr-inr .dz-bnr-inr-entry {
    text-align: center;
    padding: 30px 0;
  }
  .dz-bnr-inr .dz-bnr-inr-entry .breadcrumb-row {
    display: inline-block;
  }
}
.dz-bnr-inr h1,
.dz-bnr-inr .h1 {
  font-weight: 600;
  font-size: 50px;
  margin-bottom: 15px;
  text-transform: capitalize;
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr h1,
  .dz-bnr-inr .h1 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-bnr-inr h1,
  .dz-bnr-inr .h1 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr h1,
  .dz-bnr-inr .h1 {
    font-size: 30px;
  }
}
.dz-bnr-inr p {
  max-width: 800px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0 auto;
}
.dz-bnr-inr.dz-bnr-inr-lg {
  min-height: 850px;
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.dz-bnr-inr-lg {
    min-height: 700px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-bnr-inr.dz-bnr-inr-lg {
    min-height: 600px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.dz-bnr-inr-lg {
    min-height: 400px;
  }
}
.dz-bnr-inr.dz-bnr-inr-md {
  min-height: 400px;
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.dz-bnr-inr-md {
    min-height: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-bnr-inr.dz-bnr-inr-md {
    min-height: 350px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.dz-bnr-inr-md {
    min-height: 200px;
  }
}
.dz-bnr-inr.dz-bnr-inr-sm {
  /* min-height: 350px; */
  min-height: 50px;
}
@media only screen and (max-width: 767px) {
  .dz-bnr-inr.dz-bnr-inr-sm {
    /* min-height: 250px; */
    min-height: 50px;
  }
}

.header-transparent.style-1 + .page-content .dz-bnr-inr {
  padding-top: 380px;
}
@media only screen and (max-width: 1680px) {
  .header-transparent.style-1 + .page-content .dz-bnr-inr {
    padding-top: 140px;
  }
}
@media only screen and (max-width: 991px) {
  .header-transparent.style-1 + .page-content .dz-bnr-inr {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 575px) {
  .header-transparent.style-1 + .page-content .dz-bnr-inr {
    padding-top: 120px;
  }
}

.breadcrumb-row ul {
  background: var(--secondary);
  display: inline-block;
  padding: 12px 24px;
  border-radius: var(--border-radius-base);
  margin-top: 10px;
  margin-bottom: 0;
}
.breadcrumb-row ul li {
  padding: 0;
  margin-right: 3px;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.breadcrumb-row ul li a {
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .breadcrumb-row ul li {
    font-size: 14px;
  }
}
.breadcrumb-row ul .breadcrumb-item.active {
  color: #fff;
}
.breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
  content: "||";
  color: #ffffff;
  font-weight: 700;
  font-family: "Line Awesome Free";
  font-size: 25px;
  padding-right: 10px;
  top: -2px;
  position: relative;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .breadcrumb-row ul {
    padding: 10px 25px;
  }
}

.half-bottom {
  position: relative;
}
.half-bottom:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 30%;
  width: 100%;
  background-color: #fff;
}

.dz-bnr-inr.style-1 h1,
.dz-bnr-inr.style-1 .h1 {
  /* color: #fff; */
  color: black;
  margin-bottom: 10px;
}

.dz-bnr-inr.style-1 .dz-bnr-inr-entry {
  padding-top: 70px;
  /* padding-bottom: 70px; */
  padding-bottom: 40px;
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.style-1 .dz-bnr-inr-entry {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.dz-bnr-inr.style-1 .bnr-shapes li {
  position: absolute;
}

.dz-bnr-inr.style-1 .bnr-shapes li:nth-child(1) {
  bottom: 130px;
  left: 20%;
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(1) {
    bottom: 70px;
  }
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(1) img {
    height: 60px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(1) {
    bottom: 40px;
    left: 15%;
  }
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(1) img {
    height: 45px;
  }
}

.dz-bnr-inr.style-1 .bnr-shapes li:nth-child(2) {
  bottom: 200px;
  left: 60px;
}
@media only screen and (max-width: 1280px) {
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(2) img {
    height: 85px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(2) {
    bottom: 175px;
    left: 60px;
  }
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(2) img {
    height: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(2) {
    bottom: 135px;
    left: 30px;
  }
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(2) img {
    height: 45px;
  }
}

.dz-bnr-inr.style-1 .bnr-shapes li:nth-child(3) {
  bottom: 0px;
  right: -75px;
}
.dz-bnr-inr.style-1 .bnr-shapes li:nth-child(3) img {
  transform: rotate(180deg) rotatex(180deg) scale(0.7);
}
@media only screen and (max-width: 1280px) {
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(3) {
    bottom: -15px;
    right: -150px;
  }
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(3) img {
    transform: rotate(200deg) rotatex(180deg) scale(0.7);
  }
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(3) {
    bottom: 10px;
    right: -160px;
  }
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(3) img {
    transform: rotate(200deg) rotatex(180deg) scale(0.5);
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(3) {
    bottom: -20px;
    right: -190px;
  }
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(3) img {
    transform: rotate(200deg) rotatex(180deg) scale(0.35);
  }
}

.dz-bnr-inr.style-1 .bnr-shapes li:nth-child(4) {
  right: 27%;
  bottom: 65px;
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(4) {
    right: 15%;
    bottom: 45px;
  }
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(4) img {
    height: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(4) {
    right: 60px;
    bottom: 40px;
  }
  .dz-bnr-inr.style-1 .bnr-shapes li:nth-child(4) img {
    height: 55px;
  }
}

.header-transparent + .page-content .dz-bnr-inr {
  padding-top: 120px;
}
@media only screen and (max-width: 991px) {
  .header-transparent + .page-content .dz-bnr-inr {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .header-transparent + .page-content .dz-bnr-inr {
    padding-top: 60px;
  }
}

.page-title {
  padding: 30px 0;
}
.page-title.dashboard {
  background: transparent;
  margin-left: 75px;
  padding: 20px 0 0px;
}
.page-title .page-title-content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
.page-title .page-title-content p span {
  font-weight: 500;
  color: var(--primary);
}

.breadcrumbs {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 575px) {
  .breadcrumbs {
    justify-content: flex-start;
  }
}
.breadcrumbs li a {
  color: #aeaed5;
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}
.breadcrumbs li:first-child a {
  margin-left: 0px;
}
.breadcrumbs li.active a {
  color: var(--primary);
}

.bottom {
  background: #1b2a4e;
}
@media only screen and (max-width: 575px) {
  .bottom {
    padding-bottom: 0;
  }
}
.bottom .bottom-logo p {
  line-height: 30px;
  font-weight: 400;
  color: #aaaaaa;
}
@media only screen and (max-width: 991px) {
  .bottom .bottom-widget {
    margin-bottom: 30px;
  }
}
.bottom .bottom-widget .widget-title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.bottom .bottom-widget ul li a {
  color: #aaaaaa;
  font-weight: 400;
  margin-bottom: 10px;
  display: inline-block;
}

footer {
  --text-white: #fff;
  --title: #fff;
}
footer strong {
  color: var(--text-white);
}
footer .footer-logo {
  margin-bottom: 25px;
}
footer .footer-logo img {
  max-width: 100%;
}
@media only screen and (max-width: 575px) {
  footer .footer-logo {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
  }
}
footer .widget-logo ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
footer .widget-logo ul li {
  display: inline-block;
  flex: 50%;
  padding: 0 15px 15px 0px;
}
footer .widget_about p {
  margin-bottom: 30px;
}
@media only screen and (max-width: 1200px) {
  footer .widget_about {
    padding: 0;
  }
}
@media only screen and (max-width: 1191px) {
  footer .widget_about {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
footer .footer-title {
  margin: 0;
  font-weight: 600;
  padding-bottom: 15px;
  margin-bottom: 30px;
  position: relative;
  color: var(--title);
  line-height: 1.2;
}
footer .footer-title .dz-separator {
  position: absolute;
  bottom: 0;
  left: 0;
}
footer .widget:hover .footer-title span:after {
  width: 5px;
}
footer .widget:hover .footer-title span:before {
  width: 55px;
}
footer .footer-top {
  padding: 70px 0 30px;
  background-color: #121e31;
}
@media only screen and (max-width: 767px) {
  footer .footer-top {
    padding: 50px 0 0;
  }
}
footer .footer-bottom {
  background-color: #121e31;
  padding: 20px 0;
}
@media only screen and (max-width: 991px) {
  footer .footer-bottom {
    text-align: center !important;
  }
  footer .footer-bottom .text-left,
  footer .footer-bottom .text-right {
    text-align: center !important;
  }
  footer .footer-bottom .text-right {
    margin-top: 10px;
  }
}
footer .widget-link li {
  display: inline-block;
  text-transform: uppercase;
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  footer .widget-link li {
    margin-left: 8px;
    margin-right: 7px;
  }
}
footer .widget-link a {
  color: var(--text-white);
}
footer .widget_services ul li a {
  color: var(--text-white);
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
footer .wp-block-latest-posts li,
footer .wp-block-categories-list li,
footer .wp-block-archives-list li,
footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  left: 0;
}

.widget_getintuch ul li {
  position: relative;
  margin-bottom: 25px;
  padding-left: 40px;
  color: #fff;
}
@media only screen and (max-width: 1280px) {
  .widget_getintuch ul li {
    margin-bottom: 20px;
    padding-left: 40px;
  }
}
.widget_getintuch ul li h5,
.widget_getintuch ul li .h5 {
  font-size: 20px;
  line-height: 1.33;
  margin-bottom: 5px;
}
@media only screen and (max-width: 1280px) {
  .widget_getintuch ul li h5,
  .widget_getintuch ul li .h5 {
    font-size: 18px;
    line-height: 1.2;
  }
}
.widget_getintuch ul li i {
  position: absolute;
  font-size: 18px;
  line-height: 1;
  left: 0;
  top: 5px;
  /* color: var(--secondary); */
  color: var(--bs-warning);
}
.widget_getintuch ul li i:hover {
  /* color: var(--secondary); */
  color: #f2b952;
  transform: scale(1.1);
}

.contact-ft {
  text-align: center;
  margin-bottom: 40px;
}
.contact-ft i {
  color: var(--primary);
  font-size: 50px;
  line-height: 1;
  margin-bottom: 15px;
  display: inline-block;
}
.contact-ft h4,
.contact-ft .h4 {
  color: var(--text-white);
  text-decoration: underline;
}
.contact-ft h5,
.contact-ft .h5 {
  color: var(--text-white);
  font-weight: 400;
}

.list-column ul {
  display: flex;
  flex-wrap: wrap;
}
.list-column ul li {
  flex: 0 0 50%;
  max-width: 50%;
}

.fb-link {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fb-link li {
  display: inline-block;
}
.fb-link li a {
  color: var(--text-white);
  position: relative;
}
.fb-link li a:after {
  content: "";
  background: var(--primary);
  width: 5px;
  height: 5px;
}

.footer-link li {
  display: inline-block;
  position: relative;
  padding: 0 25px 0 20px;
}
.footer-link li a {
  color: inherit;
}
.footer-link li:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: var(--primary);
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 8px;
  transform: translateY(-50%);
}

.footer-subscribe-wrapper .wrapper-inner {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 60px;
  padding-bottom: 60px;
}

.footer-subscribe-wrapper .btn,
.footer-subscribe-wrapper .wp-block-button__link {
  border-radius: var(--border-radius-base) !important;
}
.footer-subscribe-wrapper .btn i,
.footer-subscribe-wrapper .wp-block-button__link i {
  display: none;
}

@media only screen and (max-width: 1280px) {
  .footer-subscribe-wrapper .wrapper-inner {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .footer-subscribe-wrapper .title {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-subscribe-wrapper .title {
    font-size: 24px;
  }
  .footer-subscribe-wrapper .btn span,
  .footer-subscribe-wrapper .wp-block-button__link span {
    display: none;
  }
  .footer-subscribe-wrapper .btn i,
  .footer-subscribe-wrapper .wp-block-button__link i {
    display: block;
  }
}

.footer-feature-wrapper {
  margin-bottom: -90px;
  position: relative;
  z-index: 9;
}

.site-footer.style-1 {
  --title: rgba(255, 255, 255, 0.7);
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.site-footer.style-1 p,
.site-footer.style-1 a,
.site-footer.style-1 span,
.site-footer.style-1 small,
.site-footer.style-1 .small,
.site-footer.style-1 li {
  color: rgba(255, 255, 255, 0.5);
}
.site-footer.style-1 .dz-social-icon {
  position: relative;
}
.site-footer.style-1 .footer-bottom {
  position: relative;
}
.site-footer.style-1 .footer-top {
  padding-top: 100px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 1200px) {
  .site-footer.style-1 .footer-top {
    padding-top: 80px;
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .site-footer.style-1 .footer-top {
    padding-top: 60px;
    padding-bottom: 10px;
  }
}
.site-footer.style-1 .footer-title {
  position: relative;
  padding-bottom: 0px;
  margin-bottom: 30px;
  color: #fff;
}
@media only screen and (max-width: 575px) {
  .site-footer.style-1 .footer-title {
    margin-bottom: 20px;
  }
}
.site-footer.style-1 .widget_services > ul {
  margin-top: -0.5rem;
}
.site-footer.style-1
  .recent-posts-entry
  .widget-post-bx
  .widget-post
  .dz-meta
  ul
  li {
  color: var(--title);
}
.site-footer.style-1 .recent-posts-entry .widget-post-bx .widget-post .title a {
  color: white;
}
.site-footer.style-1 .footer-bottom {
  background-color: #121e31;
  border-top: 1px solid #ffffff21;
}
.site-footer.style-1 .footer-bottom .copyright-text a {
  color: rgba(255, 255, 255, 0.5);
}
.site-footer.style-1 .footer-link li {
  color: rgba(255, 255, 255, 0.7);
}
.site-footer.style-1 .shape-1,
.site-footer.style-1 .shape-2,
.site-footer.style-1 .shape-3 {
  position: absolute;
}
.site-footer.style-1 .shape-1 {
  left: -60px;
  top: -50px;
}
.site-footer.style-1 .shape-2 {
  right: 20px;
  bottom: 100px;
  width: 150px;
}
.site-footer.style-1 .shape-3 {
  top: -20px;
  right: -60px;
}
@media only screen and (max-width: 1280px) {
  .site-footer.style-1 .shape-1 {
    left: -20px;
    top: -20px;
    width: 120px;
  }
  .site-footer.style-1 .shape-2 {
    width: 120px;
  }
  .site-footer.style-1 .shape-3 {
    right: -10px;
    width: 300px;
  }
}
@media only screen and (max-width: 1280px) {
  .site-footer.style-1 .shape-1 {
    width: 100px;
  }
  .site-footer.style-1 .shape-2 {
    width: 80px;
  }
  .site-footer.style-1 .shape-3 {
    right: -20px;
    width: 200px;
    top: -10px;
  }
}

.site-footer.style-2 {
  --title: rgba(255, 255, 255, 0.7);
}
.site-footer.style-2 p,
.site-footer.style-2 a,
.site-footer.style-2 span,
.site-footer.style-2 small,
.site-footer.style-2 .small,
.site-footer.style-2 li {
  color: rgba(255, 255, 255, 0.5);
}
.site-footer.style-2 .dz-social-icon {
  position: relative;
}
.site-footer.style-2 .footer-bottom {
  position: relative;
  background-color: var(--secondary) !important;
}
.site-footer.style-2 .footer-bottom .copyright-text {
  color: #050d36;
}
.site-footer.style-2 .footer-bottom .copyright-text a {
  color: #050d36 !important;
}
.site-footer.style-2 .shape-f1 {
  position: absolute;
  bottom: 0;
  left: 0;
}
.site-footer.style-2 .shape-f1 path {
  fill: var(--primary);
}
.site-footer.style-2 .shape-f2 {
  position: absolute;
  bottom: 10px;
  left: 0;
}
.site-footer.style-2 .shape-f2 path {
  fill: var(--secondary);
}
.site-footer.style-2 .footer-top {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  background-position: top left;
  background-color: #003b4a;
  background-blend-mode: luminosity;
  padding-top: 100px;
  padding-bottom: 40px;
  z-index: 1;
}
.site-footer.style-2 .footer-top:before {
  content: "";
  position: absolute;
  opacity: 0.9;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #003b4a;
  z-index: -1;
}
@media only screen and (max-width: 1200px) {
  .site-footer.style-2 .footer-top {
    padding-top: 80px;
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .site-footer.style-2 .footer-top {
    padding-top: 60px;
    padding-bottom: 10px;
  }
}
.site-footer.style-2 .footer-top.footer-feature {
  padding-top: 150px !important;
}
.site-footer.style-2 .footer-top .shape1,
.site-footer.style-2 .footer-top .shape2,
.site-footer.style-2 .footer-top .shape3,
.site-footer.style-2 .footer-top .shape4 {
  position: absolute;
  z-index: -1;
}
.site-footer.style-2 .footer-top .shape1 {
  left: 0;
  top: -30px;
}
.site-footer.style-2 .footer-top .shape2 {
  left: 0px;
  top: -100px;
}
.site-footer.style-2 .footer-top .shape3 {
  right: 0px;
  top: 0px;
}
.site-footer.style-2 .footer-top .shape4 {
  right: 0px;
  top: 0px;
}
@media only screen and (max-width: 1680px) {
  .site-footer.style-2 .footer-top .shape1,
  .site-footer.style-2 .footer-top .shape2 {
    width: 150px;
  }
  .site-footer.style-2 .footer-top .shape3 {
    width: 200px;
  }
  .site-footer.style-2 .footer-top .shape4 {
    width: 300px;
  }
}
@media only screen and (max-width: 1280px) {
  .site-footer.style-2 .footer-top .shape1,
  .site-footer.style-2 .footer-top .shape2 {
    width: 100px;
  }
  .site-footer.style-2 .footer-top .shape4 {
    width: 200px;
  }
}
.site-footer.style-2 .footer-top .widget.widget_about {
  padding-right: 0 !important;
}
.site-footer.style-2 .widget-title {
  margin-bottom: 12px;
  color: #fff;
  padding-bottom: 0;
}
.site-footer.style-2 .widget_services > ul {
  margin-top: -0.5rem;
}
.site-footer.style-2 .widget_services > ul li {
  padding: 8px 0;
}
.site-footer.style-2 .widget.recent-posts-entry .widget-post-bx .widget-post {
  margin-bottom: 30px;
}
.site-footer.style-2
  .widget.recent-posts-entry
  .widget-post-bx
  .widget-post
  .dz-info {
  padding-left: 0;
}
.site-footer.style-2
  .widget.recent-posts-entry
  .widget-post-bx
  .widget-post
  .dz-info
  .post-date {
  color: var(--secondary);
  font-weight: 500;
  font-size: 14px;
}
.site-footer.style-2
  .widget.recent-posts-entry
  .widget-post-bx
  .widget-post
  .dz-meta
  ul
  li {
  color: var(--title);
}
.site-footer.style-2
  .widget.recent-posts-entry
  .widget-post-bx
  .widget-post
  .title
  a {
  color: white;
  padding-right: 30px;
}
.site-footer.style-2 .widget.widget_locations .title {
  color: white;
}
.site-footer.style-2 .footer-bottom {
  background-color: #121e31;
  border-top: 1px solid #ffffff21;
}
.site-footer.style-2 .footer-bottom .copyright-text a {
  color: rgba(255, 255, 255, 0.6);
}
.site-footer.style-2 .footer-link li {
  color: rgba(255, 255, 255, 0.7);
}

/* Footer change */

.site-footer.style-3 {
  /* --text-white: rgba(255, 255, 255, 0.5); */
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  /* background-color: #141818; */
  background: #416bdb;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  z-index: 0;
  font-size: 15px;
}
.site-footer.style-3 .dz-social-icon {
  position: relative;
}
.site-footer.style-3 .footer-top {
  position: relative;
  display: flex;
  background-color: transparent;
  padding-top: 100px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 1200px) {
  .site-footer.style-3 .footer-top {
    padding-top: 80px;
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .site-footer.style-3 .footer-top {
    padding-top: 50px;
    padding-bottom: 10px;
  }
}
.site-footer.style-3 .footer-title {
  position: relative;
  padding-bottom: 0px;
  margin-bottom: 30px;
}
.site-footer.style-3 .footer-title:before {
  content: none;
}
.site-footer.style-3 .widget_about {
  padding-right: 0;
}
.site-footer.style-3 .footer-bottom {
  border-top: 1px solid #ffffff21;
  position: relative;
  background-color: transparent !important;
}
.site-footer.style-3 .copyright-text {
  color: white;
}
.site-footer.style-3 .copyright-text a {
  /* color: var(--text-white); */
  color: #ffb300;
  font-weight: 500;
}
.site-footer.style-3 .copyright-text a:hover {
  /* color: var(--text-white); */
  color: var(--bs-secondary);
  font-weight: 500;
}

#bg {
  background-attachment: fixed;
  background-size: cover;
}

.main-bar-wraper.sticky-no .main-bar {
  position: unset;
}

.boxed .page-wraper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Frame */
.frame {
  padding: 30px;
}
.frame .page-wraper {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: auto !important;
}
.frame .is-fixed .main-bar {
  left: 0;
  width: 100%;
  margin: 0;
  position: unset;
}
.frame button.scroltop {
  right: 50px;
  bottom: 50px;
}

@media only screen and (max-width: 1024px) {
  .frame {
    padding: 0;
  }
  .frame button.scroltop {
    right: 15px;
    bottom: 15px;
  }
}

.theme-sharped {
  --border-radius-base: 0;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.theme-rounded {
  --border-radius-base: 10px;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

[data-theme-version="data-typography-1"] {
  --font-family-base: "Poppins", sans-serif;
  --font-family-title: "Playfair Display", serif;
  --font-sub-title: "Amatic SC", cursive;
}
[data-theme-version="data-typography-1"] .section-head h2,
[data-theme-version="data-typography-1"] .section-head .h2 {
  font-size: 55px;
  font-weight: 800;
}
[data-theme-version="data-typography-1"] .section-head .sub-title {
  font-family: var(--font-sub-title);
  font-size: 25px;
  letter-spacing: 2px;
}
@media only screen and (max-width: 1280px) {
  [data-theme-version="data-typography-1"] .section-head h2,
  [data-theme-version="data-typography-1"] .section-head .h2 {
    font-size: 42px;
  }
  [data-theme-version="data-typography-1"] .section-head .sub-title {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  [data-theme-version="data-typography-1"] .section-head h2,
  [data-theme-version="data-typography-1"] .section-head .h2 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 575px) {
  [data-theme-version="data-typography-1"] .section-head h2,
  [data-theme-version="data-typography-1"] .section-head .h2 {
    font-size: 32px;
  }
  [data-theme-version="data-typography-1"] .section-head .sub-title {
    font-size: 18px;
  }
}
[data-theme-version="data-typography-1"] .bg-dark {
  background-color: #086077 !important;
}
[data-theme-version="data-typography-1"] .btn-dark {
  background-color: #086077 !important;
  border-color: #086077 !important;
}
[data-theme-version="data-typography-1"]
  .icon-bx-wraper.style-4
  [class*="icon-bx"]
  svg
  path {
  fill: var(--title);
}
[data-theme-version="data-typography-1"]
  .icon-bx-wraper.style-4
  [class*="icon-bx"]
  svg
  .secondary {
  fill: var(--secondary);
}
[data-theme-version="data-typography-1"]
  .icon-bx-wraper.style-4:hover
  [class*="icon-bx"]
  svg
  .secondary {
  fill: transparent;
}

[data-theme-version="data-typography-2"] {
  --font-family-base: "Poppins", sans-serif;
  --font-family-title: "Poppins", sans-serif;
  --border-radius-base: 8px;
}
[data-theme-version="data-typography-2"] .section-head h2,
[data-theme-version="data-typography-2"] .section-head .h2 {
  font-size: 50px;
}
[data-theme-version="data-typography-2"] .section-head .sub-title {
  text-transform: unset;
  font-weight: 400;
  font-size: 18px;
}
@media only screen and (max-width: 1280px) {
  [data-theme-version="data-typography-2"] .section-head h2,
  [data-theme-version="data-typography-2"] .section-head .h2 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 767px) {
  [data-theme-version="data-typography-2"] .section-head h2,
  [data-theme-version="data-typography-2"] .section-head .h2 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 575px) {
  [data-theme-version="data-typography-2"] .section-head h2,
  [data-theme-version="data-typography-2"] .section-head .h2 {
    font-size: 30px;
  }
  [data-theme-version="data-typography-2"] .section-head .sub-title {
    font-size: 16px;
  }
}
[data-theme-version="data-typography-2"]
  .site-header.style-2
  .header-nav
  .nav
  > li.active:after,
[data-theme-version="data-typography-2"]
  .site-header.style-2
  .header-nav
  .nav
  > li:hover:after {
  background-color: var(--primary);
}
[data-theme-version="data-typography-2"] .icon-bx-wraper .style-4 .separator {
  background-color: #fff;
}
[data-theme-version="data-typography-2"] .overlay-black-light:before,
[data-theme-version="data-typography-2"] .overlay-black-middle:before,
[data-theme-version="data-typography-2"] .overlay-black-dark:before {
  background: #141818;
}
[data-theme-version="data-typography-2"] .btn-secondary {
  color: #141818;
}
[data-theme-version="data-typography-2"] .bg-dark,
[data-theme-version="data-typography-2"] .btn-dark {
  background-color: var(--dark) !important;
}
[data-theme-version="data-typography-2"] .dz-social-icon.style-1 ul li a:after {
  background-color: var(--primary);
}
[data-theme-version="data-typography-2"] .container-fluid,
[data-theme-version="data-typography-2"] .container-sm,
[data-theme-version="data-typography-2"] .container-md,
[data-theme-version="data-typography-2"] .container-lg,
[data-theme-version="data-typography-2"] .container-xl {
  padding-left: 80px;
  padding-right: 80px;
}
@media only screen and (max-width: 1680px) {
  [data-theme-version="data-typography-2"] .container-fluid,
  [data-theme-version="data-typography-2"] .container-sm,
  [data-theme-version="data-typography-2"] .container-md,
  [data-theme-version="data-typography-2"] .container-lg,
  [data-theme-version="data-typography-2"] .container-xl {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (max-width: 1280px) {
  [data-theme-version="data-typography-2"] .container-fluid,
  [data-theme-version="data-typography-2"] .container-sm,
  [data-theme-version="data-typography-2"] .container-md,
  [data-theme-version="data-typography-2"] .container-lg,
  [data-theme-version="data-typography-2"] .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.section-wrapper1 {
  padding-top: 50px;
  margin-top: var(--spacing-bnr-two-sub);
}
.section-wrapper1:before {
  background-color: var(--primary);
  height: calc(100% - var(--spacing-bnr-two));
  width: 100%;
  position: absolute;
  left: 0;
  content: "";
  top: var(--spacing-bnr-two);
}
.section-wrapper1:after {
  content: "";
  position: absolute;
  right: 0;
  top: var(--spacing-bnr-two-sub);
  bottom: 0;
  background-image: url(../images/background/bg10.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: calc((100% + 1140px) / 2);
  height: calc(100% + var(--spacing-bnr-two));
  background-color: #fff;
  z-index: 1;
}
@media only screen and (max-width: 1280px) {
  .section-wrapper1:after {
    width: calc((100% + 970px) / 2);
  }
}
@media only screen and (max-width: 1191px) {
  .section-wrapper1:after {
    width: calc((100% + 750px) / 2);
  }
}
@media only screen and (max-width: 991px) {
  .section-wrapper1:after {
    width: 100%;
  }
}
.section-wrapper1 .section-head {
  padding-left: 80px;
}
@media only screen and (max-width: 1280px) {
  .section-wrapper1 .section-head {
    padding-left: 150px;
  }
}
@media only screen and (max-width: 991px) {
  .section-wrapper1 .section-head {
    padding-left: 0;
  }
}
.section-wrapper1 .container,
.section-wrapper1 .resize-wrapper,
.section-wrapper1 .container-fluid,
.section-wrapper1 .container-sm,
.section-wrapper1 .container-md,
.section-wrapper1 .container-lg,
.section-wrapper1 .container-xl {
  position: relative;
  z-index: 2;
}
.section-wrapper1 .resize-wrapper {
  margin-left: calc((100% - 1140px - 350px) / 2);
  overflow: hidden;
}
.section-wrapper1 .resize-wrapper .swiper {
  margin-right: -200px;
}
@media only screen and (max-width: 1680px) {
  .section-wrapper1 .resize-wrapper {
    margin-left: calc((100% - 970px - 350px) / 2);
  }
}
@media only screen and (max-width: 1280px) {
  .section-wrapper1 .resize-wrapper {
    margin-left: calc((100% - 1140px) / 2);
  }
}
@media only screen and (max-width: 1191px) {
  .section-wrapper1 .resize-wrapper {
    margin-left: calc((100% - 930px) / 2);
  }
}
@media only screen and (max-width: 991px) {
  .section-wrapper1 .resize-wrapper {
    margin-left: 30px;
    margin-right: 30px;
  }
  .section-wrapper1 .resize-wrapper .swiper {
    margin-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .section-wrapper1 .resize-wrapper {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.section-wrapper2:after {
  content: "";
  height: 150px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}

.section-wrapper3 .container {
  max-width: 1050px;
}

.section-wrapper3 .row > [class*="col-"]:nth-child(1),
.section-wrapper3 .row > [class*="col-"]:nth-child(3) {
  margin-top: 35px;
}

.section-wrapper3 .btn-bottom {
  margin-top: -30px;
}

.section-wrapper3 .img-1 {
  position: absolute;
  bottom: 30px;
  right: 0;
  filter: grayscale(1);
  width: 24vw;
  z-index: -1;
}

.section-wrapper3 .img-2 {
  position: absolute;
  width: 20vw;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media only screen and (max-width: 1480px) {
  .section-wrapper3 .img-1 {
    width: 15vw;
  }
}

@media only screen and (max-width: 1280px) {
  .section-wrapper3 .img-2 {
    width: 10vw;
  }
}

@media only screen and (max-width: 991px) {
  .section-wrapper3 .img-2 {
    display: none;
  }
  .section-wrapper3 .row > [class*="col-"]:nth-child(1),
  .section-wrapper3 .row > [class*="col-"]:nth-child(3),
  .section-wrapper3 .btn-bottom {
    margin-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .section-wrapper3 .img-1 {
    width: 25vw;
  }
}

.section-wrapper4 {
  margin-top: -150px;
}
.section-wrapper4 .form-wrapper {
  margin: -120px 0 30px -50px;
}
.section-wrapper4 .section-inner {
  padding-left: 20px;
}
.section-wrapper4 .img-1 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  filter: grayscale(1);
  width: 23vw;
}
.section-wrapper4 .counter-text-wrapper {
  border-left: 3px solid #d9d9d9;
  padding-left: 30px;
}
.section-wrapper4 .counter-text .counter-num {
  font-size: 35px;
  font-weight: 600;
  line-height: 1.4;
}
@media only screen and (max-width: 1280px) {
  .section-wrapper4 .form-wrapper {
    margin: -80px 0 30px 0;
  }
}
@media only screen and (max-width: 1191px) {
  .section-wrapper4 .counter-text-wrapper {
    border-left: 0;
    padding-left: 0;
  }
  .section-wrapper4 .section-inner {
    padding-left: 0;
  }
}
@media only screen and (max-width: 1280px) {
  .section-wrapper4 {
    margin-top: 0;
  }
  .section-wrapper4 .form-wrapper {
    margin: 0 0 30px;
  }
  .section-wrapper4 .img-1 {
    width: 40vw;
  }
}
@media only screen and (max-width: 575px) {
  .section-wrapper4 .img-1 {
    width: 50vw;
  }
}

.section-wrapper5 {
  z-index: 2;
}
.section-wrapper5 .dz-media {
  width: 40vw;
  margin-bottom: -150px;
  float: left;
  padding-left: 100px;
}
@media only screen and (max-width: 1480px) {
  .section-wrapper5 .dz-media {
    padding-left: 0;
  }
}
@media only screen and (max-width: 991px) {
  .section-wrapper5 .dz-media {
    width: 100%;
    margin-bottom: 0;
  }
}

.section-wrapper6:after {
  content: "";
  height: 50px;
  width: 100%;
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: absolute;
}

.section-wrapper6 .dz-media {
  padding-bottom: 50px;
}

@media only screen and (max-width: 991px) {
  .section-wrapper6 .dz-media {
    padding-bottom: 0;
  }
}

.section-wrapper7 {
  position: relative;
}
.section-wrapper7 .shape1 {
  position: absolute;
  top: 0;
  right: 0;
}
.section-wrapper7 .shape2 {
  position: absolute;
  top: -60px;
  right: 0;
}
.section-wrapper7 .shape3 {
  position: absolute;
  bottom: 150px;
  left: 0;
}
.section-wrapper7 .shape4 {
  position: absolute;
  bottom: 100px;
  left: 0;
}
.section-wrapper7 .shape5 {
  position: absolute;
  top: 0;
  left: -40px;
}
.section-wrapper7 .shape6 {
  position: absolute;
  bottom: 140px;
  right: 100px;
}
.section-wrapper7 .shape7 {
  position: absolute;
  top: 0;
  right: 250px;
}
@media only screen and (max-width: 1280px) {
  .section-wrapper7 .shape1,
  .section-wrapper7 .shape2,
  .section-wrapper7 .shape3,
  .section-wrapper7 .shape4,
  .section-wrapper7 .shape5,
  .section-wrapper7 .shape6,
  .section-wrapper7 .shape7 {
    width: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-wrapper7 .shape1,
  .section-wrapper7 .shape2,
  .section-wrapper7 .shape3,
  .section-wrapper7 .shape4,
  .section-wrapper7 .shape5,
  .section-wrapper7 .shape6,
  .section-wrapper7 .shape7 {
    width: 35px;
  }
}

.section-wrapper7 .section-inner {
  padding-left: 150px;
  padding-right: 150px;
}
@media only screen and (max-width: 1280px) {
  .section-wrapper7 .section-inner {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .section-wrapper7 .section-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.gradient-white:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    white 28%,
    white 29%,
    white 75%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    white 28%,
    white 29%,
    white 75%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    white 28%,
    white 29%,
    white 75%,
    rgba(255, 255, 255, 0) 100%
  );
}

.gradient-white:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: -moz-linear-gradient(
    top,
    white 0%,
    rgba(255, 255, 255, 0.7) 34%,
    rgba(255, 255, 255, 0.7) 67%,
    rgba(255, 255, 255, 0.71) 68%,
    white 97%,
    white 99%
  );
  background: -webkit-linear-gradient(
    top,
    white 0%,
    rgba(255, 255, 255, 0.7) 34%,
    rgba(255, 255, 255, 0.7) 67%,
    rgba(255, 255, 255, 0.71) 68%,
    white 97%,
    white 99%
  );
  background: linear-gradient(
    to bottom,
    white 0%,
    rgba(255, 255, 255, 0.7) 34%,
    rgba(255, 255, 255, 0.7) 67%,
    rgba(255, 255, 255, 0.71) 68%,
    white 97%,
    white 99%
  );
}

.section-pattren1 {
  position: relative;
}
.section-pattren1 img[class*="shape-"] {
  position: absolute;
  z-index: -1;
}
.section-pattren1 .shape-1 {
  left: 7%;
  top: 20%;
  width: 65px;
}
.section-pattren1 .shape-2 {
  top: 10%;
  right: 10%;
}
.section-pattren1 .shape-3 {
  right: 5%;
  bottom: 15%;
  width: 50px;
}
.section-pattren1 .shape-4 {
  bottom: 10%;
  left: 5%;
  width: 50px;
}
.section-pattren1 .shape-5 {
  right: 10%;
  top: 50%;
  width: 60px;
}
.section-pattren1 .shape-6 {
  left: 10%;
  top: 50%;
  width: 60px;
}
@media only screen and (max-width: 1280px) {
  .section-pattren1 img[class*="shape-"] {
    width: 35px;
  }
}

.map-wrapper {
  position: relative;
  z-index: 2;
}
@media only screen and (max-width: 575px) {
  .map-wrapper ul {
    display: none;
  }
}
.map-wrapper ul li {
  position: absolute;
}
.map-wrapper ul li:after {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background: var(--primary);
  display: block;
  border: 2px solid #fff;
}
.map-wrapper ul li div {
  position: absolute;
  padding: 15px 15px;
  background: var(--secondary);
  color: var(--title);
  line-height: 1.2;
  box-shadow: 0px 10px 30px 0px rgba(253, 114, 74, 0.1);
  font-weight: 500;
  font-size: 15px;
  border-radius: var(--border-radius-base);
  top: -20px;
  display: block;
  width: 150px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  top: -20px;
  right: 25px;
  transform: scale(0);
}
.map-wrapper ul li div:after {
  content: "";
  width: 20px;
  height: 20px;
  background: inherit;
  position: absolute;
  top: 20px;
  transform: rotate(45deg);
}
.map-wrapper ul li div.left {
  left: 35px;
}
.map-wrapper ul li div.left:after {
  left: -8px;
}
.map-wrapper ul li div.right {
  right: 35px;
}
.map-wrapper ul li div.right:after {
  right: -8px;
}
.map-wrapper ul li div a {
  border-bottom: 2px solid var(--primary);
}
.map-wrapper ul li:hover > div {
  transform: scale(1);
}
.map-wrapper ul li:nth-child(1) {
  top: 15%;
  left: 65%;
}
.map-wrapper ul li:nth-child(1) span {
  top: 0;
  left: 100%;
  transform: translateY(-100%) scale(0);
  transform-origin: left bottom;
}
.map-wrapper ul li:nth-child(1).show span {
  transform: translateY(-100%) scale(1);
}
.map-wrapper ul li:nth-child(2) {
  top: 40%;
  left: 10%;
}
.map-wrapper ul li:nth-child(2) span {
  transform: translate(-100%, -100%) scale(0);
  bottom: 0;
  left: 0;
  transform-origin: right bottom;
}
.map-wrapper ul li:nth-child(2).show span {
  transform: translate(-100%, -100%) scale(1);
}
.map-wrapper ul li:nth-child(3) {
  top: 60%;
  left: 50%;
}
.map-wrapper ul li:nth-child(3) span {
  top: 0;
  left: 100%;
  transform: translateY(-100%) scale(0);
  transform-origin: left bottom;
}
.map-wrapper ul li:nth-child(3).show span {
  transform: translateY(-100%) scale(1);
}
@media only screen and (max-width: 1280px) {
  .map-wrapper ul li div {
    top: 35px;
    right: -20px;
  }
  .map-wrapper ul li div:after {
    right: 20px;
    top: -9px;
  }
  .map-wrapper ul li div.left {
    left: -20px;
  }
  .map-wrapper ul li div.left:after {
    left: 20px;
  }
  .map-wrapper ul li div.right {
    right: -20px;
  }
  .map-wrapper ul li div.right:after {
    right: 20px;
  }
}
@media only screen and (max-width: 1280px) {
  .map-wrapper ul li div.right {
    right: auto;
    left: -20px;
  }
  .map-wrapper ul li div.right:after {
    right: auto;
    left: 20px;
  }
}

.map-wrapper1 .shape-1,
.map-wrapper1 .shape-2,
.map-wrapper1 .shape-3 {
  position: absolute;
  z-index: -1;
}

.map-wrapper1 .shape-1 {
  left: 45%;
  bottom: 30%;
}

.map-wrapper1 .shape-2 {
  left: -10px;
  bottom: 35%;
}

.map-wrapper1 .shape-3 {
  bottom: 30%;
  left: 15%;
  width: 60px;
}

/*=== Components ===*/
.card {
  border: 0px solid #dee2e6;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  background: #fff;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6;
  background: #fff;
  padding: 20px 30px;
}
.card-title {
  font-size: 24px;
  margin-bottom: 0px;
}
.card .card-body {
  padding: 20px 30px;
  background: #fff;
  border-radius: 15px;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #777;
  margin-bottom: 10px;
  background: transparent;
  border-color: #dee2e6;
}

.form-control,
.default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .dropdown-toggle.wp-block-button__link,
.default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .dropdown-toggle.wp-block-button__link,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select,
.comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p textarea {
  height: 55px;
  border: 1px solid #d2d2d2;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  color: #777;
  transition: all 0.3s ease-in-out;
  background: #fff;
  border-radius: var(--border-radius-base) !important;
}
.form-control .select,
.default-select.style-2 .btn.dropdown-toggle .select,
.default-select.style-2 .dropdown-toggle.wp-block-button__link .select,
.default-select.style-3 .btn.dropdown-toggle .select,
.default-select.style-3 .dropdown-toggle.wp-block-button__link .select,
.wp-block-categories-dropdown select .select,
.wp-block-archives-dropdown select .select,
.comment-respond .comment-form p.comment-form-author input .select,
.comment-respond .comment-form p.comment-form-email input .select,
.comment-respond .comment-form p.comment-form-url input .select,
.comment-respond .comment-form p textarea .select {
  padding: 12px 0;
}
.form-control span,
.default-select.style-2 .btn.dropdown-toggle span,
.default-select.style-2 .dropdown-toggle.wp-block-button__link span,
.default-select.style-3 .btn.dropdown-toggle span,
.default-select.style-3 .dropdown-toggle.wp-block-button__link span,
.wp-block-categories-dropdown select span,
.wp-block-archives-dropdown select span,
.comment-respond .comment-form p.comment-form-author input span,
.comment-respond .comment-form p.comment-form-email input span,
.comment-respond .comment-form p.comment-form-url input span,
.comment-respond .comment-form p textarea span {
  margin-top: 0;
}
.form-control.transparent,
.default-select.style-2 .transparent.btn.dropdown-toggle,
.default-select.style-2 .transparent.dropdown-toggle.wp-block-button__link,
.default-select.style-3 .transparent.btn.dropdown-toggle,
.default-select.style-3 .transparent.dropdown-toggle.wp-block-button__link,
.wp-block-categories-dropdown select.transparent,
.wp-block-archives-dropdown select.transparent,
.comment-respond .comment-form p.comment-form-author input.transparent,
.comment-respond .comment-form p.comment-form-email input.transparent,
.comment-respond .comment-form p.comment-form-url input.transparent,
.comment-respond .comment-form p textarea.transparent {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}
.form-control.transparent::placeholder,
.default-select.style-2 .transparent.btn.dropdown-toggle::placeholder,
.default-select.style-2
  .transparent.dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3 .transparent.btn.dropdown-toggle::placeholder,
.default-select.style-3
  .transparent.dropdown-toggle.wp-block-button__link::placeholder,
.wp-block-categories-dropdown select.transparent::placeholder,
.wp-block-archives-dropdown select.transparent::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  input.transparent::placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  input.transparent::placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  input.transparent::placeholder,
.comment-respond .comment-form p textarea.transparent::placeholder {
  color: #fff;
}
.form-control::-webkit-input-placeholder,
.default-select.style-2 .btn.dropdown-toggle::-webkit-input-placeholder,
.default-select.style-2
  .dropdown-toggle.wp-block-button__link::-webkit-input-placeholder,
.default-select.style-3 .btn.dropdown-toggle::-webkit-input-placeholder,
.default-select.style-3
  .dropdown-toggle.wp-block-button__link::-webkit-input-placeholder,
.wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown select::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  input::-webkit-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  input::-webkit-input-placeholder,
.comment-respond .comment-form p textarea::-webkit-input-placeholder {
  color: #777;
}
.form-control:-ms-input-placeholder,
.default-select.style-2 .btn.dropdown-toggle:-ms-input-placeholder,
.default-select.style-2
  .dropdown-toggle.wp-block-button__link:-ms-input-placeholder,
.default-select.style-3 .btn.dropdown-toggle:-ms-input-placeholder,
.default-select.style-3
  .dropdown-toggle.wp-block-button__link:-ms-input-placeholder,
.wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown select:-ms-input-placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  input:-ms-input-placeholder,
.comment-respond .comment-form p.comment-form-email input:-ms-input-placeholder,
.comment-respond .comment-form p.comment-form-url input:-ms-input-placeholder,
.comment-respond .comment-form p textarea:-ms-input-placeholder {
  color: #777;
}
.form-control::placeholder,
.default-select.style-2 .btn.dropdown-toggle::placeholder,
.default-select.style-2 .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3 .btn.dropdown-toggle::placeholder,
.default-select.style-3 .dropdown-toggle.wp-block-button__link::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder,
.comment-respond .comment-form p.comment-form-author input::placeholder,
.comment-respond .comment-form p.comment-form-email input::placeholder,
.comment-respond .comment-form p.comment-form-url input::placeholder,
.comment-respond .comment-form p textarea::placeholder {
  color: #777;
}
.form-control:focus,
.default-select.style-2 .btn.dropdown-toggle:focus,
.default-select.style-2 .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3 .btn.dropdown-toggle:focus,
.default-select.style-3 .dropdown-toggle.wp-block-button__link:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus,
.comment-respond .comment-form p.comment-form-author input:focus,
.comment-respond .comment-form p.comment-form-email input:focus,
.comment-respond .comment-form p.comment-form-url input:focus,
.comment-respond .comment-form p textarea:focus,
.form-control:active,
.default-select.style-2 .btn.dropdown-toggle:active,
.default-select.style-2 .dropdown-toggle.wp-block-button__link:active,
.default-select.style-3 .btn.dropdown-toggle:active,
.default-select.style-3 .dropdown-toggle.wp-block-button__link:active,
.wp-block-categories-dropdown select:active,
.wp-block-archives-dropdown select:active,
.comment-respond .comment-form p.comment-form-author input:active,
.comment-respond .comment-form p.comment-form-email input:active,
.comment-respond .comment-form p.comment-form-url input:active,
.comment-respond .comment-form p textarea:active,
.form-control.active,
.default-select.style-2 .active.btn.dropdown-toggle,
.default-select.style-2 .active.dropdown-toggle.wp-block-button__link,
.default-select.style-3 .active.btn.dropdown-toggle,
.default-select.style-3 .active.dropdown-toggle.wp-block-button__link,
.wp-block-categories-dropdown select.active,
.wp-block-archives-dropdown select.active,
.comment-respond .comment-form p.comment-form-author input.active,
.comment-respond .comment-form p.comment-form-email input.active,
.comment-respond .comment-form p.comment-form-url input.active,
.comment-respond .comment-form p textarea.active {
  border-color: var(--primary);
}

textarea.form-control,
.default-select.style-2 textarea.btn.dropdown-toggle,
.default-select.style-2 textarea.dropdown-toggle.wp-block-button__link,
.default-select.style-3 textarea.btn.dropdown-toggle,
.default-select.style-3 textarea.dropdown-toggle.wp-block-button__link,
.comment-respond .comment-form p textarea {
  min-height: unset;
  height: auto;
}

textarea {
  height: 120px;
  resize: none;
}

input:-internal-autofill-selected {
  background: white !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

.input-group-text {
  padding: 5px 15px;
  background: #ffffff;
  margin-bottom: 0px !important;
  color: #777;
  border-color: transparent;
}

.toggle {
  cursor: pointer;
  display: block;
}

.toggle-switch {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch:before,
.toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 1px;
  left: 3px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
}
.toggle-checkbox:checked + .toggle-switch {
  background: var(--primary);
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 26px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #dee2e6;
  border-radius: 5px;
  color: #aeaed5;
}
.file-upload-wrapper:after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  padding: 4px 15px;
  display: block;
  width: calc(100% - 40px);
  pointer-events: none;
  z-index: 20;
  height: calc(1.5em + 1rem + 2px);
  line-height: 30px;
  border-radius: 5px 10px 10px 5px;
  font-weight: 400;
  overflow: hidden;
}
.file-upload-wrapper:before {
  content: "Upload";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  height: calc(1.5em + 1rem + 2px);
  background: var(--primary);
  color: #fff;
  font-weight: 400;
  z-index: 25;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: capitalize;
  pointer-events: none;
  border-radius: 0 5px 5px 0;
}
.file-upload-wrapper:hover:before {
  background: #12584c;
}
.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

#ui-datepicker-div {
  display: none;
  background: #fafafa;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #777;
}

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}
.ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 0.25rem;
  line-height: 2rem;
  transition: 0.3s all;
  color: #777;
  font-size: 0.875rem;
  text-decoration: none;
}
.ui-datepicker-calendar tbody td a:hover {
  background-color: var(--primary);
  color: white;
}
.ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: var(--primary);
  color: white;
}

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}
.ui-datepicker-header a.ui-corner-all:hover {
  background-color: #59ddc7;
  color: #fff;
}

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%;
}

.ui-datepicker-header a > span {
  display: none;
}

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
  color: #777;
  font-weight: 400;
  font-size: 0.75rem;
}

label.error {
  color: #ff0003;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400;
}

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
.nice-select ul {
  width: 100% !important;
}

.nice-select .list {
  background: #050d36;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #050d36;
  color: var(--primary);
}

/* .form-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  width: 100%;
}

.form-select {
  padding: 0;
  border: 1px solid transparent;
  background-image: none;
} */
.form-select > select {
  display: none !important;
}
.form-select .dropdown-toggle {
  border: 0 !important;
  padding: 0;
  font-weight: normal;
  text-transform: unset;
  outline: none;
}
.form-select .dropdown-toggle:focus {
  outline: 0 !important;
  outline-offset: 0;
}
.form-select .dropdown-toggle:after {
  content: none;
}
.form-select.show {
  box-shadow: none !important;
  outline: 0px !important;
}
.form-select.show .dropdown-toggle {
  box-shadow: none !important;
  outline: 0px !important;
}
.form-select.show.btn-group .dropdown-menu.inner {
  display: block;
}
.form-select .dropdown-menu {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-top: -1px;
  padding: 10px 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
}
.form-select .dropdown-menu ul li {
  padding: 0;
}
.form-select .dropdown-menu ul li a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  font-size: 15px;
}
.form-select .dropdown-menu ul li a img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-right: 5px;
}
.form-select .dropdown-menu ul li a:hover {
  background-color: #f2f2f4;
}
.form-select .dropdown-menu ul li.selected a {
  background-color: var(--primary);
  color: #fff;
}
.form-select:after {
  content: "\f138";
  font-family: flaticon;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
  line-height: 1;
  font-size: 15px;
}
.form-select:focus,
.form-select:active,
.form-select.active {
  border-color: var(--primary);
}

/* .form-select {
  padding: 0;
  border: 1px solid transparent;
  background-image: none;
  border: 0 !important;
} */
.form-select > select {
  display: none !important;
}
.form-select .dropdown-toggle {
  padding: 0;
  font-weight: normal;
  text-transform: unset;
  outline: none;
  border: 1px solid #e1e1e1;
}
.form-select .dropdown-toggle:focus {
  outline: 0 !important;
  outline-offset: 0;
}
.form-select .dropdown-toggle:focus,
.form-select .dropdown-toggle:active,
.form-select .dropdown-toggle:hover {
  border-color: var(--primary);
}
.form-select .dropdown-toggle:after {
  content: none;
}
.form-select.show {
  box-shadow: none !important;
  outline: 0px !important;
}
.form-select.show .dropdown-toggle {
  box-shadow: none !important;
  outline: 0px !important;
}
.form-select.show.btn-group .dropdown-menu.inner {
  display: block;
}
.form-select .dropdown-menu {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-top: -1px;
  padding: 10px 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
  border-radius: var(--border-radius-base);
}
.form-select .dropdown-menu ul li {
  padding: 0;
}
.form-select .dropdown-menu ul li a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: 500;
  color: #333;
  white-space: nowrap;
  font-size: 15px;
}
.form-select .dropdown-menu ul li a img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-right: 5px;
}
.form-select .dropdown-menu ul li a:hover {
  background-color: #f2f2f4;
}
.form-select .dropdown-menu ul li.selected a {
  background-color: var(--primary);
  color: #fff;
}
.form-select:after {
  content: "\f107";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
  line-height: 1;
  font-size: 14px;
  font-family: "Font Awesome 6 Free";
  color: var(--primary);
  z-index: 9;
}

.form-select.btn-group {
  padding: 10px 20px;
  border-width: 1px;
}
.form-select.btn-group.show {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1) !important;
  border-color: var(--primary);
}
.form-select.btn-group .form-control .dropdown-toggle.btn-default,
.form-select.btn-group
  .default-select.style-2
  .btn.dropdown-toggle
  .dropdown-toggle.btn-default,
.default-select.style-2
  .form-select.btn-group
  .btn.dropdown-toggle
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link
  .dropdown-toggle.btn-default,
.default-select.style-2
  .form-select.btn-group
  .dropdown-toggle.wp-block-button__link
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .default-select.style-3
  .btn.dropdown-toggle
  .dropdown-toggle.btn-default,
.default-select.style-3
  .form-select.btn-group
  .btn.dropdown-toggle
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link
  .dropdown-toggle.btn-default,
.default-select.style-3
  .form-select.btn-group
  .dropdown-toggle.wp-block-button__link
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .wp-block-categories-dropdown
  select
  .dropdown-toggle.btn-default,
.wp-block-categories-dropdown
  .form-select.btn-group
  select
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .wp-block-archives-dropdown
  select
  .dropdown-toggle.btn-default,
.wp-block-archives-dropdown
  .form-select.btn-group
  select
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input
  .dropdown-toggle.btn-default,
.comment-respond
  .comment-form
  p.comment-form-author
  .form-select.btn-group
  input
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input
  .dropdown-toggle.btn-default,
.comment-respond
  .comment-form
  p.comment-form-email
  .form-select.btn-group
  input
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input
  .dropdown-toggle.btn-default,
.comment-respond
  .comment-form
  p.comment-form-url
  .form-select.btn-group
  input
  .dropdown-toggle.btn-default,
.form-select.btn-group
  .comment-respond
  .comment-form
  p
  textarea
  .dropdown-toggle.btn-default,
.comment-respond
  .comment-form
  p
  .form-select.btn-group
  textarea
  .dropdown-toggle.btn-default {
  padding: 0;
}
.form-select.btn-group .dropdown-toggle .caret {
  font-size: 10px;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.form-select.btn-group .dropdown-toggle .caret:before {
  content: "\f078";
}

.custom-file-label {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 30px !important;
  padding: 10px 20px;
  font-size: 15px;
  border-color: transparent;
}
.custom-file-label:after {
  content: none !important;
}

.dz-form.radius-no .form-control,
.dz-form.radius-no .default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .dz-form.radius-no .btn.dropdown-toggle,
.dz-form.radius-no
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link,
.default-select.style-2
  .dz-form.radius-no
  .dropdown-toggle.wp-block-button__link,
.dz-form.radius-no .default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .dz-form.radius-no .btn.dropdown-toggle,
.dz-form.radius-no
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link,
.default-select.style-3
  .dz-form.radius-no
  .dropdown-toggle.wp-block-button__link,
.dz-form.radius-no .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dz-form.radius-no select,
.dz-form.radius-no .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dz-form.radius-no select,
.dz-form.radius-no .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .dz-form.radius-no input,
.dz-form.radius-no .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .dz-form.radius-no input,
.dz-form.radius-no .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .dz-form.radius-no input,
.dz-form.radius-no .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .dz-form.radius-no textarea {
  border-radius: 0;
}

.dz-form.style-1 textarea {
  height: 185px;
  padding: 15px ​20px;
}

.form-check {
  margin-bottom: 10px;
  margin-left: 10px;
}

.form-check-input[type="checkbox"] {
  height: 25px;
  width: 25px;
  border: 2px solid var(--primary);
  margin-left: -35px;
  margin-top: 1px;
}
.form-check-input[type="checkbox"]:checked {
  background-color: var(--primary);
}

.form-group {
  margin-bottom: 40px;
}

.default-select.style-1 .btn.dropdown-toggle,
.default-select.style-1 .dropdown-toggle.wp-block-button__link {
  padding: 14px 20px;
  background-color: #fff;
  border: 1px solid #e1e1e1 !important;
  border-radius: var(--border-radius-base);
  color: #777 !important;
}
.default-select.style-1 .btn.dropdown-toggle:active,
.default-select.style-1 .dropdown-toggle.wp-block-button__link:active,
.default-select.style-1 .btn.dropdown-toggle:focus,
.default-select.style-1 .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-1 .btn.dropdown-toggle:hover,
.default-select.style-1 .dropdown-toggle.wp-block-button__link:hover {
  border-color: var(--primary) !important;
}

.default-select.style-2 {
  width: 100% !important;
}
.default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .dropdown-toggle.wp-block-button__link {
  background-color: var(--rgba-primary-1);
  border: 1px solid #eeeeee !important;
  padding: 0 20px;
  line-height: 53px;
}
.default-select.style-2 .btn.dropdown-toggle.show,
.default-select.style-2 .dropdown-toggle.show.wp-block-button__link,
.default-select.style-2 .btn.dropdown-toggle:focus,
.default-select.style-2 .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-2 .btn.dropdown-toggle:active,
.default-select.style-2 .dropdown-toggle.wp-block-button__link:active {
  color: #777 !important;
  border: 1px solid var(--primary) !important;
  background-color: #fff;
}
.default-select.style-2 .btn.dropdown-toggle:hover,
.default-select.style-2 .dropdown-toggle.wp-block-button__link:hover {
  color: #777 !important;
}

.default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .dropdown-toggle.wp-block-button__link {
  border: 1px solid #e1e1e1 !important;
  padding: 0 20px;
  line-height: 53px;
}
.default-select.style-3 .btn.dropdown-toggle.show,
.default-select.style-3 .dropdown-toggle.show.wp-block-button__link,
.default-select.style-3 .btn.dropdown-toggle:focus,
.default-select.style-3 .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3 .btn.dropdown-toggle:active,
.default-select.style-3 .dropdown-toggle.wp-block-button__link:active {
  color: #777 !important;
  border: 1px solid var(--primary) !important;
  background-color: #fff;
}
.default-select.style-3 .btn.dropdown-toggle:hover,
.default-select.style-3 .dropdown-toggle.wp-block-button__link:hover {
  color: #777 !important;
}

.checkout-form {
  margin-bottom: 40px;
}
.checkout-form .form-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);
}
.checkout-form .form-control,
.checkout-form .default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .checkout-form .btn.dropdown-toggle,
.checkout-form .default-select.style-2 .dropdown-toggle.wp-block-button__link,
.default-select.style-2 .checkout-form .dropdown-toggle.wp-block-button__link,
.checkout-form .default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .checkout-form .btn.dropdown-toggle,
.checkout-form .default-select.style-3 .dropdown-toggle.wp-block-button__link,
.default-select.style-3 .checkout-form .dropdown-toggle.wp-block-button__link,
.checkout-form .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .checkout-form select,
.checkout-form .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .checkout-form select,
.checkout-form .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .checkout-form input,
.checkout-form .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .checkout-form input,
.checkout-form .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .checkout-form input,
.checkout-form .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .checkout-form textarea {
  background: none;
  border: 1px solid transparent;
  border-bottom: 2px solid #e1e1e1;
  border-radius: 0;
  padding: 10px 0;
}
.checkout-form .form-control::placeholder,
.checkout-form .default-select.style-2 .btn.dropdown-toggle::placeholder,
.default-select.style-2 .checkout-form .btn.dropdown-toggle::placeholder,
.checkout-form
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-2
  .checkout-form
  .dropdown-toggle.wp-block-button__link::placeholder,
.checkout-form .default-select.style-3 .btn.dropdown-toggle::placeholder,
.default-select.style-3 .checkout-form .btn.dropdown-toggle::placeholder,
.checkout-form
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3
  .checkout-form
  .dropdown-toggle.wp-block-button__link::placeholder,
.checkout-form .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .checkout-form select::placeholder,
.checkout-form .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .checkout-form select::placeholder,
.checkout-form
  .comment-respond
  .comment-form
  p.comment-form-author
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .checkout-form
  input::placeholder,
.checkout-form
  .comment-respond
  .comment-form
  p.comment-form-email
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .checkout-form
  input::placeholder,
.checkout-form
  .comment-respond
  .comment-form
  p.comment-form-url
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .checkout-form
  input::placeholder,
.checkout-form .comment-respond .comment-form p textarea::placeholder,
.comment-respond .comment-form p .checkout-form textarea::placeholder {
  font-weight: 600;
  font-size: 16px;
  color: var(--seconday);
}

.tag-donate.style-1 {
  display: flex;
  flex-wrap: wrap;
}
.tag-donate.style-1 .donate-categories {
  position: relative;
  text-align: center;
}
.tag-donate.style-1 .donate-categories .form-check {
  border: 1px solid #e1e1e1;
  border-radius: var(--border-radius-base);
  padding-left: 0;
  display: inline-block;
  margin-left: 0;
  margin-right: 10px;
}
.tag-donate.style-1 .donate-categories .form-check .form-check-input {
  display: none;
}
.tag-donate.style-1
  .donate-categories
  .form-check
  .form-check-input:checked
  + .form-check-label {
  background-color: var(--primary);
  color: #fff;
  border-radius: var(--border-radius-base);
}
.tag-donate.style-1 .donate-categories .form-check .form-check-label {
  padding: 5px 10px;
  font-size: 13px;
  cursor: pointer;
}

.tag-donate.style-2 {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
}
.tag-donate.style-2 .donate-categories {
  position: relative;
  text-align: center;
}
.tag-donate.style-2 .donate-categories .form-check {
  border: 1px solid #f0f0f0;
  border-radius: var(--border-radius-base);
  padding-left: 0;
  display: inline-block;
}
.tag-donate.style-2 .donate-categories .form-check .form-check-input {
  display: none;
}
.tag-donate.style-2
  .donate-categories
  .form-check
  .form-check-input:checked
  + .form-check-label {
  background-color: var(--primary);
  color: #fff;
  border-radius: var(--border-radius-base);
}
.tag-donate.style-2 .donate-categories .form-check .form-check-label {
  padding: 6px 12px;
}

.tag-donate.style-3 {
  justify-content: center;
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 575px) {
  .tag-donate.style-3 {
    margin-bottom: 20px;
  }
}
.tag-donate.style-3 .donate-categories {
  position: relative;
  text-align: center;
}
.tag-donate.style-3 .donate-categories .form-check {
  margin-left: 0;
  margin-right: 10px;
  padding-left: 0;
}
.tag-donate.style-3 .donate-categories .form-check .form-check-label {
  border: 2px solid var(--title);
  border-radius: var(--border-radius-base);
  padding: 7px 20px;
  font-size: 18px;
  cursor: pointer;
  color: var(--title);
  font-weight: 500;
}
@media only screen and (max-width: 575px) {
  .tag-donate.style-3 .donate-categories .form-check .form-check-label {
    padding: 5px 12px;
    font-size: 15px;
  }
}
.tag-donate.style-3 .donate-categories .form-check .form-check-input {
  display: none;
}
.tag-donate.style-3
  .donate-categories
  .form-check
  .form-check-input:checked
  + .form-check-label {
  background-color: var(--primary);
  border: 2px solid var(--primary);
  color: #fff;
  border-radius: var(--border-radius-base);
}

.input-group-text.checkout-text {
  background: none;
  border-color: transparent;
  position: relative;
  right: 60px;
}
.input-group-text.checkout-text i {
  font-size: 20px;
  font-weight: 600;
}

.form-control.default-select .btn,
.default-select.style-2 .default-select.btn.dropdown-toggle .btn,
.default-select.style-2
  .default-select.dropdown-toggle.wp-block-button__link
  .btn,
.default-select.style-3 .default-select.btn.dropdown-toggle .btn,
.default-select.style-3
  .default-select.dropdown-toggle.wp-block-button__link
  .btn,
.wp-block-categories-dropdown select.default-select .btn,
.wp-block-archives-dropdown select.default-select .btn,
.comment-respond .comment-form p.comment-form-author input.default-select .btn,
.comment-respond .comment-form p.comment-form-email input.default-select .btn,
.comment-respond .comment-form p.comment-form-url input.default-select .btn,
.comment-respond .comment-form p textarea.default-select .btn,
.form-control.default-select .wp-block-button__link,
.default-select.style-2
  .default-select.btn.dropdown-toggle
  .wp-block-button__link,
.default-select.style-2
  .default-select.dropdown-toggle.wp-block-button__link
  .wp-block-button__link,
.default-select.style-3
  .default-select.btn.dropdown-toggle
  .wp-block-button__link,
.default-select.style-3
  .default-select.dropdown-toggle.wp-block-button__link
  .wp-block-button__link,
.wp-block-categories-dropdown select.default-select .wp-block-button__link,
.wp-block-archives-dropdown select.default-select .wp-block-button__link,
.comment-respond
  .comment-form
  p.comment-form-author
  input.default-select
  .wp-block-button__link,
.comment-respond
  .comment-form
  p.comment-form-email
  input.default-select
  .wp-block-button__link,
.comment-respond
  .comment-form
  p.comment-form-url
  input.default-select
  .wp-block-button__link,
.comment-respond
  .comment-form
  p
  textarea.default-select
  .wp-block-button__link {
  background: transparent;
  border-width: 0 0 2px;
  border-radius: 0;
  padding: 15px 10px !important;
  border-color: #e1e1e1;
  padding: 10px 20px;
}
.form-control.default-select .btn:focus,
.default-select.style-2 .default-select.btn.dropdown-toggle .btn:focus,
.default-select.style-2
  .default-select.dropdown-toggle.wp-block-button__link
  .btn:focus,
.default-select.style-3 .default-select.btn.dropdown-toggle .btn:focus,
.default-select.style-3
  .default-select.dropdown-toggle.wp-block-button__link
  .btn:focus,
.wp-block-categories-dropdown select.default-select .btn:focus,
.wp-block-archives-dropdown select.default-select .btn:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  input.default-select
  .btn:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  input.default-select
  .btn:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  input.default-select
  .btn:focus,
.comment-respond .comment-form p textarea.default-select .btn:focus,
.form-control.default-select .wp-block-button__link:focus,
.default-select.style-2
  .default-select.btn.dropdown-toggle
  .wp-block-button__link:focus,
.default-select.style-2
  .default-select.dropdown-toggle.wp-block-button__link
  .wp-block-button__link:focus,
.default-select.style-3
  .default-select.btn.dropdown-toggle
  .wp-block-button__link:focus,
.default-select.style-3
  .default-select.dropdown-toggle.wp-block-button__link
  .wp-block-button__link:focus,
.wp-block-categories-dropdown
  select.default-select
  .wp-block-button__link:focus,
.wp-block-archives-dropdown select.default-select .wp-block-button__link:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  input.default-select
  .wp-block-button__link:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  input.default-select
  .wp-block-button__link:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  input.default-select
  .wp-block-button__link:focus,
.comment-respond
  .comment-form
  p
  textarea.default-select
  .wp-block-button__link:focus {
  outline: 0 !important;
}
.form-control.default-select .btn:after,
.default-select.style-2 .default-select.btn.dropdown-toggle .btn:after,
.default-select.style-2
  .default-select.dropdown-toggle.wp-block-button__link
  .btn:after,
.default-select.style-3 .default-select.btn.dropdown-toggle .btn:after,
.default-select.style-3
  .default-select.dropdown-toggle.wp-block-button__link
  .btn:after,
.wp-block-categories-dropdown select.default-select .btn:after,
.wp-block-archives-dropdown select.default-select .btn:after,
.comment-respond
  .comment-form
  p.comment-form-author
  input.default-select
  .btn:after,
.comment-respond
  .comment-form
  p.comment-form-email
  input.default-select
  .btn:after,
.comment-respond
  .comment-form
  p.comment-form-url
  input.default-select
  .btn:after,
.comment-respond .comment-form p textarea.default-select .btn:after,
.form-control.default-select .wp-block-button__link:after,
.default-select.style-2
  .default-select.btn.dropdown-toggle
  .wp-block-button__link:after,
.default-select.style-2
  .default-select.dropdown-toggle.wp-block-button__link
  .wp-block-button__link:after,
.default-select.style-3
  .default-select.btn.dropdown-toggle
  .wp-block-button__link:after,
.default-select.style-3
  .default-select.dropdown-toggle.wp-block-button__link
  .wp-block-button__link:after,
.wp-block-categories-dropdown
  select.default-select
  .wp-block-button__link:after,
.wp-block-archives-dropdown select.default-select .wp-block-button__link:after,
.comment-respond
  .comment-form
  p.comment-form-author
  input.default-select
  .wp-block-button__link:after,
.comment-respond
  .comment-form
  p.comment-form-email
  input.default-select
  .wp-block-button__link:after,
.comment-respond
  .comment-form
  p.comment-form-url
  input.default-select
  .wp-block-button__link:after,
.comment-respond
  .comment-form
  p
  textarea.default-select
  .wp-block-button__link:after {
  content: "\f107";
  position: absolute;
  font-family: "Font Awesome 5 Free";
  border-style: none;
  right: 0;
}

.form-control.default-select .filter-option-inner-inner,
.default-select.style-2
  .default-select.btn.dropdown-toggle
  .filter-option-inner-inner,
.default-select.style-2
  .default-select.dropdown-toggle.wp-block-button__link
  .filter-option-inner-inner,
.default-select.style-3
  .default-select.btn.dropdown-toggle
  .filter-option-inner-inner,
.default-select.style-3
  .default-select.dropdown-toggle.wp-block-button__link
  .filter-option-inner-inner,
.wp-block-categories-dropdown select.default-select .filter-option-inner-inner,
.wp-block-archives-dropdown select.default-select .filter-option-inner-inner,
.comment-respond
  .comment-form
  p.comment-form-author
  input.default-select
  .filter-option-inner-inner,
.comment-respond
  .comment-form
  p.comment-form-email
  input.default-select
  .filter-option-inner-inner,
.comment-respond
  .comment-form
  p.comment-form-url
  input.default-select
  .filter-option-inner-inner,
.comment-respond
  .comment-form
  p
  textarea.default-select
  .filter-option-inner-inner {
  font-weight: 600;
  font-size: 16px;
}

.form-wrapper {
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: relative;
  padding: 50px;
}
@media only screen and (max-width: 767px) {
  .form-wrapper {
    padding: 30px;
  }
}
.form-wrapper .input-group {
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .form-wrapper .input-group {
    margin-bottom: 20px;
  }
}
.form-wrapper .title {
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .form-wrapper .title {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .form-wrapper h2.title,
  .form-wrapper .title.h2 {
    font-size: 24px;
  }
}
.form-wrapper.style-3 {
  /* margin-top: -60px; */
  margin-top: -30px;
  overflow: hidden;
}
.form-wrapper.style-3 .form-group .text-label {
  color: var(--title);
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .form-wrapper.style-3 {
    /* margin-top: -50px; */
    margin-top: -30px;
  }
}

.form-wizard {
  border: none;
}
.form-wizard.sw > .tab-content {
  position: relative;
  overflow: unset !important;
  height: auto !important;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .form-wizard.sw > .tab-content {
    margin-bottom: 20px;
  }
}
.form-wizard.sw > .tab-content > .tab-pane {
  padding: 0;
}
.form-wizard .target-bx {
  background: #faf8f2;
  padding: 25px 30px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .form-wizard .target-bx {
    padding: 20px 25px;
  }
}
.form-wizard .target-bx ol {
  padding-left: 22px;
  margin-bottom: 0;
}
.form-wizard .target-bx ol li {
  list-style: disc;
}
@media only screen and (max-width: 767px) {
  .form-wizard .target-bx ol li {
    font-size: 15px;
  }
}
.form-wizard .toolbar-bottom {
  padding: 0;
}
.form-wizard .nav-wizard {
  box-shadow: unset !important;
  margin: -50px -50px 50px;
}
@media only screen and (max-width: 767px) {
  .form-wizard .nav-wizard {
    flex-direction: row !important;
    margin: -30px -30px 30px;
  }
}
.form-wizard .nav-wizard .nav-link {
  padding: 15px 30px;
  color: var(--primary) !important;
  font-weight: 500;
  font-size: 18px;
  font-family: var(--font-family-title);
  background-color: #faf8f2;
  margin-right: 0;
  line-height: 1.6;
}
@media only screen and (max-width: 767px) {
  .form-wizard .nav-wizard .nav-link {
    padding: 10px 20px;
  }
}
.form-wizard .nav-wizard .nav-link:after {
  content: none;
}
.form-wizard .nav-wizard .nav-link.active,
.form-wizard .nav-wizard .nav-link.done {
  background-color: var(--secondary);
  color: #fff !important;
  border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.form-wizard .nav-wizard > li .nav-link {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.form-wizard .nav-wizard > li:last-child .nav-link {
  border-right: 0 !important;
}
.form-wizard .btn.sw-btn,
.form-wizard .sw-btn.wp-block-button__link {
  border: 1px solid var(--primary);
  background-color: var(--primary);
  padding: 10px 20px;
}
@media only screen and (max-width: 767px) {
  .form-wizard .btn.sw-btn,
  .form-wizard .sw-btn.wp-block-button__link {
    padding: 8px 14px;
  }
}

.form-wrapper1 {
  padding: 60px 0 30px;
}
@media only screen and (max-width: 991px) {
  .form-wrapper1 {
    padding: 40px 0 40px;
  }
}
@media only screen and (max-width: 575px) {
  .form-wrapper1 .g-4,
  .form-wrapper1 .gx-4 {
    --bs-gutter-y: 1rem;
    --bs-gutter-x: 1rem;
  }
  .form-wrapper1 .btn,
  .form-wrapper1 .wp-block-button__link {
    padding: 16px 20px;
  }
}
.form-wrapper1 .section-head {
  margin-bottom: 30px;
}
.form-wrapper1 .section-head h2,
.form-wrapper1 .section-head .h2 {
  font-size: 40px;
}
@media only screen and (max-width: 575px) {
  .form-wrapper1 .section-head h2,
  .form-wrapper1 .section-head .h2 {
    font-size: 32px;
  }
}

.donate-form {
  padding: 85px 50px;
  text-align: center;
  border-radius: var(--border-radius-base);
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  background-color: var(--secondary);
  background-size: 100%;
  background-blend-mode: luminosity;
  z-index: 1;
}
@media only screen and (max-width: 1280px) {
  .donate-form {
    padding: 65px 30px;
  }
}
@media only screen and (max-width: 991px) {
  .donate-form {
    margin: 0;
  }
}
@media only screen and (max-width: 575px) {
  .donate-form {
    padding: 35px 25px;
  }
}
.donate-form .title {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 700;
}
@media only screen and (max-width: 575px) {
  .donate-form .title {
    font-size: 32px;
    margin-bottom: 15px;
  }
}
.donate-form:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  border-radius: var(--border-radius-base);
  background: -moz-linear-gradient(
    top,
    rgba(247, 207, 71, 0.5) 0%,
    rgba(247, 207, 71, 0.52) 1%,
    #f7cf47 24%,
    #f7cf47 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(247, 207, 71, 0.5) 0%,
    rgba(247, 207, 71, 0.52) 1%,
    #f7cf47 24%,
    #f7cf47 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(247, 207, 71, 0.5) 0%,
    rgba(247, 207, 71, 0.52) 1%,
    #f7cf47 24%,
    #f7cf47 100%
  );
}
.donate-form .donate-info {
  margin-bottom: 50px;
}
@media only screen and (max-width: 575px) {
  .donate-form .donate-info {
    margin-bottom: 20px;
  }
}
.donate-form .donate-info p {
  color: var(--title);
}
@media only screen and (max-width: 575px) {
  .donate-form .donate-info p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .donate-form .form-group {
    margin-bottom: 30px;
  }
}
.donate-form .form-control,
.donate-form .default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .donate-form .btn.dropdown-toggle,
.donate-form .default-select.style-2 .dropdown-toggle.wp-block-button__link,
.default-select.style-2 .donate-form .dropdown-toggle.wp-block-button__link,
.donate-form .default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .donate-form .btn.dropdown-toggle,
.donate-form .default-select.style-3 .dropdown-toggle.wp-block-button__link,
.default-select.style-3 .donate-form .dropdown-toggle.wp-block-button__link,
.donate-form .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .donate-form select,
.donate-form .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .donate-form select,
.donate-form .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .donate-form input,
.donate-form .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .donate-form input,
.donate-form .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .donate-form input,
.donate-form .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .donate-form textarea {
  margin-top: 20px;
  border-color: var(--title) !important;
  border-width: 0px 0 3px 0;
  border-style: solid;
  color: #777;
  background: transparent;
  border-radius: 0 !important;
  text-align: center;
  font-size: 20px;
  padding-bottom: 40px;
}
.donate-form .form-control::placeholder,
.donate-form .default-select.style-2 .btn.dropdown-toggle::placeholder,
.default-select.style-2 .donate-form .btn.dropdown-toggle::placeholder,
.donate-form
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-2
  .donate-form
  .dropdown-toggle.wp-block-button__link::placeholder,
.donate-form .default-select.style-3 .btn.dropdown-toggle::placeholder,
.default-select.style-3 .donate-form .btn.dropdown-toggle::placeholder,
.donate-form
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3
  .donate-form
  .dropdown-toggle.wp-block-button__link::placeholder,
.donate-form .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .donate-form select::placeholder,
.donate-form .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .donate-form select::placeholder,
.donate-form
  .comment-respond
  .comment-form
  p.comment-form-author
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .donate-form
  input::placeholder,
.donate-form
  .comment-respond
  .comment-form
  p.comment-form-email
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .donate-form
  input::placeholder,
.donate-form
  .comment-respond
  .comment-form
  p.comment-form-url
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .donate-form
  input::placeholder,
.donate-form .comment-respond .comment-form p textarea::placeholder,
.comment-respond .comment-form p .donate-form textarea::placeholder {
  color: #003b4a;
  opacity: 0.4;
  font-size: 24px;
}
@media only screen and (max-width: 575px) {
  .donate-form .form-control,
  .donate-form .default-select.style-2 .btn.dropdown-toggle,
  .default-select.style-2 .donate-form .btn.dropdown-toggle,
  .donate-form .default-select.style-2 .dropdown-toggle.wp-block-button__link,
  .default-select.style-2 .donate-form .dropdown-toggle.wp-block-button__link,
  .donate-form .default-select.style-3 .btn.dropdown-toggle,
  .default-select.style-3 .donate-form .btn.dropdown-toggle,
  .donate-form .default-select.style-3 .dropdown-toggle.wp-block-button__link,
  .default-select.style-3 .donate-form .dropdown-toggle.wp-block-button__link,
  .donate-form .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .donate-form select,
  .donate-form .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .donate-form select,
  .donate-form .comment-respond .comment-form p.comment-form-author input,
  .comment-respond .comment-form p.comment-form-author .donate-form input,
  .donate-form .comment-respond .comment-form p.comment-form-email input,
  .comment-respond .comment-form p.comment-form-email .donate-form input,
  .donate-form .comment-respond .comment-form p.comment-form-url input,
  .comment-respond .comment-form p.comment-form-url .donate-form input,
  .donate-form .comment-respond .comment-form p textarea,
  .comment-respond .comment-form p .donate-form textarea {
    font-size: 16px;
    margin-top: 0px;
    border-width: 0px 0 2px 0;
    padding: 10px;
  }
  .donate-form .form-control::placeholder,
  .donate-form .default-select.style-2 .btn.dropdown-toggle::placeholder,
  .default-select.style-2 .donate-form .btn.dropdown-toggle::placeholder,
  .donate-form
    .default-select.style-2
    .dropdown-toggle.wp-block-button__link::placeholder,
  .default-select.style-2
    .donate-form
    .dropdown-toggle.wp-block-button__link::placeholder,
  .donate-form .default-select.style-3 .btn.dropdown-toggle::placeholder,
  .default-select.style-3 .donate-form .btn.dropdown-toggle::placeholder,
  .donate-form
    .default-select.style-3
    .dropdown-toggle.wp-block-button__link::placeholder,
  .default-select.style-3
    .donate-form
    .dropdown-toggle.wp-block-button__link::placeholder,
  .donate-form .wp-block-categories-dropdown select::placeholder,
  .wp-block-categories-dropdown .donate-form select::placeholder,
  .donate-form .wp-block-archives-dropdown select::placeholder,
  .wp-block-archives-dropdown .donate-form select::placeholder,
  .donate-form
    .comment-respond
    .comment-form
    p.comment-form-author
    input::placeholder,
  .comment-respond
    .comment-form
    p.comment-form-author
    .donate-form
    input::placeholder,
  .donate-form
    .comment-respond
    .comment-form
    p.comment-form-email
    input::placeholder,
  .comment-respond
    .comment-form
    p.comment-form-email
    .donate-form
    input::placeholder,
  .donate-form
    .comment-respond
    .comment-form
    p.comment-form-url
    input::placeholder,
  .comment-respond
    .comment-form
    p.comment-form-url
    .donate-form
    input::placeholder,
  .donate-form .comment-respond .comment-form p textarea::placeholder,
  .comment-respond .comment-form p .donate-form textarea::placeholder {
    font-size: 16px;
  }
}

.input-recaptcha {
  margin-bottom: 30px;
}
@media only screen and (max-width: 575px) {
  .input-recaptcha {
    transform: scale(0.77);
    -moz-transform: scale(0.77);
    -o-transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
    margin-bottom: 0px;
  }
}

button:hover,
button:active,
button:focus {
  outline: 0;
}

.btn,
.wp-block-button__link {
  padding: 14px 30px;
  display: inline-flex;
  border-radius: var(--border-radius-base);
  font-size: 16px;
  font-weight: 500;
  position: relative;
  text-transform: capitalize;
  line-height: 1.4;
  align-items: center;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  font-family: var(--font-family-base);
  justify-content: center;
}
.btn.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .wp-block-button__link,
.btn-lg.wp-block-button__link {
  padding: 24px 40px;
  font-size: 16px;
}
@media only screen and (max-width: 1480px) {
  .btn.btn-lg,
  .btn-group-lg > .btn,
  .btn-group-lg > .wp-block-button__link,
  .btn-lg.wp-block-button__link {
    padding: 20px 35px;
  }
}
@media only screen and (max-width: 575px) {
  .btn.btn-lg,
  .btn-group-lg > .btn,
  .btn-group-lg > .wp-block-button__link,
  .btn-lg.wp-block-button__link {
    padding: 15px 26px;
    font-size: 14px;
  }
}
.btn.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm > .wp-block-button__link,
.btn-sm.wp-block-button__link {
  font-size: 13px;
  padding: 10px 20px;
}
.btn.btn-primary,
.wp-block-button__link {
  color: #fff;
}
.btn.btn-primary:hover,
.wp-block-button__link:hover {
  color: #fff;
}
@media only screen and (max-width: 1480px) {
  .btn,
  .wp-block-button__link {
    padding: 14px 30px;
  }
}
@media only screen and (max-width: 991px) {
  .btn,
  .wp-block-button__link {
    padding: 12px 20px;
    font-size: 14px;
  }
}
.btn.facebook,
.facebook.wp-block-button__link {
  background-color: #3b5998;
  border: 1px solid #3b5998;
  color: #fff;
}
.btn.facebook:hover,
.facebook.wp-block-button__link:hover {
  background-color: #3b5998;
}
.btn.google-plus,
.google-plus.wp-block-button__link {
  background-color: #de4e43;
  border: 1px solid #de4e43;
  color: #fff;
}
.btn.google-plus:hover,
.google-plus.wp-block-button__link:hover {
  background-color: #de4e43;
}
.btn.whatsapp,
.whatsapp.wp-block-button__link {
  background-color: #25d366;
  border: 1px solid #25d366;
  color: #fff;
}
.btn.whatsapp:hover,
.whatsapp.wp-block-button__link:hover {
  background-color: #25d366;
  border-color: #25d366;
}

.shadow-primary {
  box-shadow: 0px 12px 20px 0px var(--rgba-primary-3);
}

.btn-info {
  color: #fff;
}
.btn-info:hover {
  color: #fff;
}

.btn-white {
  background-color: #fff;
  color: var(--primary);
}
.btn-white:hover {
  color: var(--primary);
}

.btn-primary,
.wp-block-button__link {
  border-color: var(--primary);
  background-color: var(--primary);
}
.btn-primary.disabled,
.disabled.wp-block-button__link,
.btn-primary:disabled,
.wp-block-button__link:disabled,
.btn-primary:not(:disabled):not(.disabled).active,
.wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.wp-block-button__link:not(:disabled):not(.disabled):active,
.btn-primary:active,
.wp-block-button__link:active,
.btn-primary:focus,
.wp-block-button__link:focus,
.btn-primary:hover,
.wp-block-button__link:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}
.btn-primary.light,
.light.wp-block-button__link {
  color: var(--primary);
  background-color: var(--rgba-primary-1);
}
.btn-primary.light:hover,
.light.wp-block-button__link:hover {
  background-color: var(--primary);
}

.btn-link {
  color: var(--primary);
  text-decoration: none;
}
.btn-link:hover {
  color: var(--primary-hover);
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary.disabled,
.is-style-outline .disabled.wp-block-button__link,
.btn-outline-primary:disabled,
.is-style-outline .wp-block-button__link:disabled,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled):active,
.btn-outline-primary:active,
.is-style-outline .wp-block-button__link:active,
.btn-outline-primary:focus,
.is-style-outline .wp-block-button__link:focus,
.btn-outline-primary:hover,
.is-style-outline .wp-block-button__link:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
  color: #fff;
}

.btn-outline-secondary {
  color: var(--secondary);
  border-color: var(--secondary);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:active,
.btn-outline-secondary:focus,
.btn-outline-secondary:hover {
  border-color: var(--secondary);
  background-color: var(--secondary);
  color: #fff;
}

.btn-link-lg {
  font-weight: 700;
  font-size: 18px;
}
.btn-link-lg:hover {
  text-decoration: unset;
}

.btn-light {
  background-color: #faf8f2;
  border-color: #e1e1e1;
  color: #050d36;
}
.btn-light.text-primary:hover {
  color: #fff !important;
}
.btn-light:hover {
  background-color: #e4e4e4;
  border-color: #e4e4e4;
}

.wp-block-button {
  margin-bottom: 0.3125rem;
}

.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

.wp-block-button__link {
  border-radius: 60px;
}
.wp-block-button__link:after {
  content: none;
}

.btn-secondary {
  color: #fff;
  background-color: var(--secondary);
  border-color: var(--secondary);
}
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary:hover {
  color: #fff;
  background-color: var(--secondary-hover);
}

.btn-secondary.effect:after,
.btn-success.effect:after,
.btn-info.effect:after,
.btn-danger.effect:after,
.btn-warning.effect:after {
  background: var(--secondary);
}

.btn-rounded-lg {
  border-radius: 30px;
}

.btn-block {
  display: block;
  width: 100%;
}

.bookmark-btn {
  padding: 0;
  width: 52px;
  height: 52px;
  min-width: 52px;
  justify-content: center;
}
@media only screen and (max-width: 991px) {
  .bookmark-btn {
    width: 45px;
    height: 45px;
    min-width: 45px;
  }
}

.btnhover1:hover i {
  -webkit-animation: upToDown 0.5s forwards;
  -moz-animation: upToDown 0.5s forwards;
  animation: upToDown 0.5s forwards;
}

@-webkit-keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes upToDown {
  49% {
    -moz-transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -moz-transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes upToDown {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

.btnhover2:hover i {
  -webkit-animation: upToRight 0.5s forwards;
  -moz-animation: upToRight 0.5s forwards;
  animation: upToRight 0.5s forwards;
}

@-webkit-keyframes upToRight {
  49% {
    -webkit-transform: translateX(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes upToRight {
  49% {
    -moz-transform: translateX(100%);
  }
  50% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes upToRight {
  49% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  51% {
    opacity: 1;
  }
}

.badge {
  line-height: 1.2;
  border-radius: var(--border-radius-base);
  font-weight: 600;
  padding: 6px 12px;
  border: 0.0625rem solid transparent;
  font-size: 13px;
}

.badge-rounded {
  border-radius: 1.25rem;
  padding: 0.1875rem 0.8125rem;
}

.badge-circle {
  border-radius: 6.25rem;
  padding: 0.1875rem 0.4375rem;
}

.badge-outline-primary {
  border: 0.0625rem solid var(--primary);
  color: var(--primary);
}

.badge-outline-secondary {
  border: 0.0625rem solid #ff7468;
  color: #ff7468;
}

.badge-outline-success {
  border: 0.0625rem solid #13c24d;
  color: #13c24d;
}

.badge-outline-info {
  border: 0.0625rem solid #00aeff;
  color: #00aeff;
}

.badge-outline-warning {
  border: 0.0625rem solid #fea500;
  color: #fea500;
}

.badge-outline-danger {
  border: 0.0625rem solid #ff0003;
  color: #ff0003;
}

.badge-outline-light {
  border: 0.0625rem solid #e1e1e1;
  color: #050d36;
}

.badge-outline-dark {
  border: 0.0625rem solid #050d36;
  color: #050d36;
}

.badge-lg {
  padding: 6px 14px;
  font-size: 14px;
  line-height: 1.4;
}
.badge-lg:hover {
  color: #fff;
}

.badge-sm {
  font-size: 12px;
  padding: 4px 8px;
}

.badge-success {
  background-color: #13c24d;
}

.badge-secondary {
  background-color: #ff7468;
}

.badge-info {
  background-color: #00aeff;
}

.badge-primary {
  background-color: var(--primary);
}

.badge-warning {
  background-color: #fea500;
}

.badge-danger {
  background-color: #ff0003;
}

.badge-dark {
  background-color: #050d36;
}

.badge-light {
  background-color: #faf8f2;
  border-color: #e1e1e1;
  color: #050d36;
}

.table-responsive-sm {
  min-width: 48rem;
}

.table {
  margin-bottom: 0px;
  font-weight: 400;
}
.table tr td,
.table tr th {
  padding: 15px;
}

.table tr th {
  color: #050d36;
  font-weight: 500;
}

.table-striped thead tr th {
  padding-bottom: 15px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.table-striped tfoot {
  border-top: 2px solid #dee2e6;
}
.table-striped tfoot tr th {
  padding-top: 10px;
}

.dz-separator {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 8px;
  position: relative;
}
.dz-separator.m-b0 {
  margin-bottom: 0;
}
.dz-separator.style-liner {
  width: 20px;
}
.dz-separator.style-icon {
  width: 30px;
  height: auto;
  text-align: center;
  font-size: 20px;
}
.dz-separator.style-skew {
  width: 15px;
  height: 10px;
  margin-left: 1px;
  margin-right: 1px;
  -moz-transform: skewX(-10deg);
  -webkit-transform: skewX(-10deg);
  -o-transform: skewX(-10deg);
  -ms-transform: skewX(-10deg);
  transform: skewX(-10deg);
}
.dz-separator.style-skew[class*="style-"]:after,
.dz-separator.style-skew[class*="style-"]:before {
  width: 80px;
  height: 4px;
  left: 20px;
  -moz-transform: translateY(-50%) skewX(-10deg);
  -webkit-transform: translateY(-50%) skewX(-10deg);
  -o-transform: translateY(-50%) skewX(-10deg);
  -ms-transform: translateY(-50%) skewX(-10deg);
  transform: translateY(-50%) skewX(-10deg);
}
.dz-separator.style-skew[class*="style-"]:before {
  right: 20px;
  left: auto;
}
.dz-separator.bnr-title {
  height: 1px;
  width: 155px;
  opacity: 0.5;
}
.dz-separator.bnr-title:before,
.dz-separator.bnr-title:after {
  position: absolute;
  content: "";
  background-color: inherit;
}
.dz-separator.bnr-title:before {
  height: inherit;
  right: -80px;
  width: 25px;
}
.dz-separator.bnr-title:after {
  height: inherit;
  right: -90px;
  top: 0;
  width: 6px;
}
.dz-separator.bnr-title i {
  background-color: inherit;
  display: block;
  height: inherit;
  position: absolute;
  right: -50px;
  width: 45px;
}

.dz-separator-outer {
  overflow: hidden;
}

.dz-separator.style-1:after,
.dz-separator.style-1:before {
  content: "";
  height: 2px;
  border-radius: 10px;
  background-size: 200%;
  position: absolute;
  bottom: 0;
}

.dz-separator.style-1:before {
  width: 25px;
  left: 0;
  background-color: var(--primary);
  top: auto;
}

.dz-separator.style-1:after {
  background-color: var(--primary);
  width: 55px;
  left: 32px;
}

.dz-separator.style-2 {
  height: 1px;
  width: 60px;
}

.site-filters {
  margin-bottom: 30px;
}
.site-filters ul {
  margin: 0;
  list-style: none;
}
.site-filters li {
  display: inline-block;
  padding: 0;
  margin-bottom: 3px;
}
.site-filters li.btn,
.site-filters li.wp-block-button__link {
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: none !important;
}
.site-filters li input {
  display: none;
}
.site-filters li a {
  margin: 0 5px 5px 0;
  display: block;
}
.site-filters li.active [class*="btn"] {
  color: #fff;
  background-color: #efbb20;
}
.site-filters.center {
  text-align: center;
}
.site-filters.center ul {
  display: inline-block;
  margin: auto;
}
.site-filters.center [class*="btn"] {
  display: inline-block;
  margin: 0 5px 10px;
  text-transform: uppercase;
}
@media only screen and (max-width: 575px) {
  .site-filters.center [class*="btn"] {
    margin: 0 1px 5px;
    padding: 5px 10px;
    font-size: 12px;
  }
}
.site-filters.style-1 {
  margin-bottom: 20px;
}
@media only screen and (max-width: 575px) {
  .site-filters.style-1 {
    margin-bottom: 15px;
  }
}
.site-filters.style-1 .filters {
  margin-right: -5px;
  margin-left: -5px;
  display: flex;
  flex-wrap: wrap;
}
.site-filters.style-1 .filters li {
  text-transform: capitalize !important;
  padding: 0 5px;
  margin-bottom: 10px;
  border: 0;
  border-radius: 0;
}
.site-filters.style-1 .filters li:after {
  content: none;
}
.site-filters.style-1 .filters li a {
  color: var(--primary);
  background-color: var(--rgba-primary-1);
  font-size: 14px;
  margin: 0;
  border-radius: var(--border-radius-base);
  padding: 14px 30px;
  font-weight: 500;
  text-transform: capitalize;
}
.site-filters.style-1 .filters li.active a {
  color: #fff;
  background-color: var(--primary);
}
@media only screen and (max-width: 767px) {
  .site-filters.style-1 .filters {
    justify-content: center;
  }
}
@media only screen and (max-width: 575px) {
  .site-filters.style-1 .filters {
    margin-right: -3px;
    margin-left: -3px;
  }
  .site-filters.style-1 .filters li {
    padding: 0 3px;
    margin-bottom: 6px;
  }
  .site-filters.style-1 .filters li a {
    font-size: 13px;
    padding: 10px 15px;
  }
}

.dz-img-overlay1,
.dz-img-overlay2,
.dz-img-overlay3,
.dz-img-overlay4,
.dz-img-overlay5,
.dz-img-overlay6,
.dz-img-overlay7,
.dz-img-overlay8,
.dz-img-overlay9,
.dz-img-overlay11,
.dz-img-overlay12 {
  position: relative;
}
.dz-img-overlay1:before,
.dz-img-overlay1:after,
.dz-img-overlay2:before,
.dz-img-overlay2:after,
.dz-img-overlay3:before,
.dz-img-overlay3:after,
.dz-img-overlay4:before,
.dz-img-overlay4:after,
.dz-img-overlay5:before,
.dz-img-overlay5:after,
.dz-img-overlay6:before,
.dz-img-overlay6:after,
.dz-img-overlay7:before,
.dz-img-overlay7:after,
.dz-img-overlay8:before,
.dz-img-overlay8:after,
.dz-img-overlay9:before,
.dz-img-overlay9:after,
.dz-img-overlay11:before,
.dz-img-overlay11:after,
.dz-img-overlay12:before,
.dz-img-overlay12:after {
  content: "";
  background: #282d32;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 1;
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -ms-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
}
.dz-img-overlay1.overlay-primary,
.dz-img-overlay2.overlay-primary,
.dz-img-overlay3.overlay-primary,
.dz-img-overlay4.overlay-primary,
.dz-img-overlay5.overlay-primary,
.dz-img-overlay6.overlay-primary,
.dz-img-overlay7.overlay-primary,
.dz-img-overlay8.overlay-primary,
.dz-img-overlay9.overlay-primary,
.dz-img-overlay11.overlay-primary,
.dz-img-overlay12.overlay-primary {
  background: var(--primary);
}

.dz-box:hover .dz-img-overlay1:before {
  opacity: 0.7;
}

.dz-box:hover .dz-img-overlay2:before {
  opacity: 0.9;
}

.dz-box:hover .dz-img-overlay3:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-box:hover .dz-img-overlay4:before {
  width: 100%;
  left: 0;
}

.dz-box:hover .dz-img-overlay5:before {
  height: 100%;
  top: 0;
}

.dz-box:hover .dz-img-overlay6:before {
  height: 100%;
}

.dz-box:hover .dz-img-overlay7:before,
.dz-box:hover .dz-img-overlay7:after {
  width: 50%;
}

.dz-box:hover .dz-img-overlay8:before,
.dz-box:hover .dz-img-overlay8:after {
  width: 100%;
  opacity: 0.3;
}

.dz-box:hover .dz-img-overlay9:before,
.dz-box:hover .dz-img-overlay9:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.dz-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dz-img-overlay1:hover:before {
  opacity: 0.5;
}

.dz-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}
.dz-img-overlay2:before:hover:before {
  opacity: 0.9;
}

.dz-img-overlay3:before {
  left: 50%;
  top: 50%;
}

.dz-img-overlay3:hover:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-img-overlay4:before {
  left: 50%;
  top: 0;
  height: 100%;
}

.dz-img-overlay4:hover:before {
  width: 100%;
  left: 0;
}

.dz-img-overlay5:before {
  left: 0;
  top: 50%;
  width: 100%;
}

.dz-img-overlay5:hover:before {
  height: 100%;
  top: 0;
}

.dz-img-overlay6:before {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
}

.dz-img-overlay6:hover:before {
  height: 100%;
}

.dz-img-overlay7:before,
.dz-img-overlay7:after {
  height: 100%;
}

.dz-img-overlay7:after {
  left: auto;
  right: 0;
}

.dz-img-overlay7:hover:before,
.dz-img-overlay7:hover:after {
  width: 50%;
}

.dz-img-overlay8:before,
.dz-img-overlay8:after {
  height: 100%;
}

.dz-img-overlay8:after {
  left: auto;
  right: 0;
}

.dz-img-overlay8:hover:before,
.dz-img-overlay8:hover:after {
  width: 100%;
  opacity: 0.3;
}

.dz-img-overlay9:before:before,
.dz-img-overlay9:before:after,
.dz-img-overlay9:after:before,
.dz-img-overlay9:after:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.dz-img-overlay9:before:hover:before,
.dz-img-overlay9:before:hover:after,
.dz-img-overlay9:after:hover:before,
.dz-img-overlay9:after:hover:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

/*  */
.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark,
.overlay-primary {
  position: relative;
}
.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before,
.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before,
.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before,
.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;

  height: 100%;
}
.overlay-black-light .container,
.overlay-black-light .container-fluid,
.overlay-black-light .container-sm,
.overlay-black-light .container-md,
.overlay-black-light .container-lg,
.overlay-black-light .container-xl,
.overlay-black-middle .container,
.overlay-black-middle .container-fluid,
.overlay-black-middle .container-sm,
.overlay-black-middle .container-md,
.overlay-black-middle .container-lg,
.overlay-black-middle .container-xl,
.overlay-black-dark .container,
.overlay-black-dark .container-fluid,
.overlay-black-dark .container-sm,
.overlay-black-dark .container-md,
.overlay-black-dark .container-lg,
.overlay-black-dark .container-xl,
.overlay-gradient-light .container,
.overlay-gradient-light .container-fluid,
.overlay-gradient-light .container-sm,
.overlay-gradient-light .container-md,
.overlay-gradient-light .container-lg,
.overlay-gradient-light .container-xl,
.overlay-gradient-middle .container,
.overlay-gradient-middle .container-fluid,
.overlay-gradient-middle .container-sm,
.overlay-gradient-middle .container-md,
.overlay-gradient-middle .container-lg,
.overlay-gradient-middle .container-xl,
.overlay-gradient-dark .container,
.overlay-gradient-dark .container-fluid,
.overlay-gradient-dark .container-sm,
.overlay-gradient-dark .container-md,
.overlay-gradient-dark .container-lg,
.overlay-gradient-dark .container-xl,
.overlay-white-light .container,
.overlay-white-light .container-fluid,
.overlay-white-light .container-sm,
.overlay-white-light .container-md,
.overlay-white-light .container-lg,
.overlay-white-light .container-xl,
.overlay-white-middle .container,
.overlay-white-middle .container-fluid,
.overlay-white-middle .container-sm,
.overlay-white-middle .container-md,
.overlay-white-middle .container-lg,
.overlay-white-middle .container-xl,
.overlay-white-dark .container,
.overlay-white-dark .container-fluid,
.overlay-white-dark .container-sm,
.overlay-white-dark .container-md,
.overlay-white-dark .container-lg,
.overlay-white-dark .container-xl,
.overlay-primary-light .container,
.overlay-primary-light .container-fluid,
.overlay-primary-light .container-sm,
.overlay-primary-light .container-md,
.overlay-primary-light .container-lg,
.overlay-primary-light .container-xl,
.overlay-primary-middle .container,
.overlay-primary-middle .container-fluid,
.overlay-primary-middle .container-sm,
.overlay-primary-middle .container-md,
.overlay-primary-middle .container-lg,
.overlay-primary-middle .container-xl,
.overlay-primary-dark .container,
.overlay-primary-dark .container-fluid,
.overlay-primary-dark .container-sm,
.overlay-primary-dark .container-md,
.overlay-primary-dark .container-lg,
.overlay-primary-dark .container-xl,
.overlay-primary .container,
.overlay-primary .container-fluid,
.overlay-primary .container-sm,
.overlay-primary .container-md,
.overlay-primary .container-lg,
.overlay-primary .container-xl {
  position: relative;
  z-index: 1;
}

.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before {
  background: #050d36;
}

.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.65) 100%
  );
}

.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before {
  background: #fff;
}

/* banner color */
.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  background: rgba(0, 0, 0, 0);
  /* background:var(--primary) */
}

.overlay-primary-light:before,
.overlay-white-light:before,
.overlay-gradient-light:before,
.overlay-black-light:before {
  opacity: 0.3;
}

.overlay-primary-middle:before,
.overlay-white-middle:before,
.overlay-gradient-middle:before,
.overlay-black-middle:before {
  opacity: 0.7;
}

.overlay-primary-dark:before,
.overlay-white-dark:before,
.overlay-gradient-dark:before,
.overlay-black-dark:before {
  opacity: 0.9;
}

.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.overlay-bx .align-b {
  height: auto;
  left: 0;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  transform: translate(0);
  -o-transform: translate(0);
  -moz-transform: translate(0);
  -webkit-transform: translate(0);
}
.overlay-bx .align-m {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}
.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
}
.overlay-icon a i {
  background-color: #fff;
}

.overlay-bx:hover a > i,
.dz-media:hover .overlay-bx a > i,
.dz-box-bx:hover .overlay-bx a > i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-bx:hover,
.dz-media:hover .overlay-bx,
.dz-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

.gallery-box-1 .overlay-bx .overlay-icon span i,
.gallery-box-1 .overlay-bx .overlay-icon a i {
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
}

.gallery-box-1 .overlay-bx .icon-bx-xs {
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.img-overlay-gradients {
  position: relative;
}
.img-overlay-gradients:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.img-overlay-gradients.gradients-secondary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, #ff7468 100%);
  background: -webkit-linear-gradient(bottom, transparent 0%, #ff7468 100%);
  background: linear-gradient(to bottom, transparent 0%, #ff7468 100%);
}
.img-overlay-gradients.gradients-primary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, var(--primary) 100%);
  background: -webkit-linear-gradient(
    bottom,
    transparent 0%,
    var(--primary) 100%
  );
  background: linear-gradient(to bottom, transparent 0%, var(--primary) 100%);
}

.overlay-shine .dz-media {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-base);
}
.overlay-shine .dz-media:before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
}
.overlay-shine .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-shine:hover .dz-media:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.overlay-skew .dz-media {
  position: relative;
}
.overlay-skew .dz-media:before,
.overlay-skew .dz-media:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  background: var(--primary);
  width: 0;
  left: -80px;
  opacity: 0.5;
}
.overlay-skew .dz-media:before {
  transform: skewX(20deg);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.overlay-skew .dz-media:after {
  transform: skewX(-20deg);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-skew:hover .dz-media:before,
.overlay-skew:hover .dz-media:after {
  width: 100%;
}

.background-luminosity {
  background-blend-mode: luminosity;
}

.pricingtable-features {
  margin: 0;
  padding: 0;
  list-style: none;
}
.pricingtable-features li {
  padding: 12px;
}
.pricingtable-features li i {
  margin: 0 3px;
}

.no-col-gap .pricingtable-wrapper {
  margin-left: -1px;
}

.table-option {
  border: 2px solid #adadad;
  border-radius: 6px;
  padding: 50px 0px 30px 0;
}
.table-option .pricingtable-footer {
  border: 0;
  background-color: transparent;
}
.table-option .pricingtable-features {
  border: 0;
  background-color: transparent;
}
.table-option .pricingtable-features li {
  border: 0;
  background-color: transparent;
  color: #919191;
  font-size: 18px;
}
.table-option .pricingtable-title {
  border: 0;
  background-color: transparent;
  padding: 0;
}
.table-option .pricingtable-title * {
  color: #000;
}
.table-option .pricingtable-title h2,
.table-option .pricingtable-title .h2 {
  font-size: 28px;
  line-height: 36px;
}
.table-option .pricingtable-price {
  padding: 0;
  border: 0;
  background-color: transparent;
}
.table-option .pricingtable-bx {
  color: #000;
  font-size: 63px;
  font-weight: 800;
}
.table-option .pricingtable-bx strong,
.table-option .pricingtable-bx sup {
  font-size: 30px;
  font-weight: 700;
}
.table-option:hover,
.table-option.active {
  background-color: #eeeeee;
  border: 2px solid #eeeeee;
}
.table-option.dark {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.table-option.dark:hover,
.table-option.dark.active {
  background-color: #404040;
  border: 2px solid #404040;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.5s;
}
.table-option.dark:hover .pricingtable-features li,
.table-option.dark:hover .pricingtable-title h2,
.table-option.dark:hover .pricingtable-title .h2,
.table-option.dark:hover .pricingtable-price .pricingtable-bx,
.table-option.dark.active .pricingtable-features li,
.table-option.dark.active .pricingtable-title h2,
.table-option.dark.active .pricingtable-title .h2,
.table-option.dark.active .pricingtable-price .pricingtable-bx {
  color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.table-option.dark:hover .pricingtable-footer .btn.black,
.table-option.dark:hover .pricingtable-footer .black.wp-block-button__link,
.table-option.dark.active .pricingtable-footer .btn.black,
.table-option.dark.active .pricingtable-footer .black.wp-block-button__link {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
  color: #000;
}

.pricingtable-toggle {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 0;
  margin-bottom: 10px;
}
.pricingtable-toggle span {
  font-size: 18px;
  margin-top: 5px;
  font-weight: 500;
}
.pricingtable-toggle .custom-control-label {
  margin: 0 35px 0 50px;
}

.pricingtable-wrapper {
  box-shadow: 1px 0 50px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-base) !important;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #fff;
}
.pricingtable-wrapper.style-1 {
  padding: 35px 40px;
  position: relative;
  z-index: 1;
  height: 100%;
}
.pricingtable-wrapper.style-1 .pricingtable-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pricingtable-wrapper.style-1 .pricingtable-footer {
  margin-top: auto;
}
.pricingtable-wrapper.style-1 .pricingtable-price {
  margin-bottom: 10px;
}
.pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx {
  font-size: 42px;
}
.pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx small,
.pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx .small {
  font-size: 15px;
}
.pricingtable-wrapper.style-1 .pricingtable-price p {
  margin: 5px 0;
  font-size: 14px;
}
.pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-type {
  color: #071c35;
  font-weight: 700;
  font-size: 26px;
  font-family: var(--font-family-title);
}
.pricingtable-wrapper.style-1 .pricingtable-features {
  border-top: 1px solid;
  border-color: #d5d5d9;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: left;
}
.pricingtable-wrapper.style-1 .pricingtable-features li {
  font-size: 15px;
  padding: 5px 0 5px 40px;
  position: relative;
  margin: 10px 0;
}
.pricingtable-wrapper.style-1 .pricingtable-features li:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 600;
  width: 26px;
  height: 26px;
  background: var(--rgba-secondary-1);
  display: block;
  position: absolute;
  left: 0;
  color: var(--secondary);
  border-radius: 35px;
  text-align: center;
  top: 6px;
  line-height: 26px;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}
@media only screen and (max-width: 575px) {
  .pricingtable-wrapper.style-1 .pricingtable-features li {
    margin: 5px 0;
  }
}
@media only screen and (max-width: 575px) {
  .pricingtable-wrapper.style-1 .pricingtable-features {
    padding: 15px 0;
  }
}
.pricingtable-wrapper.style-1 .pricingtable-title {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #fff;
  padding: 2px 15px;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: var(--font-family-title);
}
@media only screen and (max-width: 1200px) {
  .pricingtable-wrapper.style-1 {
    padding: 30px 35px;
  }
}
@media only screen and (max-width: 575px) {
  .pricingtable-wrapper.style-1 {
    padding: 25px 30px;
  }
}
.pricingtable-wrapper.style-1.active {
  background: var(--primary);
}
.pricingtable-wrapper.style-1.active .btn-primary,
.pricingtable-wrapper.style-1.active .wp-block-button__link {
  background: white;
  color: var(--primary);
}
.pricingtable-wrapper.style-1.active .pricingtable-type,
.pricingtable-wrapper.style-1.active .pricingtable-price,
.pricingtable-wrapper.style-1.active .pricingtable-bx {
  color: #fff !important;
}
.pricingtable-wrapper.style-1.active .pricingtable-features {
  border-color: rgba(255, 255, 255, 0.3);
}
.pricingtable-wrapper.style-1.active .pricingtable-features li {
  color: #fff;
}
.pricingtable-wrapper.style-1.active .pricingtable-features li:after {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.dz-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  vertical-align: middle;
}
.dz-img-effect img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
.dz-img-effect.opacity img:hover {
  opacity: 0.8;
}
.dz-img-effect.zoom-slow img {
  transition: all 10s;
  -moz-transition: all 10s;
  -webkit-transition: all 10s;
  -o-transition: all 10s;
}
.dz-img-effect.zoom-slow:hover img {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dz-img-effect.zoom:hover img {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}
.dz-img-effect.shrink:hover img {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}
.dz-img-effect.side-pan:hover img {
  margin-left: -11%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dz-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dz-img-effect.fade-in {
  background: #000;
}
.dz-img-effect.fade-in img {
  opacity: 0.65;
}
.dz-img-effect.fade-in:hover img {
  opacity: 1;
}
.dz-img-effect.fade-out {
  background: #000;
}
.dz-img-effect.fade-out:hover img {
  opacity: 0.7;
}
.dz-img-effect.rotate:hover img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}
.dz-img-effect.image-sepia img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}
.dz-img-effect.blurr img {
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
.dz-img-effect.blurr:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}
.dz-img-effect.blurr-invert img {
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}
.dz-img-effect.blurr-invert:hover img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
}
.dz-img-effect.off-color img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}
.dz-img-effect.off-color:hover img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}
.dz-img-effect.on-color img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}
.dz-img-effect.on-color:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.dz-box:hover .dz-img-effect.rotate img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

.dz-accordion .accordion-item {
  border-radius: var(--border-radius-base);
  overflow: hidden;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
}
@media only screen and (max-width: 575px) {
  .dz-accordion .accordion-item {
    margin-bottom: 15px;
  }
}

.dz-accordion .accordion-body {
  padding: 25px 30px;
  border-radius: 0;
}

.dz-accordion .accordion-collapse {
  border: 0;
}

.dz-accordion .accordion-header .accordion-button {
  font-size: 18px;
  border: 0;
  border-radius: var(--border-radius-base);
  padding: 22px 65px 22px 30px;
  transition: all 0.5s;
  color: #fff;
  /* background-color: var(--primary); */
  background-color: var(--bs-warning);
  font-weight: 600;
}
.dz-accordion .accordion-header .accordion-button:after {
  content: none;
}
.dz-accordion .accordion-header .accordion-button .toggle-close {
  background-color: #fff;
  font-family: themify;
  height: 55px;
  width: 55px;
  display: block;
  position: absolute;
  right: 5px;
  top: 5px;
  border-radius: var(--border-radius-base);
  line-height: 57px;
  text-align: center;
  font-size: 24px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  overflow: hidden;
}
.dz-accordion .accordion-header .accordion-button .toggle-close:after {
  content: "";
  position: absolute;
  z-index: -1;
  /* background-color: var(--primary); */
  background-color: var(--bs-warning);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
}
.dz-accordion .accordion-header .accordion-button .toggle-close:before {
  content: "\f078";
  /* color: var(--primary); */
  color: var(--bs-warning);
  font-size: 18px;
  font-weight: 800;
  font-family: "Font Awesome 5 Free";
  z-index: 99;
  opacity: 1;
  transform: rotate(0);
  transition: all 0.5s;
  line-height: 55px;
  display: block;
}
.dz-accordion .accordion-header .accordion-button.collapsed {
  background: #fff;
  color: inherit;
}
.dz-accordion .accordion-header .accordion-button.collapsed:after {
  opacity: 0;
}
.dz-accordion .accordion-header .accordion-button.collapsed .toggle-close {
  content: "";
}
.dz-accordion
  .accordion-header
  .accordion-button.collapsed
  .toggle-close:after {
  opacity: 1;
}
.dz-accordion
  .accordion-header
  .accordion-button.collapsed
  .toggle-close:before {
  opacity: 1;
  transform: rotate(-90deg);
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .dz-accordion .accordion-header .accordion-button {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-accordion .accordion-header .accordion-button {
    font-size: 14px;
    padding: 12px 55px 12px 15px;
  }
}

.dz-accordion.accordion-sm .accordion-body {
  padding: 25px;
}

.dz-accordion.accordion-sm .accordion-header .accordion-button {
  padding: 15px 55px 15px 20px;
}
.dz-accordion.accordion-sm .accordion-header .accordion-button .toggle-close {
  height: 40px;
  width: 40px;
}
.dz-accordion.accordion-sm
  .accordion-header
  .accordion-button
  .toggle-close:before {
  font-size: 14px;
  line-height: 40px;
}

@media only screen and (max-width: 575px) {
  .dz-accordion.accordion-sm .accordion-header .accordion-button {
    padding: 12px 55px 12px 15px;
  }
  .dz-accordion.accordion-sm .accordion-body {
    padding: 20px;
  }
}

.pagination-bx {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-bx .page-item {
  margin: 0;
}
.pagination-bx .page-item .page-link {
  border: none;
  border-radius: var(--border-radius-base);
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: transparent;
  color: var(--primary);
  font-weight: 700;
  text-align: center;
  padding: 0;
}
.pagination-bx .page-item .page-link:hover {
  background-color: var(--primary);
  color: #fff;
  box-shadow: none;
}
.pagination-bx .page-item .page-link.active {
  background-color: var(--primary);
  box-shadow: none;
  color: #fff;
}
.pagination-bx .page-item .page-link.prev,
.pagination-bx .page-item .page-link.next {
  background-color: var(--rgba-primary-1);
}
.pagination-bx .page-item .page-link.prev:hover,
.pagination-bx .page-item .page-link.next:hover {
  background-color: var(--primary);
  color: #fff;
  box-shadow: none;
}
@media only screen and (max-width: 575px) {
  .pagination-bx .page-item .page-link {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}
.pagination-bx .pagination {
  margin: 0 10px;
  background-color: var(--rgba-primary-1);
  border-radius: var(--border-radius-base);
}

.list-circle li,
.list-angle-double li,
.list-square li,
.list-square-check li {
  position: relative;
  padding: 6px 5px 6px 15px;
}
.list-circle li:before,
.list-angle-double li:before,
.list-square li:before,
.list-square-check li:before {
  position: absolute;
  left: 0;
  top: 4px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.list-circle.primary li:before,
.list-angle-double.primary li:before,
.list-square.primary li:before,
.list-square-check.primary li:before {
  color: var(--primary);
}

.list-angle-double li {
  padding-left: 25px;
}

ul.list-circle li:before {
  content: "\f111";
  font-size: 8px;
  font-weight: 900;
  top: 50%;
  transform: translateY(-50%);
}

ul.list-angle-double li:before {
  content: "\f101";
  font-size: 18px;
}

ul.list-square li:before {
  content: "\f45c";
  top: 50%;
  transform: translateY(-50%);
}

ul.grid-2 {
  display: flex;
  flex-wrap: wrap;
}
ul.grid-2 li {
  width: 50%;
}
@media only screen and (max-width: 575px) {
  ul.grid-2 li {
    width: 100%;
  }
}

.list-square-check li {
  padding: 10px 15px 10px 60px;
  min-height: 45px;
  font-weight: 600;
  color: var(--title);
  margin-bottom: 15px;
}
.list-square-check li:before {
  content: "\f00c";
  top: 50%;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background-color: var(--rgba-primary-1);
  color: #000;
  text-align: center;
  padding: 0 0 0 0;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
  transform: translateY(-50%);
}
.list-square-check li:hover:before {
  background-color: var(--primary);
  color: #fff;
}

.list-check-1 li {
  padding: 12px 0px 12px 40px;
  color: #071c35;
  font-weight: 500;
  position: relative;
}
.list-check-1 li:before {
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 35px;
  transform: translateY(-50%) scale(1);
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 700;
  height: 28px;
  width: 28px;
  line-height: 28px;
  background-color: var(--primary);
  color: #fff;
  text-align: center;
  font-size: 16px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 575px) {
  .list-check-1 li:before {
    height: 25px;
    width: 25px;
    line-height: 25px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 991px) {
  .list-check-1 li {
    padding: 10px 0px 10px 38px;
  }
}

.list-check-1.top li:before {
  top: 40px;
}

.list-check-1.top li p {
  font-family: var(--font-family-title);
  color: #777;
  margin-bottom: 0;
}

.list-check-1.light li:before {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

.list-check-1.light li:hover:before {
  background-color: var(--primary);
  color: #fff;
}

.list-check-2 li {
  padding: 0 0 0 38px;
  position: relative;
  margin-bottom: 10px;
}
.list-check-2 li:before {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 35px;
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: #416bdb;
  font-size: 18px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.list-check-3 li {
  padding: 10px 10px 10px 35px;
  position: relative;
  color: black;
  font-family: "Poppins";
  font-style: italic;
  font-size: 20px;
  line-height: 1.2;
}
.list-check-3 li:before {
  position: absolute;
  left: 0;
  top: 50%;
  content: "\f12f";
  font-family: "flaticon";
  font-weight: 900;
  color: var(--bs-warning);
  font-size: 18px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: translateY(-50%);
}
@media only screen and (max-width: 1191px) {
  .list-check-3 li {
    padding: 8px 8px 8px 25px;
    font-size: 16px;
  }
  .list-check-3 li:before {
    font-size: 15px;
  }
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: var(--primary);
}

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}

.lightimg {
  cursor: pointer;
}

.lg-progress-bar .lg-progress {
  background-color: var(--primary);
}

.progress-bx {
  overflow: hidden;
}
.progress-bx .progress-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.progress-bx .progress-info .title {
  margin-bottom: 0;
}
.progress-bx .progress-info .progress-value {
  margin-bottom: 0;
}
.progress-bx .progress {
  overflow: unset;
}
.progress-bx .progress .progress-bar {
  position: relative;
  overflow: unset;
}
.progress-bx.style-1 .progress {
  height: 12px;
  background-color: #e9ecef;
  border: 1px solid #d7d7d7;
  border-radius: 12px;
  overflow: hidden;
}
.progress-bx.style-1 .progress .progress-bar {
  background-color: transparent;
}
.progress-bx.style-1 .progress .progress-bar.progress-bar-primary {
  background-color: var(--primary);
}
.progress-bx.style-1 .progress .progress-bar.progress-bar-secondary {
  background-color: var(--secondary);
}
.progress-bx.style-1 .progress-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}
.progress-bx.style-1 .progress-tag li {
  font-size: 14px;
  font-weight: 500;
}
.progress-bx.style-1 .progress-tag li i {
  transform: scale(1.5);
  display: inline-block;
  margin-right: 5px;
  color: var(--primary);
}
.progress-bx.style-1 .progress-tag li p {
  margin-bottom: 0;
}
.progress-bx.style-2 {
  overflow: unset;
  padding-top: 40px;
}
.progress-bx.style-2 .progress {
  height: 7px;
  border-radius: 20px;
  border: 0;
}
.progress-bx.style-2 .progress .progress-bar {
  background-color: var(--primary);
}
.progress-bx.style-2 .progress .progress-bar .progress-value {
  position: absolute;
  right: -20px;
  top: -35px;
  font-size: 13px;
  background: #f7cf47;
  color: var(--title);
  min-width: 40px;
  min-height: 25px;
  line-height: 25px;
  z-index: 1;
  font-weight: 600;
}
.progress-bx.style-2 .progress .progress-bar .progress-value:after {
  content: "";
  width: 10px;
  height: 10px;
  background: inherit;
  position: absolute;
  bottom: -30%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: -1;
}
.progress-bx.style-2 .progress-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progress-bx.style-2 .progress-tag li {
  font-size: 13px;
  font-weight: 500;
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

.scroltop {
  box-shadow: -4px 4px 24px -10px var(--primary) !important;
  background: var(--primary);
  border-color: var(--primary);
  border-style: solid;
  border-width: 0;
  bottom: 15px;
  color: #fff;
  cursor: pointer;
  display: none;
  height: 50px;
  line-height: 50px;
  margin: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  border-radius: var(--border-radius-base);
  width: 50px;
  z-index: 999;
  box-shadow: -4px 4px 24px -10px;
  transition: all 0.8s;
  -moz-transition: all 0.8s;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  -o-transition: all 0.8s;
}
@media only screen and (max-width: 575px) {
  .scroltop {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}

.video-bx1 {
  position: relative;
  min-width: 170px;
  width: 170px;
  height: 170px;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
.video-bx1 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.video-bx1 .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.video-bx1 .video-btn .popup-youtube {
  display: inline-block;
  height: 60px;
  width: 60px;
  min-width: 60px;
  line-height: 60px;
  font-size: 20px;
  border-radius: 60px;
  background-color: #fff;
  color: var(--primary);
}
.video-bx1 .video-btn .popup-youtube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
.video-bx1 .video-btn .popup-youtube:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
@media only screen and (max-width: 575px) {
  .video-bx1 {
    height: 120px;
    width: 120px;
    min-width: 120px;
  }
  .video-bx1 .video-btn .popup-youtube {
    height: 40px;
    width: 40px;
    min-width: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}

.video-bx2 {
  height: 500px;
}
.video-bx2.large {
  height: 615px;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
.video-bx2.large img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-bx2 .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.video-bx2 .video-btn.sm .popup-youtube {
  height: 72px;
  width: 72px;
  min-width: 72px;
  line-height: 72px;
  font-size: 22px;
}
.video-bx2 .video-btn .popup-youtube {
  display: inline-block;
  height: 100px;
  width: 100px;
  min-width: 100px;
  line-height: 100px;
  font-size: 30px;
  border-radius: 100px;
  background-color: var(--primary);
  color: #fff;
}
.video-bx2 .video-btn .popup-youtube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid var(--primary);
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
.video-bx2 .video-btn .popup-youtube:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid var(--primary);
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
@media only screen and (max-width: 991px) {
  .video-bx2 {
    height: 330px;
  }
  .video-bx2.large {
    height: 380px;
  }
}
@media only screen and (max-width: 575px) {
  .video-bx2 {
    height: 200px;
  }
  .video-bx2.large {
    height: 250px;
  }
  .video-bx2 .video-btn .popup-youtube,
  .video-bx2 .video-btn.sm .popup-youtube {
    height: 60px;
    width: 60px;
    min-width: 60px;
    line-height: 60px;
    font-size: 20px;
  }
}

.video-bx3 {
  display: inline-block;
}
.video-bx3 .video-btn .popup-youtube {
  display: inline-block;
  height: 60px;
  width: 60px;
  line-height: 52px;
  text-align: center;
  font-size: 18px;
  border-radius: 60px;
  min-width: 60px;
  border: 4px solid #ececec;
  color: var(--secondary);
}
.video-bx3 .video-btn .popup-youtube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid var(--secondary);
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
.video-bx3 .video-btn .popup-youtube:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid var(--secondary);
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}

.video-bx4 .vedio-btn {
  bottom: 0;
  left: 0;
  width: 70px;
  height: 70px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: var(--primary);
}
.video-bx4 .vedio-btn i {
  color: #fff;
}

@keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    opacity: 0.8;
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0;
  }
}

@-webkit-keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0;
  }
}

.video-wrapper {
  display: flex;
  align-items: center;
}

.btn-next,
.btn-prev {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.btn-next:after,
.btn-prev:after {
  content: none;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.btn-next.style-1,
.btn-prev.style-1 {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  width: 60px;
  height: 60px;
  color: var(--primary);
  background: #fff;
  font-size: 15px;
  letter-spacing: 2px;
  z-index: 1;
  text-align: center;
  line-height: 60px;
  border-radius: 60px;
  margin: 0 10px;
  display: inline-block;
}
@media only screen and (max-width: 991px) {
  .btn-next.style-1,
  .btn-prev.style-1 {
    width: 45px;
    height: 45px;
    line-height: 45px;
  }
}
.btn-next.style-1:hover,
.btn-prev.style-1:hover {
  background: var(--primary);
  color: #fff;
  box-shadow: 0px 12px 20px 0px var(--rgba-primary-3);
}
.btn-next.style-1 i,
.btn-prev.style-1 i {
  font-size: 20px;
  line-height: inherit;
}
@media only screen and (max-width: 767px) {
  .btn-next.style-1 i,
  .btn-prev.style-1 i {
    font-size: 18px;
  }
}
.btn-next.style-2,
.btn-prev.style-2 {
  font-size: 45px;
  color: #c5c5c5;
}
.btn-next.style-2:hover,
.btn-prev.style-2:hover {
  color: var(--primary);
}
@media only screen and (max-width: 991px) {
  .btn-next.style-2,
  .btn-prev.style-2 {
    background-color: #fff;
    font-size: 20px;
    padding: 5px 5px;
  }
  .btn-next.style-2:hover,
  .btn-prev.style-2:hover {
    background-color: var(--primary);
    color: #fff;
  }
}

.swiper-pagination.style-1 {
  position: relative;
  bottom: 0;
}
.swiper-pagination.style-1 .swiper-pagination-bullet {
  height: 6px;
  width: 35px;
  border-radius: var(--border-radius-base);
  margin: 0 5px;
  background-color: #b7b2b2;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.swiper-pagination.style-1
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--secondary);
  width: 75px;
}
@media only screen and (max-width: 575px) {
  .swiper-pagination.style-1 .swiper-pagination-bullet {
    width: 20px;
  }
  .swiper-pagination.style-1
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 45px;
  }
}

.progress-pagination {
  color: #fff;
  display: flex;
  align-items: center;
}
.progress-pagination .swiper-pagination-progressbar {
  height: 3px;
  width: 100px;
  overflow: hidden;
  position: unset;
  margin: 0 10px;
}
.progress-pagination
  .swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  background-color: #fff;
}

.swiper-btn-center-lr .btn-prev,
.swiper-btn-center-lr .btn-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.swiper-btn-center-lr .btn-prev {
  left: -80px;
}

.swiper-btn-center-lr .btn-next {
  right: -80px;
}

@media only screen and (max-width: 1480px) {
  .swiper-btn-center-lr .btn-prev {
    left: -30px;
  }
  .swiper-btn-center-lr .btn-next {
    right: -30px;
  }
}

@media only screen and (max-width: 1280px) {
  .swiper-btn-center-lr .btn-prev {
    left: 0;
  }
  .swiper-btn-center-lr .btn-next {
    right: 0;
  }
}

.widget {
  margin-bottom: 50px;
}
@media only screen and (max-width: 991px) {
  .widget {
    margin-bottom: 30px;
  }
}
.widget.style-1 {
  background: var(--gray);
  border-radius: var(--border-radius-base);
  padding: 30px;
}
@media only screen and (max-width: 575px) {
  .widget.style-1 {
    padding: 25px;
  }
}
.widget.style-1 .widget-title .title {
  margin-bottom: 0;
}
.widget.style-1 .widget-title:after,
.widget.style-1 .widget-title:before {
  content: "";
  height: 2px;
  border-radius: 10px;
  background-size: 200%;
  position: absolute;
  bottom: 0;
}
.widget.style-1 .widget-title:before {
  width: 25px;
  left: 0;
  background-color: var(--bs-warning);
  top: auto;
}
.widget.style-1 .widget-title:after {
  background-color: var(--bs-warning);
  width: 55px;
  left: 32px;
}

.widget-title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
}
.widget-title .title {
  margin-bottom: 0;
  color: var(--title);
}
@media only screen and (max-width: 767px) {
  .widget-title .title {
    font-size: 20px;
  }
}
.widget-title .title i {
  color: var(--primary);
}
.widget-title.style-1 .title {
  margin-bottom: 0;
}
.widget-title.style-1:after,
.widget-title.style-1:before {
  content: "";
  height: 2px;
  border-radius: 10px;
  background-size: 200%;
  position: absolute;
  bottom: 0;
}
.widget-title.style-1:before {
  width: 25px;
  left: 0;
  background-color: var(--primary);
  top: auto;
}
.widget-title.style-1:after {
  background-color: var(--primary);
  width: 55px;
  left: 32px;
}

.search-bx.style1 form {
  margin-bottom: 10px;
}

.search-bx.style1 .form-control,
.search-bx.style1 .default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .search-bx.style1 .btn.dropdown-toggle,
.search-bx.style1
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link,
.default-select.style-2
  .search-bx.style1
  .dropdown-toggle.wp-block-button__link,
.search-bx.style1 .default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .search-bx.style1 .btn.dropdown-toggle,
.search-bx.style1
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link,
.default-select.style-3
  .search-bx.style1
  .dropdown-toggle.wp-block-button__link,
.search-bx.style1 .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .search-bx.style1 select,
.search-bx.style1 .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .search-bx.style1 select,
.search-bx.style1 .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .search-bx.style1 input,
.search-bx.style1 .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .search-bx.style1 input,
.search-bx.style1 .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .search-bx.style1 input,
.search-bx.style1 .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .search-bx.style1 textarea {
  border-radius: var(--border-radius-base) !important;
  background-color: #fff;
  box-shadow: 1px 1.732px 60px 0px rgba(0, 0, 0, 0.15);
  border: 0;
  z-index: 0 !important;
  padding: 6px 62px 6px 30px;
}

.search-bx.style1 .input-group-btn {
  position: absolute;
  top: 0;
  right: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: var(--border-radius-base);
}

.search-bx.style1 button {
  height: 100%;
  width: 60px;
  font-size: 24px;
  padding: 0;
  justify-content: center;
  border-radius: 0;
}

.search-bx.style-2 .input-group {
  position: relative;
}
.search-bx.style-2 .input-group .form-control,
.search-bx.style-2 .input-group .default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .search-bx.style-2 .input-group .btn.dropdown-toggle,
.search-bx.style-2
  .input-group
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link,
.default-select.style-2
  .search-bx.style-2
  .input-group
  .dropdown-toggle.wp-block-button__link,
.search-bx.style-2 .input-group .default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .search-bx.style-2 .input-group .btn.dropdown-toggle,
.search-bx.style-2
  .input-group
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link,
.default-select.style-3
  .search-bx.style-2
  .input-group
  .dropdown-toggle.wp-block-button__link,
.search-bx.style-2 .input-group .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .search-bx.style-2 .input-group select,
.search-bx.style-2 .input-group .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .search-bx.style-2 .input-group select,
.search-bx.style-2
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input,
.comment-respond
  .comment-form
  p.comment-form-author
  .search-bx.style-2
  .input-group
  input,
.search-bx.style-2
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input,
.comment-respond
  .comment-form
  p.comment-form-email
  .search-bx.style-2
  .input-group
  input,
.search-bx.style-2
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input,
.comment-respond
  .comment-form
  p.comment-form-url
  .search-bx.style-2
  .input-group
  input,
.search-bx.style-2 .input-group .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .search-bx.style-2 .input-group textarea {
  border-radius: var(--border-radius-base) !important;
}
.search-bx.style-2 .input-group .form-control:focus,
.search-bx.style-2
  .input-group
  .default-select.style-2
  .btn.dropdown-toggle:focus,
.default-select.style-2
  .search-bx.style-2
  .input-group
  .btn.dropdown-toggle:focus,
.search-bx.style-2
  .input-group
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-2
  .search-bx.style-2
  .input-group
  .dropdown-toggle.wp-block-button__link:focus,
.search-bx.style-2
  .input-group
  .default-select.style-3
  .btn.dropdown-toggle:focus,
.default-select.style-3
  .search-bx.style-2
  .input-group
  .btn.dropdown-toggle:focus,
.search-bx.style-2
  .input-group
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3
  .search-bx.style-2
  .input-group
  .dropdown-toggle.wp-block-button__link:focus,
.search-bx.style-2 .input-group .wp-block-categories-dropdown select:focus,
.wp-block-categories-dropdown .search-bx.style-2 .input-group select:focus,
.search-bx.style-2 .input-group .wp-block-archives-dropdown select:focus,
.wp-block-archives-dropdown .search-bx.style-2 .input-group select:focus,
.search-bx.style-2
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .search-bx.style-2
  .input-group
  input:focus,
.search-bx.style-2
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .search-bx.style-2
  .input-group
  input:focus,
.search-bx.style-2
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  .search-bx.style-2
  .input-group
  input:focus,
.search-bx.style-2 .input-group .comment-respond .comment-form p textarea:focus,
.comment-respond
  .comment-form
  p
  .search-bx.style-2
  .input-group
  textarea:focus {
  z-index: 0;
}
.search-bx.style-2 .input-group .input-group-prepend {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translateY(50%);
}
.search-bx.style-2 .input-group .input-group-prepend .btn i,
.search-bx.style-2 .input-group .input-group-prepend .wp-block-button__link i {
  color: var(--primary);
}

.search-bx .input-group-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 999;
}
.search-bx .input-group-btn button {
  display: flex;
  width: 45px;
  height: 45px;
  padding: 0;
  justify-content: center;
}

.download-file {
  background-image: var(--gradient);
  color: #fff;
  padding: 25px 25px 20px 20px;
  border-radius: 4px;
}
.download-file .title {
  color: #fff;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.download-file ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.download-file ul li a {
  background-color: #fff;
  color: #000;
  display: block;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.download-file ul li a i {
  width: 18px;
  height: 18px;
  position: relative;
}
.download-file ul li a i:after,
.download-file ul li a i:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}
.download-file ul li a i:before {
  -webkit-animation: download1 2s ease infinite;
  animation: download1 2s ease infinite;
}
.download-file ul li a .text {
  display: inline;
  position: relative;
}
.download-file ul li a:hover .text {
  color: var(--primary);
}

@-webkit-keyframes download1 {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes download1 {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.widget_contact {
  position: relative;
  padding: 50px 30px;
  border-radius: 6px;
  background-size: cover;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.widget_contact:after {
  background: var(--title);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: 200%;
  opacity: 0.9;
}
.widget_contact img {
  margin-bottom: 0;
  position: absolute;
  bottom: -60px;
  right: -35px;
  width: 150px;
  z-index: -1;
  opacity: 0.1;
}
.widget_contact h4,
.widget_contact .h4 {
  color: #fff;
  margin-bottom: 15px;
}
.widget_contact p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 20px;
}
.widget_contact .phone-number {
  position: relative;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 5px;
}
.widget_contact .phone-number a {
  color: #fff;
}
.widget_contact .email {
  position: relative;
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  margin-bottom: 30px;
}
.widget_contact .email a {
  color: #fff;
}
.widget_contact .widget-content {
  z-index: 1;
  position: relative;
}

.service-carouse .item {
  overflow: hidden;
  border-radius: 6px;
}

.list-2 {
  display: table;
}
.list-2 li {
  width: 50% !important;
  float: left;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.wp-block-latest-posts li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu ul li,
.widget_recent_entries ul li,
.widget_services ul li {
  position: relative;
  padding: 10px 0;
  margin-bottom: 0;
  line-height: 1.25rem;
}
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu ul li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
  /* color: gray; */
  color: white;
  text-transform: capitalize;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  font-size: 15px;
}
.wp-block-latest-posts li:hover a,
.wp-block-categories-list li:hover a,
.wp-block-archives-list li:hover a,
.widget_categories ul li:hover a,
.widget_archive ul li:hover a,
.widget_meta ul li:hover a,
.widget_pages ul li:hover a,
.widget_recent_comments ul li:hover a,
.widget_nav_menu ul li:hover a,
.widget_recent_entries ul li:hover a,
.widget_services ul li:hover a {
  /* color: var(--secondary); */
  color: var(--bs-warning);
}
.wp-block-latest-posts li li,
.wp-block-categories-list li li,
.wp-block-archives-list li li,
.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu ul li li,
.widget_recent_entries ul li li,
.widget_services ul li li {
  border-bottom: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.375rem;
}
.wp-block-latest-posts li li:last-child,
.wp-block-categories-list li li:last-child,
.wp-block-archives-list li li:last-child,
.widget_categories ul li li:last-child,
.widget_archive ul li li:last-child,
.widget_meta ul li li:last-child,
.widget_pages ul li li:last-child,
.widget_recent_comments ul li li:last-child,
.widget_nav_menu ul li li:last-child,
.widget_recent_entries ul li li:last-child,
.widget_services ul li li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.wp-block-latest-posts li li:before,
.wp-block-categories-list li li:before,
.wp-block-archives-list li li:before,
.widget_categories ul li li:before,
.widget_archive ul li li:before,
.widget_meta ul li li:before,
.widget_pages ul li li:before,
.widget_recent_comments ul li li:before,
.widget_nav_menu ul li li:before,
.widget_recent_entries ul li li:before,
.widget_services ul li li:before {
  top: 0.5rem;
  left: 0;
}
.wp-block-latest-posts ul,
.wp-block-categories-list ul,
.wp-block-archives-list ul,
.widget_categories ul ul,
.widget_archive ul ul,
.widget_meta ul ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_nav_menu ul ul,
.widget_recent_entries ul ul,
.widget_services ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
  margin-bottom: -0.625rem;
}

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1.2rem !important;
  line-height: 1.3 !important;
}

.wp-block-categories-list li:before,
.wp-block-archives-list li:before,
.wp-block-latest-posts li:before {
  content: none !important;
}

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
  float: left;
  text-transform: capitalize;
  text-align: left;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
  margin-bottom: 2.5rem;
  padding: 0 !important;
}

.widget_recent_comments ul {
  margin-top: -0.625rem;
}
.widget_recent_comments ul li {
  padding-left: 1.875rem;
  line-height: 1.625rem;
}
.widget_recent_comments ul li a {
  font-weight: 500;
}
.widget_recent_comments ul li a:before {
  content: none;
}
.widget_recent_comments ul li:before {
  content: "\f27a";
  font-family: "Font Awesome 5 Free";
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 0.625rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none;
}

.widget_calendar th,
.widget_calendar td {
  text-align: center;
  border: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.4em;
}

.widget_calendar th,
.widget_calendar tfoot td {
  border-color: rgba(0, 0, 0, 0.05);
}
.widget_calendar th:last-child,
.widget_calendar tfoot td:last-child {
  border: 0;
}

.widget_calendar tr {
  border-bottom: 0.0625rem solid var(--rgba-primary-1);
}
.widget_calendar tr:nth-child(2n + 2) {
  background-color: var(--rgba-primary-05);
}

.widget_calendar table {
  border-collapse: collapse;
  margin: 0;
  width: 100%;
}

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
  color: #fff;
  background: #1f2471;
}

.widget_calendar .calendar_wrap td a {
  font-weight: 600;
  text-transform: uppercase;
  color: inherit;
}

.widget_calendar caption {
  text-align: center;
  font-weight: 600;
  caption-side: top;
  background: #1f2471;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9375rem;
}

footer .widget_calendar th,
footer .widget_calendar td {
  border-color: rgba(255, 255, 255, 0.1);
}

footer .widget_calendar tr {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}
footer .widget_calendar tr:nth-child(2n + 2) {
  background-color: rgba(255, 255, 255, 0.05);
}

.wp-calendar-nav {
  background: var(--title);
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.wp-calendar-nav span {
  padding: 0.3125rem 1.25rem;
  width: 44%;
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}
.wp-calendar-nav span a {
  color: #fff;
}

.widget_archive select {
  width: 100%;
  padding: 0.3125rem;
  border: 0.0625rem solid #ccc;
}

.widget_text select {
  width: 100%;
  border: 0.0625rem solid #ccc;
}
.widget_text select option {
  width: 100%;
}

.widget_text p {
  font-size: 0.9375rem;
  line-height: 1.75rem;
}

.rsswidget img {
  display: inherit;
}

.widget-title .rsswidget {
  display: inline-block;
}

ul a.rsswidget,
ul cite {
  font-weight: 600;
  color: var(--title);
}

ul a.rsswidget {
  font-size: 1.125rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
ul a.rsswidget:hover {
  color: var(--primary);
}

.rssSummary {
  margin: 1.25rem 0;
  line-height: 1.7;
  font-size: 15px;
}

.rss-date {
  display: block;
  margin-top: 0.3125rem;
  font-weight: 400;
}

.widget_rss ul li {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
  padding-top: 0;
}
.widget_rss ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.widget_gallery ul {
  padding-left: 0;
  display: table;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.widget_gallery.gallery-grid-2 li {
  width: 50%;
}

.widget_gallery.gallery-grid-4 li {
  width: 25%;
}

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding: 0.625rem;
}
.widget_gallery li img {
  display: inline-block;
  width: 100%;
}
.widget_gallery li:nth-child(3n-3) {
  margin-right: 0;
}
.widget_gallery li:hover {
  opacity: 0.7;
}
.widget_gallery li a {
  display: inline-block;
}

.widget_tag_cloud .tagcloud {
  clear: both;
  display: table;
}
.widget_tag_cloud .tagcloud a {
  position: relative;
  border: 1px solid #e1e1e1;
  border-radius: var(--border-radius-base);
  padding: 6px 12px;
  display: inline-block;
  margin: 0 12px 12px 0;
  font-size: 14px;
  color: inherit;
  line-height: 1.4;
}
.widget_tag_cloud .tagcloud a:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 12px 20px 0px var(--rgba-primary-3);
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .widget_tag_cloud .tagcloud a {
    padding: 6px 12px;
  }
}

.widget_fund p span {
  color: var(--title);
  font-weight: 600;
}

.widget_fund .detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}
.widget_fund .detail li h3,
.widget_fund .detail li .h3 {
  margin-bottom: 0;
}

.widget_fund .progress {
  margin-bottom: 20px;
}

.recent-posts-entry .widget-post-bx .widget-post {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  margin-bottom: 20px;
  transition: all 0.5s;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-media {
  width: 100px;
  min-width: 100px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 1199px) {
  .recent-posts-entry .widget-post-bx .widget-post .dz-media {
    width: 100px;
    min-width: 65px;
  }
}
.recent-posts-entry .widget-post-bx .widget-post .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-meta {
  margin-bottom: 0;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-info {
  padding-left: 15px;
}
.recent-posts-entry .widget-post-bx .widget-post .title {
  margin-bottom: 8px;
}
.recent-posts-entry .widget-post-bx .widget-post:last-child {
  margin-bottom: 0;
}
.recent-posts-entry .widget-post-bx .widget-post:hover .dz-media img {
  transform: scale3d(1.2, 1.2, 1.05) rotate(7deg);
}

.recent-posts-entry.style-1 .widget-post-bx .widget-post .dz-media {
  width: 80px;
  min-width: 80px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: var(--border-radius-base);
}
.recent-posts-entry.style-1 .widget-post-bx .widget-post .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.service_menu_nav ul li {
  padding: 0;
}
.service_menu_nav ul li a {
  background-color: #fff;
  display: block;
  border-radius: var(--border-radius-base);
  padding: 15px 25px;
  margin-bottom: 12px;
  color: #000;
  font-weight: 600;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
@media only screen and (max-width: 1280px) {
  .service_menu_nav ul li a {
    padding: 15px;
  }
}
.service_menu_nav ul li a:before {
  content: "\f30b";
  font-weight: 900;
  font-family: "Line Awesome Free";
  position: absolute;
  right: 50px;
  left: auto;
  top: 50%;
  font-size: 30px;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
@media only screen and (max-width: 1280px) {
  .service_menu_nav ul li a:before {
    font-size: 22px;
  }
}
.service_menu_nav ul li:hover a,
.service_menu_nav ul li.current-menu-item a,
.service_menu_nav ul li.active a {
  padding-left: 20px;
  border-color: transparent;
  transform: translateX(0);
  -moz-transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  background-color: var(--primary);
  color: #fff;
}
.service_menu_nav ul li:hover a:before,
.service_menu_nav ul li.current-menu-item a:before,
.service_menu_nav ul li.active a:before {
  opacity: 1;
  right: 10px;
  color: #fff;
}
@media only screen and (max-width: 1280px) {
  .service_menu_nav ul li:hover a:before,
  .service_menu_nav ul li.current-menu-item a:before,
  .service_menu_nav ul li.active a:before {
    font-size: 22px;
    right: 5px;
  }
}

.wp-block-categories-dropdown,
.wp-block-archives-dropdown {
  margin-bottom: 20px;
}
.wp-block-categories-dropdown select:after,
.wp-block-archives-dropdown select:after {
  content: "\f078";
  font-weight: 900;
  font-family: "Line Awesome Free";
  position: absolute;
  right: 50px;
  left: auto;
  top: 50%;
}

.post-tags strong {
  font-weight: 700;
  color: #212529;
  margin-right: 10px;
}

.post-tags a {
  margin-right: 10px;
  display: inline-block;
}

.widget_profile .dz-media {
  width: 100%;
  min-width: 100%;
}
.widget_profile .dz-media img {
  border-radius: var(--border-radius-base);
}

.widget_subscribe .input-group {
  overflow: hidden;
  border-radius: var(--border-radius-base);
}

.widget_subscribe .input-group-addon {
  position: absolute;
  right: 0;
  height: 100%;
  border-radius: 0;
}
.widget_subscribe .input-group-addon .btn,
.widget_subscribe .input-group-addon .wp-block-button__link {
  height: 100%;
  border-radius: 0;
  z-index: 5;
  padding: 16px 20px;
}

.widget_subscribe small,
.widget_subscribe .small {
  font-size: 13px;
  display: block;
  line-height: 20px;
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .widget_subscribe input {
    height: 50px;
  }
}

.widget_categories .cat-item:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 14px;
  position: absolute;
  left: 0;
  color: var(--primary);
}

.widget_categories .cat-item:hover {
  color: var(--primary);
}

.widget_checkbox_filter ul li {
  padding: 0.5rem 0rem !important;
}
.widget_checkbox_filter ul li i {
  color: #ffc105;
  margin-right: 5px;
  font-size: 14px;
}
.widget_checkbox_filter ul li .form-check {
  margin-bottom: 0;
}

.widget_checkbox_filter .form-check-label {
  color: var(--bs-title);
  font-weight: 500;
  font-size: 15px;
}

.widget_checkbox_filter .btn,
.widget_checkbox_filter .wp-block-button__link {
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}

.filter-bar {
  background: var(--gray);
  padding: 12px 12px 12px 20px;
  border-radius: var(--border-radius-base);
  margin-bottom: 30px;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.filter-bar .bar-title {
  color: grey;
  font-weight: 400;
}
.filter-bar .filter-btn {
  color: var(--title) !important;
  background-color: #e9e9e9;
  border-radius: var(--border-radius-base);
  font-size: 15px;
  outline: none !important;
  border: 1px solid #d9d9d9;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  margin-right: 5px;
}
.filter-bar .default-select.style-1.bootstrap-select {
  width: 180px !important;
}
@media only screen and (max-width: 575px) {
  .filter-bar {
    padding: 10px 15px;
  }
  .filter-bar .default-select.style-1.bootstrap-select {
    width: auto !important;
  }
  .filter-bar .default-select.style-1.bootstrap-select select {
    width: 45px !important;
  }
  .filter-bar
    .default-select.style-1.bootstrap-select
    .dropdown-toggle
    .filter-option {
    display: none;
  }
  .filter-bar .default-select.style-1.bootstrap-select .btn,
  .filter-bar .default-select.style-1.bootstrap-select .wp-block-button__link {
    padding: 7px 15px;
  }
  .filter-bar
    .default-select.style-1.bootstrap-select
    .btn.dropdown-toggle:after,
  .filter-bar
    .default-select.style-1.bootstrap-select
    .dropdown-toggle.wp-block-button__link:after {
    margin-left: 0;
  }
}

.widget_offers > ul li {
  margin-bottom: 15px;
}

.widget_offers .price-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: wrap;
}
.widget_offers .price-box .discount-price {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
}
@media only screen and (max-width: 575px) {
  .widget_offers .price-box .discount-price {
    font-size: 18px;
  }
}
.widget_offers .price-box .offer-price {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: grey;
  position: relative;
}
.widget_offers .price-box .discount-tag {
  padding: 0 10px;
  background: #0795611a;
  color: #079561;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
}

.widget_offers .icon-box {
  display: flex;
  align-items: center;
}
.widget_offers .icon-box .title {
  margin: 0;
  color: black;
  font-size: 15px;
  font-weight: 500;
}
.widget_offers .icon-box .title span {
  color: grey;
}
.widget_offers .icon-box .icon {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  line-height: 40px;
}
.widget_offers .icon-box .icon i {
  font-size: 19px;
  vertical-align: middle;
}

.widget_avatar .avatar-wrapper .avatar-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.widget_avatar .avatar-wrapper .avatar-item .avatar-media {
  height: 50px;
  width: 50px;
  border-radius: var(--border-radius-base);
  overflow: hidden;
  margin-right: 15px;
}
.widget_avatar .avatar-wrapper .avatar-item .donors-item {
  color: var(--primary);
  font-weight: 700;
  display: block;
  line-height: 1.2;
}
.widget_avatar .avatar-wrapper .avatar-item:last-child {
  margin-bottom: 0;
}

.widget_donate .btn-donate {
  justify-content: center;
  height: 60px;
  margin-bottom: 20px;
}

.widget_donate .tagcloud {
  clear: both;
  display: table;
}
.widget_donate .tagcloud a {
  position: relative;
  border: 1px solid #e1e1e1;
  border-radius: var(--border-radius-base);
  padding: 6px 12px;
  display: inline-block;
  margin: 0 12px 12px 0;
  font-size: 14px;
  color: inherit;
  line-height: 1.4;
}
.widget_donate .tagcloud a:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 12px 20px 0px var(--rgba-primary-3);
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .widget_donate .tagcloud a {
    padding: 6px 12px;
  }
}

.widget_donate .btn-bottom {
  justify-content: center;
  margin-top: 20px;
}

.widget_refer .widget-title {
  margin-bottom: 20px;
}

.widget_refer p {
  margin-bottom: 20px;
}

@media only screen and (max-width: 991px) {
  .reverse-sidebar {
    flex-direction: column-reverse;
  }
}

.btn-close {
  background: none;
  color: #fff;
  padding: 0;
  height: auto;
  width: auto;
}

.inquiry-modal {
  padding: 0 15px;
}
.inquiry-modal .modal-dialog {
  max-width: 700px;
  display: flex;
  min-height: auto;
  background-color: #fff;
  align-items: stretch;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
.inquiry-modal .inquiry-adv {
  flex: 0 0 50%;
  max-width: 50%;
}
.inquiry-modal .inquiry-adv img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.inquiry-modal .modal-content {
  border: 0;
  border-radius: 0;
  background: #fff;
  padding: 30px;
  flex: 0 0 50%;
  max-width: 50%;
}
@media only screen and (max-width: 767px) {
  .inquiry-modal .modal-content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px;
  }
}
.inquiry-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  display: block;
  text-align: center;
}
.inquiry-modal .modal-content .modal-header .modal-title {
  font-size: 24px;
  margin-bottom: 0;
  line-height: 1.3;
  font-family: var(--font-family-title);
  text-align: center;
  width: 100%;
}
.inquiry-modal .modal-content .modal-header i {
  color: var(--primary);
  font-size: 54px;
  line-height: 1;
  display: inline-block;
}
.inquiry-modal .modal-content .modal-body {
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .inquiry-modal .modal-content .modal-header .modal-title {
    font-size: 18px;
    padding: 5px 15px 15px;
  }
  .inquiry-modal .modal-content .modal-header i {
    font-size: 36px;
  }
}
.inquiry-modal .btn-close {
  margin: 0;
  position: absolute;
  right: 5px;
  top: 0px;
  color: #000;
  font-weight: 100;
  text-shadow: none;
  opacity: 1;
  font-size: 40px;
  padding: 0;
  height: 40px;
  line-height: 40px;
  width: 40px;
  border: 0;
  background: transparent;
}

.modal.modal-wrapper-lg .modal-dialog {
  max-width: 600px;
}

.modal-wrapper .modal-header {
  padding: 15px 30px;
  background-color: var(--primary);
  border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
}
.modal-wrapper .modal-header .modal-title {
  color: #fff;
}
@media only screen and (max-width: 575px) {
  .modal-wrapper .modal-header {
    padding: 15px 25px;
  }
}

.modal-wrapper .modal-body {
  padding: 30px;
}
@media only screen and (max-width: 575px) {
  .modal-wrapper .modal-body {
    padding: 25px;
  }
}

.modal-wrapper .modal-title {
  padding: 0;
}
@media only screen and (max-width: 575px) {
  .modal-wrapper .modal-title {
    font-size: 18px;
  }
}

.modal-wrapper .modal-content {
  border-radius: var(--border-radius-base);
  border: 0;
}

.modal-wrapper .modal-content + .modal-content {
  margin: 0;
}

.modal-wrapper .modal-dialog {
  max-width: 450px;
  position: relative;
  padding: 15px 15px;
}

.modal-wrapper .form-group {
  margin-bottom: 20px;
  text-align: left;
}

.auth-modal .modal-dialog {
  padding: 0;
  max-width: 380px;
}

.auth-modal .btn,
.auth-modal .wp-block-button__link {
  font-size: 14px;
}

.auth-modal .modal-content {
  padding: 30px;
}

.auth-modal .title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: -30px -30px 30px;
  background: var(--primary);
  line-height: 1.2;
  padding: 18px 20px;
  color: #fff;
  border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
}

.auth-modal .sign-text {
  background: #eee;
  margin: 30px -30px -30px -30px;
  padding: 10px 30px;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  color: #000;
  border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
}
.auth-modal .sign-text .btn-link {
  font-size: 13px;
}

.auth-modal .reset-password {
  text-align: right;
}

.auth-modal .reset-password .btn-link {
  font-size: 14px;
}

.auth-modal .reset-password.modal-content {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
  left: 0;
}
.auth-modal .reset-password.modal-content .sign-text {
  margin: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.auth-modal .reset-password.modal-content .sign-text {
  margin: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.auth-modal .password-icon-bx p {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.auth-modal .password-icon-bx i {
  width: 100px;
  height: 100px;
  margin: 0px auto 20px;
  display: block;
  text-align: center;
  line-height: 100px;
  font-size: 36px;
  background: #eee;
  border-radius: 100%;
}

.auth-modal .sign-up.modal-content {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 100%;
}
.auth-modal .sign-up.modal-content .sign-text {
  margin: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.auth-modal .modal-content + .modal-content {
  margin: 0;
}

.auth-modal .small-bx {
  background: #f6f7f7;
  display: block;
  padding: 8px 10px;
  margin: 0 -30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.modal-title {
  font-size: 20px;
  padding: 20px 30px;
  font-weight: 500;
}

.popup-profile-info {
  margin: 0 0 20px 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  text-align: left;
}

.popup-profile-info li {
  flex: 0 0 50%;
  width: 50%;
  padding: 5px 0;
  font-size: 15px;
}

.popup-profile-info li strong {
  margin-right: 10px;
  font-weight: 600;
  color: #000;
}

.popup-profile-info li span {
  color: #929292;
  font-weight: 400;
}

.dzmove1 {
  animation: dzMove1 1s linear infinite;
}

.dzheart {
  animation: dzHeart 1s linear infinite;
}

@keyframes dzMove1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }
  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes dzHeart {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

.nav-tabs.nav-tabs1 {
  border: 0;
  justify-content: space-between;
}
.nav-tabs.nav-tabs1 .nav-link {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 600;
  color: #777;
  border-radius: 0;
  line-height: 18px;
  padding: 20px 30px;
}
.nav-tabs.nav-tabs1 .nav-link.active {
  color: var(--title);
  border: 1px solid #dee2e6;
}
@media only screen and (max-width: 1280px) {
  .nav-tabs.nav-tabs1 .nav-link {
    font-size: 16px;
    line-height: 16px;
    padding: 18px 25px;
  }
}
@media only screen and (max-width: 575px) {
  .nav-tabs.nav-tabs1 .nav-link {
    font-size: 14px;
    line-height: 14px;
    padding: 15px;
  }
}

.checkbox-info .form-check-input:focus {
  border-color: #00aeff;
  outline: 0;
}

.checkbox-info .form-check-input:checked {
  background-color: #00aeff;
  border-color: #00aeff;
}

.checkbox-danger .form-check-input:focus {
  border-color: #ff0003;
  outline: 0;
}

.checkbox-danger .form-check-input:checked {
  background-color: #ff0003;
  border-color: #ff0003;
}

.checkbox-success .form-check-input:focus {
  border-color: #13c24d;
  outline: 0;
}

.checkbox-success .form-check-input:checked {
  background-color: #13c24d;
  border-color: #13c24d;
}

.checkbox-warning .form-check-input:focus {
  border-color: #fea500;
  outline: 0;
}

.checkbox-warning .form-check-input:checked {
  background-color: #fea500;
  border-color: #fea500;
}

.checkbox-secondary .form-check-input:focus {
  border-color: var(--primary);
  outline: 0;
}

.checkbox-secondary .form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.content-bx.style-1 {
  padding: 30px;
  height: 100%;
  border-radius: var(--border-radius-base);
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.content-bx.style-1:hover {
  transform: translateY(-10px);
}

.content-bx.style-2 .inner-content {
  padding-left: 70px;
}

@media only screen and (max-width: 1280px) {
  .content-bx.style-2 .inner-content {
    padding-left: 0;
  }
}

.counter-style-1 {
  z-index: 1;
  position: relative;
}
.counter-style-1 .counter-num {
  color: #fff;
  font-size: 60px;
  font-weight: 700;
  line-height: 1.4;
}
.counter-style-1 p {
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 0;
}
@media only screen and (max-width: 575px) {
  .counter-style-1 {
    font-size: 1.875rem;
  }
  .counter-style-1 p {
    font-size: 14px;
  }
  .counter-style-1 .counter-num {
    font-size: 2.5rem;
  }
}

.counter-wrapper-1 .counter-inner {
  margin: 0 -135px 0 -135px;
  padding: 90px 80px 60px;
  position: relative;
  overflow: hidden;
  border-radius: 14px;
}
.counter-wrapper-1 .counter-inner .pattren1,
.counter-wrapper-1 .counter-inner .pattren2,
.counter-wrapper-1 .counter-inner .pattren3,
.counter-wrapper-1 .counter-inner .pattren4,
.counter-wrapper-1 .counter-inner .pattren5,
.counter-wrapper-1 .counter-inner .pattren6 {
  position: absolute;
}
.counter-wrapper-1 .counter-inner .pattren1 {
  left: 25%;
  bottom: 10%;
}
.counter-wrapper-1 .counter-inner .pattren2 {
  bottom: 15%;
  right: 20%;
  width: 40px;
}
.counter-wrapper-1 .counter-inner .pattren3 {
  bottom: -10%;
  left: 10%;
}
.counter-wrapper-1 .counter-inner .pattren4 {
  top: 0;
  width: 16%;
  right: 8px;
}
.counter-wrapper-1 .counter-inner .pattren5 {
  top: 15%;
  left: 11%;
}
.counter-wrapper-1 .counter-inner .pattren6 {
  top: -5%;
  left: 0;
}
@media only screen and (max-width: 1480px) {
  .counter-wrapper-1 .counter-inner {
    margin: 0 -50px 0 -50px;
    padding: 60px 30px 30px;
  }
}
@media only screen and (max-width: 1280px) {
  .counter-wrapper-1 .counter-inner {
    margin: 0;
  }
  .counter-wrapper-1 .counter-inner .pattren6 {
    top: -5%;
    left: -40px;
  }
}
@media only screen and (max-width: 991px) {
  .counter-wrapper-1 .counter-inner {
    padding: 40px 30px 10px;
  }
  .counter-wrapper-1 .counter-inner .pattren6 {
    top: -5%;
    left: -70px;
  }
  .counter-wrapper-1 .counter-inner .pattren1 {
    left: 40%;
  }
  .counter-wrapper-1 .counter-inner .pattren2 {
    bottom: 15%;
    right: 30%;
    width: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .counter-wrapper-1 .counter-inner {
    padding: 30px 40px 10px;
  }
  .counter-wrapper-1 .counter-inner .pattren5 {
    top: 25%;
    left: 40%;
  }
}

.counter-style-2 {
  text-align: center;
}
.counter-style-2 .counter-num {
  font-size: 80px;
  line-height: 1.2;
  font-weight: 700;
  display: block;
}
@media only screen and (max-width: 991px) {
  .counter-style-2 .counter-num {
    font-size: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .counter-style-2 .counter-num {
    font-size: 42px;
  }
}

.counter-style-3 .counter-num {
  font-size: 65px;
  line-height: 1.2;
  font-weight: 700;
  display: block;
}
@media only screen and (max-width: 575px) {
  .counter-style-3 .counter-num {
    font-size: 45px;
  }
}

.counter-style-4 h4,
.counter-style-4 .h4 {
  margin-bottom: 0;
}

.counter-style-4 .counter-num {
  font-size: 35px;
  color: var(--primary);
  font-weight: 600;
}

.donate-wrapper .counter-style-2 {
  text-align: left !important;
}
.donate-wrapper .counter-style-2 .counter-num {
  font-size: 80px;
  line-height: 1.2;
  font-weight: 700;
  display: block;
  margin-top: 10px;
}

.move-1 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite;
}

@keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-10px, -10px);
    transform: rotate(-1deg) translate(-10px, -10px);
  }
  50% {
    -webkit-transform: rotate(1deg) translate(10px, 10px);
    transform: rotate(1deg) translate(10px, 10px);
  }
  100% {
    -webkit-transform: rotate(-1deg) translate(-10px, -10px);
    transform: rotate(-1deg) translate(-10px, -10px);
  }
}

.move-2 {
  -webkit-animation: move2 2s infinite;
  animation: move2 2s infinite;
}

@keyframes move2 {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
}

.move-3 {
  -webkit-animation: move3 5s infinite;
  animation: move3 5s infinite;
}

@keyframes move3 {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(5px, 0);
  }
  40% {
    transform: translate(5px, 5px);
  }
  65% {
    transform: translate(0, 5px);
  }
  65% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.move-4 {
  -webkit-animation: move4 5s infinite;
  animation: move4 5s infinite;
}

@keyframes move4 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }
  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.move-5 {
  -webkit-animation: move5 5s infinite;
  animation: move5 5s infinite;
}

@keyframes move5 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
  }
  50% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }
  75% {
    -webkit-transform: translate(-10px, 5px);
    transform: translate(-10px, 5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.move-6 {
  -webkit-animation: move6 5s infinite;
  animation: move6 5s infinite;
}

@keyframes move6 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(15px, 15px);
    transform: translate(15px, 15px);
  }
  50% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }
  75% {
    -webkit-transform: translate(15px, -8px);
    transform: translate(15px, -8px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.move-7 {
  -webkit-animation: move7 5s infinite;
  animation: move7 5s infinite;
}

@keyframes move7 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px);
  }
  50% {
    -webkit-transform: translate(-8px, -8px);
    transform: translate(-8px, -8px);
  }
  75% {
    -webkit-transform: translate(-15px, 8px);
    transform: translate(-15px, 8px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.shake:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.rotating {
  animation: rotation 10s infinite linear;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.main-bnr-one {
  position: relative;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  padding-bottom: 120px;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one {
    padding-bottom: 0;
  }
}
.main-bnr-one .banner-content {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
  z-index: 2;
  padding-left: 210px;
  padding-right: 30px;
  align-items: center;
}
.main-bnr-one .banner-content .sub-title {
  font-family: var(--font-sub-title);
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 5px;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-content .sub-title {
    font-size: 24px;
  }
}
@media only screen and (max-width: 1191px) {
  .main-bnr-one .banner-content .sub-title {
    font-size: 20px;
  }
}
.main-bnr-one .banner-content .title {
  line-height: 1.2;
  font-size: 60px;
  font-weight: 700;
  position: relative;
  margin-bottom: 20px;
  color: #fff;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-one .banner-content .title {
    font-size: 52px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-content .title {
    font-size: 42px;
  }
}
@media only screen and (max-width: 1191px) {
  .main-bnr-one .banner-content .title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-content .title {
    font-size: 32px;
    margin-bottom: 15px;
  }
}
.main-bnr-one .banner-content p {
  font-size: 16px;
  margin-bottom: 35px;
  color: #d7d7d7;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-content p {
    font-size: 15px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 1191px) {
  .main-bnr-one .banner-content p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .banner-content p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
.main-bnr-one .banner-content .btn,
.main-bnr-one .banner-content .wp-block-button__link {
  margin-right: 20px;
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-content .btn,
  .main-bnr-one .banner-content .wp-block-button__link {
    margin-right: 15px;
  }
}
@media only screen and (max-width: 1680px) {
  .main-bnr-one .banner-content {
    padding-left: 150px;
    padding-right: 0;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-content {
    padding-left: 0;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-content {
    padding-top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-one .banner-content {
    padding-bottom: 0;
    padding-left: 0;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .banner-content {
    font-size: 28px;
  }
}
.main-bnr-one .banner-media {
  z-index: 1;
  position: relative;
  display: flex;
  padding-top: 40px;
  padding-bottom: 30px;
}
.main-bnr-one .banner-media .main-img1 {
  margin-bottom: 1.5rem;
  transform: translate(-30px, -30px);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.main-bnr-one .banner-media .main-img1 img {
  border-radius: 100px 0 0 0;
  height: 365px;
  object-fit: cover;
  width: 100%;
}
.main-bnr-one .banner-media .main-img2 {
  padding-left: 50px;
  transform: translate(-30px, 30px);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.main-bnr-one .banner-media .main-img2 img {
  border-radius: 0 0 0 100px;
  width: 100%;
  height: 336px;
  object-fit: cover;
}
.main-bnr-one .banner-media .main-img3 {
  transform: translate(30px, -30px);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.main-bnr-one .banner-media .main-img3 img {
  border-radius: 0 0 100px 0;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-one .banner-media .main-img1 img {
    height: 300px;
  }
  .main-bnr-one .banner-media .main-img2 img {
    height: 250px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-media .main-img1 img {
    height: 240px;
  }
  .main-bnr-one .banner-media .main-img2 {
    padding-left: 30px;
  }
  .main-bnr-one .banner-media .main-img2 img {
    height: 200px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-media {
    margin: 0 15px;
  }
  .main-bnr-one .banner-media .main-img1 img {
    height: 200px;
    border-radius: 50px 0 0 0;
  }
  .main-bnr-one .banner-media .main-img2 img {
    height: 150px;
    border-radius: 0 0 0 50px;
  }
  .main-bnr-one .banner-media .main-img3 img {
    border-radius: 0 0 50px 0;
    height: 300px;
    width: 100%;
    object-fit: cover;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-one .banner-media {
    margin: 0 -10px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .banner-media {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .main-bnr-one .banner-media .main-img1 img {
    height: 100px;
    border-radius: 30px 0 0 0;
  }
  .main-bnr-one .banner-media .main-img2 {
    padding-left: 15px;
  }
  .main-bnr-one .banner-media .main-img2 img {
    height: 120px;
    border-radius: 0 0 0 30px;
  }
  .main-bnr-one .banner-media .main-img3 img {
    border-radius: 0 0 30px 0;
    height: 180px;
  }
}
.main-bnr-one .bottom-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 40px;
  bottom: 40px;
  z-index: 1;
}
.main-bnr-one .bottom-wrapper .social-list li {
  display: inline-block;
  position: relative;
  padding-left: 17px;
  margin-left: 12px;
}
.main-bnr-one .bottom-wrapper .social-list li a {
  color: #fff;
}
.main-bnr-one .bottom-wrapper .social-list li:after {
  content: "";
  height: 5px;
  width: 5px;
  background-color: var(--secondary);
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
}
.main-bnr-one .bottom-wrapper .social-list li:first-child {
  margin-left: 0;
  padding-left: 0;
}
.main-bnr-one .bottom-wrapper .social-list li:first-child:after {
  content: none;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .bottom-wrapper {
    right: 20px;
    bottom: 20px;
  }
  .main-bnr-one .bottom-wrapper .social-list {
    display: none;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .bottom-wrapper {
    right: 15px;
    bottom: 30px;
  }
}
.main-bnr-one .btn-wrapper {
  display: flex;
  margin-left: 30px;
}
.main-bnr-one .btn-wrapper .main-btn {
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  color: #fff;
  z-index: 1;
  border-radius: var(--border-radius-base);
  background-color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin: 0 8px;
}
.main-bnr-one .btn-wrapper .main-btn:hover {
  color: var(--title);
  background-color: var(--secondary);
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .btn-wrapper .main-btn {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }
}
.main-bnr-one .main-slider {
  overflow: unset;
  position: relative;
}
.main-bnr-one .banner-inner {
  position: relative;
  min-height: 800px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  background-position: bottom left;
  background-color: #003b4a;
  background-blend-mode: luminosity;
}
.main-bnr-one .banner-inner:before {
  content: "";
  position: absolute;
  opacity: 0.8;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #003b4a;
}
.main-bnr-one .banner-inner .shape1,
.main-bnr-one .banner-inner .shape2,
.main-bnr-one .banner-inner .shape3,
.main-bnr-one .banner-inner .shape4 {
  position: absolute;
}
.main-bnr-one .banner-inner .shape1 {
  left: 0;
  bottom: -100px;
}
.main-bnr-one .banner-inner .shape2 {
  left: 0;
  bottom: -150px;
}
.main-bnr-one .banner-inner .shape3,
.main-bnr-one .banner-inner .shape4 {
  right: -10px;
  top: -10px;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-one .banner-inner {
    min-height: 650px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-inner {
    min-height: 550px;
  }
  .main-bnr-one .banner-inner .shape1 {
    left: -40px;
    bottom: -80px;
    width: 100px;
  }
  .main-bnr-one .banner-inner .shape2 {
    left: -50px;
    bottom: -140px;
    width: 100px;
  }
  .main-bnr-one .banner-inner .shape3 {
    width: 200px;
  }
  .main-bnr-one .banner-inner .shape4 {
    width: 300px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-inner {
    min-height: 720px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .banner-inner {
    min-height: 650px;
  }
  .main-bnr-one .banner-inner .shape3 {
    width: 150px;
  }
  .main-bnr-one .banner-inner .shape4 {
    width: 200px;
  }
}
.main-bnr-one .swiper-slide .main-img1,
.main-bnr-one .swiper-slide .main-img2,
.main-bnr-one .swiper-slide .main-img3 {
  transform: translate(0) !important;
}

:root {
  --spacing-bnr-two: 60px;
  --spacing-bnr-two-sub: -60px;
}
@media only screen and (max-width: 991px) {
  :root {
    --spacing-bnr-two: 0;
    --spacing-bnr-two-sub: 0;
  }
}

.main-bnr-two {
  position: relative;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  padding-bottom: var(--spacing-bnr-two);
}
.main-bnr-two:after {
  content: "";
  height: var(--spacing-bnr-two);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--primary);
}
.main-bnr-two .banner-content {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
  z-index: 2;
  align-items: center;
  max-width: 650px;
  padding-bottom: var(--spacing-bnr-two);
}
.main-bnr-two .banner-content .sub-title {
  font-family: var(--font-sub-title);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
}
@media only screen and (max-width: 991px) {
  .main-bnr-two .banner-content .sub-title {
    font-size: 16px;
  }
}
.main-bnr-two .banner-content .title {
  line-height: 1.2;
  font-size: 60px;
  font-weight: 600;
  position: relative;
  margin-bottom: 20px;
  color: #fff;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-two .banner-content .title {
    font-size: 52px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-two .banner-content .title {
    font-size: 42px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-two .banner-content .title {
    font-size: 32px;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-two .banner-content .title {
    font-size: 28px;
  }
}
.main-bnr-two .banner-content p {
  font-size: 16px;
  margin-bottom: 35px;
  color: #d7d7d7;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-two .banner-content p {
    font-size: 15px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-two .banner-content p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-two .banner-content p {
    font-size: 14px;
  }
}
.main-bnr-two .banner-content .btn,
.main-bnr-two .banner-content .wp-block-button__link {
  margin-right: 20px;
}
@media only screen and (max-width: 575px) {
  .main-bnr-two .banner-content .btn,
  .main-bnr-two .banner-content .wp-block-button__link {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-two .banner-content {
    padding-top: 0px;
    padding-bottom: 0;
  }
}
.main-bnr-two .banner-media {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 0 100px;
  margin-top: 50px;
  margin-bottom: var(--spacing-bnr-two-sub);
  opacity: 0;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
  height: 100%;
}
.main-bnr-two .banner-media img {
  width: 100%;
}
@media only screen and (max-width: 1680px) {
  .main-bnr-two .banner-media {
    padding: 0 60px 0 30px;
  }
}
@media only screen and (max-width: 1191px) {
  .main-bnr-two .banner-media {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-two .banner-media {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-two .banner-media {
    padding: 30px 0 0;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-two .banner-media {
    max-width: 250px;
  }
}
.main-bnr-two .banner-media2 {
  position: relative;
  opacity: 0;
  height: 100%;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
  margin: 0 -10px 0 -50px;
  display: flex;
  align-items: flex-end;
}
.main-bnr-two .banner-media2 img {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .main-bnr-two .banner-media2 {
    margin: 0;
  }
}
.main-bnr-two
  .swiper-slide.swiper-slide-visible.swiper-slide-active
  .banner-media,
.main-bnr-two
  .swiper-slide.swiper-slide-visible.swiper-slide-active
  .banner-media2 {
  opacity: 1;
}
.main-bnr-two .bottom-wrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: var(--spacing-bnr-two);
  z-index: 1;
}
.main-bnr-two .bottom-wrapper .social-list li {
  display: inline-block;
  position: relative;
  padding-left: 17px;
  margin-left: 12px;
}
.main-bnr-two .bottom-wrapper .social-list li a {
  color: #fff;
}
.main-bnr-two .bottom-wrapper .social-list li:after {
  content: "";
  height: 5px;
  width: 5px;
  background-color: var(--secondary);
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
}
.main-bnr-two .bottom-wrapper .social-list li:first-child {
  margin-left: 0;
  padding-left: 0;
}
.main-bnr-two .bottom-wrapper .social-list li:first-child:after {
  content: none;
}
.main-bnr-two .btn-wrapper {
  display: flex;
  margin-left: 30px;
}
.main-bnr-two .btn-wrapper .main-btn {
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  color: #fff;
  z-index: 1;
  border-radius: 0;
  background-color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 18px;
}
@media only screen and (max-width: 991px) {
  .main-bnr-two .btn-wrapper .main-btn {
    height: 45px;
    width: 45px;
    line-height: 45px;
  }
}
.main-bnr-two .btn-wrapper .main-btn:hover {
  color: var(--title);
  background-color: var(--secondary);
}
.main-bnr-two .btn-wrapper .main-btn-prev {
  background-color: #fff;
  color: var(--primary);
  border-radius: var(--border-radius-base) 0 0 0;
}
.main-bnr-two .main-slider {
  overflow: unset;
  position: relative;
}
.main-bnr-two .banner-inner {
  position: relative;
  height: 880px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  background-position: bottom left;
  background-color: #141818;
  background-blend-mode: luminosity;
  padding-top: 100px;
}
.main-bnr-two .banner-inner .row {
  height: 100%;
}
.main-bnr-two .banner-inner:before {
  content: "";
  position: absolute;
  opacity: 0.8;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #141818;
}
@media only screen and (max-width: 1680px) {
  .main-bnr-two .banner-inner {
    height: 800px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-two .banner-inner {
    height: 700px;
  }
}
@media only screen and (max-width: 1191px) {
  .main-bnr-two .banner-inner {
    height: 580px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-two .banner-inner {
    height: 500px;
    padding-top: 120px;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-two .banner-inner {
    height: 750px;
  }
  .main-bnr-two .banner-inner .row {
    flex-direction: column-reverse;
    height: auto;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-two .banner-inner {
    padding-top: 100px;
    height: 645px;
  }
}

.banner-one {
  position: relative;
  align-items: center;
  overflow: hidden;
  z-index: 1;
}
.banner-one:after,
.banner-one:before {
  content: "";
  position: absolute;
  height: 700px;
  width: 700px;
}
.banner-one:before {
  left: -300px;
  top: 0;
  z-index: -1;
  transform: translateY(-50%);
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 116, 104, 0.1) 30%,
    rgba(255, 116, 104, 0.1) 35%,
    rgba(255, 116, 104, 0) 76%,
    rgba(255, 116, 104, 0) 96%
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 116, 104, 0.1) 30%,
    rgba(255, 116, 104, 0.1) 35%,
    rgba(255, 116, 104, 0) 76%,
    rgba(255, 116, 104, 0) 96%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(255, 116, 104, 0.1) 30%,
    rgba(255, 116, 104, 0.1) 35%,
    rgba(255, 116, 104, 0) 76%,
    rgba(255, 116, 104, 0) 96%
  );
}
.banner-one:after {
  right: 0;
  top: 0;
  background: -moz-linear-gradient(
    60deg,
    rgba(255, 116, 104, 0) 27%,
    rgba(255, 116, 104, 0) 44%,
    rgba(255, 116, 104, 0.1) 100%
  );
  background: -webkit-linear-gradient(
    60deg,
    rgba(255, 116, 104, 0) 27%,
    rgba(255, 116, 104, 0) 44%,
    rgba(255, 116, 104, 0.1) 100%
  );
  background: linear-gradient(
    60deg,
    rgba(255, 116, 104, 0) 27%,
    rgba(255, 116, 104, 0) 44%,
    rgba(255, 116, 104, 0.1) 100%
  );
}
@media only screen and (max-width: 1199px) {
  .banner-one {
    height: 700px;
  }
}
@media only screen and (max-width: 991px) {
  .banner-one {
    padding-top: 60px;
    height: auto;
  }
}
@media only screen and (max-width: 575px) {
  .banner-one {
    padding-top: 50px;
  }
}
.banner-one .banner-content {
  padding-right: 100px;
}
@media only screen and (max-width: 991px) {
  .banner-one .banner-content {
    padding-right: 0;
  }
}
.banner-one .banner-content h1,
.banner-one .banner-content .h1 {
  line-height: 1.2;
  font-size: 45px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1480px) {
  .banner-one .banner-content h1,
  .banner-one .banner-content .h1 {
    font-size: 45px;
  }
}
@media only screen and (max-width: 1199px) {
  .banner-one .banner-content h1,
  .banner-one .banner-content .h1 {
    font-size: 37px;
  }
}
@media only screen and (max-width: 991px) {
  .banner-one .banner-content h1,
  .banner-one .banner-content .h1 {
    font-size: 29px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-one .banner-content h1,
  .banner-one .banner-content .h1 {
    font-size: 29px;
  }
}
@media only screen and (max-width: 575px) {
  .banner-one .banner-content h1,
  .banner-one .banner-content .h1 {
    font-size: 24px;
  }
}
.banner-one .banner-content .sub-title {
  position: relative;
  margin-left: 30px;
  margin-bottom: 10px;
}
.banner-one .banner-content .sub-title:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30px;
  background-color: var(--primary);
  width: 20px;
  height: 3px;
  border-radius: var(--border-radius-base);
  transform: translate(0, -50%);
}
.banner-one .banner-content p {
  font-size: 18px;
  margin-bottom: 40px;
  margin-right: auto;
  max-width: 730px;
}
@media only screen and (max-width: 991px) {
  .banner-one .banner-content p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .banner-one .banner-content p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 991px) {
  .banner-one .banner-content {
    padding-bottom: 50px;
  }
}
.banner-one .banner-content .btn-secondary {
  box-shadow: 0px 25px 50px 0px rgba(255, 116, 104, 0.36);
}
.banner-one .banner-media {
  position: relative;
  text-align: center;
  z-index: 1;
  margin-right: -50px;
  margin-left: -30px;
}
.banner-one .banner-media .main-img {
  width: 100%;
  height: 100%;
}
.banner-one .banner-media:before,
.banner-one .banner-media:after {
  content: "";
  position: absolute;
  border-radius: 25px;
  transform: skew(-15deg);
  z-index: -1;
}
.banner-one .banner-media:before {
  background: #1b8271;
  background: -moz-linear-gradient(
    left,
    var(--primary) 0%,
    var(--rgba-primary-8) 100%
  );
  background: -webkit-linear-gradient(
    left,
    var(--primary) 0%,
    var(--rgba-primary-8) 100%
  );
  background: linear-gradient(
    to right,
    var(--primary) 0%,
    var(--rgba-primary-8) 100%
  );
  height: 200px;
  width: 270px;
  left: 20px;
  top: 60px;
}
.banner-one .banner-media:after {
  background: #ff7468;
  background: -moz-linear-gradient(left, #ff7468 0%, #ff5c4e 100%);
  background: -webkit-linear-gradient(left, #ff7468 0%, #ff5c4e 100%);
  background: linear-gradient(to right, #ff7468 0%, #ff5c4e 100%);
  height: 380px;
  width: 340px;
  right: -50px;
  bottom: 30px;
}
.banner-one .banner-media .dz-media {
  width: 125px;
  height: 125px;
  position: absolute;
  left: -30%;
  bottom: 20%;
  transform: translateX(50%);
  z-index: 1;
}
.banner-one .banner-media .dz-media .main-shape {
  width: 100%;
  height: 100%;
}
.banner-one .dz-shape .shape-1 {
  width: 70px;
  position: absolute;
  right: 47%;
  bottom: 14%;
}
.banner-one .dz-shape .shape-2 {
  width: 50px;
  position: absolute;
  left: 2%;
  top: 10%;
}
.banner-one .dz-shape .shape-3 {
  width: 70px;
  position: absolute;
  left: 10%;
  bottom: 25%;
}
.banner-one .dz-shape .shape-4 {
  width: 50px;
  position: absolute;
  right: 15%;
  top: 30%;
}
.banner-one .banner-inner {
  position: relative;
  height: 740px;
  z-index: 99;
  align-items: flex-end;
}
.banner-one .banner-inner:before {
  content: "";
  position: absolute;
  height: 700px;
  width: 700px;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(64, 186, 212, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(64, 186, 212, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(64, 186, 212, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: -1;
}
@media only screen and (max-width: 991px) {
  .banner-one .banner-inner {
    height: auto;
    align-items: center;
  }
}
@media only screen and (max-width: 1480px) {
  .banner-one .banner-media {
    margin-right: -10px;
  }
  .banner-one .banner-media:before {
    height: 180px;
    width: 240px;
  }
  .banner-one .banner-media:after {
    height: 330px;
    width: 260px;
    right: -20px;
    bottom: 10px;
  }
  .banner-one .dz-shape .shape-3 {
    left: 5%;
  }
}
@media only screen and (max-width: 1280px) {
  .banner-one .banner-media {
    margin-right: 0px;
  }
  .banner-one .banner-media:before {
    height: 160px;
    width: 220px;
  }
  .banner-one .banner-media:after {
    height: 290px;
    width: 250px;
    right: 5px;
    bottom: 30px;
  }
  .banner-one .dz-shape .shape-3 {
    left: 0%;
  }
  .banner-one .dz-shape .shape-4 {
    right: 5%;
  }
}
@media only screen and (max-width: 1199px) {
  .banner-one .banner-media:before {
    height: 140px;
    width: 180px;
  }
  .banner-one .banner-media:after {
    height: 230px;
    width: 210px;
    right: -5px;
    bottom: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .banner-one .banner-media .main-img {
    width: 350px;
    height: auto;
  }
  .banner-one .banner-media:before {
    height: 120px;
    width: 120px;
    left: 22%;
    top: 40px;
    border-radius: 10px;
  }
  .banner-one .banner-media:after {
    height: 100px;
    width: 100px;
    right: 70%;
    bottom: 20px;
    border-radius: 10px;
  }
  .banner-one .banner-media .dz-media {
    width: 80px;
    height: 80px;
    left: 65%;
  }
  .banner-one .dz-shape .shape-1 {
    z-index: 999;
  }
  .banner-one .dz-shape .shape-3 {
    z-index: 999;
  }
}
@media only screen and (max-width: 767px) {
  .banner-one .banner-media .main-img {
    width: 300px;
    height: auto;
  }
  .banner-one .banner-media:before {
    height: 160px;
    width: 210px;
    left: 15%;
    top: 60px;
  }
  .banner-one .banner-media:after {
    height: 220px;
    width: 220px;
    right: 15%;
    bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .banner-one .banner-media .main-img {
    width: 250px;
    height: auto;
  }
  .banner-one .banner-media:before {
    height: 130px;
    width: 145px;
    left: 20%;
    top: 60px;
  }
  .banner-one .banner-media:after {
    height: 180px;
    width: 180px;
    right: 10%;
    bottom: 20px;
  }
  .banner-one .banner-media .dz-media {
    width: 80px;
    height: 80px;
  }
}

.banner-clients-swiper {
  padding: 60px 0;
  margin-left: 0;
  width: 80%;
}
.banner-clients-swiper .swiper-wrapper {
  align-items: center;
}
.banner-clients-swiper .swiper-wrapper .clients-logo {
  cursor: pointer;
}
.banner-clients-swiper .swiper-wrapper .clients-logo img {
  margin: auto;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.banner-clients-swiper .swiper-wrapper .clients-logo .logo-main {
  opacity: 0.2;
}
.banner-clients-swiper .swiper-wrapper .clients-logo:hover .logo-main {
  opacity: 1;
}
@media only screen and (max-width: 991px) {
  .banner-clients-swiper {
    display: none;
  }
}

.about-wrapper2 .container {
  z-index: 2;
}

.about-wrapper2:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: #fff;
  width: 100%;
  height: 100px;
}

.about-bx5 .section-head {
  margin-bottom: 30px;
}

.about-bx5 .dz-about-media {
  position: relative;
  overflow: unset;
  margin-right: 20px;
  padding-top: 100px;
}
.about-bx5 .dz-about-media .img-wrapper {
  position: absolute;
  top: 0;
  left: -70px;
  width: 330px;
}
.about-bx5 .dz-about-media .img-wrapper .video-btn {
  position: absolute;
  background-color: var(--primary);
  top: 10px;
  left: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 20px;
}
.about-bx5 .dz-about-media .img-wrapper .video-btn i {
  color: #fff;
}
.about-bx5 .dz-about-media .img-wrapper .img1 {
  margin-right: 0;
  z-index: -1;
  padding: 0 20px 20px 0;
  background-color: #fff;
  /* background-color:transparent; */
  border-radius: var(--border-radius-base);
}
.about-bx5 .dz-about-media .img2 {
  border-radius: var(--border-radius-base);
  width: 85%;
  height: 85%;
  z-index: 1 !important;
  overflow: hidden;
  position: relative;
  clip-path: polygon(50% 0%, 100% 0, 100% 80%, 85% 100%, 51% 100%, 0 100%, 0 0);
}
.about-bx5 .dz-about-media .img2 img {
  border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 1280px) {
  .about-bx5 .dz-about-media {
    padding-left: 80px;
  }
  .about-bx5 .dz-about-media .img-wrapper .img1 {
    padding: 0 10px 10px 0;
  }
  .about-bx5 .dz-about-media .img-wrapper {
    left: 0;
    width: 250px;
  }
}
@media only screen and (max-width: 575px) {
  .about-bx5 .dz-about-media {
    margin-right: 0;
  }
  .about-bx5 .dz-about-media .img-wrapper {
    width: 180px;
  }
  .about-bx5 .dz-about-media .img-wrapper .img1 {
    padding: 0 5px 5px 0;
  }
  .about-bx5 .dz-about-media .img-wrapper .video-bx {
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
  }
}

.about-bx5 .dz-about-info {
  border-left: 5px solid var(--bs-warning);
  border-right: 5px solid var(--bs-warning);
  padding: 10px 0 10px 30px;
  margin-bottom: 40px;
}
@media only screen and (max-width: 1191px) {
  .about-bx5 .dz-about-info {
    padding: 5px 0 5px 25px;
  }
}

.about-bx2 {
  box-shadow: 0px 0 60px rgba(0, 0, 0, 0.09);
  z-index: 1;
  background-color: #fff;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
.about-bx2 .dz-media {
  height: 100%;
}
.about-bx2 .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.about-bx2 .dz-info {
  padding: 50px 50px;
}
.about-bx2 .dz-info .signature {
  margin-top: 50px;
}
@media only screen and (max-width: 767px) {
  .about-bx2 .dz-info {
    padding: 35px;
  }
}
@media only screen and (max-width: 575px) {
  .about-bx2 .dz-info {
    padding: 25px;
  }
  .about-bx2 .dz-info .signature {
    margin-top: 20px;
  }
  .about-bx2 .dz-info .signature img {
    width: 120px;
  }
}

.about-bx3 .dz-media {
  position: relative;
  text-align: center;
  overflow: visible;
  margin-left: -50px;
  padding-right: 50px;
}
.about-bx3 .dz-media img {
  width: 100%;
  height: 100%;
}
.about-bx3 .dz-media:before {
  content: "";
  position: absolute;
  border-radius: 25px;
  transform: skew(-15deg);
  z-index: -1;
  background-color: var(--primary);
  height: 300px;
  width: 290px;
  left: -70px;
  top: 150px;
}

.about-bx3 .section-head {
  margin-bottom: 30px;
}

@media only screen and (max-width: 1280px) {
  .about-bx3 .dz-media {
    margin-left: 50px;
    padding-right: 30px;
  }
  .about-bx3 .dz-media:before {
    height: 290px;
    width: 250px;
    left: -20px;
    bottom: 170px;
  }
}

@media only screen and (max-width: 1199px) {
  .about-bx3 .dz-media {
    margin-left: auto;
    margin-right: auto;
    padding-right: 0;
  }
  .about-bx3 .dz-media:before {
    height: 260px;
    width: 230px;
    bottom: 100px;
    left: -10px;
  }
}

@media only screen and (max-width: 991px) {
  .about-bx3 {
    flex-direction: column-reverse;
  }
  .about-bx3 .dz-media img {
    width: 390px;
  }
  .about-bx3 .dz-media:before {
    height: 290px;
    width: 260px;
    left: 70px;
    bottom: 130px;
  }
}

@media only screen and (max-width: 575px) {
  .about-bx3 .dz-media img {
    width: 250px;
  }
  .about-bx3 .dz-media:before {
    height: 160px;
    width: 160px;
    left: 20px;
    top: 70px;
    border-radius: 10px;
  }
}

.dz-box {
  position: relative;
}

.dz-box.style-1 {
  border-radius: var(--border-radius-base);
  overflow: hidden;
  text-align: center;
}
.dz-box.style-1 .dz-info {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.2);
  width: 100%;
  padding: 0 20px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-box.style-1 .dz-info .title,
.dz-box.style-1 .dz-info .title a {
  color: #fff;
}
.dz-box.style-1 .tags-list li {
  margin-bottom: 8px;
  display: inline-block;
}
.dz-box.style-1 .tags-list li a {
  display: inline-block;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: var(--border-radius-base);
  padding: 10px 18px;
}
.dz-box.style-1:hover .dz-info {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.portfolio-area2:after,
.portfolio-area2:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: #fff;
  bottom: 0;
}

.portfolio-area2:after {
  height: 40%;
  left: 0;
  width: 100%;
}

.portfolio-area2:before {
  height: 100%;
  right: 0;
  width: 15%;
}

.portfolio-area2 .setResizeMargin {
  padding-right: 80px;
}

@media only screen and (max-width: 1680px) {
  .portfolio-area2 .setResizeMargin {
    padding-right: 40px;
  }
  .portfolio-area2:before {
    width: 10%;
  }
}

@media only screen and (max-width: 1480px) {
  .portfolio-area2:before {
    content: none;
  }
}

@media only screen and (max-width: 1280px) {
  .portfolio-area2 .setResizeMargin {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .portfolio-area2 .setResizeMargin {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .portfolio-area2 .setResizeMargin {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.dz-box.style-2 {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  overflow: hidden;
  padding-bottom: 90px;
}
@media only screen and (max-width: 767px) {
  .dz-box.style-2 .dz-media img {
    min-height: 350px;
  }
}
.dz-box.style-2 .dz-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: rgba(33, 37, 41, 0);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-box.style-2 .sub-title {
  writing-mode: tb-rl;
  float: right;
  margin: 0;
  color: #fff;
  background: var(--primary);
  font-weight: 300;
  position: absolute;
  top: 0;
  padding: 40px 25px;
  left: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-box.style-2 .sub-title a {
  color: inherit;
}
@media only screen and (max-width: 1280px) {
  .dz-box.style-2 .sub-title {
    padding: 20px 12px;
    font-size: 15px;
  }
}
.dz-box.style-2 .title {
  margin: 0;
  font-size: 30px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-box.style-2 .title span {
  display: block;
}
.dz-box.style-2 .port-info {
  position: absolute;
  bottom: 10px;
  right: 200px;
  background: #fff;
  padding: 50px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  width: 100%;
}
@media only screen and (max-width: 1280px) {
  .dz-box.style-2 .port-info {
    padding: 30px;
  }
  .dz-box.style-2 .port-info .dz-meta {
    margin-bottom: 5px;
  }
  .dz-box.style-2 .port-info .dz-meta ul li {
    font-size: 15px;
  }
  .dz-box.style-2 .port-info h2.title,
  .dz-box.style-2 .port-info .title.h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-box.style-2 .port-info {
    right: 30px;
    max-width: 350px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-box.style-2 .port-info h2.title,
  .dz-box.style-2 .port-info .title.h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-box.style-2 .port-info {
    padding: 20px;
    right: 15px;
    max-width: 90%;
  }
}
.dz-box.style-2 .dz-meta ul li {
  color: var(--primary);
  font-size: 18px;
  padding-left: 15px;
  position: relative;
}
.dz-box.style-2 .dz-meta ul li:after {
  content: "";
  height: 8px;
  width: 8px;
  background: var(--primary);
  position: absolute;
  border-radius: 4px;
  left: 0;
  top: 50%;
  margin-top: -4px;
}
.dz-box.style-2 .view-btn {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 30px;
  right: 30px;
  border-radius: var(--border-radius-base);
  z-index: 1;
  transition: background 0.5s ease;
  -moz-transition: background 0.5s ease;
  -webkit-transition: background 0.5s ease;
  -ms-transition: background 0.5s ease;
  -o-transition: background 0.5s ease;
  transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  -ms-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  -webkit-transition-delay: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dz-box.style-2 .view-btn:after {
  content: "\f00e";
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  font-family: "Line Awesome Free";
  font-weight: 900;
  color: #fff;
  font-size: 35px;
  display: inline-block;
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
}
@media only screen and (max-width: 767px) {
  .dz-box.style-2 .view-btn {
    width: 50px;
    height: 50px;
    top: 15px;
    right: 15px;
  }
  .dz-box.style-2 .view-btn:after {
    font-size: 25px;
  }
}
.dz-box.style-2:hover .view-btn {
  background: var(--primary);
  transition-delay: 0s;
}
.dz-box.style-2:hover .view-btn:after {
  opacity: 1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}

.testimonial-pic {
  background: #fff;
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  border: 5px solid #fff;
}
.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-pic.radius img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-pic.shadow {
  -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}

.testimonial-swiper .swiper-pagination {
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .testimonial-swiper {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.quote-left,
.quote-right {
  position: relative;
}
.quote-left:before,
.quote-right:before {
  font-family: "Flaticon";
  position: absolute;
  z-index: 10;
  height: 36px;
  width: 36px;
  line-height: 36px;
  background-color: var(--primary);
  color: #fff;
  border-radius: 30px;
  text-align: center;
  font-size: 18px;
}

.quote-left:before {
  top: 15px;
  content: "\f115";
  left: 0;
}

.quote-right:before {
  top: 15px;
  content: "\f11e";
  right: 0;
}

.testimonial-text {
  position: relative;
  font-size: 16px;
  font-weight: 400;
}
.testimonial-text p:last-child {
  margin: 0;
}

.testimonial-detail {
  padding: 5px;
}

.testimonial-position {
  font-size: 15px;
}

.testimonial-name,
.testimonial-position {
  display: block;
}

.testimonial-bg {
  color: #fff;
}

.star-rating li {
  display: inline-block;
}

.testimonial-1 {
  position: relative;
  padding: 120px 40px 40px;
  border-radius: 14px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-color: var(--secondary);
  border-color: var(--primary);
}
.testimonial-1:after {
  position: absolute;
  content: "\f10c";
  top: 20px;
  left: 30px;
  line-height: 1;
  font-size: 90px;
  color: #fff;
  font-family: flaticon !important;
}
.testimonial-1 .testimonial-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.testimonial-1 .testimonial-info {
  display: flex;
  align-items: center;
}
.testimonial-1 p,
.testimonial-1 .testimonial-name,
.testimonial-1 .testimonial-position {
  color: #fff;
}
.testimonial-1 .testimonial-rating ul {
  margin: 0 -5px;
}
.testimonial-1 .testimonial-rating ul li {
  display: inline-block;
  color: #fff;
  padding: 0 5px;
  font-size: 20px;
}
.testimonial-1 .testimonial-rating ul li .gray-light {
  opacity: 0.3;
  color: #fff;
}
.testimonial-1 .testimonial-text {
  margin-bottom: 30px;
}
.testimonial-1 .testimonial-text p {
  font-style: italic;
  font-size: 18px;
  opacity: 0.8;
}
.testimonial-1 .testimonial-pic {
  overflow: hidden;
  height: 70px;
  width: 70px;
  min-width: 70px;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
  border: 2px solid #fff;
}
.testimonial-1 .testimonial-name {
  margin-bottom: 0;
}
.testimonial-1 .testimonial-position {
  opacity: 0.5;
  font-family: var(--font-family-title);
}
@media only screen and (max-width: 575px) {
  .testimonial-1 {
    padding: 80px 25px 25px 25px;
  }
  .testimonial-1:after {
    top: 15px;
    left: 20px;
    font-size: 65px;
  }
  .testimonial-1 .testimonial-pic {
    height: 60px;
    width: 60px;
    min-width: 60px;
    margin-right: 15px;
  }
  .testimonial-1 .testimonial-text {
    margin-bottom: 20px;
  }
  .testimonial-1 .testimonial-text p {
    font-size: 15px;
  }
  .testimonial-1 .testimonial-details {
    display: block;
  }
  .testimonial-1 .testimonial-rating {
    display: none;
  }
  .testimonial-1 .testimonial-name {
    font-size: 18px;
  }
}

.testimonial-wrapper1 {
  position: relative;
  overflow: hidden;
}
.testimonial-wrapper1 .swiper-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.testimonial-wrapper1 .swiper-slide {
  padding: 0 30px;
  opacity: 0.1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  max-width: 900px;
}
.testimonial-wrapper1 .swiper-slide.swiper-slide-active {
  opacity: 1;
}
.testimonial-wrapper1 .swiper-slide.swiper-slide-active .testimonial-1 {
  background-color: var(--primary);
}
.testimonial-wrapper1
  .swiper-slide.swiper-slide-active
  .testimonial-1
  .testimonial-text
  p {
  color: #fff;
}
.testimonial-wrapper1
  .swiper-slide.swiper-slide-active
  .testimonial-1
  .testimonial-name {
  color: #fff;
}
.testimonial-wrapper1
  .swiper-slide.swiper-slide-active
  .testimonial-1
  .testimonial-position {
  color: #fff;
}

.testimonial-wrapper2:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    white 28%,
    white 29%,
    white 75%,
    rgba(255, 255, 255, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    white 28%,
    white 29%,
    white 75%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    white 28%,
    white 29%,
    white 75%,
    rgba(255, 255, 255, 0) 100%
  );
}

.testimonial-wrapper2:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: -moz-linear-gradient(
    top,
    white 0%,
    rgba(255, 255, 255, 0.7) 34%,
    rgba(255, 255, 255, 0.7) 67%,
    rgba(255, 255, 255, 0.71) 68%,
    white 97%,
    white 99%
  );
  background: -webkit-linear-gradient(
    top,
    white 0%,
    rgba(255, 255, 255, 0.7) 34%,
    rgba(255, 255, 255, 0.7) 67%,
    rgba(255, 255, 255, 0.71) 68%,
    white 97%,
    white 99%
  );
  background: linear-gradient(
    to bottom,
    white 0%,
    rgba(255, 255, 255, 0.7) 34%,
    rgba(255, 255, 255, 0.7) 67%,
    rgba(255, 255, 255, 0.71) 68%,
    white 97%,
    white 99%
  );
}

.testimonial-2 {
  padding: 30px;
  background-color: #fff4cf;
  border-radius: var(--border-radius-base);
  display: flex;
  width: 100%;
  margin: auto;
}
@media only screen and (max-width: 767px) {
  .testimonial-2 {
    padding: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-2 {
    display: block;
  }
}
.testimonial-2 .testimonial-media {
  width: 320px;
  height: auto;
  margin-right: 30px;
  min-width: 320px;
}
.testimonial-2 .testimonial-media img {
  border-radius: var(--border-radius-base);
  filter: grayscale(0.8);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 991px) {
  .testimonial-2 .testimonial-media {
    width: 250px;
    min-width: 250px;
  }
}
@media only screen and (max-width: 767px) {
  .testimonial-2 .testimonial-media {
    width: 200px;
    min-width: 200px;
    margin-right: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-2 .testimonial-media {
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
}
.testimonial-2 .testimonial-content .testimonial-title {
  color: var(--primary);
}
.testimonial-2 .testimonial-content .testimonial-text {
  margin-bottom: 30px;
}
.testimonial-2 .testimonial-content .testimonial-text a {
  color: var(--primary);
  text-decoration: underline;
}
.testimonial-2 .testimonial-content .testimonial-info {
  display: flex;
  align-items: center;
}
.testimonial-2 .testimonial-content .testimonial-info .quotes i {
  color: var(--primary);
  font-size: 50px;
  margin-right: 20px;
}
.testimonial-2 .testimonial-content .testimonial-info .testimonial-name {
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .testimonial-2 .testimonial-content .testimonial-text {
    margin-bottom: 15px;
    font-size: 15px;
  }
}

.testimonial-wrapper3 {
  overflow: hidden;
}
.testimonial-wrapper3:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: -moz-linear-gradient(
    left,
    rgba(20, 24, 24, 0) 0%,
    #141818 28%,
    #141818 29%,
    #141818 75%,
    rgba(20, 24, 24, 0) 100%
  );
  background: -webkit-linear-gradient(
    left,
    rgba(20, 24, 24, 0) 0%,
    #141818 28%,
    #141818 29%,
    #141818 75%,
    rgba(20, 24, 24, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(20, 24, 24, 0) 0%,
    #141818 28%,
    #141818 29%,
    #141818 75%,
    rgba(20, 24, 24, 0) 100%
  );
}
.testimonial-wrapper3:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background: -moz-linear-gradient(
    top,
    #141818 0%,
    rgba(20, 24, 24, 0.7) 34%,
    rgba(20, 24, 24, 0.7) 67%,
    rgba(20, 24, 24, 0.71) 68%,
    #141818 97%,
    #141818 99%
  );
  background: -webkit-linear-gradient(
    top,
    #141818 0%,
    rgba(20, 24, 24, 0.7) 34%,
    rgba(20, 24, 24, 0.7) 67%,
    rgba(20, 24, 24, 0.71) 68%,
    #141818 97%,
    #141818 99%
  );
  background: linear-gradient(
    to bottom,
    #141818 0%,
    rgba(20, 24, 24, 0.7) 34%,
    rgba(20, 24, 24, 0.7) 67%,
    rgba(20, 24, 24, 0.71) 68%,
    #141818 97%,
    #141818 99%
  );
}
.testimonial-wrapper3 .swiper {
  margin-right: -200px;
}
.testimonial-wrapper3 .resize-wrapper {
  padding-bottom: 80px;
}
@media only screen and (max-width: 991px) {
  .testimonial-wrapper3 .resize-wrapper {
    padding-bottom: 40px;
  }
}
.testimonial-wrapper3 .swiper-btn {
  display: flex;
}
.testimonial-wrapper3 .swiper-btn .btn-prev,
.testimonial-wrapper3 .swiper-btn .btn-next {
  padding: 0 20px;
  font-size: 30px;
  opacity: 0.7;
  cursor: pointer;
}
.testimonial-wrapper3 .swiper-btn .btn-prev:hover,
.testimonial-wrapper3 .swiper-btn .btn-next:hover {
  opacity: 1;
}
@media only screen and (max-width: 1191px) {
  .testimonial-wrapper3 .swiper {
    margin-right: -35%;
  }
}
@media only screen and (max-width: 991px) {
  .testimonial-wrapper3 .swiper {
    margin-right: 0;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-wrapper3 .swiper-btn {
    margin-bottom: 20px;
  }
  .testimonial-wrapper3 .swiper-btn .btn-prev,
  .testimonial-wrapper3 .swiper-btn .btn-next {
    padding: 0 5px;
    font-size: 20px;
  }
  .testimonial-wrapper3 .swiper-btn .btn-prev svg,
  .testimonial-wrapper3 .swiper-btn .btn-next svg {
    width: 12px;
  }
}

.testimonial-3 {
  background-color: #fff;
  border-radius: var(--border-radius-base);
  display: flex;
  overflow: hidden;
}
.testimonial-3.right {
  background-color: #fff;
  border-radius: var(--border-radius-base);
  display: flex;
  overflow: hidden;
  justify-content: space-between;
}

@media only screen and (max-width: 575px) {
  .testimonial-3 {
    display: block;
  }
  .testimonial-3.right {
    display: block;
  }
}
.testimonial-3 .testimonial-media {
  height: auto;
  width: 312px;
  min-width: 312px;
  background-color: #003b4a;
}
.testimonial-3 .testimonial-media img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  object-fit: contain;
  opacity: 0.7;
}
@media only screen and (max-width: 1280px) {
  .testimonial-3 .testimonial-media {
    width: 250px;
    min-width: 250px;
  }
}
@media only screen and (max-width: 767px) {
  .testimonial-3 .testimonial-media {
    width: 220px;
    min-width: 220px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-3 .testimonial-media {
    width: 100%;
    min-width: 100%;
    /* height: 250px; */
    height: auto;
  }
}
.testimonial-3 .testimonial-content {
  padding: 35px;
  position: relative;
}
@media only screen and (max-width: 1280px) {
  .testimonial-3 .testimonial-content {
    padding: 25px;
  }
}
.testimonial-3 .testimonial-content .testimonial-title {
  color: var(--primary);
  font-weight: 500;
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  .testimonial-3 .testimonial-content .testimonial-title {
    margin-bottom: 10px;
    font-size: 16px;
  }
}
.testimonial-3 .testimonial-content .testimonial-text {
  margin-bottom: 30px;
}
.testimonial-3 .testimonial-content .testimonial-text a {
  color: var(--primary);
  text-decoration: underline;
}
@media only screen and (max-width: 1280px) {
  .testimonial-3 .testimonial-content .testimonial-text {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .testimonial-3 .testimonial-content .testimonial-text {
    font-size: 15px;
  }
}
.testimonial-3 .testimonial-content .testimonial-info {
  display: flex;
  align-items: center;
}
.testimonial-3 .testimonial-content .testimonial-info .quotes i {
  color: var(--primary);
  font-size: 50px;
  margin-right: 20px;
}
.testimonial-3 .testimonial-content .testimonial-info .testimonial-name {
  margin-bottom: 0;
}
/* .testimonial-3 .testimonial-content:after {
  content: "";
  position: absolute;
  left: -25px;
  top: 40px;
  border-left: 25px solid transparent;
  border-top: 30px solid #fff;
  z-index: 1;
}
.testimonial-3.right .testimonial-content:after {
  left: auto;
  border-left: 0;
  border-top: 30px solid #fff;
  right: -25px;
  border-right: 25px solid transparent;
 
} */

.section-head.style-1 h2,
.section-head.style-1 .h2 {
  font-size: 2.8125rem;
}
.section-head.style-1 h2 span,
.section-head.style-1 .h2 span {
  color: var(--primary);
}
@media only screen and (max-width: 1280px) {
  .section-head.style-1 h2,
  .section-head.style-1 .h2 {
    font-size: 2.25rem;
  }
}
@media only screen and (max-width: 767px) {
  .section-head.style-1 h2,
  .section-head.style-1 .h2 {
    font-size: 1.875rem;
  }
}

.section-head.style-1 .sub-title:before,
.section-head.style-1 .sub-title:after {
  content: "\f111";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
  display: inline-block;
}

.section-head.style-1 .sub-title:before {
  transform-origin: left;
  -moz-transform-origin: left;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  -o-transform-origin: left;
}

.section-head.style-1 .sub-title:after {
  content: none;
  transform-origin: right;
  -moz-transform-origin: right;
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  -o-transform-origin: right;
}

.section-head.style-1.text-center .sub-title:after {
  content: "\f111";
}

.section-head.style-2 h2,
.section-head.style-2 .h2 {
  font-size: 2.8125rem;
}
.section-head.style-2 h2 span,
.section-head.style-2 .h2 span {
  color: var(--primary);
}
@media only screen and (max-width: 1280px) {
  .section-head.style-2 h2,
  .section-head.style-2 .h2 {
    font-size: 2.25rem;
  }
}
@media only screen and (max-width: 767px) {
  .section-head.style-2 h2,
  .section-head.style-2 .h2 {
    font-size: 1.875rem;
  }
}

.section-head.style-2 .sub-title {
  display: inline-flex;
  align-items: center;
}
.section-head.style-2 .sub-title:before,
.section-head.style-2 .sub-title:after {
  content: "";
  height: 1px;
  width: 55px;
  background-color: #000;
  display: inline-block;
  margin-right: 10px;
}
@media only screen and (max-width: 575px) {
  .section-head.style-2 .sub-title:before,
  .section-head.style-2 .sub-title:after {
    width: 35px;
  }
}
.section-head.style-2 .sub-title:before {
  margin-right: 10px;
}
.section-head.style-2 .sub-title:after {
  content: none;
  margin-left: 10px;
}
.section-head.style-2 .sub-title.text-primary:before,
.section-head.style-2 .sub-title.text-primary:after {
  background-color: var(--primary);
}
.section-head.style-2 .sub-title.text-secondary:before,
.section-head.style-2 .sub-title.text-secondary:after {
  background-color: var(--secondary);
}
.section-head.style-2 .sub-title.text-white:before,
.section-head.style-2 .sub-title.text-white:after {
  background-color: #fff;
}

.section-head.style-2.text-end .sub-title:before {
  content: none;
}

.section-head.style-2.text-end .sub-title:after {
  content: "";
}

.icon-bx-xl {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 150px;
  height: 150px;
  line-height: 150px;
}
.icon-bx-xl.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-xl i {
  font-size: 80px;
  vertical-align: middle;
}
.icon-bx-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-bx-lg {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 120px;
  height: 120px;
  line-height: 120px;
}
.icon-bx-lg.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-lg i {
  font-size: 60px;
  vertical-align: middle;
}
.icon-bx-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 55px;
}

.icon-bx-md {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.icon-bx-md.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-md i {
  font-size: 45px;
  vertical-align: middle;
}
.icon-bx-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 40px;
}

.icon-bx-sm {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 65px;
  height: 65px;
  min-width: 65px;
  line-height: 65px;
}
.icon-bx-sm.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-sm i {
  font-size: 35px;
  vertical-align: middle;
}
.icon-bx-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 35px;
}

.icon-bx-xs {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 60px;
  height: 60px;
  line-height: 60px;
}
.icon-bx-xs.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-xs i {
  font-size: 30px;
  vertical-align: middle;
}
.icon-bx-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-xl {
  display: inline-block;
  text-align: center;
  width: 100px;
  line-height: 1;
}
.icon-xl i {
  vertical-align: middle;
  font-size: 80px;
}
.icon-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-lg {
  display: inline-block;
  text-align: center;
  line-height: 1;
}
.icon-lg i {
  vertical-align: middle;
  font-size: 60px;
}
@media only screen and (max-width: 767px) {
  .icon-lg i {
    font-size: 50px;
    line-height: 1.2;
  }
}
.icon-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 70px;
}

.icon-md {
  display: inline-block;
  text-align: center;
  line-height: 1;
}
.icon-md i {
  vertical-align: middle;
  font-size: 45px;
}
.icon-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 50px;
}

.icon-sm {
  display: inline-block;
  text-align: center;
  width: 40px;
  line-height: 1;
}
.icon-sm i {
  vertical-align: middle;
  font-size: 30px;
}
.icon-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 30px;
}

.icon-xs {
  display: inline-block;
  text-align: center;
  width: 30px;
  line-height: 1;
}
.icon-xs i {
  vertical-align: middle;
  font-size: 20px;
}
.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-bx-wraper {
  position: relative;
}
.icon-bx-wraper .dz-tilte {
  margin-top: 0;
  font-weight: 600;
}
.icon-bx-wraper .after-titile-line {
  margin-bottom: 10px;
}
.icon-bx-wraper p:last-child {
  margin: 0;
}
.icon-bx-wraper.center {
  text-align: center;
}
.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xs {
  float: left;
  margin-right: 15px;
}
.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xs {
  line-height: 1;
  float: left;
  margin-right: 10px;
}
.icon-bx-wraper.right {
  text-align: right;
}
.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xs {
  float: right;
  margin-left: 20px;
}
.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xs {
  line-height: 1;
  float: right;
  margin-left: 10px;
}
.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ebedf2;
}
.icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
  margin-left: -75px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
  margin-left: -60px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-md {
  margin-left: -50px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
  margin-left: -40px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
  margin-left: -20px;
}
.icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.icon-content {
  overflow: hidden;
}

[class*="icon-bx-"][class*="bg-"] a,
[class*="icon-bx-"][class*="bg-"] span {
  color: #fff;
}

[class*="icon-bx-"].bg-white a {
  color: inherit;
}

[class*="icon-bx-"][class*="border-"] {
  display: table;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
}

[class*="icon-bx-"][class*="border-"] .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

.dz-box,
.dz-info,
.dz-tilte,
.dz-tilte-inner {
  position: relative;
}

.dz-tilte-inner {
  display: inline-block;
}

.dz-box[class*="border-"],
.dz-info[class*="border-"] {
  border-color: #eee;
}

.dz-info.border-1,
.dz-info.border-2,
.dz-info.border-3,
.dz-info.border-4,
.dz-info.border-5 {
  border-top: none;
}

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
  border-style: solid;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.left-border,
.right-border {
  position: relative;
}
.left-border:before,
.right-border:before {
  content: "";
  position: absolute;
  top: 5px;
  width: 1px;
  height: 90%;
  background: #ccc;
}

.right-border:before {
  right: 0;
}

.dz-media,
.dz-post-media {
  position: relative;
  overflow: hidden;
}
.dz-media img,
.dz-post-media img {
  width: 100%;
  height: auto;
}

.dz-box-bg {
  overflow: hidden;
  background-size: cover;
}
.dz-box-bg .btn,
.dz-box-bg .wp-block-button__link {
  overflow: unset;
}
.dz-box-bg .glyph-icon {
  font-size: 50px;
}
.dz-box-bg .icon-bx-wraper {
  background-color: #f8f8f8;
}
.dz-box-bg .text-primary,
.dz-box-bg .icon-content .dz-tilte,
.dz-box-bg .icon-content p,
.dz-box-bg .icon-box-btn .btn,
.dz-box-bg .icon-box-btn .wp-block-button__link {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-box-bg.active .icon-bx-wraper {
  background-color: rgba(0, 0, 0, 0.7);
}
.dz-box-bg.active .text-primary,
.dz-box-bg.active .icon-content .dz-tilte,
.dz-box-bg.active .icon-content p,
.dz-box-bg.active .icon-box-btn .btn,
.dz-box-bg.active .icon-box-btn .wp-block-button__link {
  color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-box-bg .icon-box-btn .btn,
.dz-box-bg .icon-box-btn .wp-block-button__link {
  background-color: #eeeeee;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 0;
  color: #1abc9c;
  font-weight: 600;
  margin-bottom: 0;
}

.icon-bx-wraper.style-1 {
  background-color: #fff;
  padding: 30px;
  border-radius: 14px;
  border: 1px solid lightgrey;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  overflow: hidden;
  margin-bottom: 30px;
  z-index: 1;
}
.icon-bx-wraper.style-1:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  color: var(--primary);
  /* background-color: var(--secondary); */
  background-color: var(--bs-warning);

  width: 100%;
  height: 100%;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
  z-index: -1;
  opacity: 0;
}
.icon-bx-wraper.style-1 .icon-content p {
  margin-top: 10px;
  margin-top: 10px;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.icon-bx-wraper.style-1 .icon-cell {
  position: relative;
}
.icon-bx-wraper.style-1 .icon-cell:after {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--primary);
  top: 15%;
  left: 50%;
  z-index: -1;
  opacity: 0.1;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.icon-bx-wraper.style-1 [class*="icon-bx"] {
  transform: scale(1) rotate(0deg);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-1 i {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  display: inline-block;
}
.icon-bx-wraper.style-1:hover {
  box-shadow: 1px 10px 30px 0px rgba(255, 116, 104, 0.3);
}
.icon-bx-wraper.style-1:hover:before {
  opacity: 1;
}
.icon-bx-wraper.style-1:hover .icon-lg .icon-cell i {
  color: #fff;
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}
.icon-bx-wraper.style-1:hover .icon-content .dz-tilte,
.icon-bx-wraper.style-1:hover .icon-content .dz-tilte a {
  color: #fff;
}
.icon-bx-wraper.style-1:hover .icon-content p {
  color: #fff;
}
.icon-bx-wraper.style-1:hover .icon-cell:after {
  background-color: #fff;
}
@media only screen and (max-width: 767px) {
  .icon-bx-wraper.style-1 {
    padding: 25px 30px;
  }
}
@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-1 {
    padding: 20px;
  }
  .icon-bx-wraper.style-1 .icon-cell:after {
    width: 40px;
    height: 40px;
  }
  .icon-bx-wraper.style-1 .dz-tilte {
    font-size: 16px;
  }
}

.icon-bx-wraper.style-2 {
  background: #fff;
  width: 100%;
  overflow: hidden;
  text-align: left;
  display: inline-flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: var(--border-radius-base);
  padding: 30px;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-2 .icon-bx {
  height: 76px;
  width: 76px;
  text-align: center;
  line-height: 76px;
  position: relative;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
}
.icon-bx-wraper.style-2 .icon-bx .ico-bg-shape {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
  z-index: -1;
  filter: drop-shadow(0px 4px 6px var(--rgba-primary-4));
}
.icon-bx-wraper.style-2 .dz-title,
.icon-bx-wraper.style-2 .dz-title a {
  font-weight: 700;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-2 .icon-cell {
  margin-right: 25px;
  background-color: var(--rgba-primary-1);
  display: flex;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.icon-bx-wraper.style-2 i {
  height: 40px;
  font-size: 36px;
  border-radius: 100%;
  width: 40px;
  color: var(--primary);
  text-align: center;
  line-height: 40px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-2:hover {
  border: 1px solid #fff;
  box-shadow: 0px 0 60px rgba(0, 0, 0, 0.09);
}
.icon-bx-wraper.style-2:hover .icon-cell {
  background-color: var(--primary);
}
.icon-bx-wraper.style-2:hover .icon-cell i {
  color: #fff;
}
@media only screen and (max-width: 1199px) {
  .icon-bx-wraper.style-2 {
    font-size: 14px;
    padding: 20px 15px;
  }
}
@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-2 {
    text-align: center;
    display: block;
  }
  .icon-bx-wraper.style-2 .icon-cell {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.icon-bx-wraper.style-3 {
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: left;
  display: inline-flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: var(--border-radius-base);
  padding: 25px 25px;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-3:hover {
  border: 1px solid #fff;
  box-shadow: 0px 0 45px rgba(0, 0, 0, 0.08);
}
.icon-bx-wraper.style-3:hover .icon-cell i {
  animation: toTopFromBottom 0.8s forwards;
}
@media only screen and (max-width: 1199px) {
  .icon-bx-wraper.style-3 {
    font-size: 14px;
    padding: 20px 15px;
  }
}
.icon-bx-wraper.style-3 .icon-bx {
  height: 76px;
  width: 76px;
  text-align: center;
  line-height: 76px;
  position: relative;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
}
.icon-bx-wraper.style-3 .icon-bx .ico-bg-shape {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
  z-index: -1;
  filter: drop-shadow(0px 4px 6px var(--rgba-primary-4));
}
.icon-bx-wraper.style-3 .dz-title,
.icon-bx-wraper.style-3 .dz-title a {
  font-weight: 700;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-3 .icon-cell {
  margin-right: 15px;
  background-color: var(--rgba-primary-1);
  display: flex;
  height: 65px;
  width: 65px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.icon-bx-wraper.style-3 i {
  height: 40px;
  font-size: 25px;
  border-radius: 100%;
  width: 40px;
  color: var(--primary);
  text-align: center;
  line-height: 40px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.icon-bx-wraper.style-4 {
  padding: 30px;
  border-radius: 14px;
  background-color: #fff;
  border: 1px solid #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  overflow: hidden;
  margin-bottom: 30px;
  z-index: 1;
}
.icon-bx-wraper.style-4.transparent {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
}
.icon-bx-wraper.style-4.transparent p {
  color: #fff;
}
.icon-bx-wraper.style-4 p {
  color: var(--title);
  font-weight: 400;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
  font-size: 18px;
  line-height: 1.6;
}
.icon-bx-wraper.style-4 .separator {
  position: relative;
  height: 5px;
  background-color: var(--primary-dark);
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
  border-radius: 14px;
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.icon-bx-wraper.style-4 [class*="icon-bx"] {
  transform: scale(1) rotate(0deg);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-4 [class*="icon-bx"] i {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-4 [class*="icon-bx"] svg path {
  /* fill: var(--primary); */
  fill: var(--bs-warning);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-4 [class*="icon-bx"] svg .secondary {
  fill: var(--secondary);
}
@media only screen and (max-width: 767px) {
  .icon-bx-wraper.style-4 {
    padding: 15px;
  }
  .icon-bx-wraper.style-4 p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-4 [class*="icon-bx"] {
    margin-bottom: 10px;
  }
  .icon-bx-wraper.style-4 [class*="icon-bx"] svg {
    width: 40px;
    height: 40px;
  }
  .icon-bx-wraper.style-4 .separator {
    height: 3px;
    width: 60px;
  }
}
.icon-bx-wraper.style-4:hover {
  box-shadow: 1px 10px 30px 0px rgba(255, 116, 104, 0.3);
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
}
.icon-bx-wraper.style-4:hover .dz-tilte,
.icon-bx-wraper.style-4:hover .dz-tilte a {
  color: #fff;
}
.icon-bx-wraper.style-4:hover p {
  color: #fff;
}
.icon-bx-wraper.style-4:hover [class*="icon-bx"] svg path {
  fill: black;
}

.icon-bx-wraper.style-5 {
  padding: 20px;
}
@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-5 {
    padding: 0;
  }
}
.icon-bx-wraper.style-5 .icon-content p {
  font-weight: 500;
  max-width: 280px;
  font-size: 13px;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.icon-bx-wraper.style-5 .title {
  margin-bottom: 10px;
}
.icon-bx-wraper.style-5 .icon-lg {
  position: relative;
  border: 2px dashed var(--secondary);
  display: flex;
  padding: 25px;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
.icon-bx-wraper.style-5 .icon-lg .badge {
  position: absolute;
  background-color: var(--primary);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: -3px;
  right: -3px;
  padding: 0;
  line-height: 35px;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.icon-bx-wraper.style-5:hover .icon-cell {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}
.icon-bx-wraper.style-5:hover .badge {
  background-color: var(--secondary);
  color: var(--title);
}

@-webkit-keyframes toTopFromBottom {
  49% {
    -webkit-transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}

@-moz-keyframes toTopFromBottom {
  49% {
    -moz-transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    -moz-transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes toTopFromBottom {
  49% {
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}

.dz-team {
  transition: all 0.5s;
}
.dz-team .dz-name a {
  transition: all 0.5s;
}
.dz-team .dz-content {
  transition: all 0.5s;
  position: relative;
}
.dz-team .dz-media img {
  width: 100%;
}
.dz-team .dz-position {
  display: block;
}

.dz-team.style-1 {
  position: relative;
  border-radius: var(--border-radius-base);
}
.dz-team.style-1 .dz-media {
  position: relative;
  overflow: hidden;
}
.dz-team.style-1 .dz-media img {
  border-radius: var(--border-radius-base);
  height: 100%;
  object-fit: cover;
  /* object-fit: contain; */

  object-position: center;
}

.dz-team.style-1 .dz-media .overlay-content {
  position: absolute;
}

/* committee photo gallery */
.dz-team.style-1 .dz-media:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1.2rem solid #fead18;
  background-color: var(--rgba-primary-2);
  border-radius: var(--border-radius-base);
  /* top: -460px; */
  top: -480px;
  left: 0;
  transform: scale(1.1);

  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.dz-team.style-1 .team-social {
  position: absolute;
  left: 0;
  bottom: 20px;
  z-index: 1;
  width: 100%;
  text-align: center;
}
.dz-team.style-1 .team-social li {
  display: inline-block;
  position: relative;
  opacity: 0;
  transform: translateY(80px);
}
.dz-team.style-1 .team-social li:nth-child(1) {
  transition: 0.2s;
}
.dz-team.style-1 .team-social li:nth-child(2) {
  transition: 0.4s;
}
.dz-team.style-1 .team-social li:nth-child(3) {
  transition: 0.6s;
}
.dz-team.style-1 .team-social li a {
  display: inline-block;
  margin: 0 5px;
  height: 35px;
  line-height: 35px;
  width: 35px;
  border-radius: var(--border-radius-base);
  text-align: center;
  color: #fff;
  background-color: var(--secondary);
}
.dz-team.style-1 .team-social li a:hover {
  background-color: #fff;
  color: var(--secondary);
}
.dz-team.style-1 .dz-position {
  margin-bottom: 0;
  font-weight: 400;
  font-family: var(--font-family-base);
  font-size: 15px;
}
.dz-team.style-1 .dz-name {
  margin-bottom: 2px;
}
.dz-team.style-1 .dz-content {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.dz-team.style-1:hover,
.dz-team.style-1.active {
  box-shadow: 0 0rem 1.5rem rgba(0, 0, 0, 0.07) !important;
  background-color: #fff;
}
.dz-team.style-1:hover .team-social li,
.dz-team.style-1.active .team-social li {
  transform: translateY(0px);
  opacity: 1;
}
.dz-team.style-1:hover .dz-content,
.dz-team.style-1.active .dz-content {
  background-color: #fff;
}
.dz-team.style-1:hover .dz-media:after,
.dz-team.style-1.active .dz-media:after {
  top: 0;
}

.dz-team.style-2 {
  position: relative;
  border-top: 6px solid var(--primary);
}
.dz-team.style-2 .dz-media {
  position: relative;
  overflow: hidden;
}
.dz-team.style-2 .dz-media img {
  height: 100%;
  object-fit: cover;
}
.dz-team.style-2 .dz-media .overlay-content {
  position: absolute;
}
.dz-team.style-2 .dz-media:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary-style-2-lifemember);
  border-radius: var(--border-radius-base);
  top: -460px;
  left: 0;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.dz-team.style-2 .dz-footer {
  background-color: var(--secondary);
  text-align: center;
  padding: 8px;
}
.dz-team.style-2 .team-social li {
  display: inline-block;
  position: relative;
}
.dz-team.style-2 .team-social li a {
  display: inline-block;
  margin: 0 5px;
  height: 25px;
  line-height: 25px;
  width: 25px;
  border-radius: var(--border-radius-base);
  text-align: center;
  color: #fff;
  background-color: var(--secondary);
}
.dz-team.style-2 .team-social li a:hover {
  background-color: #fff;
  color: var(--secondary);
}
.dz-team.style-2 .dz-position {
  margin-bottom: 0;
  font-weight: 400;
  font-family: var(--font-family-base);
}
.dz-team.style-2 .dz-name {
  margin-bottom: 3px;
}
.dz-team.style-2 .dz-content {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}
.dz-team.style-2:hover,
.dz-team.style-2.active {
  box-shadow: 0 0rem 1.5rem rgba(0, 0, 0, 0.07) !important;
  background-color: #fff;
}
.dz-team.style-2:hover .team-social li,
.dz-team.style-2.active .team-social li {
  transform: translateY(0px);
  opacity: 1;
}
.dz-team.style-2:hover .dz-content,
.dz-team.style-2.active .dz-content {
  background-color: #fff;
}
.dz-team.style-2:hover .dz-media:after,
.dz-team.style-2.active .dz-media:after {
  top: 0;
}
@media only screen and (max-width: 575px) {
  .dz-team.style-2 {
    padding: 0;
  }
}

.team-slider {
  margin: 0 -15px;
}
.team-slider .dz-team {
  margin: 0 15px;
}

.blog-swiper {
  margin: -15px;
  width: auto;
}
.blog-swiper .swiper-slide > div {
  margin: 15px;
  height: 100%;
}

.dz-card {
  position: relative;
}
.dz-card .dz-info {
  padding: 30px;
  position: relative;
}
.dz-card .dz-title {
  margin-bottom: 15px;
  word-break: break-word;
}
@media only screen and (max-width: 575px) {
  .dz-card .dz-title {
    margin-bottom: 15px;
  }
}
.dz-card.blog-half {
  display: flex;
  margin-bottom: 30px;
}
.dz-card.blog-half .dz-info {
  padding: 30px;
  position: relative;
  flex: 1;
}
.dz-card.blog-half .dz-media {
  margin-bottom: 0;
  max-width: 300px;
  min-width: 300px;
}
.dz-card.blog-half .dz-media img {
  height: 100%;
  object-fit: cover;
  min-height: 150px;
}
@media only screen and (max-width: 575px) {
  .dz-card.blog-half .dz-media img {
    min-height: auto;
  }
}
.dz-card.blog-half.post-video .post-video-icon {
  width: 60px;
  height: 60px;
  font-size: 20px;
}
.dz-card.blog-half .swiper-container {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .dz-card.blog-half {
    display: block;
  }
  .dz-card.blog-half .dz-media {
    max-width: 100%;
    min-width: 100%;
  }
}

.dz-media.height-sm {
  height: 220px;
}
.dz-media.height-sm img {
  height: 100%;
  object-fit: cover;
}

.dz-media.height-md {
  height: 265px;
}
.dz-media.height-md img {
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 1199px) {
  .dz-media.height-md {
    height: 220px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-media.height-md {
    height: 200px;
  }
}

.dz-media.height-lg {
  height: 350px;
}
@media only screen and (max-width: 1199px) {
  .dz-media.height-lg {
    height: 300px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-media.height-lg {
    height: 220px;
  }
  .dz-media.height-lg img {
    height: 100%;
    object-fit: cover;
  }
}

.dz-meta {
  margin-bottom: 10px;
}
.dz-meta ul {
  margin: 0;
  padding: 0;
}
.dz-meta ul li {
  display: inline-block;
  color: #777;
  margin-right: 20px;
}
.dz-meta ul li:last-child {
  margin-right: 0;
}
.dz-meta ul li i {
  margin-right: 5px;
  color: var(--primary);
}
.dz-meta ul li a {
  color: #777;
}

.dz-badge {
  background-color: var(--primary);
  color: #fff;
  border-radius: 3px;
  padding: 6px 15px;
  font-size: 15px;
}
.dz-badge:hover {
  color: white;
}

.post-video .post-video-icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--primary);
  font-size: 30px;
  margin: 0 auto;
  background-color: #fff;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}
@media only screen and (max-width: 575px) {
  .post-video .post-video-icon {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}
.post-video .post-video-icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
.post-video .post-video-icon:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}

.dz-card.style-1 {
  border-radius: 15px;
  overflow: hidden;
  transition: all 0.8s;
  border: 1px solid var(--bs-gray-200);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #fff;
}
.dz-card.style-1 .dz-info {
  padding: 30px 25px;
}
@media only screen and (max-width: 575px) {
  .dz-card.style-1 .dz-info {
    padding: 20px 15px;
    padding-top: 20px;
  }
}
.dz-card.style-1 .dz-info p {
  margin-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .dz-card.style-1 .dz-info p {
    margin-bottom: 20px;
  }
}
.dz-card.style-1 .dz-media {
  overflow: hidden;
  position: relative;
  border-radius: unset;
}
.dz-card.style-1 .dz-media:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -100%;
  left: 0;
  background-color: var(--primary);
  opacity: 0.9;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.dz-card.style-1 .dz-media .dz-badge-list {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
}
.dz-card.style-1 .dz-media .dz-badge {
  background-color: var(--secondary);
  color: #fff;
  border-radius: var(--border-radius-base);
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.dz-card.style-1 .dz-media .dz-badge:hover {
  background-color: white;
  color: var(--primary);
}
.dz-card.style-1 .dz-media .btn,
.dz-card.style-1 .dz-media .wp-block-button__link {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
  z-index: 1;
}
.dz-card.style-1:hover .dz-media:after {
  bottom: 0;
}
.dz-card.style-1:hover .btn,
.dz-card.style-1:hover .wp-block-button__link {
  top: 50%;
}
.dz-card.style-1:hover .dz-badge {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.swiper-container .dz-card.style-1 {
  margin: 15px;
}

.dz-card.style-2 {
  margin: 0;
  border: 1px solid var(--bs-gray-300);
  border-radius: 14px;
  overflow: hidden;
  background-color: #fff;
}
.dz-card.style-2 .dz-media {
  border-radius: unset;
}
.dz-card.style-2 .dz-media .dz-badge-list {
  position: absolute;
  bottom: 25px;
  left: 20px;
}
@media only screen and (max-width: 991px) {
  .dz-card.style-2 .dz-media .dz-badge-list {
    bottom: 15px;
  }
}
.dz-card.style-2 .dz-media .dz-badge {
  background-color: var(--primary);
  color: #fff;
  border-radius: 3px;
  padding: 6px 15px;
  font-size: 12px;
  text-transform: uppercase;
}
.dz-card.style-2 .dz-info {
  padding: 25px 30px;
}
.dz-card.style-2 .dz-info .dz-category {
  display: inline-flex;
}
.dz-card.style-2 .dz-info .dz-category li {
  margin-right: 10px;
}
.dz-card.style-2 .dz-info .dz-user-rate ul,
.dz-card.style-2 .dz-info .dz-meta ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dz-card.style-2 .dz-info .dz-user-rate ul .dz-price i,
.dz-card.style-2 .dz-info .dz-meta ul .dz-price i {
  color: var(--primary);
  font-size: 16px !important;
}
.dz-card.style-2 .dz-info .dz-user-rate ul .dz-price span,
.dz-card.style-2 .dz-info .dz-meta ul .dz-price span {
  font-size: 14px;
  color: var(--title);
  font-weight: 400;
}
.dz-card.style-2 .dz-info .dz-user-rate ul .dz-date i,
.dz-card.style-2 .dz-info .dz-meta ul .dz-date i {
  color: var(--primary);
  font-size: 16px !important;
}
.dz-card.style-2 .dz-info .dz-user-rate ul .dz-date span,
.dz-card.style-2 .dz-info .dz-meta ul .dz-date span {
  font-size: 14px;
  color: var(--title);
  font-weight: 400;
}
.dz-card.style-2 .dz-info p {
  margin-bottom: 20px;
}
.dz-card.style-2 .dz-info .dz-user {
  display: flex;
  align-items: center;
}
.dz-card.style-2 .dz-info .dz-user img {
  height: 45px;
  width: 45px;
  margin-right: 10px;
  border: 3px solid white;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  object-fit: cover;
}
.dz-card.style-2 .dz-info .dz-ratings i {
  color: #ffc107;
  margin-right: 5px;
  font-size: 18px;
}
.dz-card.style-2 .dz-info .dz-price h4,
.dz-card.style-2 .dz-info .dz-price .h4 {
  color: var(--primary);
  margin: 0;
}
.dz-card.style-2 .dz-info .separator {
  height: 1px;
  width: 100%;
  background: lightgrey;
}
.dz-card.style-2 .dz-info .dz-view i {
  color: limegreen;
  margin-right: 5px;
  background: #90ee904f;
  padding: 7px;
  border-radius: 100%;
}
.dz-card.style-2 .dz-info .dz-category li a {
  font-weight: 700;
  font-size: 15px;
}
.dz-card.style-2 .dz-info .footer-bottom {
  margin-top: 20px;
}
.dz-card.style-2 .dz-info .footer-bottom a i {
  font-size: 14px;
}
@media only screen and (max-width: 991px) {
  .dz-card.style-2 .dz-info {
    padding: 20px 20px;
  }
}
.dz-card.style-2.card-list {
  display: flex;
}
.dz-card.style-2.card-list .dz-media {
  width: 350px;
  min-width: 350px;
}
.dz-card.style-2.card-list .dz-media img {
  height: 100%;
  object-fit: cover;
}
@media screen and (min-width: 991px) and (max-width: 1191px) {
  .dz-card.style-2.card-list {
    display: block;
  }
  .dz-card.style-2.card-list .dz-media {
    width: 100%;
    min-width: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .dz-card.style-2.card-list .dz-media {
    width: 300px;
    min-width: 300px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-card.style-2.card-list {
    display: block;
  }
  .dz-card.style-2.card-list .dz-media {
    width: 100%;
    min-width: 100%;
  }
}

.dz-card.style-3 {
  position: relative;
  border-radius: var(--border-radius-base);
  overflow: hidden;
  background: #fff;
  height: 100%;
}
.dz-card.style-3 .dz-media {
  height: 100%;
}
.dz-card.style-3 .dz-media:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.67) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.67) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.67) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#ab000000',GradientType=0 );
}
.dz-card.style-3 .dz-media img {
  height: 100%;
  object-fit: cover;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.dz-card.style-3 .dz-info {
  padding: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.dz-card.style-3 .dz-info .dz-text {
  color: #fff;
}
.dz-card.style-3 .dz-category {
  margin-bottom: 15px;
}
.dz-card.style-3 .dz-category li a {
  display: inline-block;
  background: rgba(255, 255, 255, 0.19);
  color: #fff;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  border-radius: var(--border-radius-base);
}
.dz-card.style-3 .dz-category li a:hover {
  background: var(--primary);
}
.dz-card.style-3:hover .dz-media img {
  transform: rotate(5deg) scale(1.2);
}
@media only screen and (max-width: 575px) {
  .dz-card.style-3 {
    height: 300px;
  }
  .dz-card.style-3 .dz-info {
    padding: 20px;
  }
  .dz-card.style-3 .dz-info .dz-text {
    margin-bottom: 0;
  }
  .dz-card.style-3 .dz-title {
    margin-bottom: 10px;
  }
  .dz-card.style-3 .dz-category {
    margin-bottom: 20px;
  }
}
.dz-card.style-3.overlay .dz-meta li .post-date {
  color: rgba(255, 255, 255, 0.8);
}

.dz-card.style-4 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  border-radius: var(--border-radius-base);
  height: 100%;
  margin-top: 20px;
}
.dz-card.style-4:after {
  background-color: var(--dark);
  border-radius: var(--border-radius-base);
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
  border: 2px solid var(--dark);
}
.dz-card.style-4 .dz-meta {
  margin-bottom: 20px;
}
.dz-card.style-4 .dz-meta ul li {
  color: #fff;
  position: relative;
  font-size: 15px;
  margin-right: 30px;
}
.dz-card.style-4 .dz-meta ul li:after {
  content: "";
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background-color: #fff;
  right: -20px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.dz-card.style-4 .dz-meta ul li:nth-last-child(2),
.dz-card.style-4 .dz-meta ul li:last-child {
  margin-right: 0;
}
.dz-card.style-4 .dz-meta ul li:nth-last-child(2):after,
.dz-card.style-4 .dz-meta ul li:last-child:after {
  content: none;
}
.dz-card.style-4 .dz-meta .post-author img {
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  width: 35px;
  height: 35px;
}
.dz-card.style-4 .dz-meta .post-date {
  background-color: var(--primary);
  padding: 10px;
  display: inline-block;
  text-align: center;
  border-radius: var(--border-radius-base);
  position: absolute;
  left: 25px;
  top: -15px;
}
.dz-card.style-4 .dz-meta .post-date .day {
  font-size: 45px;
  color: #fff;
  font-weight: 700;
  display: block;
  line-height: 45px;
}
.dz-card.style-4 .dz-meta .post-date .month {
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
}
@media only screen and (max-width: 991px) {
  .dz-card.style-4 .dz-meta {
    margin-bottom: 10px;
  }
  .dz-card.style-4 .dz-meta .post-author img {
    /* display: none; */
  }
}
@media only screen and (max-width: 575px) {
  .dz-card.style-4 .dz-meta .post-date .day {
    font-size: 35px;
    line-height: 35px;
  }
}
.dz-card.style-4 .dz-info {
  border-radius: var(--border-radius-base);
  padding: 105px 25px 25px;
  z-index: 1;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.dz-card.style-4 .dz-info p {
  color: #fff;
  margin-bottom: 25px;
  opacity: 0.7;
}
.dz-card.style-4 .dz-info .dz-title {
  margin-bottom: 10px;
}
.dz-card.style-4 .dz-info .dz-title a {
  color: #fff;
}
@media only screen and (max-width: 575px) {
  .dz-card.style-4 .dz-info {
    padding: 85px 25px 25px;
  }
}
.dz-card.style-4:hover:after {
  opacity: 0.8;
  border-color: var(--primary);
}

.dz-card.style-5 {
  border: 1px solid var(--bs-gray-300);
  overflow: hidden;
  border-radius: 14px;
  overflow: hidden;
  margin: 0;
}
.dz-card.style-5 .dz-media {
  border-radius: unset;
}
.dz-card.style-5 .dz-media .dz-badge-list {
  position: absolute;
  bottom: 25px;
  left: 20px;
}
@media only screen and (max-width: 991px) {
  .dz-card.style-5 .dz-media .dz-badge-list {
    bottom: 15px;
  }
}
.dz-card.style-5 .dz-media .dz-badge {
  background-color: var(--primary);
  color: #fff;
  border-radius: 3px;
  padding: 6px 15px;
  font-size: 12px;
  text-transform: uppercase;
}
.dz-card.style-5 .author-wrappper .author-media {
  height: 24px;
  width: 24px;
  min-width: 24px;
}
.dz-card.style-5 .dz-info {
  padding: 25px 30px;
  background-color: #fff;
}
@media only screen and (max-width: 575px) {
  .dz-card.style-5 .dz-info {
    padding: 20px;
  }
}
.dz-card.style-5 .dz-info .dz-category li a {
  font-size: 14px;
  font-weight: 600;
}
.dz-card.style-5 .dz-info .dz-meta {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
.dz-card.style-5 .dz-info .dz-meta span {
  font-size: 14px;
  font-weight: 400;
  color: var(--title);
}
.dz-card.style-5 .dz-info .dz-meta .author-media {
  display: flex;
  align-items: center;
}
.dz-card.style-5 .dz-info .dz-meta .dz-date {
  margin-left: 20px;
}
.dz-card.style-5 .dz-info .dz-meta .dz-date i {
  color: var(--primary);
}
.dz-card.style-5 .dz-info p {
  margin-bottom: 0 !important;
}

.dz-card.style-6 {
  border-radius: var(--border-radius-base);
  overflow: hidden;
  transition: all 0.8s;
  border: 1px solid transparent;
  background-color: var(--bs-gray-200);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-card.style-6 .dz-info {
  padding: 30px 25px;
}
@media only screen and (max-width: 575px) {
  .dz-card.style-6 .dz-info {
    padding: 25px 20px;
  }
}
.dz-card.style-6 .dz-info p {
  margin-bottom: 0;
}
.dz-card.style-6 .dz-meta {
  margin-bottom: 20px;
}
.dz-card.style-6 .dz-meta ul li {
  font-size: 14px;
  font-weight: 500;
  position: relative;
  margin-right: 30px;
}
.dz-card.style-6 .dz-meta ul li.dz-user {
  color: var(--title);
  font-weight: 500;
}
.dz-card.style-6 .dz-meta ul li.dz-user span {
  color: var(--primary);
}
.dz-card.style-6 .dz-meta ul li.dz-date {
  color: var(--title);
  font-weight: 500;
}
.dz-card.style-6 .dz-meta ul li:last-child {
  margin-right: 0;
}
.dz-card.style-6 .dz-meta ul li:not(:last-child):after {
  content: "";
  height: 6px;
  width: 6px;
  position: absolute;
  right: -20px;
  top: 50%;
  border-radius: 50%;
  background-color: var(--primary);
  transform: translate(0%, -50%);
}
.dz-card.style-6 .dz-media {
  overflow: hidden;
  position: relative;
  border-radius: unset;
}
.dz-card.style-6 .dz-media:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: -100%;
  left: 0;
  background-color: var(--primary);
  opacity: 0.9;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.dz-card.style-6 .dz-media .dz-badge-list {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}
.dz-card.style-6 .dz-media .dz-badge {
  background-color: var(--secondary);
  color: var(--title);
  border-radius: var(--border-radius-base);
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  display: block;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.dz-card.style-6 .dz-media .dz-badge:hover {
  background-color: white;
  color: var(--primary);
}
.dz-card.style-6 .dz-media .btn,
.dz-card.style-6 .dz-media .wp-block-button__link {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .dz-card.style-6.blog-half {
    display: block;
  }
  .dz-card.style-6.blog-half .dz-media {
    max-width: 100%;
    min-width: 100%;
  }
}
.dz-card.style-6:hover .dz-media:after {
  bottom: 0;
}
.dz-card.style-6:hover .btn,
.dz-card.style-6:hover .wp-block-button__link {
  top: 50%;
}
.dz-card.style-6:hover .dz-badge {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.dz-card.style-7 {
  position: relative;
  border-radius: var(--border-radius-base);
  overflow: hidden;
  background-color: #003b4a;
  height: 100%;
  background-blend-mode: luminosity;
  min-height: 600px;
  background-size: cover;
  object-fit: cover;
}
.dz-card.style-7:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: -moz-linear-gradient(top, rgba(0, 59, 74, 0) 0%, #003b4a 100%);
  background: -webkit-linear-gradient(top, rgba(0, 59, 74, 0) 0%, #003b4a 100%);
  background: linear-gradient(to bottom, rgba(0, 59, 74, 0) 0%, #003b4a 100%);
  opacity: 0.9;
}
.dz-card.style-7 .dz-media {
  height: 100%;
  position: relative;
}
.dz-card.style-7 .dz-media img {
  filter: grayscale(0.1);
  height: 100%;
  object-fit: cover;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.dz-card.style-7 .dz-info {
  padding: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.dz-card.style-7 .dz-info .dz-meta {
  margin-bottom: 0;
}
.dz-card.style-7 .dz-info .dz-meta ul li {
  position: relative;
}
.dz-card.style-7 .dz-info .dz-meta ul li.dz-user {
  color: var(--title);
  font-weight: 500;
  color: #fff;
}
.dz-card.style-7 .dz-info .dz-meta ul li.dz-user span {
  color: var(--secondary);
}
.dz-card.style-7 .dz-info .dz-meta ul li.dz-user i {
  color: var(--secondary);
}
.dz-card.style-7 .dz-info .dz-meta ul li.dz-date {
  color: var(--title);
  font-weight: 500;
  color: #fff;
}
.dz-card.style-7 .dz-info .dz-meta ul li.dz-date i {
  color: var(--secondary);
}
.dz-card.style-7 .dz-info .dz-meta ul li:not(:last-child):after {
  content: "";
  height: 6px;
  width: 6px;
  position: absolute;
  right: -10%;
  top: 50%;
  border-radius: 50%;
  background-color: var(--primary);
  transform: translate(0%, -50%);
}
.dz-card.style-7 .dz-category {
  margin-bottom: 15px;
  display: inline-flex;
}
.dz-card.style-7 .dz-category .dz-badge-list {
  position: absolute;
  top: 40px;
  left: 40px;
  z-index: 1;
}
.dz-card.style-7 .dz-category .dz-badge-list li .dz-badge {
  background-color: var(--secondary);
  color: var(--title);
  font-weight: 500;
  border-radius: var(--border-radius-base);
  font-size: 15px;
  text-transform: uppercase;
  display: block;
}
.dz-card.style-7 .dz-category .dz-badge-list li .dz-badge:hover {
  background-color: white;
  color: var(--primary);
}
.dz-card.style-7:hover .dz-media img {
  transform: rotate(5deg) scale(1.2);
}
@media only screen and (max-width: 575px) {
  .dz-card.style-7 {
    min-height: 450px;
  }
  .dz-card.style-7 .dz-info {
    padding: 20px;
  }
  .dz-card.style-7 .dz-info .dz-text {
    margin-bottom: 0;
  }
  .dz-card.style-7 .dz-title {
    margin-bottom: 10px;
  }
  .dz-card.style-7 .dz-category {
    margin-bottom: 20px;
  }
}
.dz-card.style-7.overlay {
  background-color: #000;
  opacity: 0.9;
  z-index: 1;
}
.dz-card.style-7.overlay .dz-meta li .post-date {
  color: rgba(255, 255, 255, 0.8);
}

/*=== Pages ===*/
.shadow-secondary {
  box-shadow: 0px 10px 40px 15px rgba(0, 150, 255, 0.2);
}

.shadow-info {
  box-shadow: 0px 12px 20px 0px rgba(6, 163, 245, 0.3);
}

.call-action.style-1 .inner-action {
  border-radius: 40px !important;
  margin: 0 -135px;
  text-align: center;
  padding: 100px 60px;
  overflow: hidden;
  background-attachment: fixed;
}
.call-action.style-1 .inner-action .dzSubscribeMsg .alert {
  border-radius: 50px;
}
.call-action.style-1 .inner-action .inner-content {
  z-index: 1;
  position: relative;
}
.call-action.style-1 .inner-action .inner-content .form-control,
.call-action.style-1
  .inner-action
  .inner-content
  .default-select.style-2
  .btn.dropdown-toggle,
.default-select.style-2
  .call-action.style-1
  .inner-action
  .inner-content
  .btn.dropdown-toggle,
.call-action.style-1
  .inner-action
  .inner-content
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link,
.default-select.style-2
  .call-action.style-1
  .inner-action
  .inner-content
  .dropdown-toggle.wp-block-button__link,
.call-action.style-1
  .inner-action
  .inner-content
  .default-select.style-3
  .btn.dropdown-toggle,
.default-select.style-3
  .call-action.style-1
  .inner-action
  .inner-content
  .btn.dropdown-toggle,
.call-action.style-1
  .inner-action
  .inner-content
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link,
.default-select.style-3
  .call-action.style-1
  .inner-action
  .inner-content
  .dropdown-toggle.wp-block-button__link,
.call-action.style-1
  .inner-action
  .inner-content
  .wp-block-categories-dropdown
  select,
.wp-block-categories-dropdown
  .call-action.style-1
  .inner-action
  .inner-content
  select,
.call-action.style-1
  .inner-action
  .inner-content
  .wp-block-archives-dropdown
  select,
.wp-block-archives-dropdown
  .call-action.style-1
  .inner-action
  .inner-content
  select,
.call-action.style-1
  .inner-action
  .inner-content
  .comment-respond
  .comment-form
  p.comment-form-author
  input,
.comment-respond
  .comment-form
  p.comment-form-author
  .call-action.style-1
  .inner-action
  .inner-content
  input,
.call-action.style-1
  .inner-action
  .inner-content
  .comment-respond
  .comment-form
  p.comment-form-email
  input,
.comment-respond
  .comment-form
  p.comment-form-email
  .call-action.style-1
  .inner-action
  .inner-content
  input,
.call-action.style-1
  .inner-action
  .inner-content
  .comment-respond
  .comment-form
  p.comment-form-url
  input,
.comment-respond
  .comment-form
  p.comment-form-url
  .call-action.style-1
  .inner-action
  .inner-content
  input,
.call-action.style-1
  .inner-action
  .inner-content
  .comment-respond
  .comment-form
  p
  textarea,
.comment-respond
  .comment-form
  p
  .call-action.style-1
  .inner-action
  .inner-content
  textarea {
  background: transparent;
  border-radius: 30px !important;
  border: 1px solid #fff;
  margin-right: 10px;
  color: #fff;
  padding: 10px 25px;
}
.call-action.style-1 .inner-action .inner-content .form-control::placeholder,
.call-action.style-1
  .inner-action
  .inner-content
  .default-select.style-2
  .btn.dropdown-toggle::placeholder,
.default-select.style-2
  .call-action.style-1
  .inner-action
  .inner-content
  .btn.dropdown-toggle::placeholder,
.call-action.style-1
  .inner-action
  .inner-content
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-2
  .call-action.style-1
  .inner-action
  .inner-content
  .dropdown-toggle.wp-block-button__link::placeholder,
.call-action.style-1
  .inner-action
  .inner-content
  .default-select.style-3
  .btn.dropdown-toggle::placeholder,
.default-select.style-3
  .call-action.style-1
  .inner-action
  .inner-content
  .btn.dropdown-toggle::placeholder,
.call-action.style-1
  .inner-action
  .inner-content
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3
  .call-action.style-1
  .inner-action
  .inner-content
  .dropdown-toggle.wp-block-button__link::placeholder,
.call-action.style-1
  .inner-action
  .inner-content
  .wp-block-categories-dropdown
  select::placeholder,
.wp-block-categories-dropdown
  .call-action.style-1
  .inner-action
  .inner-content
  select::placeholder,
.call-action.style-1
  .inner-action
  .inner-content
  .wp-block-archives-dropdown
  select::placeholder,
.wp-block-archives-dropdown
  .call-action.style-1
  .inner-action
  .inner-content
  select::placeholder,
.call-action.style-1
  .inner-action
  .inner-content
  .comment-respond
  .comment-form
  p.comment-form-author
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .call-action.style-1
  .inner-action
  .inner-content
  input::placeholder,
.call-action.style-1
  .inner-action
  .inner-content
  .comment-respond
  .comment-form
  p.comment-form-email
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .call-action.style-1
  .inner-action
  .inner-content
  input::placeholder,
.call-action.style-1
  .inner-action
  .inner-content
  .comment-respond
  .comment-form
  p.comment-form-url
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .call-action.style-1
  .inner-action
  .inner-content
  input::placeholder,
.call-action.style-1
  .inner-action
  .inner-content
  .comment-respond
  .comment-form
  p
  textarea::placeholder,
.comment-respond
  .comment-form
  p
  .call-action.style-1
  .inner-action
  .inner-content
  textarea::placeholder {
  color: #fff;
}
.call-action.style-1 .inner-action .inner-content .input-group-addon .btn i,
.call-action.style-1
  .inner-action
  .inner-content
  .input-group-addon
  .wp-block-button__link
  i {
  display: none;
}
@media only screen and (max-width: 575px) {
  .call-action.style-1 .inner-action .inner-content .input-group-addon .btn,
  .call-action.style-1
    .inner-action
    .inner-content
    .input-group-addon
    .wp-block-button__link {
    padding: 14px 15px;
  }
  .call-action.style-1
    .inner-action
    .inner-content
    .input-group-addon
    .btn
    span,
  .call-action.style-1
    .inner-action
    .inner-content
    .input-group-addon
    .wp-block-button__link
    span {
    display: none;
  }
  .call-action.style-1 .inner-action .inner-content .input-group-addon .btn i,
  .call-action.style-1
    .inner-action
    .inner-content
    .input-group-addon
    .wp-block-button__link
    i {
    display: block;
  }
}

@media only screen and (max-width: 1480px) {
  .call-action.style-1 .inner-action {
    margin: 0 -50px;
  }
}

@media only screen and (max-width: 1280px) {
  .call-action.style-1 .inner-action {
    margin: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .call-action.style-1 .inner-action {
    padding: 70px 50px;
  }
}

@media only screen and (max-width: 767px) {
  .call-action.style-1 .inner-action {
    border-radius: 15px !important;
    padding: 40px;
  }
  .call-action.style-1 .inner-action .inner-content .section-head .title {
    font-size: 1.5rem !important;
  }
}

@media only screen and (max-width: 575px) {
  .call-action.style-1 .inner-action {
    padding: 30px;
  }
  .call-action.style-1 .inner-action .inner-content .section-head .title {
    font-size: 1.25rem !important;
  }
  .call-action.style-1
    .inner-action
    .inner-content
    .input-group
    .input-group-addon
    .btn,
  .call-action.style-1
    .inner-action
    .inner-content
    .input-group
    .input-group-addon
    .wp-block-button__link {
    width: 55px;
    height: 55px;
    text-align: center;
    justify-content: center;
  }
}

.dz-subscribe .dz-subscribe.style-1::after {
  content: none !important;
}

.dz-subscribe.style-1 {
  border-radius: var(--border-radius-base);
  padding: 55px 60px;
}
.dz-subscribe.style-1 .form-head {
  position: relative;
  padding: 0 0px 0 75px;
}
.dz-subscribe.style-1 .form-head img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.dz-subscribe.style-1 .form-head .info .title {
  font-size: 30px;
  margin-bottom: 5px;
}
.dz-subscribe.style-1 .form-head .info p {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
}
.dz-subscribe.style-1 .dzSubscribe .form-control,
.dz-subscribe.style-1 .dzSubscribe .default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .dz-subscribe.style-1 .dzSubscribe .btn.dropdown-toggle,
.dz-subscribe.style-1
  .dzSubscribe
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link,
.default-select.style-2
  .dz-subscribe.style-1
  .dzSubscribe
  .dropdown-toggle.wp-block-button__link,
.dz-subscribe.style-1 .dzSubscribe .default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .dz-subscribe.style-1 .dzSubscribe .btn.dropdown-toggle,
.dz-subscribe.style-1
  .dzSubscribe
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link,
.default-select.style-3
  .dz-subscribe.style-1
  .dzSubscribe
  .dropdown-toggle.wp-block-button__link,
.dz-subscribe.style-1 .dzSubscribe .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dz-subscribe.style-1 .dzSubscribe select,
.dz-subscribe.style-1 .dzSubscribe .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dz-subscribe.style-1 .dzSubscribe select,
.dz-subscribe.style-1
  .dzSubscribe
  .comment-respond
  .comment-form
  p.comment-form-author
  input,
.comment-respond
  .comment-form
  p.comment-form-author
  .dz-subscribe.style-1
  .dzSubscribe
  input,
.dz-subscribe.style-1
  .dzSubscribe
  .comment-respond
  .comment-form
  p.comment-form-email
  input,
.comment-respond
  .comment-form
  p.comment-form-email
  .dz-subscribe.style-1
  .dzSubscribe
  input,
.dz-subscribe.style-1
  .dzSubscribe
  .comment-respond
  .comment-form
  p.comment-form-url
  input,
.comment-respond
  .comment-form
  p.comment-form-url
  .dz-subscribe.style-1
  .dzSubscribe
  input,
.dz-subscribe.style-1 .dzSubscribe .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .dz-subscribe.style-1 .dzSubscribe textarea {
  border-radius: var(--border-radius-base) !important;
  height: 70px;
  z-index: 1;
  padding-right: 200px;
}
.dz-subscribe.style-1 .dzSubscribe .input-group {
  margin-bottom: 0;
}
.dz-subscribe.style-1 .dzSubscribe .input-group-addon {
  position: absolute;
  right: 5px;
  top: 5px;
}
@media only screen and (max-width: 1200px) {
  .dz-subscribe.style-1 {
    padding: 40px 45px;
  }
  .dz-subscribe.style-1 .form-head .info .title {
    font-size: 25px;
  }
  .dz-subscribe.style-1 .form-head .info p {
    font-size: 16px;
  }
  .dz-subscribe.style-1 .dzSubscribe .form-control,
  .dz-subscribe.style-1
    .dzSubscribe
    .default-select.style-2
    .btn.dropdown-toggle,
  .default-select.style-2
    .dz-subscribe.style-1
    .dzSubscribe
    .btn.dropdown-toggle,
  .dz-subscribe.style-1
    .dzSubscribe
    .default-select.style-2
    .dropdown-toggle.wp-block-button__link,
  .default-select.style-2
    .dz-subscribe.style-1
    .dzSubscribe
    .dropdown-toggle.wp-block-button__link,
  .dz-subscribe.style-1
    .dzSubscribe
    .default-select.style-3
    .btn.dropdown-toggle,
  .default-select.style-3
    .dz-subscribe.style-1
    .dzSubscribe
    .btn.dropdown-toggle,
  .dz-subscribe.style-1
    .dzSubscribe
    .default-select.style-3
    .dropdown-toggle.wp-block-button__link,
  .default-select.style-3
    .dz-subscribe.style-1
    .dzSubscribe
    .dropdown-toggle.wp-block-button__link,
  .dz-subscribe.style-1 .dzSubscribe .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .dz-subscribe.style-1 .dzSubscribe select,
  .dz-subscribe.style-1 .dzSubscribe .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .dz-subscribe.style-1 .dzSubscribe select,
  .dz-subscribe.style-1
    .dzSubscribe
    .comment-respond
    .comment-form
    p.comment-form-author
    input,
  .comment-respond
    .comment-form
    p.comment-form-author
    .dz-subscribe.style-1
    .dzSubscribe
    input,
  .dz-subscribe.style-1
    .dzSubscribe
    .comment-respond
    .comment-form
    p.comment-form-email
    input,
  .comment-respond
    .comment-form
    p.comment-form-email
    .dz-subscribe.style-1
    .dzSubscribe
    input,
  .dz-subscribe.style-1
    .dzSubscribe
    .comment-respond
    .comment-form
    p.comment-form-url
    input,
  .comment-respond
    .comment-form
    p.comment-form-url
    .dz-subscribe.style-1
    .dzSubscribe
    input,
  .dz-subscribe.style-1 .dzSubscribe .comment-respond .comment-form p textarea,
  .comment-respond .comment-form p .dz-subscribe.style-1 .dzSubscribe textarea {
    height: 62px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-subscribe.style-1 .form-head {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-subscribe.style-1 {
    padding: 15px 15px;
  }
  .dz-subscribe.style-1 .form-head {
    padding: 0 0px 0 70px;
  }
  .dz-subscribe.style-1 .form-head .info .title {
    font-size: 20px;
  }
  .dz-subscribe.style-1 .form-head .info p {
    font-size: 15px;
  }
  .dz-subscribe.style-1 .btn,
  .dz-subscribe.style-1 .wp-block-button__link {
    padding: 8px 10px;
    font-size: 14px;
  }
  .dz-subscribe.style-1 .dzSubscribe .form-control,
  .dz-subscribe.style-1
    .dzSubscribe
    .default-select.style-2
    .btn.dropdown-toggle,
  .default-select.style-2
    .dz-subscribe.style-1
    .dzSubscribe
    .btn.dropdown-toggle,
  .dz-subscribe.style-1
    .dzSubscribe
    .default-select.style-2
    .dropdown-toggle.wp-block-button__link,
  .default-select.style-2
    .dz-subscribe.style-1
    .dzSubscribe
    .dropdown-toggle.wp-block-button__link,
  .dz-subscribe.style-1
    .dzSubscribe
    .default-select.style-3
    .btn.dropdown-toggle,
  .default-select.style-3
    .dz-subscribe.style-1
    .dzSubscribe
    .btn.dropdown-toggle,
  .dz-subscribe.style-1
    .dzSubscribe
    .default-select.style-3
    .dropdown-toggle.wp-block-button__link,
  .default-select.style-3
    .dz-subscribe.style-1
    .dzSubscribe
    .dropdown-toggle.wp-block-button__link,
  .dz-subscribe.style-1 .dzSubscribe .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .dz-subscribe.style-1 .dzSubscribe select,
  .dz-subscribe.style-1 .dzSubscribe .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .dz-subscribe.style-1 .dzSubscribe select,
  .dz-subscribe.style-1
    .dzSubscribe
    .comment-respond
    .comment-form
    p.comment-form-author
    input,
  .comment-respond
    .comment-form
    p.comment-form-author
    .dz-subscribe.style-1
    .dzSubscribe
    input,
  .dz-subscribe.style-1
    .dzSubscribe
    .comment-respond
    .comment-form
    p.comment-form-email
    input,
  .comment-respond
    .comment-form
    p.comment-form-email
    .dz-subscribe.style-1
    .dzSubscribe
    input,
  .dz-subscribe.style-1
    .dzSubscribe
    .comment-respond
    .comment-form
    p.comment-form-url
    input,
  .comment-respond
    .comment-form
    p.comment-form-url
    .dz-subscribe.style-1
    .dzSubscribe
    input,
  .dz-subscribe.style-1 .dzSubscribe .comment-respond .comment-form p textarea,
  .comment-respond .comment-form p .dz-subscribe.style-1 .dzSubscribe textarea {
    height: 49px;
    padding-right: 150px;
  }
}

.dz-social-icon ul {
  margin: 0 -5px;
}
.dz-social-icon ul li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
.dz-social-icon ul li a {
  font-size: 18px;
  color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-social-icon ul li a:hover {
  color: var(--primary);
}

.dz-social-icon.dark ul li a {
  color: #050d36;
}

.dz-social-icon.style-1 ul li {
  display: inline-block;
}
.dz-social-icon.style-1 ul li a {
  color: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  height: 45px;
  width: 45px;
  min-width: 45px;
  text-align: center;
  line-height: 45px;
  z-index: 1;
  border-radius: var(--border-radius-base);
  background-color: rgba(255, 255, 255, 0.05);
  overflow: hidden;
}
.dz-social-icon.style-1 ul li a:after {
  content: "";
  position: absolute;
  height: 45px;
  width: 0;
  background-color: var(--secondary);
  left: 0px;
  top: 0;
  z-index: -1;
  border-radius: var(--border-radius-base);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-social-icon.style-1 ul li a:hover {
  color: white;
}
.dz-social-icon.style-1 ul li a:hover:after {
  width: 45px;
}
@media only screen and (max-width: 767px) {
  .dz-social-icon.style-1 ul li a {
    height: 40px;
    width: 40px;
    min-width: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}

.dz-social-icon.style-2 ul li a {
  height: 45px;
  min-width: 45px;
  line-height: 45px;
  font-size: 18px;
  text-align: center;
  border-radius: var(--border-radius-base);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border: 1px solid black;
  color: black;
}
.dz-social-icon.style-2 ul li a:hover {
  border-color: var(--primary);
  background-color: var(--primary);
  color: white;
  box-shadow: 0px 12px 20px 0px var(--rgba-primary-3);
}
@media only screen and (max-width: 767px) {
  .dz-social-icon.style-2 ul li a {
    height: 40px;
    width: 40px;
    min-width: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}

.dz-social-icon.style-3 ul li {
  display: inline-block;
}
.dz-social-icon.style-3 ul li a {
  color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  height: 45px;
  width: 45px;
  min-width: 45px;
  text-align: center;
  line-height: 45px;
  z-index: 1;
  border-radius: var(--border-radius-base);
  background-color: var(--bs-warning);
  overflow: hidden;
}
.dz-social-icon.style-3 ul li a:after {
  content: "";
  position: absolute;
  background-color: #e8b450;
  height: 45px;
  width: 0;
  left: 0px;
  top: 0;
  z-index: -1;
  border-radius: var(--border-radius-base);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-social-icon.style-3 ul li a:hover {
  color: black;
}
.dz-social-icon.style-3 ul li a:hover:after {
  width: 45px;
}
@media only screen and (max-width: 767px) {
  .dz-social-icon.style-3 ul li a {
    height: 40px;
    width: 40px;
    min-width: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}

.clients-section-1 {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media only screen and (max-width: 575px) {
  .clients-section-1 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.clients-box {
  border-radius: var(--border-radius-base);
  padding: 30px 30px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .clients-box {
    padding: 20px 25px;
  }
}
@media only screen and (max-width: 575px) {
  .clients-box {
    padding: 15px 20px;
  }
}

.clients-wrapper {
  padding: 60px 0;
}
.clients-wrapper .swiper-wrapper {
  align-items: center;
}
.clients-wrapper .swiper-wrapper .clients-logo {
  cursor: pointer;
}
.clients-wrapper .swiper-wrapper .clients-logo img {
  margin: auto;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
  max-width: 100%;
}
.clients-wrapper .swiper-wrapper .clients-logo .logo-main {
  opacity: 0.5;
}
.clients-wrapper .swiper-wrapper .clients-logo:hover .logo-main {
  opacity: 1;
  transform: scale(1.1);
}

.features-wraper {
  overflow: hidden;
}
.features-wraper .dz-media {
  height: 100%;
  width: 50vw;
}
@media only screen and (max-width: 767px) {
  .features-wraper .dz-media {
    height: auto;
    width: 100%;
    margin-bottom: 50px;
  }
}
.features-wraper .dz-media img {
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 767px) {
  .features-wraper .dz-media img {
    height: auto;
  }
}

.dz-media.style-1 {
  position: relative;
}
@media only screen and (max-width: 991px) {
  .dz-media.style-1 {
    margin-right: 0;
  }
}
.dz-media.style-1 img {
  max-width: 75%;
  margin: auto;
  display: block;
}
@media only screen and (max-width: 767px) {
  .dz-media.style-1 img {
    max-width: 50%;
  }
}
@media only screen and (max-width: 575px) {
  .dz-media.style-1 img {
    max-width: 70%;
  }
}
.dz-media.style-1:after {
  content: "";
  position: absolute;
  height: 40%;
  width: 100%;
  border-radius: 50px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: var(--secondary);
  z-index: -1;
}
@media only screen and (max-width: 991px) {
  .dz-media.style-1:after {
    border-radius: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-media.style-1:after {
    width: 75%;
  }
}
@media only screen and (max-width: 575px) {
  .dz-media.style-1:after {
    width: 100%;
  }
}

.dz-media.style-2 {
  height: 500px;
  width: 100%;
  padding: 20px;
  position: relative;
}
.dz-media.style-2 img {
  object-fit: cover;
  height: 100%;
  border-radius: var(--border-radius-base);
}
.dz-media.style-2:after,
.dz-media.style-2:before {
  content: "";
  position: absolute;
  height: 170px;
  width: 170px;
  border-radius: var(--border-radius-base);
  z-index: -1;
}
.dz-media.style-2:after {
  background: var(--primary);
  top: 0;
  left: 0;
}
.dz-media.style-2:before {
  background: var(--secondary);
  bottom: 0;
  right: 0;
}
@media only screen and (max-width: 575px) {
  .dz-media.style-2 {
    height: 400px;
  }
  .dz-media.style-2:after,
  .dz-media.style-2:before {
    height: 150px;
    width: 150px;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary);
}

.dz-gallery-box {
  overflow: hidden;
  border-radius: var(--border-radius-base);
}
.dz-gallery-box .dz-info {
  position: absolute;
  bottom: 30px;
  left: 30px;
  background-color: #050d36;
  right: 30px;
  padding: 20px 30px;
  -webkit-clip-path: inset(0 100% 0 0);
  opacity: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-gallery-box .dz-info .title {
  color: #fff;
  margin-bottom: 5px;
}
.dz-gallery-box .dz-info .title a {
  color: #fff;
}
.dz-gallery-box .dz-info span {
  color: #fff;
  font-size: 14px;
  opacity: 0.8;
}
.dz-gallery-box:hover .dz-info {
  -webkit-clip-path: inset(0 0 0 0);
  opacity: 1;
}

.dz-effect {
  overflow: hidden;
  border-radius: var(--border-radius-base);
}
.dz-effect:after {
  content: "";
  position: absolute;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  opacity: 0.9;
  background-color: var(--secondary);
  border-radius: var(--border-radius-base);
  transform: scale(0);
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.dz-effect .lightimg {
  height: 50px;
  width: 50px;
  line-height: 50px;
  color: var(--primary);
  display: block;
  margin: auto;
  background-color: #fff;
  border-radius: var(--border-radius-base);
}
.dz-effect:hover:after {
  transform: scale(1);
}

.clients-swiper-1 {
  width: 80%;
  margin-left: 0;
  margin-top: 00px;
}

.author-wrappper {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.author-wrappper .author-media {
  height: 50px;
  width: 50px;
  min-width: 45px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}
.author-wrappper .author-content .author-head {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.author-wrappper .author-content .author-head .author-name {
  float: left;
  margin-right: 10px;
  margin-bottom: 0;
  font-size: 15px;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.author-wrappper .author-content .author-head .author-name:hover {
  color: var(--primary);
}
.author-wrappper .author-content .author-head .rating-list li {
  display: inline-block;
  font-size: 13px;
  color: #fe8d4d;
}
.author-wrappper .author-content .author-meta {
  margin: 0 -10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.author-wrappper .author-content .author-meta li {
  display: inline-block;
  position: relative;
  padding: 0 10px;
  font-size: 14px;
}
.author-wrappper .author-content .author-meta li.date {
  color: var(--primary);
  font-weight: 500;
}
.author-wrappper .author-content .author-meta li.campaign {
  color: var(--primary);
  font-weight: 500;
}
.author-wrappper .author-content .author-meta li:not(:last-child):after {
  content: "";
  height: 5px;
  width: 5px;
  position: absolute;
  right: -3px;
  top: 50%;
  border-radius: 50%;
  background-color: var(--bs-body-color);
  transform: translateY(-50%);
}
@media only screen and (max-width: 991px) {
  .author-wrappper .author-content .author-meta li {
    padding: 0 7px;
  }
  .author-wrappper .author-media {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .author-wrappper {
    margin-top: 10px;
  }
}

.project-bx .dz-content {
  padding-left: 20px;
}
@media only screen and (max-width: 991px) {
  .project-bx .dz-content {
    padding-left: 0;
    padding-top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .project-bx .dz-content {
    padding-top: 20px;
  }
}

.project-bx.left .dz-media {
  position: relative;
  text-align: center;
  margin-left: -135px;
  border-radius: 14px;
}
.project-bx.left .dz-media img {
  width: 100%;
  height: 100%;
  border-radius: 14px;
}
.project-bx.left .dz-media .shape-3 {
  position: absolute;
  bottom: 20%;
  left: -5%;
  width: 50px;
  height: auto;
}

@media only screen and (max-width: 1480px) {
  .project-bx.left .dz-media {
    margin-left: -50px;
  }
}

@media only screen and (max-width: 1280px) {
  .project-bx.left .dz-media {
    margin-left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .project-bx.left .dz-media .shape-3 {
    width: 50px;
    left: 0;
  }
}

.project-bx.right .dz-media {
  position: relative;
  text-align: center;
  margin-right: -135px;
}
.project-bx.right .dz-media img {
  border-radius: 14px;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1480px) {
  .project-bx.right .dz-media {
    margin-right: -50px;
  }
}

@media only screen and (max-width: 1280px) {
  .project-bx.right .dz-media {
    margin-right: 0;
  }
}

.dz-terms ul {
  padding-left: 20px;
}
.dz-terms ul li {
  list-style: disc;
  margin-bottom: 10px;
}
.dz-terms ul li::marker {
  color: #000;
}

.dz-terms .dz-terms-content {
  margin-bottom: 50px;
}
.dz-terms .dz-terms-content .title {
  margin-bottom: 12px;
}

.dz-terms-list {
  background: var(--gray);
  border-radius: var(--border-radius-base);
  padding: 20px 0;
  margin-right: 15px;
}
@media only screen and (max-width: 991px) {
  .dz-terms-list {
    margin-right: 0;
  }
}
.dz-terms-list ul {
  padding-left: 0;
}
.dz-terms-list ul li {
  display: block;
  margin-bottom: 0;
}
.dz-terms-list ul li a.nav-link {
  display: block;
  padding: 8px 25px;
  border-left: 5px solid;
  border-color: var(--gray);
  color: gray;
}
.dz-terms-list ul li.active a.nav-link {
  border-color: var(--primary);
}

.wizard-authentication {
  z-index: 1;
  position: absolute;
  top: 0;
  width: 100%;
}

.certificates-bx .dz-media {
  padding: 5px;
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.dzSubscribe .input-group .input-group-addon button {
  height: 100%;
}

.tag-donate.style-1 p {
  font-size: 13px;
  font-style: italic;
}
.tag-donate.style-1 p span {
  color: var(--primary);
}

.fund-tabs {
  margin-top: 30px;
}
.fund-tabs .nav-tabs {
  margin-bottom: 30px;
  border-width: 3px;
}
.fund-tabs .nav-tabs .nav-link {
  margin-bottom: -3px;
  border: 3px solid transparent;
  color: var(--bs-body-color);
  font-weight: 600;
}
.fund-tabs .nav-tabs .nav-link:hover {
  border-bottom-color: #dee2e6;
}
.fund-tabs .nav-tabs .nav-link.active {
  color: var(--primary);
  border-bottom-color: var(--primary) !important;
}
.fund-tabs .nav-tabs .nav-link.show {
  color: var(--primary);
  border-bottom-color: var(--primary) !important;
}

.refer-wraper {
  margin: 50px 0;
  padding: 30px 30px;
  background: var(--gray);
  border-radius: var(--border-radius-base);
}

.split-box {
  position: relative;
  overflow: hidden;
}
.split-box > div {
  opacity: 0;
  -webkit-transition: 0s 0.8s;
  -o-transition: 0s 0.8s;
  transition: 0s 0.8s;
}
.split-box:after,
.split-box:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.split-box:before {
  background-color: var(--primary);
  z-index: 1;
  -webkit-transform: translateX(-101%);
  -ms-transform: translateX(-101%);
  transform: translateX(-101%);
}
.split-box:after {
  background-color: var(--secondary);
  z-index: 2;
  -webkit-transform: translateX(-101%);
  -ms-transform: translateX(-101%);
  transform: translateX(-101%);
}
.split-box.split-active > div {
  opacity: 1;
}
.split-box.split-active:after {
  -webkit-animation: split-box-animation 1.2s 0.2s
    cubic-bezier(1, 0, 0.54, 0.99) forwards;
  animation: split-box-animation 1.2s 0.2s cubic-bezier(1, 0, 0.54, 0.99)
    forwards;
}
.split-box.split-active:before {
  -webkit-animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1)
    forwards;
  animation: split-box-animation 1.5s cubic-bezier(0.86, 0, 0.07, 1) forwards;
}

@-webkit-keyframes split-box-animation {
  0% {
    -webkit-transform: translateX(-101%);
    transform: translateX(-101%);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(101%);
    transform: translateX(101%);
  }
}

@keyframes split-box-animation {
  0% {
    -webkit-transform: translateX(-101%);
    transform: translateX(-101%);
  }
  50% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(101%);
    transform: translateX(101%);
  }
}

.i-false:after {
  content: none;
}

.blog-single {
  margin-bottom: 3.125rem;
}
.blog-single .dz-post-meta {
  margin-bottom: 1.25rem;
}
.blog-single .dz-post-text {
  padding-bottom: 5px;
}
.blog-single .dz-post-text:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}
.blog-single .dz-post-tags {
  margin-top: 1.25rem;
}
.blog-single .dz-post-media {
  border-radius: 0.25rem;
}
.blog-single.dz-card .post-tags {
  margin-bottom: 0;
}
.blog-single .dz-media img,
.blog-single .dz-post-media img {
  border-radius: var(--border-radius-base);
}
.blog-single .dz-title {
  font-size: 36px;
}
@media only screen and (max-width: 767px) {
  .blog-single .dz-title {
    font-size: 28px;
  }
}
@media only screen and (max-width: 575px) {
  .blog-single .dz-title {
    font-size: 24px;
  }
}
.blog-single.style-1 .dz-title {
  margin: 10px 0;
  font-weight: 700;
}
.blog-single.style-1 .btn-link {
  color: black;
  font-weight: 600;
  font-size: 15px;
}
.blog-single.style-1 .dz-media img {
  border-radius: var(--border-radius-base);
}
.blog-single.style-1 .dz-media .dz-badge-list {
  position: absolute;
  top: 20px;
  left: 20px;
}
.blog-single.style-1 .dz-media .dz-badge {
  background-color: var(--primary);
  color: #fff;
  border-radius: var(--border-radius-base);
  padding: 8px 15px;
  font-size: 12px;
  text-transform: uppercase;
}
.blog-single.style-1:hover .dz-media {
  border-color: var(--primary);
}
.blog-single.style-1:hover .btn-link {
  color: var(--primary);
}

.dz-post-text a {
  text-decoration: underline;
  box-shadow: inset 0 -10px 0 var(--rgba-primary-1);
}

.dz-post-text .widget_archive li a,
.dz-post-text .wp-block-latest-posts li a,
.dz-post-text .wp-block-categories-list li a,
.dz-post-text .wp-block-archives-list li a,
.dz-post-text .widget_categories li a,
.dz-post-text blockquote a,
.dz-post-text .wp-block-button__link {
  box-shadow: none;
  text-decoration: none;
}

.post-header {
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 575px) {
  .post-header {
    margin-bottom: 1.25rem;
  }
}
.post-header .dz-media img {
  min-height: 250px;
  object-fit: cover;
}
.post-header .dz-info {
  position: absolute;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 1%,
    rgba(0, 0, 0, 0.6) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 1%,
    rgba(0, 0, 0, 0.6) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 1%,
    rgba(0, 0, 0, 0.6) 100%
  );
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 100px 30px 30px 30px !important;
  width: 100%;
}
@media only screen and (max-width: 575px) {
  .post-header .dz-info {
    padding: 40px 15px 15px 15px !important;
  }
}
.post-header .dz-info .dz-title {
  color: #fff;
}
@media only screen and (max-width: 575px) {
  .post-header .dz-info .dz-title {
    font-size: 1.125rem;
  }
}
.post-header .dz-info .dz-meta > ul {
  justify-content: center;
}
.post-header .dz-info .dz-meta > ul > li {
  color: #fff;
}

.post-link-in {
  padding: 15px 50px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.post-link-in:hover {
  background: var(--primary);
  color: #fff;
}

.side-bar {
  padding-bottom: 1px;
}
@media only screen and (max-width: 991px) {
  .side-bar {
    padding-left: 0;
    padding-right: 0;
  }
}
.side-bar.left {
  padding-left: 0;
  padding-right: 20px;
}
@media only screen and (max-width: 991px) {
  .side-bar.left {
    padding-right: 0;
    padding-left: 0;
  }
}

.alignnone {
  margin: 0.3125rem 0 1.563rem 0;
}
.alignnoneimg,
.alignnone.wp-caption,
.alignnone.is-resized {
  margin: 0.3125rem 0 1.563rem 0;
}

.aligncenter {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}
.aligncenterimg,
.aligncenter.wp-caption,
.aligncenter.is-resized {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}

.alignright {
  float: right;
  margin: 0.3125rem 0 1.563rem 1.563rem;
}
.alignrightimg,
.alignright.wp-caption,
.alignright.is-resized {
  margin: 0.3125rem 0 1.563rem 1.563rem;
  float: right;
}

.alignleft {
  float: left;
  margin: 0.3125rem 1.563rem 1.563rem 0;
}
.alignleftimg,
.alignleft.wp-caption,
.alignleft.is-resized {
  margin: 0.3125rem 1.563rem 1.563rem 0;
  float: left;
}

.wp-caption {
  max-width: 100%;
  text-align: center;
}
.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 0;
}
.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}
.wp-caption p.wp-caption-text {
  font-size: 0.813rem;
  line-height: 1.125rem;
  margin: 0;
  padding: 0.625rem 0;
  text-align: left;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft,
blockquote {
  padding: 1.25rem 2rem 1.25rem 3rem;
  font-size: 0.938rem;
  color: var(--title);
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  line-height: 1.875rem;
  position: relative;
  clear: both;
  font-weight: 700;
  z-index: 1;
  border: 0;
}
@media only screen and (max-width: 991px) {
  .wp-block-quote.is-large,
  .wp-block-quote.is-style-large,
  blockquote.wp-block-quote,
  blockquote.wp-block-pullquote,
  blockquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft,
  blockquote {
    padding: 1.25rem 1.25rem 0.938rem 1.25rem;
    font-size: 0.813rem;
  }
}
.wp-block-quote.is-large cite,
.wp-block-quote.is-style-large cite,
blockquote.wp-block-quote cite,
blockquote.wp-block-pullquote cite,
blockquote.wp-block-pullquote.alignright cite,
blockquote.wp-block-pullquote.alignleft cite,
blockquote cite {
  font-style: normal;
  position: relative;
  display: block;
  margin-top: 10px;
  font-weight: 700;
  color: var(--secondary);
  line-height: 1.3;
  padding-left: 60px;
  font-family: var(--font-family-title);
}
.wp-block-quote.is-large cite:before,
.wp-block-quote.is-style-large cite:before,
blockquote.wp-block-quote cite:before,
blockquote.wp-block-pullquote cite:before,
blockquote.wp-block-pullquote.alignright cite:before,
blockquote.wp-block-pullquote.alignleft cite:before,
blockquote cite:before {
  position: absolute;
  content: "";
  width: 50px;
  height: 0.1px;
  top: 50%;
  transform: translateY(50%);
  left: 0;
  background: var(--secondary);
}
.wp-block-quote.is-large b,
.wp-block-quote.is-large strong,
.wp-block-quote.is-large .strong,
.wp-block-quote.is-style-large b,
.wp-block-quote.is-style-large strong,
.wp-block-quote.is-style-large .strong,
blockquote.wp-block-quote b,
blockquote.wp-block-quote strong,
blockquote.wp-block-quote .strong,
blockquote.wp-block-pullquote b,
blockquote.wp-block-pullquote strong,
blockquote.wp-block-pullquote .strong,
blockquote.wp-block-pullquote.alignright b,
blockquote.wp-block-pullquote.alignright strong,
blockquote.wp-block-pullquote.alignright .strong,
blockquote.wp-block-pullquote.alignleft b,
blockquote.wp-block-pullquote.alignleft strong,
blockquote.wp-block-pullquote.alignleft .strong,
blockquote b,
blockquote strong,
blockquote .strong {
  color: inherit;
}
.wp-block-quote.is-large.wp-block-pullquote.alignleft,
.wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
blockquote.wp-block-quote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft {
  margin: 0rem 1.563rem 1.563rem 0rem;
}
.wp-block-quote.is-large.wp-block-pullquote.alignright,
.wp-block-quote.is-style-large.wp-block-pullquote.alignright,
blockquote.wp-block-quote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright {
  margin: 0rem 0 1.563rem 1.563rem;
}
.wp-block-quote.is-large.style-1,
.wp-block-quote.is-style-large.style-1,
blockquote.wp-block-quote.style-1,
blockquote.wp-block-pullquote.style-1,
blockquote.wp-block-pullquote.alignright.style-1,
blockquote.wp-block-pullquote.alignleft.style-1,
blockquote.style-1 {
  background: #f3f4f8;
  border-radius: var(--border-radius-base);
  color: var(--primary);
  padding: 25px 40px;
  overflow: hidden;
}
@media only screen and (max-width: 575px) {
  .wp-block-quote.is-large.style-1,
  .wp-block-quote.is-style-large.style-1,
  blockquote.wp-block-quote.style-1,
  blockquote.wp-block-pullquote.style-1,
  blockquote.wp-block-pullquote.alignright.style-1,
  blockquote.wp-block-pullquote.alignleft.style-1,
  blockquote.style-1 {
    padding: 25px 30px;
  }
}
.wp-block-quote.is-large.style-1:after,
.wp-block-quote.is-style-large.style-1:after,
blockquote.wp-block-quote.style-1:after,
blockquote.wp-block-pullquote.style-1:after,
blockquote.wp-block-pullquote.alignright.style-1:after,
blockquote.wp-block-pullquote.alignleft.style-1:after,
blockquote.style-1:after {
  content: "";
  width: 8px;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--primary);
  position: absolute;
}
@media only screen and (max-width: 767px) {
  .wp-block-quote.is-large.style-1:after,
  .wp-block-quote.is-style-large.style-1:after,
  blockquote.wp-block-quote.style-1:after,
  blockquote.wp-block-pullquote.style-1:after,
  blockquote.wp-block-pullquote.alignright.style-1:after,
  blockquote.wp-block-pullquote.alignleft.style-1:after,
  blockquote.style-1:after {
    width: 5px;
  }
}
.wp-block-quote.is-large.style-1:before,
.wp-block-quote.is-style-large.style-1:before,
blockquote.wp-block-quote.style-1:before,
blockquote.wp-block-pullquote.style-1:before,
blockquote.wp-block-pullquote.alignright.style-1:before,
blockquote.wp-block-pullquote.alignleft.style-1:before,
blockquote.style-1:before {
  position: absolute;
  content: "\f10c";
  font-family: Flaticon;
  color: var(--primary);
  bottom: 15px;
  right: 25px;
  line-height: 1;
  opacity: 0.2;
  font-size: 60px;
  transform: rotate(180deg);
}
@media only screen and (max-width: 767px) {
  .wp-block-quote.is-large.style-1:before,
  .wp-block-quote.is-style-large.style-1:before,
  blockquote.wp-block-quote.style-1:before,
  blockquote.wp-block-pullquote.style-1:before,
  blockquote.wp-block-pullquote.alignright.style-1:before,
  blockquote.wp-block-pullquote.alignleft.style-1:before,
  blockquote.style-1:before {
    font-size: 45px;
    bottom: 20px;
    right: 20px;
  }
}

blockquote.wp-block-pullquote:not(.is-style-solid-color) {
  background: #5608e0;
}

.wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.3;
}

.dz-card.blog-single .dz-post-text blockquote p,
.dz-page-text blockquote p,
blockquote p {
  font-size: 20px;
  line-height: 1.6;
  color: var(--title);
  margin-bottom: 1rem;
  font-weight: 700;
  font-style: italic;
}
.dz-card.blog-single .dz-post-text blockquote p cite,
.dz-page-text blockquote p cite,
blockquote p cite {
  margin-top: 20px;
}
@media only screen and (max-width: 991px) {
  .dz-card.blog-single .dz-post-text blockquote p,
  .dz-page-text blockquote p,
  blockquote p {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 575px) {
  .dz-card.blog-single .dz-post-text blockquote p,
  .dz-page-text blockquote p,
  blockquote p {
    font-size: 1rem;
  }
}

.dz-page-text {
  padding-bottom: 30px !important;
}

.dz-card.blog-single .dz-post-text blockquote.style-1 p,
.dz-page-text blockquote.style-1 p,
blockquote.style-1 p {
  color: var(--title);
  margin-bottom: 1.5rem;
  font-family: var(--font-family-title);
}
@media only screen and (max-width: 991px) {
  .dz-card.blog-single .dz-post-text blockquote.style-1 p,
  .dz-page-text blockquote.style-1 p,
  blockquote.style-1 p {
    margin-bottom: 1.2rem;
  }
}
@media only screen and (max-width: 575px) {
  .dz-card.blog-single .dz-post-text blockquote.style-1 p,
  .dz-page-text blockquote.style-1 p,
  blockquote.style-1 p {
    margin-bottom: 1rem;
  }
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

.min-container,
.dz-page-text,
.dz-page-text + #comment-list,
.dz-page-text + #comment-list + .paginate-links {
  max-width: 51.875rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
}

.dz-card.blog-single.sidebar + #comment-list {
  padding: 0;
  max-width: 100%;
}

.max-container {
  max-width: 93.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.4375rem;
  padding-right: 3.4375rem;
}

.dz-page-text + #comment-list + .paginate-links,
.dz-page-text + #comment-list,
.dz-page-text {
  padding: 0;
}

.dz-page-text.sidebar {
  width: 100%;
  padding: 0;
  max-width: 100%;
}

.dz-page-text > ol,
.dz-page-text > ul,
.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul {
  list-style: none;
  margin-bottom: 1.875rem;
}

.dz-page-text > ol,
.dz-page-text > ul,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul,
.dz-card.blog-single .dz-post-text.text > ol,
.dz-card.blog-single .dz-post-text.text > ul {
  padding-left: 1.25rem;
}
.dz-page-text > ol ol,
.dz-page-text > ol ul,
.dz-page-text > ul ol,
.dz-page-text > ul ul,
.dz-card.blog-single .dz-post-text > ol ol,
.dz-card.blog-single .dz-post-text > ol ul,
.dz-card.blog-single .dz-post-text > ul ol,
.dz-card.blog-single .dz-post-text > ul ul,
.dz-card.blog-single .dz-post-text.text > ol ol,
.dz-card.blog-single .dz-post-text.text > ol ul,
.dz-card.blog-single .dz-post-text.text > ul ol,
.dz-card.blog-single .dz-post-text.text > ul ul {
  padding-left: 1.563rem;
}

.dz-page-text > ol li,
.dz-page-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text.text > ol li,
.dz-card.blog-single .dz-post-text.text > ul li {
  padding: 0.5rem 0.5rem;
  position: relative;
  list-style: inherit;
}

.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery {
  padding: 0;
  list-style: none;
}

.dz-page-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li {
  padding: 0;
  list-style: none;
  margin: 0.25rem;
}

.dz-page-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li:before {
  content: none;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  margin: 0 0.5rem 0.5rem 0;
}

.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
  margin-right: 0.5rem;
}

.content-spacious-full .wp-block-gallery {
  margin-left: calc(-12.5rem - (0.313rem / 2));
  margin-right: calc(-12.5rem - (0.313rem / 2));
  width: calc(100% + 25rem + 0.313rem);
  max-width: initial;
}

.alignfull,
.alignwide {
  margin-left: calc(-6.25rem - (0.313rem / 2));
  margin-right: calc(-6.25rem - (0.313rem / 2));
  width: calc(100% + 12.5rem + 0.313rem);
  max-width: initial;
}

.alignfull,
.alignfullwide {
  margin-left: calc(50% - 50vw - (var(--content-share-ml, 0px) / 2));
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
}

.dz-page-text h1,
.dz-page-text .h1,
.dz-post-text h1,
.dz-post-text .h1,
.thm-unit-test .dz-post-text h1,
.thm-unit-test .dz-post-text .h1 {
  margin-bottom: 1rem;
}

.dz-page-text h2,
.dz-page-text .h2,
.dz-post-text h2,
.dz-post-text .h2,
.thm-unit-test .dz-post-text h2,
.thm-unit-test .dz-post-text .h2 {
  margin-bottom: 1rem;
}

.dz-page-text h3,
.dz-page-text .h3,
.dz-post-text h3,
.dz-post-text .h3,
.thm-unit-test .dz-post-text h3,
.thm-unit-test .dz-post-text .h3 {
  margin-bottom: 1rem;
  font-weight: 700;
}

.dz-page-text h4,
.dz-page-text .h4,
.dz-post-text h4,
.dz-post-text .h4,
.thm-unit-test .dz-post-text h4,
.thm-unit-test .dz-post-text .h4 {
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.dz-page-text h5,
.dz-page-text .h5,
.dz-post-text h5,
.dz-post-text .h5,
.thm-unit-test .dz-post-text h5,
.thm-unit-test .dz-post-text .h5 {
  margin-bottom: 0.75rem;
}

.dz-page-text h6,
.dz-page-text .h6,
.dz-post-text h6,
.dz-post-text .h6,
.thm-unit-test .dz-post-text h6,
.thm-unit-test .dz-post-text .h6 {
  margin-bottom: 0.625rem;
}

.wp-block-code,
pre.wp-block-code,
pre {
  margin: 0 0 1.563rem;
  padding: 1.25rem;
  color: #fff;
  background-color: #212326;
  white-space: pre;
  font-size: 0.938rem;
  border-radius: 0.1875rem;
}

.dz-page-text,
.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li,
.dz-card.blog-single
  .dz-card.blog-single
  .dz-post-text
  p:not(.has-text-color):not(.has-text-align-center):not(
    .has-text-align-left
  ):not(.has-text-align-right) {
  font-size: 1rem;
  line-height: 1.7;
}

.wp-block-cover,
.wp-block-cover-image {
  color: #fff;
}

.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
  margin-bottom: 0;
}

.wp-block-quote.has-text-align-right {
  border-right: 0;
}

.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li {
  position: relative;
}

.dz-page-text ul > li:before,
.dz-page-text ol > li:before,
.dz-card.blog-single .dz-post-text ul > li:before,
.dz-card.blog-single .dz-post-text ol > li:before {
  content: "";
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  background: #050d36;
  left: -0.938rem;
  position: absolute;
  top: 1.1rem;
  border-radius: 4px;
}

.dz-page-text ul > li li:before,
.dz-page-text ol > li li:before,
.dz-card.blog-single .dz-post-text ul > li li:before,
.dz-card.blog-single .dz-post-text ol > li li:before {
  content: none;
}

.dz-page-text p,
.dz-card.blog-single .dz-post-text p {
  margin-bottom: 1.65rem;
}

.paginate-links {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 4rem 0;
}

.paginate-links a,
.paginate-links > span {
  margin: 0 0 0 0.625rem;
  position: relative;
  border: 0rem solid #6cc000;
  color: #1f2471;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  text-transform: capitalize;
  transition: all 500ms ease 0s;
  background: #e0e0e0;
  border-radius: 0.1875rem;
}

.wp-block-columns {
  margin-bottom: 0;
}

.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 1rem 1rem 0;
  position: relative;
}

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
  height: 100%;
  margin: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image figure,
  .wp-block-gallery .blocks-gallery-item figure {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
  }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image img,
  .wp-block-gallery .blocks-gallery-item img {
    width: auto;
  }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.3) 60%,
    transparent
  );
  bottom: 0;
  color: #fff;
  font-size: 0.813rem;
  max-height: 100%;
  overflow: auto;
  padding: 2.5rem 0.625rem 0.3125rem;
  position: absolute;
  text-align: center;
  width: 100%;
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
  display: inline;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .wp-block-gallery.is-cropped .blocks-gallery-item img {
    -o-object-fit: cover;
    flex: 1;
    height: 100%;
    object-fit: cover;
  }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc(50% - 0.5rem);
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0;
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%;
}

@media only screen and (max-width: 64rem) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
    max-width: 100% !important;
  }
  .alignwide .alignleft img {
    width: 100%;
  }
  .alignwide .alignleft {
    margin: 0.3125rem 0rem 1.563rem 0;
    float: none;
  }
}

@media (min-width: 37.5rem) {
  .wp-block-gallery.columns-3 .blocks-gallery-image,
  .wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(33.33333% - 0.666rem);
  }
  .wp-block-gallery.columns-4 .blocks-gallery-image,
  .wp-block-gallery.columns-4 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(25% - 0.75rem);
  }
  .wp-block-gallery.columns-5 .blocks-gallery-image,
  .wp-block-gallery.columns-5 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(20% - 0.8rem);
  }
  .wp-block-gallery.columns-6 .blocks-gallery-image,
  .wp-block-gallery.columns-6 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(16.66667% - 0.833rem);
  }
  .wp-block-gallery.columns-7 .blocks-gallery-image,
  .wp-block-gallery.columns-7 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(14.28571% - 0.857rem);
  }
  .wp-block-gallery.columns-8 .blocks-gallery-image,
  .wp-block-gallery.columns-8 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(12.5% - 0.875rem);
  }
  .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0;
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
  width: 100%;
}

.wp-block-image.alignfullwide img {
  border-radius: 0;
}

.wp-block-image img {
  border-radius: 0.625rem;
}

.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
}

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  max-width: 19.0625rem;
  width: 100%;
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  display: flex;
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
  justify-content: center;
}

.dz-page-text.sidebar .alignfull,
.dz-page-text.sidebar .alignfullwide,
.dz-page-text.sidebar .alignwide,
.dz-page-text.sidebar .wp-block-gallery,
.blog-single.dz-card.sidebar .alignfullwide,
.blog-single.dz-card.sidebar .alignwide,
.blog-single.dz-card.sidebar .dz-post-text .wp-block-gallery {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: initial;
}

.blog-overlap {
  background: #fff;
  margin-top: -8.75rem;
  padding: 1.25rem 1.25rem 0;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
}

.blog-single.dz-card.sidebar {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.dz-post-text table,
.dz-page-text table,
.wp-block-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  font-size: 1rem;
  margin-bottom: 1.875rem;
}

.wp-block-image figcaption,
.dz-post-text figcaption,
.dz-page-text figcaption {
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  margin-top: 0.625rem;
}

.dz-post-text table tbody tr:nth-of-type(odd),
.dz-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

.dz-post-text td,
.dz-post-text th,
.dz-page-text td,
.dz-page-text th,
.wp-block-table td,
.wp-block-table th {
  padding: 0.625rem 0.938rem;
  border: 0.0625rem solid #e4e4e4;
  border-right: 0;
  border-left: 0;
}

.wp-block-media-text {
  margin-bottom: 30px;
}
.wp-block-media-text .wp-block-media-text__content {
  padding: 0 1.875rem;
}

.wp-block-column ol li:before,
.wp-block-column ul li:before {
  content: none !important;
}

.wp-block-pullquote {
  padding: 1em 0;
  border-top: 0;
  border-bottom: 0;
}
.wp-block-pullquote cite {
  color: #fff;
}
.wp-block-pullquote.alignright,
.wp-block-pullquote.alignleft {
  padding: 0;
  border-top: 0;
  border-bottom: 0;
}
.wp-block-pullquote.alignright blockquote,
.wp-block-pullquote.alignleft blockquote {
  margin: 0;
}

.dz-post-text .wp-block-cover-image-text,
.dz-post-text .wp-block-cover-text,
.dz-post-text section.wp-block-cover-image h2,
.dz-post-text section.wp-block-cover-image .h2,
.dz-page-text .wp-block-cover-image-text,
.dz-page-text .wp-block-cover-text,
.dz-page-text section.wp-block-cover-image h2,
.dz-page-text section.wp-block-cover-image .h2 {
  color: #fff;
}

.dz-post-text .bootstrap-select .dropdown-menu li a,
.dz-page-text .bootstrap-select .dropdown-menu li a {
  box-shadow: none;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
}

.dz-post-text .wp-block-calendar a {
  box-shadow: none !important;
  text-decoration: unset;
}

.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar {
  margin-bottom: 2.5rem;
}

.admin-bar .is-fixed .main-bar {
  top: 1.875rem;
}

.wp-block-quote.is-style-large cite:before {
  display: none;
}

.post-password-form label {
  font-size: 1rem;
}

.post-password-form input[type="submit"] {
  padding: 0.625rem 1.563rem;
  background-color: var(--primary);
  border: none;
  height: 2.8125rem;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
}

.post-password-form input[type="submit"]:hover {
  background-color: var(--primary);
}

.post-password-form input[type="password"] {
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}

.wp-block-search .wp-block-search__button {
  background: var(--primary);
  border: 0;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.625rem 1.563rem;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}

.wp-block-search .wp-block-search__input {
  border: 0.0625rem solid #e1e6eb;
  height: 2.8125rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  outline: none;
}

.wp-block-tag-cloud a {
  padding: 0.5rem 0.625rem;
  background: var(--primary);
  font-size: 0.75rem;
  display: inline-block;
  margin: 0 0 0.3125rem;
  color: #fff;
  font-weight: 600;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  text-decoration: none;
  box-shadow: none;
}
.wp-block-tag-cloud a:hover {
  background: var(--primary-hover);
}

.wp-block-latest-comments {
  padding: 0 !important;
}
.wp-block-latest-comments .avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}
.wp-block-latest-comments
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-excerpt {
  margin-left: 4.375rem;
}
.wp-block-latest-comments
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-meta {
  color: var(--title);
  font-family: "Poppins", sans-serif;
  font-size: 1.063rem;
  font-weight: 500;
  line-height: 1.7;
  margin-bottom: 0.625rem;
}
.wp-block-latest-comments
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-meta
  a {
  color: var(--title);
  box-shadow: none;
  text-decoration: none;
}
.wp-block-latest-comments
  img
  + article
  .wp-block-latest-comments__comment
  .wp-block-latest-comments__comment-meta {
  margin-left: 4.375rem;
}
.wp-block-latest-comments .wp-block-latest-comments__comment-meta time {
  display: block;
  width: 100%;
  color: #9fa1a4;
  font-size: 0.813rem;
  font-weight: 400;
}
.wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  margin-bottom: 0.625rem !important;
}
.wp-block-latest-comments li {
  padding: 0 !important;
  border-bottom: 0.0625rem solid #eee;
  padding-bottom: 0.625rem !important;
}
.wp-block-latest-comments li:before {
  content: none !important;
}

.wp-block-latest-comments__comment-avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}

.comment-content.dz-page-text {
  max-width: 100%;
}

.wp-block-rss {
  padding: 0 !important;
}
.wp-block-rss .wp-block-rss__item {
  padding: 0.3125rem 0 !important;
  border-bottom: 0.0625rem solid #eee;
}
.wp-block-rss .wp-block-rss__item:before {
  content: none !important;
}
.wp-block-rss .wp-block-rss__item a {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  box-shadow: unset !important;
  font-weight: 600;
  color: var(--title);
  text-decoration: none;
}

@media only screen and (max-width: 75rem) {
  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: calc(-6.25rem - (0.625rem / 2));
    margin-right: calc(-6.25rem - (0.625rem / 2));
    width: calc(100% + 12.5rem + 0.625rem);
  }
}

@media only screen and (max-width: 61.9375rem) {
  .admin-bar .mo-left .header-nav {
    top: 1.875rem;
    height: calc(100vh - 1.875rem) !important;
  }
  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .dz-page-text,
  .dz-page-text ul li,
  .dz-page-text ol li,
  .dz-page-text p,
  .dz-card.blog-single .dz-post-text,
  .dz-card.blog-single .dz-post-text ul li,
  .dz-card.blog-single .dz-post-text ol li,
  .dz-card.blog-single .dz-post-text p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 48.875rem) {
  .admin-bar .is-fixed .main-bar {
    top: 2.8125rem;
  }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }
  .wp-block-media-text {
    display: block;
  }
  .wp-block-media-text .wp-block-media-text__media {
    margin-bottom: 0.938rem;
  }
  .wp-block-media-text .wp-block-media-text__content {
    padding: 0;
  }
}

@media only screen and (max-width: 37.5rem) {
  .admin-bar .is-fixed .main-bar {
    top: 0;
  }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }
  .admin-bar .mo-left .is-fixed .header-nav {
    top: 0;
    height: 100vh !important;
  }
}

.post-footer {
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.938rem 0 0;
}
.post-footer .dz-meta .tag-list {
  padding-bottom: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: capitalize;
  display: inline;
}
.post-footer .dz-meta ul li {
  margin-right: 0.3125rem;
  padding: 0;
  display: inline-block;
  color: #333333;
  font-weight: 500;
  font-size: 0.938rem;
  font-style: italic;
}
.post-footer .post-tag a {
  text-transform: capitalize;
  font-size: 0.938rem;
  color: #999;
  background: transparent;
  padding: 0;
  border: 0;
  line-height: 0.875rem;
  font-weight: 500;
}

.share-post li {
  display: inline-block;
}

.share-post a {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 2.1875rem;
  border: 0.0625rem solid #eee;
  display: block;
  text-align: center;
  line-height: 2.0625rem;
  color: #050d36;
}

.extra-blog {
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .comment-respond.style-1 .comment-reply-title {
    margin-bottom: 20px;
  }
}

.default-form.comment-respond .comment-reply-title,
.comments-area .comments-title,
.extra-blog .blog-title {
  font-size: 23px;
  font-weight: 800;
}

.comments-area .comment-list {
  margin-bottom: 60px;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list {
    margin-bottom: 40px;
  }
}
.comments-area .comment-list .default-form {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.comments-area .comment-list .default-form small,
.comments-area .comment-list .default-form .small {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}
.comments-area .comment-list .default-form small a,
.comments-area .comment-list .default-form .small a {
  color: #e10000;
}
.comments-area .comment-list .dz-page-text {
  padding-bottom: 0 !important;
  margin: 0;
}
.comments-area .comment-list > .comment .comment-body {
  position: relative;
  padding-left: 120px;
  margin-bottom: 30px;
  min-height: 95px;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 25px;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list > .comment .comment-body {
    padding-bottom: 15px;
    padding-left: 75px;
    margin-bottom: 20px;
  }
}
.comments-area .comment-list > .comment .comment-body .comment-author .avatar {
  position: absolute;
  left: 0;
  height: 100px;
  width: 100px;
  border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 767px) {
  .comments-area
    .comment-list
    > .comment
    .comment-body
    .comment-author
    .avatar {
    height: 60px;
    width: 60px;
  }
}
.comments-area .comment-list > .comment .comment-body .comment-author .fn {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: var(--font-family-title);
  color: var(--title);
  font-weight: 600;
  font-style: normal;
  margin-bottom: 5px;
  display: block;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list > .comment .comment-body .comment-author .fn {
    font-size: 1.125rem;
  }
}
.comments-area .comment-list > .comment .comment-body .comment-meta {
  margin-bottom: 5px;
}
.comments-area .comment-list > .comment .comment-body .comment-meta a {
  color: var(--secondary);
}
.comments-area .comment-list > .comment .comment-body p {
  margin-bottom: 10px;
}
.comments-area
  .comment-list
  > .comment
  .comment-body
  .reply
  .comment-reply-link {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--primary);
  display: inline-block;
  line-height: 1.3;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.comments-area
  .comment-list
  > .comment
  .comment-body
  .reply
  .comment-reply-link
  i {
  margin-right: 8px;
}
.comments-area .comment-list > .comment .children {
  padding-left: 120px;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list > .comment .children {
    padding-left: 75px;
  }
}
@media only screen and (max-width: 575px) {
  .comments-area .comment-list > .comment .children {
    padding-left: 30px;
  }
}

.comment-reply-title a {
  font-size: 16px;
  font-weight: 600;
}

.comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.comment-respond .comment-form p {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.comment-respond .comment-form p label {
  display: none;
}
.comment-respond .comment-form p textarea {
  height: 120px !important;
}
.comment-respond .comment-form .comment-form-comment {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.comment-respond .comment-form .comment-form-author,
.comment-respond .comment-form .comment-form-email,
.comment-respond .comment-form .comment-form-url {
  width: 50%;
}

@media only screen and (max-width: 575px) {
  .comment-respond .comment-form .comment-form-author,
  .comment-respond .comment-form .comment-form-email,
  .comment-respond .comment-form .comment-form-url {
    width: 100% !important;
  }
}

.dz-page-text [class*="galleryid-"],
.dz-post-text [class*="galleryid-"] {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}

.gallery .gallery-item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 0.3125rem;
  position: relative;
  box-sizing: border-box;
}
.gallery .gallery-item img {
  float: left;
  padding: 0 0rem;
  width: 100%;
  border: none !important;
}

.gallery .gallery-caption {
  font-size: 0.813rem;
  color: #707070;
  display: block;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5;
  padding: 0.5em 0;
  clear: both;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.post-password-form {
  position: relative;
  clear: both;
}
.post-password-form label {
  display: block;
  font-size: 1rem;
}
.post-password-form input[type="password"] {
  width: 100%;
  border: 0.0625rem solid #ebedf2;
  padding: 0.625rem 6.25rem 0.625rem 0.938rem;
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}
.post-password-form input[type="submit"] {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.625rem 1.25rem;
  background: var(--primary);
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
  height: 2.8125rem;
}
.post-password-form input[type="submit"]:hover {
  background: var(--primary-hover);
}

@media only screen and (max-width: 991px) {
  .side-bar.sticky-top {
    position: unset;
  }
}

.blog-post-banner {
  min-height: 300px;
  padding-top: 70px;
  padding-bottom: 70px;
}
.blog-post-banner .dz-title {
  color: #fff;
  font-size: 32px;
  margin-bottom: 20px;
}
.blog-post-banner .dz-meta {
  color: #fff;
}
.blog-post-banner .dz-meta ul {
  display: flex;
  opacity: 0.7;
}
.blog-post-banner .dz-meta ul li {
  margin-right: 20px;
}
.blog-post-banner .dz-meta ul li a {
  color: #fff;
}

.post-outside {
  margin-top: -120px;
}

.single-post .main-bar {
  border-bottom: 1px solid #eee;
}

.dz-load-more i:before,
.loadmore-btn i:before {
  -webkit-animation: unset !important;
  -moz-animation: unset !important;
  animation: unset !important;
}

.dz-share-post {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  border-top: 1px dashed #cccccc;
}
.dz-share-post .post-tags {
  display: flex;
  align-items: center;
}
.dz-share-post .post-tags .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--title);
  margin-right: 10px;
  font-family: var(--font-family-title);
}
.dz-share-post .post-tags a {
  border-radius: var(--border-radius-base);
  padding: 6px 12px;
  border: 1px solid #e1e1e1;
  line-height: 1.4;
  display: inline-block;
  color: inherit;
  font-size: 14px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-share-post .post-tags a i {
  color: var(--primary);
}
.dz-share-post .post-tags a:last-child {
  margin-right: 0;
}
.dz-share-post .post-tags a:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 12px 20px 0px var(--rgba-primary-3);
  color: #fff;
}
.dz-share-post .post-tags a:hover i {
  color: #fff;
}
.dz-share-post .dz-social-icon {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 991px) {
  .dz-share-post .dz-social-icon {
    margin-bottom: 15px;
  }
}
.dz-share-post .dz-social-icon .title {
  margin-right: 20px;
  margin-bottom: 0;
}
.dz-share-post .dz-social-icon ul {
  margin-left: -3px;
  margin-right: -3px;
}
.dz-share-post .dz-social-icon ul li {
  padding: 0 3px;
}
.dz-share-post .dz-social-icon ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  display: block;
  background-color: var(--primary);
  color: #fff;
  text-align: center;
  line-height: 35px;
  border-radius: var(--border-radius-base);
}
.dz-share-post .dz-social-icon ul li a:hover {
  color: #fff;
  background-color: var(--primary-hover);
}
@media only screen and (max-width: 991px) {
  .dz-share-post {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .dz-share-post {
    display: block;
  }
  .dz-share-post .post-tags {
    margin-bottom: 20px;
  }
}

#comment-list:empty + .paginate-links {
  margin-top: -30px;
}

.post-swiper {
  position: relative;
}
.post-swiper .prev-post-swiper-btn,
.post-swiper .next-post-swiper-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  border-radius: var(--border-radius-base);
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  background-color: #fff;
  color: var(--primary);
  z-index: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.post-swiper .prev-post-swiper-btn:hover,
.post-swiper .next-post-swiper-btn:hover {
  background-color: var(--primary);
  color: #fff;
}
.post-swiper .prev-post-swiper-btn {
  left: 10px;
}
.post-swiper .next-post-swiper-btn {
  right: 10px;
}

.blog-single .dz-media + .dz-info {
  padding: 30px 0 0;
}

.author-box {
  padding: 35px;
  background: #f3f4f8;
  border-radius: var(--border-radius-base);
}
.author-box .author-profile-info {
  display: flex;
}
.author-box .author-profile-info .author-profile-content {
  padding-left: 25px;
}
.author-box .author-profile-info .author-profile-content p {
  margin-bottom: 25px;
}
.author-box .author-profile-info .author-profile-content ul {
  padding: 0;
  margin: 0;
  margin-right: 5px;
  display: table;
  float: left;
}
.author-box .author-profile-info .author-profile-content ul li {
  padding: 0;
  margin: 0;
  margin-right: 10px;
  float: left;
}
.author-box .author-profile-info .author-profile-content ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  display: block;
  background-color: var(--primary);
  color: #fff;
  text-align: center;
  line-height: 35px;
  border-radius: var(--border-radius-base);
}
.author-box .author-profile-info .author-profile-content ul li a:hover {
  color: #fff;
  background-color: var(--primary-hover);
}
.author-box .author-profile-info .author-profile-pic {
  width: 120px;
  height: 120px;
  overflow: hidden;
  min-width: 120px;
  border-radius: var(--border-radius-base);
}
.author-box .author-profile-info .author-profile-pic img {
  width: 100%;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info {
    display: block;
    text-align: center;
  }
  .author-box .author-profile-info .author-profile-pic {
    width: 80px;
    height: 80px;
    min-width: 80px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .author-box .author-profile-info .author-profile-content {
    padding-left: 0;
    padding-right: 0;
  }
  .author-box .author-profile-info .author-profile-content ul {
    display: inline-block;
    float: none;
    margin-right: 0;
  }
  .author-box .author-profile-info .author-profile-content ul li {
    margin-right: 5px;
    margin-left: 5px;
  }
  .author-box .author-profile-info .author-profile-content ul li a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    background: var(--primary);
    color: #fff;
    vertical-align: middle;
    display: block;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
  }
  .author-box .author-profile-info .author-profile-content ul li a:hover {
    color: #fff;
    background: var(--primary-hover);
  }
}

.error-page {
  height: 100vh;
  padding: 50px;
  position: relative;
  background-color: var(--primary);
  background-blend-mode: luminosity;
  background-size: cover;
}
.error-page:before {
  content: "";
  position: absolute;
  opacity: 0.8;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
}
.error-page .error-inner {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 600px;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 100%;
}
@media only screen and (max-width: 575px) {
  .error-page .error-inner {
    width: 100%;
  }
}
.error-page .error-head {
  color: #fff;
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 1.4;
}
@media only screen and (max-width: 575px) {
  .error-page .error-head {
    font-size: 20px;
    margin-bottom: 30px;
  }
}
.error-page .dz-error {
  position: relative;
  color: #fff;
  font-size: 200px;
  letter-spacing: 28px;
  animation: dzError 1s infinite linear alternate-reverse;
  font-weight: 900;
  line-height: 200px;
  margin: auto;
}
@media only screen and (max-width: 991px) {
  .error-page .dz-error {
    font-size: 150px;
    line-height: 150px;
  }
}
@media only screen and (max-width: 575px) {
  .error-page .dz-error {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 5px;
  }
}
.error-page .dz-error::before,
.error-page .dz-error::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.error-page .dz-error::before {
  left: 2px;
  text-shadow: -2px 0 #f7cf47;
  clip: rect(44px, 450px, 56px, 0);
  animation: dzError1 5s infinite linear alternate-reverse;
}
.error-page .dz-error::after {
  left: -2px;
  text-shadow: -2px 0 #f7cf47, 2px 2px #f7cf47;
  clip: rect(44px, 450px, 56px, 0);
  animation: dzError2 5s infinite linear alternate-reverse;
}

@keyframes dzError1 {
  0% {
    clip: rect(296px, 9999px, 221px, 0);
    transform: skew(0.9deg);
  }
  2.5% {
    clip: rect(82px, 9999px, 486px, 0);
    transform: skew(0.918deg);
  }
  5% {
    clip: rect(222px, 9999px, 366px, 0);
    transform: skew(0.748deg);
  }
  7.5% {
    clip: rect(293px, 9999px, 278px, 0);
    transform: skew(0.75deg);
  }
  10% {
    clip: rect(27px, 9999px, 86px, 0);
    transform: skew(0.674deg);
  }
  12.5% {
    clip: rect(402px, 9999px, 53px, 0);
    transform: skew(0.626deg);
  }
  15% {
    clip: rect(423px, 9999px, 256px, 0);
    transform: skew(0.992deg);
  }
  17.5% {
    clip: rect(323px, 9999px, 361px, 0);
    transform: skew(0.302deg);
  }
  20% {
    clip: rect(104px, 9999px, 398px, 0);
    transform: skew(0.692deg);
  }
  22.5% {
    clip: rect(9px, 9999px, 490px, 0);
    transform: skew(0.2deg);
  }
  25% {
    clip: rect(251px, 9999px, 324px, 0);
    transform: skew(0.822deg);
  }
  27.5% {
    clip: rect(31px, 9999px, 63px, 0);
    transform: skew(0.366deg);
  }
  30% {
    clip: rect(135px, 9999px, 406px, 0);
    transform: skew(0.968deg);
  }
  32.5% {
    clip: rect(454px, 9999px, 499px, 0);
    transform: skew(0.07deg);
  }
  35% {
    clip: rect(390px, 9999px, 368px, 0);
    transform: skew(0.328deg);
  }
  37.5% {
    clip: rect(429px, 9999px, 118px, 0);
    transform: skew(0.924deg);
  }
  40% {
    clip: rect(345px, 9999px, 279px, 0);
    transform: skew(0.18deg);
  }
  42.5% {
    clip: rect(156px, 9999px, 265px, 0);
    transform: skew(0.854deg);
  }
  45% {
    clip: rect(269px, 9999px, 198px, 0);
    transform: skew(0.858deg);
  }
  47.5% {
    clip: rect(429px, 9999px, 429px, 0);
    transform: skew(0.814deg);
  }
  50% {
    clip: rect(122px, 9999px, 224px, 0);
    transform: skew(0.492deg);
  }
  52.5% {
    clip: rect(364px, 9999px, 493px, 0);
    transform: skew(0.206deg);
  }
  55% {
    clip: rect(379px, 9999px, 420px, 0);
    transform: skew(0.974deg);
  }
  57.5% {
    clip: rect(294px, 9999px, 379px, 0);
    transform: skew(0.856deg);
  }
  60% {
    clip: rect(9px, 9999px, 109px, 0);
    transform: skew(0.368deg);
  }
  62.5% {
    clip: rect(52px, 9999px, 418px, 0);
    transform: skew(0.586deg);
  }
  65% {
    clip: rect(360px, 9999px, 11px, 0);
    transform: skew(0.18deg);
  }
  67.5% {
    clip: rect(305px, 9999px, 232px, 0);
    transform: skew(0.976deg);
  }
  70% {
    clip: rect(15px, 9999px, 32px, 0);
    transform: skew(0.826deg);
  }
  72.5% {
    clip: rect(26px, 9999px, 469px, 0);
    transform: skew(0.272deg);
  }
  75% {
    clip: rect(208px, 9999px, 412px, 0);
    transform: skew(0.996deg);
  }
  77.5% {
    clip: rect(448px, 9999px, 495px, 0);
    transform: skew(0.216deg);
  }
  80% {
    clip: rect(410px, 9999px, 152px, 0);
    transform: skew(0.6deg);
  }
  82.5% {
    clip: rect(435px, 9999px, 76px, 0);
    transform: skew(0.352deg);
  }
  85% {
    clip: rect(30px, 9999px, 214px, 0);
    transform: skew(0.106deg);
  }
  87.5% {
    clip: rect(143px, 9999px, 232px, 0);
    transform: skew(0.336deg);
  }
  90% {
    clip: rect(479px, 9999px, 418px, 0);
    transform: skew(0.168deg);
  }
  92.5% {
    clip: rect(499px, 9999px, 398px, 0);
    transform: skew(0.036deg);
  }
  95% {
    clip: rect(412px, 9999px, 88px, 0);
    transform: skew(0.988deg);
  }
  97.5% {
    clip: rect(100px, 9999px, 221px, 0);
    transform: skew(0.57deg);
  }
}

@keyframes dzError2 {
  0% {
    clip: rect(81px, 9999px, 118px, 0);
    transform: skew(0.198deg);
  }
  2.5% {
    clip: rect(88px, 9999px, 426px, 0);
    transform: skew(0.236deg);
  }
  5% {
    clip: rect(7px, 9999px, 276px, 0);
    transform: skew(0.89deg);
  }
  7.5% {
    clip: rect(238px, 9999px, 311px, 0);
    transform: skew(0.764deg);
  }
  10% {
    clip: rect(131px, 9999px, 333px, 0);
    transform: skew(0.954deg);
  }
  12.5% {
    clip: rect(283px, 9999px, 74px, 0);
    transform: skew(0.206deg);
  }
  15% {
    clip: rect(377px, 9999px, 436px, 0);
    transform: skew(0.298deg);
  }
  17.5% {
    clip: rect(64px, 9999px, 228px, 0);
    transform: skew(0.47deg);
  }
  20% {
    clip: rect(359px, 9999px, 246px, 0);
    transform: skew(0.428deg);
  }
  22.5% {
    clip: rect(277px, 9999px, 230px, 0);
    transform: skew(0.558deg);
  }
  25% {
    clip: rect(329px, 9999px, 409px, 0);
    transform: skew(0.388deg);
  }
  27.5% {
    clip: rect(200px, 9999px, 306px, 0);
    transform: skew(0.076deg);
  }
  30% {
    clip: rect(59px, 9999px, 133px, 0);
    transform: skew(0.88deg);
  }
  32.5% {
    clip: rect(332px, 9999px, 416px, 0);
    transform: skew(0.69deg);
  }
  35% {
    clip: rect(423px, 9999px, 368px, 0);
    transform: skew(0.304deg);
  }
  37.5% {
    clip: rect(171px, 9999px, 225px, 0);
    transform: skew(0.652deg);
  }
  40% {
    clip: rect(214px, 9999px, 334px, 0);
    transform: skew(0.788deg);
  }
  42.5% {
    clip: rect(169px, 9999px, 154px, 0);
    transform: skew(0.75deg);
  }
  45% {
    clip: rect(77px, 9999px, 48px, 0);
    transform: skew(0.554deg);
  }
  47.5% {
    clip: rect(251px, 9999px, 339px, 0);
    transform: skew(0.784deg);
  }
  50% {
    clip: rect(251px, 9999px, 158px, 0);
    transform: skew(0.088deg);
  }
  52.5% {
    clip: rect(96px, 9999px, 360px, 0);
    transform: skew(0.716deg);
  }
  55% {
    clip: rect(135px, 9999px, 108px, 0);
    transform: skew(0.006deg);
  }
  57.5% {
    clip: rect(173px, 9999px, 405px, 0);
    transform: skew(0.018deg);
  }
  60% {
    clip: rect(463px, 9999px, 364px, 0);
    transform: skew(0.818deg);
  }
  62.5% {
    clip: rect(94px, 9999px, 5px, 0);
    transform: skew(0.67deg);
  }
  65% {
    clip: rect(114px, 9999px, 220px, 0);
    transform: skew(0.054deg);
  }
  67.5% {
    clip: rect(397px, 9999px, 168px, 0);
    transform: skew(0.782deg);
  }
  70% {
    clip: rect(340px, 9999px, 56px, 0);
    transform: skew(0.446deg);
  }
  72.5% {
    clip: rect(161px, 9999px, 34px, 0);
    transform: skew(0.634deg);
  }
  75% {
    clip: rect(183px, 9999px, 123px, 0);
    transform: skew(0.68deg);
  }
  77.5% {
    clip: rect(105px, 9999px, 391px, 0);
    transform: skew(0.818deg);
  }
  80% {
    clip: rect(423px, 9999px, 426px, 0);
    transform: skew(0.004deg);
  }
  82.5% {
    clip: rect(492px, 9999px, 363px, 0);
    transform: skew(0.53deg);
  }
  85% {
    clip: rect(197px, 9999px, 443px, 0);
    transform: skew(0.11deg);
  }
  87.5% {
    clip: rect(394px, 9999px, 36px, 0);
    transform: skew(0.15deg);
  }
  90% {
    clip: rect(477px, 9999px, 8px, 0);
    transform: skew(0.168deg);
  }
  92.5% {
    clip: rect(307px, 9999px, 311px, 0);
    transform: skew(0.562deg);
  }
  95% {
    clip: rect(346px, 9999px, 270px, 0);
    transform: skew(0.468deg);
  }
  97.5% {
    clip: rect(115px, 9999px, 133px, 0);
    transform: skew(0.032deg);
  }
}

@keyframes dzError {
  0% {
    transform: skew(3deg);
  }
  10% {
    transform: skew(2deg);
  }
  20% {
    transform: skew(-2deg);
  }
  30% {
    transform: skew(4deg);
  }
  40% {
    transform: skew(4deg);
  }
  50% {
    transform: skew(4deg);
  }
  60% {
    transform: skew(4deg);
  }
  70% {
    transform: skew(3deg);
  }
  80% {
    transform: skew(1deg);
  }
  90% {
    transform: skew(1deg);
  }
}

.coming-soon {
  height: 80vh;
  min-height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: var(--primary);
  background-color: var(--primary);
  background-blend-mode: luminosity;
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  padding: 60px;
  flex-direction: column;
  justify-content: space-between;
}
.coming-soon:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--primary);
  opacity: 0.6;
  z-index: -1;
}
.coming-soon .inner-content {
  padding-bottom: 70px;
}
.coming-soon .dz-head {
  font-size: 55px;
  margin-bottom: 10px;
  color: #fff;
  display: block;
  clear: both;
}
.coming-soon svg {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
}
.coming-soon svg path {
  fill: var(--primary);
}
.coming-soon p {
  font-size: 18px;
  max-width: 530px;
  color: #fff;
  margin-bottom: 30px;
}
.coming-soon .countdown {
  margin-bottom: 50px;
  margin-top: 30px;
}
.coming-soon .countdown .date {
  margin: 0 20px;
  display: inline-block;
  border-radius: 0;
}
.coming-soon .countdown .date .skew {
  position: relative;
}
.coming-soon .countdown .date span {
  display: block;
  font-weight: 500;
  color: #fff;
  border-radius: 6px;
  padding: 2px;
}
.coming-soon .countdown .date .time {
  margin: 0;
  font-weight: 700;
  font-size: 100px;
  color: #fff;
}
.coming-soon .logo-header {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
.coming-soon:after {
  content: attr(data-text);
  position: absolute;
  font-weight: 700;
  font-size: 250px;
  line-height: 1;
  right: 0px;
  color: #fff;
  opacity: 0.1;
  bottom: 0;
}
@media only screen and (max-width: 1280px) {
  .coming-soon {
    padding: 40px;
  }
  .coming-soon:after {
    font-size: 120px;
  }
}
@media only screen and (max-width: 991px) {
  .coming-soon .dz-head {
    font-size: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .coming-soon .countdown-timer .clock .val {
    font-size: 32px;
    line-height: 125px;
  }
  .coming-soon:after {
    font-size: 80px;
  }
  .coming-soon .inner-content {
    text-align: center;
  }
}
@media only screen and (max-width: 575px) {
  .coming-soon {
    padding: 20px;
    justify-content: center;
  }
  .coming-soon .inner-content {
    padding-bottom: 50px;
  }
  .coming-soon .countdown-timer .clock .val {
    font-size: 20px;
    line-height: 68px;
    margin-bottom: 10px;
  }
  .coming-soon .countdown-timer .clock .type-time {
    font-size: 13px;
  }
  .coming-soon p {
    font-size: 15px;
  }
  .coming-soon .logo-header {
    margin-bottom: 20px;
  }
  .coming-soon .dz-head {
    font-size: 32px;
  }
}

.inquiry-modal {
  padding: 0 15px;
}
.inquiry-modal .modal-dialog {
  max-width: 700px;
  display: flex;
  min-height: auto;
  background-color: #fff;
  align-items: stretch;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
@media only screen and (max-width: 575px) {
  .inquiry-modal .modal-dialog {
    display: block;
  }
}
.inquiry-modal .inquiry-adv {
  flex: 0 0 50%;
  max-width: 50%;
}
.inquiry-modal .inquiry-adv img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media only screen and (max-width: 575px) {
  .inquiry-modal .inquiry-adv {
    display: none;
  }
}
.inquiry-modal .modal-content {
  border: 0;
  border-radius: 0;
  background: #fff;
  padding: 0;
  flex-direction: unset;
  flex: unset;
  max-width: unset;
}
.inquiry-modal .modal-content .contact-modal {
  padding: 30px;
}
.inquiry-modal .modal-content .modal-body {
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .inquiry-modal .modal-content {
    padding: 25px;
  }
}
@media only screen and (max-width: 575px) {
  .inquiry-modal .modal-content {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.inquiry-modal .modal-header {
  border: 0;
  padding: 0;
  display: block;
  text-align: center;
}
.inquiry-modal .modal-header .modal-title {
  font-size: 24px;
  margin-bottom: 25px;
  line-height: 1.3;
  font-family: var(--font-family-title);
  text-align: center;
  width: 100%;
}
.inquiry-modal .modal-header i {
  color: var(--primary);
  font-size: 40px;
  line-height: 1;
  display: inline-block;
}
.inquiry-modal .btn-close {
  margin: 0;
  position: absolute;
  right: 5px;
  top: 0px;
  color: #000;
  font-weight: 100;
  text-shadow: none;
  opacity: 1;
  font-size: 40px;
  padding: 0;
  height: 40px;
  line-height: 40px;
  width: 40px;
  border: 0;
  background: transparent;
}

.clock-item {
  position: relative;
  margin: 0 10px;
}
@media only screen and (max-width: 575px) {
  .clock-item {
    margin: 0 5px;
  }
}

.countdown-timer {
  padding-bottom: 30px;
}
.countdown-timer .clock {
  display: flex;
}
.countdown-timer .clock .text {
  position: absolute;
  top: 0;
  width: 100%;
}
.countdown-timer .clock .type-time {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 0;
}
.countdown-timer .clock .kineticjs-content {
  position: relative;
}
.countdown-timer .clock .kineticjs-content:after {
  content: "";
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  z-index: -1;
}
.countdown-timer .clock .val {
  font-size: 70px;
  color: #fff;
  width: 100%;
  line-height: 155px;
  text-align: center;
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  .countdown-timer .clock .clock-canvas {
    height: 130px;
    width: 130px;
  }
}
@media only screen and (max-width: 575px) {
  .countdown-timer .clock .clock-canvas {
    height: 70px;
    width: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .countdown-timer .clock {
    justify-content: center;
  }
}

.contact-bx-wrapper {
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-base);
  margin: -60px 150px 0;
  background-color: #fff;
  padding: 80px;
  position: relative;
  z-index: 1;
}
.contact-bx-wrapper label {
  font-size: 18px;
  display: block;
}
.contact-bx-wrapper .form-control,
.contact-bx-wrapper .default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .contact-bx-wrapper .btn.dropdown-toggle,
.contact-bx-wrapper
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link,
.default-select.style-2
  .contact-bx-wrapper
  .dropdown-toggle.wp-block-button__link,
.contact-bx-wrapper .default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .contact-bx-wrapper .btn.dropdown-toggle,
.contact-bx-wrapper
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link,
.default-select.style-3
  .contact-bx-wrapper
  .dropdown-toggle.wp-block-button__link,
.contact-bx-wrapper .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .contact-bx-wrapper select,
.contact-bx-wrapper .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .contact-bx-wrapper select,
.contact-bx-wrapper .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .contact-bx-wrapper input,
.contact-bx-wrapper .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .contact-bx-wrapper input,
.contact-bx-wrapper .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .contact-bx-wrapper input,
.contact-bx-wrapper .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .contact-bx-wrapper textarea {
  height: 60px;
  border-bottom: 2px solid #ebe5db;
  color: var(--title);
  background: #fff;
  border-width: 0 0 2px 0;
  padding: 10px 0;
  font-weight: 700;
  border-radius: 0;
  -webkit-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s;
}
.contact-bx-wrapper .form-control::placeholder,
.contact-bx-wrapper .default-select.style-2 .btn.dropdown-toggle::placeholder,
.default-select.style-2 .contact-bx-wrapper .btn.dropdown-toggle::placeholder,
.contact-bx-wrapper
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-2
  .contact-bx-wrapper
  .dropdown-toggle.wp-block-button__link::placeholder,
.contact-bx-wrapper .default-select.style-3 .btn.dropdown-toggle::placeholder,
.default-select.style-3 .contact-bx-wrapper .btn.dropdown-toggle::placeholder,
.contact-bx-wrapper
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3
  .contact-bx-wrapper
  .dropdown-toggle.wp-block-button__link::placeholder,
.contact-bx-wrapper .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .contact-bx-wrapper select::placeholder,
.contact-bx-wrapper .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .contact-bx-wrapper select::placeholder,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-author
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .contact-bx-wrapper
  input::placeholder,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-email
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .contact-bx-wrapper
  input::placeholder,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-url
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .contact-bx-wrapper
  input::placeholder,
.contact-bx-wrapper .comment-respond .comment-form p textarea::placeholder,
.comment-respond .comment-form p .contact-bx-wrapper textarea::placeholder {
  color: var(--title);
}
.contact-bx-wrapper .form-control:focus,
.contact-bx-wrapper .default-select.style-2 .btn.dropdown-toggle:focus,
.default-select.style-2 .contact-bx-wrapper .btn.dropdown-toggle:focus,
.contact-bx-wrapper
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-2
  .contact-bx-wrapper
  .dropdown-toggle.wp-block-button__link:focus,
.contact-bx-wrapper .default-select.style-3 .btn.dropdown-toggle:focus,
.default-select.style-3 .contact-bx-wrapper .btn.dropdown-toggle:focus,
.contact-bx-wrapper
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3
  .contact-bx-wrapper
  .dropdown-toggle.wp-block-button__link:focus,
.contact-bx-wrapper .wp-block-categories-dropdown select:focus,
.wp-block-categories-dropdown .contact-bx-wrapper select:focus,
.contact-bx-wrapper .wp-block-archives-dropdown select:focus,
.wp-block-archives-dropdown .contact-bx-wrapper select:focus,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-author
  input:focus,
.comment-respond
  .comment-form
  p.comment-form-author
  .contact-bx-wrapper
  input:focus,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-email
  input:focus,
.comment-respond
  .comment-form
  p.comment-form-email
  .contact-bx-wrapper
  input:focus,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-url
  input:focus,
.comment-respond
  .comment-form
  p.comment-form-url
  .contact-bx-wrapper
  input:focus,
.contact-bx-wrapper .comment-respond .comment-form p textarea:focus,
.comment-respond .comment-form p .contact-bx-wrapper textarea:focus,
.contact-bx-wrapper .form-control:active,
.contact-bx-wrapper .default-select.style-2 .btn.dropdown-toggle:active,
.default-select.style-2 .contact-bx-wrapper .btn.dropdown-toggle:active,
.contact-bx-wrapper
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:active,
.default-select.style-2
  .contact-bx-wrapper
  .dropdown-toggle.wp-block-button__link:active,
.contact-bx-wrapper .default-select.style-3 .btn.dropdown-toggle:active,
.default-select.style-3 .contact-bx-wrapper .btn.dropdown-toggle:active,
.contact-bx-wrapper
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:active,
.default-select.style-3
  .contact-bx-wrapper
  .dropdown-toggle.wp-block-button__link:active,
.contact-bx-wrapper .wp-block-categories-dropdown select:active,
.wp-block-categories-dropdown .contact-bx-wrapper select:active,
.contact-bx-wrapper .wp-block-archives-dropdown select:active,
.wp-block-archives-dropdown .contact-bx-wrapper select:active,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-author
  input:active,
.comment-respond
  .comment-form
  p.comment-form-author
  .contact-bx-wrapper
  input:active,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-email
  input:active,
.comment-respond
  .comment-form
  p.comment-form-email
  .contact-bx-wrapper
  input:active,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-url
  input:active,
.comment-respond
  .comment-form
  p.comment-form-url
  .contact-bx-wrapper
  input:active,
.contact-bx-wrapper .comment-respond .comment-form p textarea:active,
.comment-respond .comment-form p .contact-bx-wrapper textarea:active,
.contact-bx-wrapper .form-control:hover,
.contact-bx-wrapper .default-select.style-2 .btn.dropdown-toggle:hover,
.default-select.style-2 .contact-bx-wrapper .btn.dropdown-toggle:hover,
.contact-bx-wrapper
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link:hover,
.default-select.style-2
  .contact-bx-wrapper
  .dropdown-toggle.wp-block-button__link:hover,
.contact-bx-wrapper .default-select.style-3 .btn.dropdown-toggle:hover,
.default-select.style-3 .contact-bx-wrapper .btn.dropdown-toggle:hover,
.contact-bx-wrapper
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link:hover,
.default-select.style-3
  .contact-bx-wrapper
  .dropdown-toggle.wp-block-button__link:hover,
.contact-bx-wrapper .wp-block-categories-dropdown select:hover,
.wp-block-categories-dropdown .contact-bx-wrapper select:hover,
.contact-bx-wrapper .wp-block-archives-dropdown select:hover,
.wp-block-archives-dropdown .contact-bx-wrapper select:hover,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-author
  input:hover,
.comment-respond
  .comment-form
  p.comment-form-author
  .contact-bx-wrapper
  input:hover,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-email
  input:hover,
.comment-respond
  .comment-form
  p.comment-form-email
  .contact-bx-wrapper
  input:hover,
.contact-bx-wrapper
  .comment-respond
  .comment-form
  p.comment-form-url
  input:hover,
.comment-respond
  .comment-form
  p.comment-form-url
  .contact-bx-wrapper
  input:hover,
.contact-bx-wrapper .comment-respond .comment-form p textarea:hover,
.comment-respond .comment-form p .contact-bx-wrapper textarea:hover {
  border-bottom: 2px solid var(--primary);
}
.contact-bx-wrapper textarea.form-control,
.contact-bx-wrapper .default-select.style-2 textarea.btn.dropdown-toggle,
.default-select.style-2 .contact-bx-wrapper textarea.btn.dropdown-toggle,
.contact-bx-wrapper
  .default-select.style-2
  textarea.dropdown-toggle.wp-block-button__link,
.default-select.style-2
  .contact-bx-wrapper
  textarea.dropdown-toggle.wp-block-button__link,
.contact-bx-wrapper .default-select.style-3 textarea.btn.dropdown-toggle,
.default-select.style-3 .contact-bx-wrapper textarea.btn.dropdown-toggle,
.contact-bx-wrapper
  .default-select.style-3
  textarea.dropdown-toggle.wp-block-button__link,
.default-select.style-3
  .contact-bx-wrapper
  textarea.dropdown-toggle.wp-block-button__link,
.contact-bx-wrapper .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .contact-bx-wrapper textarea {
  height: 120px;
  color: var(--title);
  padding: 15px 0;
}
.contact-bx-wrapper textarea.form-control::placeholder,
.contact-bx-wrapper
  .default-select.style-2
  textarea.btn.dropdown-toggle::placeholder,
.default-select.style-2
  .contact-bx-wrapper
  textarea.btn.dropdown-toggle::placeholder,
.contact-bx-wrapper
  .default-select.style-2
  textarea.dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-2
  .contact-bx-wrapper
  textarea.dropdown-toggle.wp-block-button__link::placeholder,
.contact-bx-wrapper
  .default-select.style-3
  textarea.btn.dropdown-toggle::placeholder,
.default-select.style-3
  .contact-bx-wrapper
  textarea.btn.dropdown-toggle::placeholder,
.contact-bx-wrapper
  .default-select.style-3
  textarea.dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3
  .contact-bx-wrapper
  textarea.dropdown-toggle.wp-block-button__link::placeholder,
.contact-bx-wrapper .comment-respond .comment-form p textarea::placeholder,
.comment-respond .comment-form p .contact-bx-wrapper textarea::placeholder {
  color: var(--title);
}

.map-iframe.style-1 {
  height: 600px;
  margin-top: -140px;
}
.map-iframe.style-1 iframe {
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 1480px) {
  .map-iframe.style-1 {
    margin-top: -100px;
  }
}
@media only screen and (max-width: 991px) {
  .map-iframe.style-1 {
    height: 500px;
    margin-top: -70px;
  }
}
@media only screen and (max-width: 767px) {
  .map-iframe.style-1 {
    height: 400px;
  }
}
@media only screen and (max-width: 575px) {
  .map-iframe.style-1 {
    height: 250px;
  }
}

.contact-info.style-1 {
  margin-bottom: 30px;
  margin-right: 100px;
  margin-top: -500px;
  max-width: 900px;
}
@media only screen and (max-width: 991px) {
  .contact-info.style-1 {
    margin-right: 30px;
    margin-left: 30px;
    margin-top: -100px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-info.style-1 {
    margin-right: 0;
    margin-left: 0;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .contact-info.style-1 .title {
    font-size: 24px;
  }
}

.contact-info.style-2 {
  margin-left: auto;
}
@media only screen and (max-width: 991px) {
  .contact-info.style-2 {
    margin-top: -40px;
  }
}
@media only screen and (max-width: 575px) {
  .contact-info.style-2 {
    margin-top: -20px;
  }
}

/* Error Page */
.under-construction {
  overflow: hidden;
  padding: 100px;
  height: 100vh;
  vertical-align: middle;
  width: 100vw;
  position: relative;
  text-align: center;
  background-color: #fff;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.under-construction .dz-head {
  color: #050d36;
  margin-bottom: 15px;
  line-height: 1.1;
  font-size: 65px;
  font-weight: 700;
  z-index: 1;
  position: relative;
}
.under-construction .logo-header {
  width: 100%;
  height: auto;
  margin-bottom: 25px;
  z-index: 2;
  position: relative;
}
.under-construction p {
  font-size: 30px;
  color: black;
  line-height: 1.4;
  z-index: 1;
  position: relative;
}
.under-construction .construction-img {
  height: auto;
  width: 40%;
  right: 7%;
  top: 45%;
  z-index: -1;
  position: absolute;
  transform: translatey(-50%);
}
.under-construction .under-progress {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  max-width: 700px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  position: relative;
}
.under-construction .under-progress .progress-bar {
  height: 25px;
  font-size: 14px;
  background-color: var(--primary);
}
.under-construction .shape-1,
.under-construction .shape-2,
.under-construction .shape-3,
.under-construction .shape-4,
.under-construction .shape-5,
.under-construction .shape-6,
.under-construction .shape-7,
.under-construction .shape-8 {
  position: absolute;
}
.under-construction .shape-1 {
  width: 80px;
  left: 0;
  top: 0;
}
.under-construction .shape-2 {
  width: 80px;
  left: 50px;
  bottom: 50px;
}
.under-construction .shape-3 {
  right: -4px;
  top: -3px;
  width: 350px;
}
.under-construction .shape-4 {
  left: 50%;
  top: 50px;
  width: 60px;
}
.under-construction .shape-5 {
  bottom: 10%;
  right: 10%;
}
.under-construction .shape-6 {
  right: 20%;
  bottom: 60%;
}
.under-construction .shape-7 {
  bottom: 50px;
  right: 50%;
}
.under-construction .shape-8 {
  left: 10%;
  top: 50%;
}
.under-construction .shape-10 {
  width: 60px;
  position: absolute;
  left: 25%;
  top: 10%;
}
.under-construction .shape-9 {
  width: 60px;
  position: absolute;
  right: -3px;
  bottom: 30px;
}
.under-construction .shape-11 {
  width: 15%;
  position: absolute;
  left: -6px;
  bottom: -3px;
}
@media only screen and (max-width: 991px) {
  .under-construction .dz-head {
    font-size: 42px;
  }
  .under-construction p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 575px) {
  .under-construction {
    padding: 30px;
  }
  .under-construction .dz-head {
    font-size: 32px;
  }
  .under-construction p {
    font-size: 16px;
  }
  .under-construction .under-progress {
    margin-top: 20px;
  }
  .under-construction .under-progress .progress-bar {
    height: 20px;
  }
  .under-construction .shape-1,
  .under-construction .shape-2,
  .under-construction .shape-4,
  .under-construction .shape-5,
  .under-construction .shape-7,
  .under-construction .shape-8,
  .under-construction .shape-10 {
    width: 40px;
  }
  .under-construction .shape-3 {
    width: 120px;
  }
  .under-construction .shape-9 {
    width: 30px;
  }
}

.fundraiser-single {
  margin-bottom: 50px;
}
.fundraiser-single .fundraiser-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  margin-top: 30px;
}
.fundraiser-single .fundraiser-bottom li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

.fundraiser-gallery-wrapper {
  margin-bottom: 30px;
}
.fundraiser-gallery-wrapper .dz-media {
  overflow: hidden;
  border-radius: var(--border-radius-base);
}
.fundraiser-gallery-wrapper .fundraiser-gallery-thumb {
  margin-top: 10px;
}
.fundraiser-gallery-wrapper .fundraiser-gallery-thumb .dz-media {
  cursor: pointer;
}
.fundraiser-gallery-wrapper
  .fundraiser-gallery-thumb
  .swiper-slide.swiper-slide-thumb-active
  .dz-media {
  opacity: 0.5;
}
.fundraiser-gallery-wrapper .swiper-button-prev,
.fundraiser-gallery-wrapper .swiper-button-next {
  position: absolute;
  top: 50%;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 20px;
  display: block;
  border-radius: var(--border-radius-base);
  height: 50px;
  width: 50px;
  line-height: 50px;
  color: #fff;
  margin: 0 20px;
  text-align: center;
  transform: translateY(-50%);
}
.fundraiser-gallery-wrapper .swiper-button-prev:hover,
.fundraiser-gallery-wrapper .swiper-button-next:hover {
  background-color: var(--primary);
}
.fundraiser-gallery-wrapper .swiper-button-prev::after,
.fundraiser-gallery-wrapper .swiper-button-next::after {
  font-size: 1.125rem;
  font-weight: 900;
}
.fundraiser-gallery-wrapper .swiper-button-next {
  right: 0;
  left: auto;
}
.fundraiser-gallery-wrapper .swiper-button-prev {
  left: 0;
  right: auto;
}

.select-drop .select-drop-btn {
  padding: 12px 20px;
  background-color: #fff;
  border: 1px solid #e1e1e1 !important;
  border-radius: var(--border-radius-base);
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.select-drop .select-drop-btn i {
  font-weight: 600;
  color: var(--primary);
}
.select-drop .select-drop-btn.style-2 {
  background-color: var(--rgba--primary-1);
  font-size: 14px;
}

.select-drop .dropdown-menu .dropdown-item:hover {
  background-color: var(--primary);
  color: #fff;
}

.form-wizard.dz-form-step .nav-wizard {
  padding: 0;
}
.form-wizard.dz-form-step .nav-wizard > div {
  padding-left: 0;
  padding-right: 0;
}
.form-wizard.dz-form-step .nav-wizard > div [class^="ConnectorContainer"] {
  display: none;
}
.form-wizard.dz-form-step
  .nav-wizard
  > div
  .StepMain-0-2-3
  .StepButtonContent-0-2-5 {
  font-size: 1.125rem;
}
.form-wizard.dz-form-step .nav-wizard > div:last-child .nav-link {
  border-right: 0 !important;
}
.form-wizard.dz-form-step .nav-wizard .nav-link {
  width: 100%;
  border-radius: 0;
  padding: 30px 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.form-wizard.dz-form-step .nav-wizard .nav-link .StepButtonContent-0-2-5 {
  color: var(--primary);
}
.form-wizard.dz-form-step .nav-wizard .nav-link.completed {
  background-color: var(--secondary);
  border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.form-wizard.dz-form-step
  .nav-wizard
  .nav-link.completed
  .StepButtonContent-0-2-5 {
  color: #fff;
}
.form-wizard.dz-form-step .nav-wizard .nav-link.active {
  border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
}
.form-wizard.dz-form-step
  .nav-wizard
  .nav-link.active
  .StepButtonContent-0-2-5 {
  color: #fff;
}

.form-wizard.dz-form-step .toolbar .btn,
.form-wizard.dz-form-step .toolbar .wp-block-button__link {
  color: #fff;
}

.find-bx {
  border-radius: var(--border-radius-base);
  margin-bottom: 20px;
  max-width: 1280px;
  padding: 20px 30px 20px 0;
  margin-top: -50px;
  box-shadow: 0px 25px 50px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 99;
}
.find-bx .btn-light:hover {
  color: var(--title) !important;
}
.find-bx .row > * {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.find-bx .row > *:last-child {
  border-right: 0;
}
.find-bx .input-group-text {
  font-size: 24px;
  padding-right: 5px;
}
.find-bx .default-select {
  border: 0 !important;
  padding: 0;
}
.find-bx .default-select .dropdown-toggle {
  background-color: transparent !important;
  color: var(--title);
  font-size: 18px;
  font-weight: 600;
  height: 60px;
  line-height: 60px;
  padding: 0 10px 0 40px;
  border: 0 !important;
}
@media only screen and (max-width: 991px) {
  .find-bx .default-select .dropdown-toggle {
    padding: 0 15px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .find-bx .default-select .dropdown-toggle {
    padding: 0 10px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .find-bx .default-select:after {
    right: 15px;
  }
}
.find-bx .form-control,
.find-bx .default-select.style-2 .btn.dropdown-toggle,
.default-select.style-2 .find-bx .btn.dropdown-toggle,
.find-bx .default-select.style-2 .dropdown-toggle.wp-block-button__link,
.default-select.style-2 .find-bx .dropdown-toggle.wp-block-button__link,
.find-bx .default-select.style-3 .btn.dropdown-toggle,
.default-select.style-3 .find-bx .btn.dropdown-toggle,
.find-bx .default-select.style-3 .dropdown-toggle.wp-block-button__link,
.default-select.style-3 .find-bx .dropdown-toggle.wp-block-button__link,
.find-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .find-bx select,
.find-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .find-bx select,
.find-bx .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .find-bx input,
.find-bx .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .find-bx input,
.find-bx .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .find-bx input,
.find-bx .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .find-bx textarea {
  background: #fff;
  border-width: 0;
  color: #000;
  font-size: 18px;
  height: 60px;
}
.find-bx .form-control:focus,
.find-bx .default-select.style-2 .btn.dropdown-toggle:focus,
.default-select.style-2 .find-bx .btn.dropdown-toggle:focus,
.find-bx .default-select.style-2 .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-2 .find-bx .dropdown-toggle.wp-block-button__link:focus,
.find-bx .default-select.style-3 .btn.dropdown-toggle:focus,
.default-select.style-3 .find-bx .btn.dropdown-toggle:focus,
.find-bx .default-select.style-3 .dropdown-toggle.wp-block-button__link:focus,
.default-select.style-3 .find-bx .dropdown-toggle.wp-block-button__link:focus,
.find-bx .wp-block-categories-dropdown select:focus,
.wp-block-categories-dropdown .find-bx select:focus,
.find-bx .wp-block-archives-dropdown select:focus,
.wp-block-archives-dropdown .find-bx select:focus,
.find-bx .comment-respond .comment-form p.comment-form-author input:focus,
.comment-respond .comment-form p.comment-form-author .find-bx input:focus,
.find-bx .comment-respond .comment-form p.comment-form-email input:focus,
.comment-respond .comment-form p.comment-form-email .find-bx input:focus,
.find-bx .comment-respond .comment-form p.comment-form-url input:focus,
.comment-respond .comment-form p.comment-form-url .find-bx input:focus,
.find-bx .comment-respond .comment-form p textarea:focus,
.comment-respond .comment-form p .find-bx textarea:focus {
  border: none;
}
.find-bx .form-control:active,
.find-bx .default-select.style-2 .btn.dropdown-toggle:active,
.default-select.style-2 .find-bx .btn.dropdown-toggle:active,
.find-bx .default-select.style-2 .dropdown-toggle.wp-block-button__link:active,
.default-select.style-2 .find-bx .dropdown-toggle.wp-block-button__link:active,
.find-bx .default-select.style-3 .btn.dropdown-toggle:active,
.default-select.style-3 .find-bx .btn.dropdown-toggle:active,
.find-bx .default-select.style-3 .dropdown-toggle.wp-block-button__link:active,
.default-select.style-3 .find-bx .dropdown-toggle.wp-block-button__link:active,
.find-bx .wp-block-categories-dropdown select:active,
.wp-block-categories-dropdown .find-bx select:active,
.find-bx .wp-block-archives-dropdown select:active,
.wp-block-archives-dropdown .find-bx select:active,
.find-bx .comment-respond .comment-form p.comment-form-author input:active,
.comment-respond .comment-form p.comment-form-author .find-bx input:active,
.find-bx .comment-respond .comment-form p.comment-form-email input:active,
.comment-respond .comment-form p.comment-form-email .find-bx input:active,
.find-bx .comment-respond .comment-form p.comment-form-url input:active,
.comment-respond .comment-form p.comment-form-url .find-bx input:active,
.find-bx .comment-respond .comment-form p textarea:active,
.comment-respond .comment-form p .find-bx textarea:active {
  border: none;
}
.find-bx .form-control::placeholder,
.find-bx .default-select.style-2 .btn.dropdown-toggle::placeholder,
.default-select.style-2 .find-bx .btn.dropdown-toggle::placeholder,
.find-bx
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-2
  .find-bx
  .dropdown-toggle.wp-block-button__link::placeholder,
.find-bx .default-select.style-3 .btn.dropdown-toggle::placeholder,
.default-select.style-3 .find-bx .btn.dropdown-toggle::placeholder,
.find-bx
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3
  .find-bx
  .dropdown-toggle.wp-block-button__link::placeholder,
.find-bx .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .find-bx select::placeholder,
.find-bx .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .find-bx select::placeholder,
.find-bx
  .comment-respond
  .comment-form
  p.comment-form-author
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .find-bx
  input::placeholder,
.find-bx .comment-respond .comment-form p.comment-form-email input::placeholder,
.comment-respond .comment-form p.comment-form-email .find-bx input::placeholder,
.find-bx .comment-respond .comment-form p.comment-form-url input::placeholder,
.comment-respond .comment-form p.comment-form-url .find-bx input::placeholder,
.find-bx .comment-respond .comment-form p textarea::placeholder,
.comment-respond .comment-form p .find-bx textarea::placeholder {
  color: #000;
}
@media only screen and (max-width: 991px) {
  .find-bx .form-control,
  .find-bx .default-select.style-2 .btn.dropdown-toggle,
  .default-select.style-2 .find-bx .btn.dropdown-toggle,
  .find-bx .default-select.style-2 .dropdown-toggle.wp-block-button__link,
  .default-select.style-2 .find-bx .dropdown-toggle.wp-block-button__link,
  .find-bx .default-select.style-3 .btn.dropdown-toggle,
  .default-select.style-3 .find-bx .btn.dropdown-toggle,
  .find-bx .default-select.style-3 .dropdown-toggle.wp-block-button__link,
  .default-select.style-3 .find-bx .dropdown-toggle.wp-block-button__link,
  .find-bx .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .find-bx select,
  .find-bx .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .find-bx select,
  .find-bx .comment-respond .comment-form p.comment-form-author input,
  .comment-respond .comment-form p.comment-form-author .find-bx input,
  .find-bx .comment-respond .comment-form p.comment-form-email input,
  .comment-respond .comment-form p.comment-form-email .find-bx input,
  .find-bx .comment-respond .comment-form p.comment-form-url input,
  .comment-respond .comment-form p.comment-form-url .find-bx input,
  .find-bx .comment-respond .comment-form p textarea,
  .comment-respond .comment-form p .find-bx textarea {
    font-size: 16px;
  }
}
.find-bx .btn,
.find-bx .wp-block-button__link {
  padding: 10px 15px;
}
.find-bx .btn i,
.find-bx .wp-block-button__link i {
  font-size: 24px;
  color: var(--primary);
}
@media only screen and (max-width: 991px) {
  .find-bx .btn i,
  .find-bx .wp-block-button__link i {
    font-size: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .find-bx .btn i,
  .find-bx .wp-block-button__link i {
    font-size: 16px;
  }
}
.find-bx .input-group {
  align-items: center;
  flex-wrap: unset;
  margin: 0;
}
.find-bx .input-group .form-control::placeholder,
.find-bx .input-group .default-select.style-2 .btn.dropdown-toggle::placeholder,
.default-select.style-2 .find-bx .input-group .btn.dropdown-toggle::placeholder,
.find-bx
  .input-group
  .default-select.style-2
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-2
  .find-bx
  .input-group
  .dropdown-toggle.wp-block-button__link::placeholder,
.find-bx .input-group .default-select.style-3 .btn.dropdown-toggle::placeholder,
.default-select.style-3 .find-bx .input-group .btn.dropdown-toggle::placeholder,
.find-bx
  .input-group
  .default-select.style-3
  .dropdown-toggle.wp-block-button__link::placeholder,
.default-select.style-3
  .find-bx
  .input-group
  .dropdown-toggle.wp-block-button__link::placeholder,
.find-bx .input-group .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .find-bx .input-group select::placeholder,
.find-bx .input-group .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .find-bx .input-group select::placeholder,
.find-bx
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-author
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-author
  .find-bx
  .input-group
  input::placeholder,
.find-bx
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-email
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-email
  .find-bx
  .input-group
  input::placeholder,
.find-bx
  .input-group
  .comment-respond
  .comment-form
  p.comment-form-url
  input::placeholder,
.comment-respond
  .comment-form
  p.comment-form-url
  .find-bx
  .input-group
  input::placeholder,
.find-bx .input-group .comment-respond .comment-form p textarea::placeholder,
.comment-respond .comment-form p .find-bx .input-group textarea::placeholder {
  color: #777;
}
@media only screen and (max-width: 575px) {
  .find-bx .input-group .form-control,
  .find-bx .input-group .default-select.style-2 .btn.dropdown-toggle,
  .default-select.style-2 .find-bx .input-group .btn.dropdown-toggle,
  .find-bx
    .input-group
    .default-select.style-2
    .dropdown-toggle.wp-block-button__link,
  .default-select.style-2
    .find-bx
    .input-group
    .dropdown-toggle.wp-block-button__link,
  .find-bx .input-group .default-select.style-3 .btn.dropdown-toggle,
  .default-select.style-3 .find-bx .input-group .btn.dropdown-toggle,
  .find-bx
    .input-group
    .default-select.style-3
    .dropdown-toggle.wp-block-button__link,
  .default-select.style-3
    .find-bx
    .input-group
    .dropdown-toggle.wp-block-button__link,
  .find-bx .input-group .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .find-bx .input-group select,
  .find-bx .input-group .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .find-bx .input-group select,
  .find-bx
    .input-group
    .comment-respond
    .comment-form
    p.comment-form-author
    input,
  .comment-respond
    .comment-form
    p.comment-form-author
    .find-bx
    .input-group
    input,
  .find-bx
    .input-group
    .comment-respond
    .comment-form
    p.comment-form-email
    input,
  .comment-respond
    .comment-form
    p.comment-form-email
    .find-bx
    .input-group
    input,
  .find-bx .input-group .comment-respond .comment-form p.comment-form-url input,
  .comment-respond .comment-form p.comment-form-url .find-bx .input-group input,
  .find-bx .input-group .comment-respond .comment-form p textarea,
  .comment-respond .comment-form p .find-bx .input-group textarea {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 991px) {
  .find-bx {
    padding: 10px;
    margin-top: -30px;
  }
}
@media only screen and (max-width: 767px) {
  .find-bx {
    padding: 0px 15px;
  }
  .find-bx .row > * {
    border-right: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .find-bx .row > *:last-child {
    border: 0;
  }
}

.tag-list {
  text-align: center;
  font-weight: 500;
}
.tag-list a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: var(--primary);
  margin: 0 10px;
}
.tag-list a.title {
  color: var(--title);
}
@media only screen and (max-width: 767px) {
  .tag-list a {
    font-size: 14px;
    margin: 0 5px;
  }
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  font-weight: 500;
}

.job-filter.style-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
@media only screen and (max-width: 575px) {
  .job-filter.style-1 {
    display: block;
    margin-bottom: 20px;
  }
}

.job-filter.style-2 {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 40px;
}
.job-filter.style-2 .clearfix {
  margin: 0 10px;
}
@media only screen and (max-width: 991px) {
  .job-filter.style-2 {
    justify-content: start;
  }
  .job-filter.style-2 .clearfix {
    margin: 10px;
  }
}
@media only screen and (max-width: 575px) {
  .job-filter.style-2 {
    display: block;
    margin-bottom: 20px;
  }
}

.select-drop-2 .select-drop-btn-2 {
  background-color: transparent !important;
  color: var(--title);
  font-size: 18px;
  font-weight: 500;
  height: 60px;
  line-height: 60px;
  padding: 0 10px 0 40px;
  border: 0 !important;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.select-drop-2 .select-drop-btn-2 i {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary);
}

.select-drop-2 .dropdown-menu .dropdown-item:first-child {
  background-color: var(--primary);
  color: #fff;
}

[data-primary="color-skin-1"] {
  --primary: #1b8271;
  --secondary: #ff7468;
  --secondary-hover: #f64f40;
  --primary-hover: #186f61;
  --primary-dark: #ea4619;
  --rgba-primary-1: rgba(27, 130, 113, 0.1);
  --rgba-primary-2: rgba(27, 130, 113, 0.2);
  --rgba-primary-3: rgba(27, 130, 113, 0.3);
  --rgba-primary-4: rgba(27, 130, 113, 0.4);
  --rgba-primary-5: rgba(27, 130, 113, 0.5);
  --rgba-primary-6: rgba(27, 130, 113, 0.6);
  --rgba-primary-7: rgba(27, 130, 113, 0.7);
  --rgba-primary-8: rgba(27, 130, 113, 0.8);
  --rgba-primary-9: rgba(27, 130, 113, 0.9);
  --title: #071c35;
}

[data-primary="color-skin-2"] {
  --primary: #d54400;
  --secondary: #f7cf47;
  --secondary-hover: #e2be3d;
  --primary-hover: #c03d00;
  --primary-dark: #9d3200;
  --rgba-primary-1: rgba(213, 68, 0, 0.1);
  --rgba-primary-2: rgba(213, 68, 0, 0.2);
  --rgba-primary-3: rgba(213, 68, 0, 0.3);
  --rgba-primary-4: rgba(213, 68, 0, 0.4);
  --rgba-primary-5: rgba(213, 68, 0, 0.5);
  --rgba-primary-6: rgba(213, 68, 0, 0.6);
  --rgba-primary-7: rgba(213, 68, 0, 0.7);
  --rgba-primary-8: rgba(213, 68, 0, 0.8);
  --rgba-primary-9: rgba(213, 68, 0, 0.9);
  --title: #003b4a;
}
[data-primary="color-skin-2"] .btn-secondary {
  color: var(--title);
}
[data-primary="color-skin-2"] .btn-secondary:hover {
  color: var(--title);
  border-color: var(--secondary-hover);
}
[data-primary="color-skin-2"] .bg-gray {
  background-color: rgba(0, 59, 74, 0.1);
}

[data-primary="color-skin-3"] {
  --primary: #01bfbd;
  --secondary: #f2f2f2;
  --primary-hover: #00a2a1;
  --primary-dark: #008584;
  --dark: #141818;
  --rgba-primary-1: rgba(1, 191, 189, 0.1);
  --rgba-primary-2: rgba(1, 191, 189, 0.2);
  --rgba-primary-3: rgba(1, 191, 189, 0.3);
  --rgba-primary-4: rgba(1, 191, 189, 0.4);
  --rgba-primary-5: rgba(1, 191, 189, 0.5);
  --rgba-primary-6: rgba(1, 191, 189, 0.6);
  --rgba-primary-7: rgba(1, 191, 189, 0.7);
  --rgba-primary-8: rgba(1, 191, 189, 0.8);
  --rgba-primary-9: rgba(1, 191, 189, 0.9);
  --title: #141818;
}
[data-primary="color-skin-3"] .breadcrumb-row ul {
  background: #faf8f2;
}
[data-primary="color-skin-3"] .breadcrumb-row ul li a {
  color: #050d36;
}
[data-primary="color-skin-3"] .breadcrumb-row ul .breadcrumb-item.active {
  color: var(--primary);
}
[data-primary="color-skin-3"] .dz-card.style-1 .dz-media .dz-badge {
  color: #050d36;
}
[data-primary="color-skin-3"]
  .progress-bx.style-1
  .progress
  .progress-bar.progress-bar-secondary {
  background-color: #1b8271;
}
[data-primary="color-skin-3"] .testimonial-1,
[data-primary="color-skin-3"] .icon-bx-wraper.style-1:before,
[data-primary="color-skin-3"] .form-wizard .nav-wizard .nav-link.active,
[data-primary="color-skin-3"]
  .form-wizard.dz-form-step
  .nav-wizard
  .nav-link.completed,
[data-primary="color-skin-3"] .dz-team.style-1 .team-social li a {
  background-color: #ff7468;
}



.curcomImg {
  height: 360px !important;
  object-fit: cover;
}
@media only screen and (max-width: 700px) {
  .curcomImg {
    height: 550px !important;
    object-fit: cover;
  }

  .dz-team.style-1 .dz-media:after {
    border: 2rem solid #fead18;
    top: -650px;
  }
}

@media only screen and (max-width: 455px) {
  .curcomImg {
    height: 450px !important;
    object-fit: cover;
  }

  .dz-team.style-1 .dz-media:after {
    border: 1.7rem solid #fead18;
    top: -530px;
  }
}

/* @media only screen and (max-width: 450px) {
  .curcomImg{
    height: 420px !important;
    object-fit: cover;
  }
} */

.cardImgOverlay {
  position: absolute;
  top:9.3rem;
  left:14.7rem;
}
@media only screen and (max-width: 700px) {
  .cardImgOverlay {
    
    top:9.3rem;
    left:18.7rem;
  }
}
@media only screen and (max-width: 455px) {
  .cardImgOverlay {
    
    top:9.3rem;
    left:14rem;
  }
}