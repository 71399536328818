.pagination-bx{
	display: flex;
	align-items: center;
	justify-content: center;
	.page-item{
		margin:0;
		.page-link{
			border: none;
			border-radius: var(--border-radius-base);
			width: 50px;
			height: 50px;
			line-height: 50px;
			background-color: transparent;
			color: var(--primary);
			font-weight: 700;
			text-align: center;
			padding: 0;

			&:hover{
				background-color: var(--primary);
				color: #fff;
				box-shadow: none;
			}
			&.active{
				background-color: var(--primary);
				box-shadow: none;
				color: #fff;
			}
			&.prev,
			&.next{
				background-color: var(--rgba-primary-1);
				&:hover{
					background-color: var(--primary);
					color: #fff;
					box-shadow: none;
				}
			}
			@include respond ('phone'){
				width: 40px;
				height: 40px;
				line-height: 40px;
				font-size: 14px;
			}
		}
	}
	.pagination{
		margin: 0 10px;
		background-color: var(--rgba-primary-1);
		border-radius: var(--border-radius-base);
	}
}
