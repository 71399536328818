// video-bx
.video-bx1{
	position: relative;
	min-width:170px;
	width:170px;
	height:170px;
	border-radius:var(--border-radius-base);
	overflow:hidden;
	img{
		height:100%;
		width:100%;
		object-fit:cover;
	}
	.video-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		.popup-youtube{
			display:inline-block;
			height:60px;
			width:60px;
			min-width:60px;
			line-height:60px;
			font-size: 20px;
			border-radius:60px;
			background-color:#fff;
			color:var(--primary);
			&:before{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				border: 1px solid #fff;
				-webkit-border-radius: 50%;
				-khtml-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
				border-radius: 100%;
				animation: animationSignal1;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal1;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
				z-index: -1;
				transform:  scale(1);
				-moz-transform:  scale(1);
				-webkit-transform:  scale(1);
				-ms-transform:  scale(1);
				-o-transform:  scale(1);
				-moz-transition:all 0.5;
				-o-transition:all 0.5;
				-webkit-transition:all 0.5;
				-ms-transition:all 0.5;
				transition:all 0.5;
			}
			&:after{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				border: 1px solid #fff;
				-webkit-border-radius: 100%;
				-khtml-border-radius: 100%;
				-moz-border-radius: 100%;
				-ms-border-radius: 100%;
				-o-border-radius: 100%;
				border-radius: 100%;
				animation: animationSignal2;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal2;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
				z-index: -1;
				transform:  scale(1);
				-moz-transform:  scale(1);
				-webkit-transform:  scale(1);
				-ms-transform:  scale(1);
				-o-transform:  scale(1);
				-moz-transition:all 0.5;
				-o-transition:all 0.5;
				-webkit-transition:all 0.5;
				-ms-transition:all 0.5;
				transition:all 0.5;
			}
		}
	}
	@include respond ('phone'){
		height:120px;
		width:120px;
		min-width:120px;
		.video-btn {
			.popup-youtube{
				height:40px;
				width:40px;
				min-width:40px;
				line-height:40px;
				font-size:16px;
			}
		}
	}
}
.video-bx2{
	height:500px;
	&.large{
		height:615px;
		border-radius:var(--border-radius-base);
		overflow:hidden;
		img{
			width:100%;
			height:100%;
			object-fit:cover;
		}
	}
	.video-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		&.sm{
			.popup-youtube{
				height:72px;
				width:72px;
				min-width:72px;
				line-height:72px;
				font-size: 22px;
			}
		}
		.popup-youtube{
			display:inline-block;
			height:100px;
			width:100px;
			min-width:100px;
			line-height:100px;
			font-size: 30px;
			border-radius:100px;
			background-color:var(--primary);
			color:#fff;
			&:before{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				border: 1px solid var(--primary);
				-webkit-border-radius: 50%;
				-khtml-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
				border-radius: 100%;
				animation: animationSignal1;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal1;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
				z-index: -1;
				transform:  scale(1);
				-moz-transform:  scale(1);
				-webkit-transform:  scale(1);
				-ms-transform:  scale(1);
				-o-transform:  scale(1);
				-moz-transition:all 0.5;
				-o-transition:all 0.5;
				-webkit-transition:all 0.5;
				-ms-transition:all 0.5;
				transition:all 0.5;
			}
			&:after{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				border: 1px solid var(--primary);
				-webkit-border-radius: 100%;
				-khtml-border-radius: 100%;
				-moz-border-radius: 100%;
				-ms-border-radius: 100%;
				-o-border-radius: 100%;
				border-radius: 100%;
				animation: animationSignal2;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal2;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
				z-index: -1;
				transform:  scale(1);
				-moz-transform:  scale(1);
				-webkit-transform:  scale(1);
				-ms-transform:  scale(1);
				-o-transform:  scale(1);
				-moz-transition:all 0.5;
				-o-transition:all 0.5;
				-webkit-transition:all 0.5;
				-ms-transition:all 0.5;
				transition:all 0.5;
			}
		}
	}
	@include respond ('tab-port'){
		height:330px;
		&.large{
			height:380px;
		}
	}
	@include respond ('phone'){
		height:200px;
		&.large{
			height:250px;
		}
		.video-btn,
		.video-btn.sm{
			.popup-youtube{
				height: 60px;
				width: 60px;
				min-width: 60px;
				line-height: 60px;
				font-size: 20px;
			}
		}
	}
}
.video-bx3{
	display: inline-block;
	.video-btn {
		.popup-youtube{
			display:inline-block;
			height:60px;
			width:60px;
			line-height: 52px;
			text-align: center;
			font-size: 18px;
			border-radius:60px;
			min-width:60px;
			border:4px solid #ececec;
			color:var(--secondary);
			&:before{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				border: 1px solid var(--secondary);
				-webkit-border-radius: 50%;
				-khtml-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
				border-radius: 100%;
				animation: animationSignal1;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal1;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
				z-index: -1;
				transform:  scale(1);
				-moz-transform:  scale(1);
				-webkit-transform:  scale(1);
				-ms-transform:  scale(1);
				-o-transform:  scale(1);
				-moz-transition:all 0.5;
				-o-transition:all 0.5;
				-webkit-transition:all 0.5;
				-ms-transition:all 0.5;
				transition:all 0.5;
			}
			&:after{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				border: 1px solid var(--secondary);
				-webkit-border-radius: 100%;
				-khtml-border-radius: 100%;
				-moz-border-radius: 100%;
				-ms-border-radius: 100%;
				-o-border-radius: 100%;
				border-radius: 100%;
				animation: animationSignal2;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal2;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
				z-index: -1;
				transform:  scale(1);
				-moz-transform:  scale(1);
				-webkit-transform:  scale(1);
				-ms-transform:  scale(1);
				-o-transform:  scale(1);
				-moz-transition:all 0.5;
				-o-transition:all 0.5;
				-webkit-transition:all 0.5;
				-ms-transition:all 0.5;
				transition:all 0.5;
			}
		}
	}
}

.video-bx4{
	.vedio-btn{
		bottom: 0;
		left: 0;
		width: 70px;
		height: 70px;
		font-size: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		background-color: var(--primary);
		
		i{
			color: #fff;
		}
	}
}

@keyframes animationSignal1 {
  /*Video Popup*/
	0% {
		opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.5);
		-moz-transform:  scale(1.5);
		-ms-transform:  scale(1.5);
		-o-transform:  scale(1.5);
		-webkit-transform:  scale(1.5);
		opacity: 0;
	}
}
@-webkit-keyframes animationSignal1 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.5);
		-moz-transform:  scale(1.5);
		-ms-transform:  scale(1.5);
		-o-transform:  scale(1.5);
		-webkit-transform:  scale(1.5);
		opacity: 0;
	}
}
@keyframes animationSignal2 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.9);
		-moz-transform:  scale(1.9);
		-webkit-transform:  scale(1.9);
		-ms-transform:  scale(1.9);
		-o-transform:  scale(1.9);
		opacity: 0;
	}
}
@-webkit-keyframes animationSignal2 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.9);
		-moz-transform:  scale(1.9);
		-webkit-transform:  scale(1.9);
		-ms-transform:  scale(1.9);
		-o-transform:  scale(1.9);
		opacity: 0;
	}
}

.video-wrapper{
	display:flex;
	align-items:center;
}