.seat {
  background-color: black;
  color: white;
  padding: 2px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: xx-small;
  text-align: center;
}

.seat:hover {
  background-color: rgb(31, 223, 31);
  color: blanchedalmond;
  border: 2px black;
  border-style: outset;
  transform: scale(1.2);
  cursor: pointer;
  transition: ease-in-out;
  transition-duration: 0.5s;
  transform-style: flat;
  text-align: center;
}

.selected {
  background-color: rgb(31, 223, 73) !important;
}
.sold {
  background-color: rgb(167, 169, 170) !important;
  cursor: not-allowed !important;
}

.hold {
  background-color: rgb(167, 169, 170) !important;
  cursor: not-allowed !important;
}

@media only screen and (max-width: 900px) {
  .seat {
    background-color: black;

    height: 20px;
    width: 20px;
    padding: 2px;
    font-size: 8px;
    text-align: center;
    align-content: center;
  }
}
@media only screen and (max-width: 500px) {
  .seat {
    background-color: black;

    height: 20px;
    width: 20px;
    padding: 2px;
    font-size: 8px;
    text-align: center;
  }
}
