.section-wrapper1{
	padding-top: 50px;
    margin-top: var(--spacing-bnr-two-sub);
	
	&:before{
		background-color: var(--primary);
		height: calc(100% - var(--spacing-bnr-two));
		width: 100%;
		position: absolute;
		left: 0;
		content: "";
		top: var(--spacing-bnr-two);
	}
	&:after{
		content: "";
		position: absolute;
		right: 0;
		top: var(--spacing-bnr-two-sub);
		bottom: 0;
		background-image: url(../images/background/bg10.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: calc((100% + 1140px) / 2);
		height: calc(100% + var(--spacing-bnr-two));
		background-color: #fff;
		z-index: 1;
		
		@include respond('tab-land'){
			width: calc((100% + 970px) / 2);
		}
		@media only screen and (max-width: 1191px){
			width: calc((100% + 750px) / 2);
		}
		@include respond('tab-port'){
			width: 100%;
		}
	}
	.section-head {
		padding-left: 80px;
		
		@include respond('tab-land'){
			padding-left: 150px;
		}
		@include respond('tab-port'){
			padding-left: 0;
		}
	}
	.container,
	.resize-wrapper,
	.container-fluid{
		position: relative;
		z-index: 2;
	}
	.resize-wrapper{
		margin-left: calc((100% - 1140px - 350px) / 2);
		overflow: hidden;
		
		.swiper{
			margin-right: -200px;
		}
		@include respond('wide-desktop'){
			margin-left: calc((100% - 970px - 350px) / 2);			
		}		
		@include respond('tab-land'){
			margin-left: calc((100% - 1140px) / 2);
		}
		@media only screen and (max-width: 1191px){
			margin-left: calc((100% - 930px) / 2);
		}
		@include respond('tab-port'){
			margin-left: 30px;
			margin-right: 30px;
			
			.swiper{
				margin-right: 0;
			}
		}
		@include respond('phone-land'){
			margin-left: 15px;
			margin-right: 15px;
		}
	}
}

.section-wrapper2{
	&:after{
		content: "";
		height: 150px;
		width: 100%;
		background-color: #fff;
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

.section-wrapper3{
	.container{
		max-width: 1050px;
	}
	.row > [class*="col-"]:nth-child(1),
	.row > [class*="col-"]:nth-child(3){
		margin-top: 35px;
	}
	.btn-bottom{
		margin-top: -30px;
	}
	.img-1{
		position: absolute;
		bottom: 30px;
		right: 0;
		filter: grayscale(1);
		width: 24vw;
		z-index: -1;
	}
	.img-2{
		position: absolute;
		width: 20vw;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	@include respond('laptop'){
		.img-1{
			width: 15vw;
		}
	}
	@include respond('tab-land'){
		.img-2{
			width: 10vw;
		}
	}
	@include respond('tab-port'){
		.img-2{
			display: none;
		}
		.row > [class*="col-"]:nth-child(1),
		.row > [class*="col-"]:nth-child(3),
		.btn-bottom{
			margin-top: 0;
		}
	}
	@include respond('phone'){
		.img-1{
			width: 25vw;
		}
	}
}

.section-wrapper4{
	margin-top: -150px;
	
	.form-wrapper{
		margin: -120px 0 30px -50px;
	}
	.section-inner{
		padding-left: 20px;
	}
	.img-1{
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: -1;
		filter: grayscale(1);
		width: 23vw;
	}
	.counter-text-wrapper{
		border-left: 3px solid #D9D9D9;
		padding-left: 30px;
	}
	.counter-text .counter-num{
		font-size: 35px;
		font-weight: 600;
		line-height: 1.4;
	}
	
	@include respond('tab-land'){
		.form-wrapper{
			margin: -80px 0 30px 0;
		}
	}
	@media only screen and (max-width: 1191px){
		.counter-text-wrapper{
			border-left: 0;
			padding-left: 0;
		}
		.section-inner{
			padding-left: 0;
		}
	}
	@include respond('tab-land'){
		margin-top: 0;

		.form-wrapper{
			margin: 0 0 30px;
		}
		.img-1 {
			width: 40vw;
		}
	}
	@include respond('phone'){
		.img-1 {
			width: 50vw;
		}
	}
}

.section-wrapper5{
	z-index: 2;
	.dz-media{
		width: 40vw;
		margin-bottom: -150px;
		float: left;
		padding-left: 100px;
		
		@include respond('laptop'){
			padding-left: 0;
		}
		@include respond('tab-port'){
			width: 100%;
			margin-bottom: 0;
		}
	}
}

.section-wrapper6{
	&:after{
		content: "";
		height: 50px;
		width: 100%;
		background-color: #fff;
		bottom: 0;
		left: 0;
		position: absolute;
	}
	.dz-media{
		padding-bottom: 50px;
	}
	@include respond('tab-port'){
		.dz-media{
			padding-bottom: 0;
		}
	}
}

.section-wrapper7{
	position: relative;
	.shape1{
		position: absolute;
		top: 0;
		right: 0;
	}
	.shape2{
		position: absolute;
		top: -60px;
		right: 0;
	}
	.shape3{
		position: absolute;
		bottom: 150px;
		left: 0;
	}
	.shape4{
		position: absolute;
		bottom: 100px;
		left: 0;
	}
	.shape5{
		position: absolute;
		top: 0;
		left: -40px;
	}
	.shape6{
		position: absolute;
		bottom: 140px;
		right: 100px;
		
	}
	.shape7{
		position: absolute;
		top: 0;
		right: 250px;
	}
	@include respond('tab-land'){
		.shape1,
		.shape2,
		.shape3,
		.shape4,
		.shape5,
		.shape6,
		.shape7{
			width: 60px;
		}
	}
	@include respond('phone-land'){
		.shape1,
		.shape2,
		.shape3,
		.shape4,
		.shape5,
		.shape6,
		.shape7{
			width: 35px;
		}
	}
}

.section-wrapper7{
	.section-inner{
		padding-left: 150px;
		padding-right: 150px;
		@include respond('tab-land'){
			padding-left: 50px;
			padding-right: 50px;
		}
		@include respond('phone'){
			padding-left: 15px;
			padding-right: 15px;
		}
	}
}

.gradient-white{
	&:before{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 28%, rgba(255,255,255,1) 29%, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 100%);
		background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 29%,rgba(255,255,255,1) 75%,rgba(255,255,255,0) 100%);
		background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 29%,rgba(255,255,255,1) 75%,rgba(255,255,255,0) 100%);
	}
	&:after{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.7) 34%, rgba(255,255,255,0.7) 67%, rgba(255,255,255,0.71) 68%, rgba(255,255,255,1) 97%, rgba(255,255,255,1) 99%);
		background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.7) 34%,rgba(255,255,255,0.7) 67%,rgba(255,255,255,0.71) 68%,rgba(255,255,255,1) 97%,rgba(255,255,255,1) 99%);
		background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.7) 34%,rgba(255,255,255,0.7) 67%,rgba(255,255,255,0.71) 68%,rgba(255,255,255,1) 97%,rgba(255,255,255,1) 99%);
	}
}

.section-pattren1{
	position: relative;
	
	img[class*="shape-"]{
		position: absolute;
		z-index: -1;
	}
	.shape-1{
		left: 7%;
		top: 20%;
		width: 65px;
	}
	.shape-2{	
		top: 10%;
		right: 10%;
	}
	.shape-3{
		right: 5%;
		bottom: 15%;
		width: 50px;
	}
	.shape-4{
		bottom: 10%;
		left: 5%;
		width: 50px;
	}
	.shape-5{
		right: 10%;
		top: 50%;
		width: 60px;
	}
	.shape-6{
		left: 10%;
		top: 50%;
		width: 60px;
	}
	@include respond('tab-land'){
		img[class*="shape-"]{
			width: 35px;
		}
	}
}

.map-wrapper{
	position: relative;
	z-index: 2;
	
	ul {
		@include respond('phone'){
			display: none;
		}
		li {
			position: absolute;
			&:after{	
				content: "";
				width: 18px;
				height: 18px;
				border-radius: 100%;
				background:var(--primary);
				display: block;
				border: 2px solid #fff;
			}
			
			div{
				position: absolute;
				padding: 15px 15px;
				background: var(--secondary);
				color: var(--title);
				line-height: 1.2;
				box-shadow: 0px 10px 30px 0px rgba(253, 114, 74,  0.10);
				font-weight: 500;
				font-size: 15px;
				border-radius: var(--border-radius-base);
				top: -20px;
				display: block;
				width: 150px;
				@include transitionMedium;
				top: -20px;
				right: 25px;
			    transform: scale(0);
				&:after{
					content: "";
					width: 20px;
					height: 20px;
					background: inherit;
					position: absolute;
					top: 20px;
					transform: rotate(45deg);
				}
				&.left{
					left: 35px;
					&:after{
						left: -8px;
					}
				}
				&.right{
					right: 35px;
					&:after{
						right: -8px;
					}
				}
				a{
					border-bottom: 2px solid var(--primary);
				}
			}
			&:hover > div{
			    transform: scale(1);
			}
			&:nth-child(1) {
				top: 15%;
				left: 65%;
				span {
				   top: 0;
				   left: 100%;
				   transform: translateY(-100%) scale(0);
				   transform-origin: left bottom; 
				}
				&.show {
					span {
						transform: translateY(-100%) scale(1);
					}
				}
			}
			&:nth-child(2) {
				top: 40%;
				left: 10%;
				span {
				   transform: translate(-100%,-100%) scale(0);
					bottom: 0;
					left: 0;
					transform-origin: right bottom; 
				}
				&.show {
					span {
						transform: translate(-100%,-100%) scale(1);
					}
				}
			}
			&:nth-child(3) {
				top: 60%;
				left: 50%;
				span {
					top: 0;
					left: 100%;
					transform: translateY(-100%) scale(0);
					transform-origin: left bottom; 
				}
				&.show {
					span {
						transform: translateY(-100%) scale(1);
					}
				}
			}
			@include respond('tab-land'){
				div{
					top: 35px;
					right: -20px;
					
					&:after{
						right: 20px;
						top: -9px;
					}
					&.left{
						left: -20px;
						&:after{
							left: 20px;
						}
					}
					&.right{
						right: -20px;
						&:after {
							right: 20px;
						}
					}
				}
			}
			@include respond('tab-land'){
				div{
					&.right{
						right: auto;
						left: -20px;
						&:after {
							right: auto;
							left: 20px;
						}
					}
				}
			}
		}
	}
}

.map-wrapper1{
	.shape-1,
	.shape-2,
	.shape-3{
		position: absolute;
		z-index: -1;
	}
	.shape-1{
		left: 45%;
		bottom: 30%;
	}
	.shape-2{
		left: -10px;
		bottom: 35%;
	}
	.shape-3{
		bottom: 30%;
		left: 15%;
		width: 60px;
	}
}