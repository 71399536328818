.countDown {
  font-size: 6px !important;
  color: #416bdb !important;
  font-style: italic !important;
  font-weight: bolder !important;
}
.countdown_element {
  display: inline-block !important;
  margin: 0px 6px !important;
  padding: 0px 18px !important;
  /* background-color: #f2f2f2 !important; */
  line-height: 0.7;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 50px !important;

  /* font-weight: bold !important; */
}
.letters {
  font-size: 20px !important;
}

@media (max-width: 768px) {
  /* Your CSS styles for small screens (e.g., smartphones) go here */
  .countDown {
    font-size: 8px !important;
  }
  .countdown_element {
    font-size: 24px !important;
  }
  .letters {
    font-size: 16px !important;
  }
}
@media (min-width: 100px) and (max-width: 500px) {
  /* Your CSS styles for small screens (e.g., smartphones) go here */
  .countDown {
    font-size: 6px !important;
  }
  .countdown_element {
    font-size: 18px !important;
  }
  .letters {
    font-size: 10px !important;
  }
}
.landscape {
  display: inline-block;
}
.landscape img {
  /* height: 200px; */
  height: auto;
  width: 100%;
}
/* @media (max-width: 400px) {
  .landscape img {
    height: 200px;
    width: 100%;
  }
} */
/* .portrait {
} */

@media (max-width: 575.98px) {
  /* Your CSS styles for extra-small screens go here */
  .border-setting {
    border-top: 1px solid;
  }
  .border-setting > div > h2 {
    font-size: 20px;
  }
}

/* Small Screens (sm) */
@media (min-width: 576px) and (max-width: 991.98px) {
  /* Your CSS styles for small screens go here */
  .border-setting {
    border-top: 1px solid;
  }
  .border-setting > div > h2 {
    font-size: 20px;
  }
}
@media (min-width: 992px) {
  .border-setting {
    border-left: 1px solid;
  }
  .border-setting > div > h2 {
    font-size: 30;
  }
  .para {
    font-size: 20px;
  }
}
